import { message } from 'antd';
import React from 'react';
import { useMutation, useQueryClient,useQuery } from 'react-query';
import { useNavigate,useParams } from 'react-router-dom';

import { createCourse,useGetCourse, useGetCourseAttendances} from '../../../api/courses';
// import { createCourseAttendances} from '../../../api/attendances';
import { createClassReplaces} from '../../../api/classReplaces';
import { Course } from '../../../interfaces/course';
import ClassReplacesForm from './classReplaces/Form';
import { PAGE_SIZE } from '../../../shared/constants';
import { getCoachesList } from '../../../api/coaches';

const ClassReplacementNewPage: React.FC = () => {
  // const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: createMutate } = useMutation(createClassReplaces, {
    onSuccess: async () => {
      message.info('Create Class Replacement successfully!');
      queryClient.invalidateQueries({
        queryKey: ['course', 'list'],
      });
      navigate(`/admin/classReplaces`);
    },
    onError: () => {
      message.error('Create Class Replacement failed! Please try again');
    },
    onSettled: () => {
      queryClient.prefetchQuery({
        // queryKey: ['course', 'list', { start: 0, count: PAGE_SIZE, filter: undefined }],
        // queryFn: () => useGetCourseAttendances(Number(id)),
      });
    },
  });

  const handleSubmit = async (values: any) => {
    createMutate(values);
  };

  return (
    <div className="courses-container">
      <div className="page-container">
        <div className="page-content">
          <div className="page-content-header">
            <h2 className="title-page">Add New Course Attendances</h2>
          </div>
          <ClassReplacesForm 
          handleSubmit={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default ClassReplacementNewPage;
