import React from 'react';
import { User } from '../../../interfaces/user';
import { humanizeText } from '../../../utils/global';
import TableBase, { Props as TableProps } from './Base';

type Props = TableProps & {
  withAction?(_: any): void;
  dataSource?: any[];
};

const ParentsTable: React.FC<Props> = ({ dataSource, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: 'Join Date',
      dataIndex: 'joinDate',
      width: 150,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 150,
    },
    {
      title: 'Contact',
      dataIndex: 'contact',
      width: 150,
    },
    {
      title: 'Name',
      width: 150,
      render: (user: User) => {
        return (
          <span className="text-capitalize">
            {user?.firstName} {user?.lastName}
          </span>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      width: 150,
      render: (isActive: boolean) => {
        return (
          <span className={`table-status ${isActive ? 'active' : 'inactive'}`}>{isActive ? 'Active' : 'Inactive'}</span>
        );
      },
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 50,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default ParentsTable;
