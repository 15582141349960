import {message,Spin } from 'antd';
import React, {useState} from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { PAGE_SIZE } from '../../../shared/constants';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetClassReplaces, deleteClassReplaces, getClassReplacesList } from '../../../api/classReplaces';
import { QueryParams } from '../../../interfaces';
import PrimaryButton from '../../../shared/components/buttons/PrimaryButton';
import SecondaryButton from '../../../shared/components/buttons/SecondaryButton';
import CancelIcon from '../../../shared/svgs/CancelIcon';
import DangerButton from '../../../shared/components/buttons/DangerButton';
import DeleteForeverIcon from '../../../shared/svgs/DeleteForeverIcon';
import EditIcon from '../../../shared/svgs/EditIcon';

const ClassReplacementsDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [queryParams] = useState<QueryParams>({ start: 0, count: PAGE_SIZE });
  const { data: clasRepalce, isLoading } = useGetClassReplaces(Number(id));

  const handleClickDelete = async () => {
    await mutationDelete(Number(id));
    queryClient.invalidateQueries({
      queryKey: ['classReplaces', 'list'],
    });
    navigate(`/admin/classReplaces`);
  };

  const { mutateAsync: mutationDelete } = useMutation(deleteClassReplaces, {
    onSuccess: async () => {
      message.info('Deleted!');
      queryClient.invalidateQueries({
        queryKey: ['classReplaces', 'list'],
      });
      navigate(`/admin/classReplaces`);
    },
    onError: () => {
      message.error('The action is failed! Please try again');
    },
    onSettled: () => {
      queryClient.prefetchQuery({
        queryKey: ['classReplaces', 'list', { start: 0, count: PAGE_SIZE, filter: undefined }],
        queryFn: () => getClassReplacesList({ start: 0, count: PAGE_SIZE, filter: undefined }),
      });
    },
  });

  return (
    <Spin spinning={isLoading}>
  <div id="user-details-container">
    <div className="page-container">
      <div className="page-content">
        <div className="page-content-header">
          <h2 className="title-page">Class Replace Details</h2>
        </div>
        <div className="page-header">
          <div></div>
          {/* <div className="page-header-actions">
            <Link to={`/admin/attendances/${courseAttendances?.id}/new`}>
              <PrimaryButton label="Create Attendances" icon={<AddCircleOutlineIcon />} />
            </Link>
          </div> */}
        </div>
        <div className="details-container">
          <div className="item">
            <label>Student Name</label>
            <span className="value">{clasRepalce?.student?.chineseName}</span>
          </div>
          <div className="item">
            <label>Absent Date</label>
            <span className="value">
              {clasRepalce?.absentCourse?.date}({clasRepalce?.absentCourse?.day}){clasRepalce?.absentCourse?.startAt}{clasRepalce?.absentCourse?.endAt}
            </span>
          </div>
          <div className="item">
            <label>Replace Date</label>
            <span className="value">
            {clasRepalce?.replaceCourse?.date}({clasRepalce?.replaceCourse?.day}){clasRepalce?.replaceCourse?.startAt}{clasRepalce?.replaceCourse?.endAt}
            </span>
          </div>
          <div className="item">
            <label>Replace status</label>
            <span className="value">
            <span style={{width:'100%',color:'#ffff',background: `${clasRepalce?.isDone ? 'green' : 'red'}`}} className={`table-status ${clasRepalce?.isDone ? 'done' : 'inactive'}`}>{clasRepalce?.isDone ? 'Done' : 'Pending'}</span>
            </span>
          </div>
          <div className="item">
            <label>Assign Status</label>
            <span className="value">
            <span style={{width:'100%',color:'#ffff',background: `${clasRepalce?.isAssigned ? 'green' : 'red'}`}} className={`table-status ${clasRepalce?.isAssigned ? 'Assigned' : 'inactive'}`}>{clasRepalce?.isAssigned ? 'Assigned' : 'Pending'}</span>
            </span>
          </div>
        </div>
        
      </div>
    
      <div className="page-content-footer"> 
              <Link to={`/admin/classReplaces/${id}/edit`}>
                <PrimaryButton label="Edit" icon={<EditIcon />} />
              </Link>
              <DangerButton label="Delete" onOk={handleClickDelete} icon={<DeleteForeverIcon />} />
              <Link to={`/admin/classReplaces`}>
                <SecondaryButton label="Cancel" icon={<CancelIcon />} />
              </Link>
            </div>
          </div>
  </div>
</Spin>
);
};

export default ClassReplacementsDetailsPage;
