import { pathToUrl } from '../utils/router';
import { Attendances } from '../interfaces/attendances';
import { StudentAttendances } from '../interfaces/studentAttendances';
import { GetListResponse } from '../interfaces';
import { useFetch, usePost } from '../shared/reactQuery';
import { api } from '../shared/api';
import { handleFilterParams } from '../utils/global';

export const useGetStudentAttendances = (id: number) => useFetch<StudentAttendances>(`studentAttendances/${id}/attendances`);
export const useGetStudentAttendancesByToday = (id: number,date:string) => useFetch<StudentAttendances>(`studentAttendances/${id}/todayAttendances/${date}`);
export const useGetAttendancesList = async ({ start, count, filter }: { start?: number; count?: number; filter?: any }) => {
  const newParams = handleFilterParams({
    start,
    count,
    filter,
  });
  const { data: response }: any = await api.get(`studentAttendances?${newParams}`);
  return response;
};
export const createCourseAttendances = async (values: Attendances) => {
  const { data: response }: any = await api.post('attendances', values);
  return response.data;
};

export const updateStudentAttendances = async ({ id, ...values }: { id: string; values: GetListResponse }) => {
  const { data: response }: any = await api.patch(`studentAttendances/${id}`, values);
  return response;
};

export const updateStudentUpdateByStatus = async ({ id, ...values }: { id: string; values: GetListResponse }) => {
  const { data: response }: any = await api.patch(`studentAttendances/${id}/updateStatus`, values);
  return response;
};

