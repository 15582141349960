import React from 'react';
import { Student } from '../../../interfaces/student';
import TableBase, { Props as TableProps } from './Base';
import { DateFormat } from '../../../utils/global';
type Props = TableProps & {
  withAction?(_: any): void;
  students?: Student[];
};

const StudentsTable: React.FC<Props> = ({ students, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: 'Student',
      width: 150,
      render: (student: Student) => {
        return (
          <span className="text-capitalize">
            {student?.firstName} {student?.lastName} ({student?.chineseName})
            <br></br>
            Gender : {student?.gender}
            <br></br>
            Age : {student?.age} ({DateFormat(student?.dateOfBirth)})
          </span>
        );
      },
    },
    {
      title: 'Parent',
      width: 250,
      render: (student: Student) => {
        return (
          <span className="text-capitalize">
            {student?.parents?.firstName} {student?.parents?.lastName} ( {student?.parents?.email} ) {student?.parents?.contact}
            <br></br>
            {student?.parents?.emergencyName}({student?.parents?.emergencyContact})({student?.parents?.emergencyContactRelationship})
          </span>
        );
      },
    },
    {
      title: 'Message',
      width: 100,
      render: (student: Student) => {
        return (
          <span className="text-capitalize">
            Foundation: {student?.foundation}
            <br></br>
            Expectation:({student?.expectation})
          </span>
        );
      },
    },{
      title: 'Remarks',
      width: 100,
      render: (student: Student) => {
        return (
          <span className="text-capitalize">
            {student?.remarks}
          </span>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      width: 100,
      render: (isActive: boolean) => {
        return (
          <span className={`table-status ${isActive ? 'active' : 'inactive'}`}>{isActive ? 'Active' : 'Inactive'}</span>
        );
      },
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 50,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={students} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default StudentsTable;
