import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { TOKEN_NAME } from '../../shared/constants';
import { useQueryClient } from 'react-query';
import useAuth from '../../shared/hooks/useAuth';

const LogoutPage: React.FC = () => {
  useEffect(() => {
    setAuth({});
    Cookies.remove(TOKEN_NAME);
    queryClient.invalidateQueries();
    window.location.href = '/login';
  }, []);
  const { setAuth }: any = useAuth();
  const queryClient = useQueryClient();

  return <></>;
};

export default LogoutPage;
