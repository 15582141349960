import React from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import HeaderMain from './HeaderMain';
import { LoginResponseI } from '../../../interfaces/auth';
import AdminSiderContent from '../siderContents/Admin';

type Props = {
  currentUser: LoginResponseI;
};

const AdminLayout: React.FC<Props> = ({ currentUser }) => {
  return (
    <Layout className="admin-layout">
      <AdminSiderContent />
      <Layout className="site-layout">
        <HeaderMain currentUser={currentUser}/>
        <Outlet />
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
