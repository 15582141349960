import { Form  } from 'antd';
import React, { useEffect,useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import './Profile.scss';
import { getParentExpiredListByParent } from '../../../../api/payments';
import StudentClassWorks from '../StudentClassWorks/View';
import { QueryParams } from '../../../../interfaces';
import { DateFormat } from '../../../../utils/global';
import { Parent } from '../../../../interfaces/parent';
import { Student } from '../../../../interfaces/student';
import { Course } from '../../../../interfaces/course';
import { Props as TableProps } from './Base';
import '../../../../mixins.scss';
import  "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import { PAGE_SIZE } from '../../../../shared/constants';
import PrimaryButton from '../../../../shared/components/buttons/PrimaryButton';
var days = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
var onDayStatuses = [
  'Mummy 我今天有上画画班哦',
  'Mummy 我在上画画课了',
  'Mummy 我下课了！可以来画画班载我了',
  'Mummy 我回家了'
];

type Props = TableProps & {
  student : Student
};

const StudentDashboardView: React.FC<Props> = ({ student, ...props }) => {
  const date  =new Date()
  var day = date.getDay()
  date.setHours(0, 0, 0, 0)
  const todayDate = date.setDate(date.getDate()).toString()
  
  let [queryParams, setQueryParams] = useState<QueryParams>({start: 0, count: PAGE_SIZE,filter:{todayDate} });
 
  const [form] = Form.useForm();
  useEffect(() => {
    if (student) {
      form.setFieldsValue(student);
    }
  }, [student]);
      return (
      // <div className="card-row">maxWidth:"100%", 
    <div className="col-sm-12"  style={{paddingRight:"1px", width:"100%",paddingLeft:"1px"}}>
    <div className="card" style={{width: '18rem', marginLeft:'10px'}}>
    <div className="card-body">
    {student?.gender?.toUpperCase() === "MALE" ? (
      <img src="/boy.png" alt="avatar"
              className="rounded-circle img-fluid" style={{width: '150px'}} />

    ):(
      <img src="/girl.png" alt="avatar"
              className="rounded-circle img-fluid" style={{width: '150px'}} />
    )
  }
   </div>
   <h4 className="card-title" style={{textAlign:"center"}}>{student?.chineseName}</h4>
   <ul className="list-group list-group-flush">
    </ul>
    <StudentClassWorks  
               student={student}
               />  
      </div>
      </div>
      // </div>
      )
  }
export default StudentDashboardView;