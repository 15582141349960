import React from 'react';
import { DateFormat } from '../../../utils/global';
import { Payment } from '../../../interfaces/payment';
import TableBase, { Props as TableProps } from './Base';

type Props = TableProps & {
  withAction?(_: any): void;
  dataSource?: Payment[];
};

const PaymentsTable: React.FC<Props> = ({ dataSource, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: 'Parent',
      // dataIndex: 'parent.email',
      width: 100,
      render: (payment: Payment) => {
        return (
          <span className="text-capitalize">
            {payment?.parent.firstName} {payment?.parent.lastName}
          </span>
        );
      },
    },
    {
      title: 'Student',
      // dataIndex: 'parent.email',
      width: 100,
      render: (payment: Payment) => {
        return (
          <span className="text-capitalize">
            {payment?.student.firstName} {payment?.student.lastName}
            ({payment?.course.name})
          </span>
        );
      },
    },
    {
      title: 'Period',
      width: 100,
      render: (payment: Payment) => {
        return (
          <span className="text-capitalize">
             <span>{DateFormat(payment?.periodStartDate)} - {DateFormat(payment?.periodEndDate)}</span>
          </span>
        );
      },
    },
    {
      title: 'Payment Type',
      // dataIndex: 'parent.email',
      width: 100,
      render: (payment: Payment) => {
        return (
          <span className="text-capitalize">
            {payment?.paymentType}
            <br></br>
            {payment?.remark}({` RM${payment?.amount}`})
          </span>
        );
      },
    },
    {
      title: 'Amount(RM)',
      dataIndex: 'amount',
      width: 55
    },
    {
      title: 'Payment Type',
      // dataIndex: 'parent.email',
      width: 100,
      render: (payment: Payment) => {
        const Startymd=payment?.periodStartDate.split("-")
        const Endymd=payment?.periodEndDate.split("-")
        let ymd = '0'
        if(Startymd[1] == Endymd[1]){
          ymd=Startymd[1]
        }else{
          ymd=`${Startymd[1].toString()} - ${Endymd[1].toString()}`
        }
        return (
          <span className="text-capitalize">
            {`mummy 这个是${payment?.student.chineseName},${ymd}月的单据,谢谢您`}
          </span>
        );
      },
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 50,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default PaymentsTable;
