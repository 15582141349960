import { pathToUrl } from '../utils/router';
import { CourseMaterial } from '../interfaces/courseMaterial';
import { GetListResponse } from '../interfaces';
import { useFetch, usePost } from '../shared/reactQuery';
import { api } from '../shared/api';
import { handleFilterParams } from '../utils/global';

export const useGetCourseMaterialsList = () => useFetch<GetListResponse>('courseMaterials');

export const useGetCourseMaterial = (id: number) => useFetch<CourseMaterial>(`courseMaterials/${id}`);

export const useCreateCourseMaterial = (updater: (oldData: CourseMaterial[], newData: CourseMaterial) => CourseMaterial[]) => {
  return usePost<CourseMaterial[], CourseMaterial>(pathToUrl('courseMaterials'), undefined, updater);
};

export const getCourseMaterialsList = async ({ start, count, filter }: { start?: number; count?: number; filter?: any }) => {
  const newParams = handleFilterParams({
    start,
    count,
    filter,
  });
  const { data: response }: any = await api.get(`courseMaterials?${newParams}`);
  return response;
};

export const createCourseMaterial = async (values: CourseMaterial) => {
  const { data: response }: any = await api.post('courseMaterials', values);
  return response.data;
};

export const updateCourseMaterial = async ({ id, ...values }: { id: string; values: CourseMaterial }) => {
  const { data: response }: any = await api.patch(`courseMaterials/${id}`, values);
  return response.data;
};

export const deleteCourseMaterial = async (id: number) => {
  const { data: response }: any = await api.delete(`courseMaterials/${id}`);
  return response.data;
};
