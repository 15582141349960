import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import './Card.scss';
import DocumentViewer from '../../../../shared/components/Upload/DocumentViewer';
import { Props as TableProps } from '../Base';
import "bootstrap-css-only/css/bootstrap.min.css";
import '../../../../mixins.scss';
import htmr from 'htmr';
type Props = TableProps & {
  file:any;
};

const ClassWorksCardView: React.FC<Props> = ({ file, ...props }) => {
  const [ isEmergencyContactModalVisible , setIsEmergencyContactModalVisible] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>();
  const onEmergencyContactCancel = () => {
    setIsEmergencyContactModalVisible(false);
  };

  const onEditClick = (modalData:any) => {
    setModalData(modalData)
    setIsEmergencyContactModalVisible(true);
  };

  const handleCancel = () => {
    setIsEmergencyContactModalVisible(false);
  };
  
  const [form] = Form.useForm();
  useEffect(() => {
    if (file) {
      form.setFieldsValue(file);
    }
  }, [file]);
      return (
              <div className="row" style={{width:'100%' ,display:'flex',flexDirection:'column',alignItems:'center' ,marginRight:'0px',marginLeft:'0px'}}>
              {/* <div className="col-sm-6" style={{width:'100%'}}> */}
              {/* <div className="card-imageView" > */}
              <DocumentViewer files={file} />
              <p className="card-text"> {file && htmr(file.comment)}</p>
        
           
            {/* </div> */}
            </div>
      ); 
  }
export default ClassWorksCardView;