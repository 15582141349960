import { DATE_FORMAT_FRONTEND } from './../shared/constants/index';
import { pgpEncrypt } from '../helpers/openPGPHelper';
import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import { TOKEN_NAME } from '../shared/constants';
import * as qs from 'qs';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import moment from 'moment';
import _ from 'lodash';

dayjs.extend(customParseFormat);

const openPgpState = process.env.REACT_APP_PGP_STATE;

export const handleEncryptData = async (params: any) => {
  if (openPgpState === 'on') {
    let parseToString = JSON.stringify(params);
    const pgpCipherText = await pgpEncrypt(parseToString);
    return { data: pgpCipherText };
  } else {
    return params;
  }
};

export default function parseToken(authToken: string) {
  const token = authToken || Cookies.get(TOKEN_NAME);
  let user = {};
  if (!token) return undefined;
  try {
    user = jwtDecode(token);
  } catch (e) {
    console.log(e);
  }
  return user;
}

export const humanizeText = (text: string) => {
  return text?.replace(/[_]/g, ' ');
};

export const handleFilterParams = (params: any) => {
  return qs.stringify(params, { encode: false, skipNulls: true });
};

export const convertDayJSToISOString = (dayjsString: Dayjs) => {
  return dayjs(dayjsString);
};

export const DateFormat = (date: any) => {
  if (date === null) {
    return;
  }

  return dayjs(date).format(DATE_FORMAT_FRONTEND);
};

export const convertDateTimeToNumber = (datetime: Dayjs) => {
  return datetime ? Number(dayjs(datetime).valueOf()) : null;
};


export const convertToTreeData = (data: any[]) => {
  const newLocation = data.map((location: any) => {
    return { ...location, children: null };
  });

  _(newLocation).forEach((f) => {
    const child = _(newLocation)
      .filter((g) => g.parentId === f.id)
      .value();

    if (child.length !== 0) {
      f.children = child;
    } else {
      delete f.children;
    }
  });

  return _(newLocation)
    .filter((f) => f.parentId === null)
    .value();
};

export const DateTimeFormat = (datetime: any) => {
  if (datetime === null) {
    return;
  }
  return moment(datetime).local().format('DD-MM-YYYY, HH:mm:ss');
};

export const TimeFormat = (datetime: any) => {
  if (datetime === null) {
    return;
  }
  return moment(datetime).local().format('HH:mm:ss');
};

export const getExportFileName = (disposition: any) => {
  let filename = null;
  if (disposition) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  return filename;
};

export const handleDownloadExportFile = (payload: any, customHeader?: string) => {
  const { data } = payload.response;
  const headers = payload.response.headers;
  const disposition = headers['content-disposition'];
  const filename = disposition && getExportFileName(disposition);
  const url = URL.createObjectURL(data);
  window.open(url, '_blank');
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename || customHeader);
  document.body.appendChild(link);
  link.click();
};

export const handleMobileDownloadExportFile = (payload: any, customHeader?: string) => {
  const { data } = payload.response;
  const headers = payload.response.headers;
  const disposition = headers['content-disposition'];
  const filename = disposition && getExportFileName(disposition);
  const url = URL.createObjectURL(data);
  window.open(url, '_blank');
  // const link = document.createElement('a');
  // link.href = url;
  // link.setAttribute('download', filename || customHeader);
  // document.body.appendChild(link);
  // link.click();
};

function getFileType(fileType: string) {
  switch (fileType) {
    case 'jpeg':
      return `image/${fileType}`;
    case 'png':
      return `image/${fileType}`;
    case 'jpg':
      return `image/${fileType}`;
    case 'mp4':
      return 'video';
    case 'mov':
      return 'video';
    default:
      return fileType;
  }
}

export const handleGetFileNameAndTypeFromS3Url = (url: string) => {
  if (url) {
    const pathname: string = new URL(url).pathname;
    const index: number = pathname.lastIndexOf('/');
    const fileNameWithParams: string = pathname.substring(index + 1);
    const fileNameParts: string[] = fileNameWithParams.split('.');
    const fileName: string = fileNameParts.slice(0, -1).join('.');
    const fileTypeParts: string[] = fileNameParts[fileNameParts.length - 1].split('-');
    const fileExtension: string = fileTypeParts[0];
    const type = getFileType(fileExtension);
    return [fileName, type];
  } else {
    return ['no file name', 'no file type'];
  }
};

export const convertFileTypes = (originalType: string) => {
  switch (originalType) {
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'application/docx';
    case 'application/msword':
      return 'application/doc';
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'application/xlsx';
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return 'application/pptx';
    case 'video/quicktime':
      return 'video/mov';
    default:
      return originalType;
  }
};



