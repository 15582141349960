import { Form, DatePicker , Input, Select, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import ImageUploader from '../../Upload/Image';
import { convertDateTimeToNumber } from '../../../../utils/global';
import { DATE_FORMAT_FRONTEND } from '../../../constants';
import { QueryParams } from '../../../../interfaces';
import { Payroll } from '../../../../interfaces/payroll';
import { Coach } from '../../../../interfaces/coach';
import { getCoachesList } from '../../../../api/coaches';
import { PASSWORD_TIP } from '../../../constants';
import CancelIcon from '../../../svgs/CancelIcon';
import CheckCircleIcon from '../../../svgs/CheckCircleIcon';
import PrimaryButton from '../../buttons/PrimaryButton';
import SecondaryButton from '../../buttons/SecondaryButton';
import {MONTH_NAME, PAYMENT_METHODS} from '../../../constants';
const { RangePicker } = DatePicker;
type Props = {
  isFetching?: boolean;
  payrollId?: number;
  payroll?: Payroll;
  handleSubmit(_: any): void;
};

const PayrollForm: React.FC<Props> = ({ payroll, payrollId, isFetching, handleSubmit }) => {
  const date = new Date();
let day = date.getDate();
let currentMonth = date.getMonth();
let year = date.getFullYear();
// console.log('month',MONTH_NAME[month])

  const [form] = Form.useForm();
  const [queryParams, setQueryParams] = useState<QueryParams>();
  const [listOfStudent, setListOfStudents] = useState<any>({});
  const [studentId, setstudentId] = useState('');
  const [files, setFiles] = useState<any>([]);
  const [listOfCourse, setListOfCourses] = useState<any>({});
  const { data:CoachesList, isLoading:isParentFetching } = useQuery(['coaches', 'list', queryParams], () => getCoachesList({}), {
    staleTime: Infinity,
  });
  const handleOnFinish = (values: Payroll) => {
    const newValues: any = values;
    newValues.amount=Number(newValues.amount)
    newValues.creditClass=Number(newValues.creditClass)
    const periodStartDate =
      values.startEndDateRange && Object.values(values.startEndDateRange).length > 0
        ? convertDateTimeToNumber(dayjs(values.startEndDateRange[0]))
        : null;
    const periodEndDate =
      values.startEndDateRange && Object.values(values.startEndDateRange).length > 0
        ? convertDateTimeToNumber(dayjs(values.startEndDateRange[1]))
        : null;

    newValues.periodStartDate = periodStartDate;
    newValues.periodEndDate = periodEndDate;
    handleSubmit(newValues);
  };


  function findIndexWithDifferentId(data: any[], idArray: number[]) {
    for (let i = 0; i < data.length; i++) {
      if (!idArray.includes(data[i].id)) {
        return i;
      }
    }
    return -1;
  }

  useEffect(() => {
    if (payroll && payroll.id === payrollId) {
      const _files = payroll?.files;
      // listOfStudent = StudentsList
      // const student = StudentsList?.data?.filter((student: Student) => student.parentId === payroll.parent.id);
      // setListOfStudents(student);
      // const course = CoursesList?.data?.filter((course: Course) => course.id === payroll.course.id);
      // setListOfCourses(course);
      form.setFieldsValue({
        ...payroll,
        // startEndDateRange:
        // payroll?.periodStartDate && payroll?.periodEndDate ? [dayjs(payroll.periodStartDate), dayjs(payroll.periodEndDate)] : null,
        // coach:payroll.coach.id,
        // files: { fileList: payroll.files?.filter((d: any) => d) },
      });
      setFiles(
        _files?.map((d: any) => {
          return { ...d, url: d.s3Url };
        }),
      );
    }
  }, [payroll]); 
  return (
    <div className="payroll-form-container">
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        onFinish={handleOnFinish}
        scrollToFirstError
        autoComplete="off"
        initialValues={{ isActive: true }}
      >
        <div className="form-container">
          <div className="form-content">
          <Form.Item label="Coach" name= 'coachId'rules={[{ required: true, message: 'Required' }]}  >
            <Select placeholder="Required" showSearch optionFilterProp="children" loading={isParentFetching} >
               {CoachesList?.data?.map((coach: Coach) => (
                                <Select.Option key={coach.id} value={coach.id}>
                                  {coach.firstName}{coach.lastName} ({coach.email})
                                </Select.Option>
                              ))}
                            </Select >
                          </Form.Item>
           {/* <Form.Item
              name="startEndDateRange"
              label="Start - End Date"
              rules={[{ required: true, message: 'required' }]}
            >
              <RangePicker className="ant" format={DATE_FORMAT_FRONTEND} />
            </Form.Item> */}
            <Form.Item label="Amount" name="amount">
              <Input placeholder="Optional"  />
            </Form.Item>
            <Form.Item label="Year" name="year" rules={[{  message: 'Required' }]}>
              <Input  placeholder="year" defaultValue={year}/>
            </Form.Item>
            <Form.Item label="Month" name='month' rules={[{  message: 'Required' }]}  >
              <Select placeholder="month" defaultValue={MONTH_NAME[currentMonth]}>
                  {MONTH_NAME.map((item: any, index: number) => (
                    <Select.Option key={item.name} value={item.name} >
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
            </Form.Item>
            <Form.Item label="Payment Type" name= 'paymentType'rules={[{ message: 'Required' }]}  >
              <Select placeholder="Payment Type" defaultValue={PAYMENT_METHODS[1]}>
                  {PAYMENT_METHODS.map((item: any, index: number) => (
                    <Select.Option key={item.name} value={item.name}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
            </Form.Item>
            <Form.Item label="Remark" name="remark">
              <Input placeholder="Optional" />
            </Form.Item>
            <Form.Item label="File">
              <ImageUploader
                name="posterImage"
                documents={files}
                setDocuments={setFiles}
                multiple={false}
                maxCount={2}
                acceptType="image/png, image/jpeg"
              />
            </Form.Item>
          </div>
          <div className="form-footer">
            <PrimaryButton
              loading={isFetching}
              label={payrollId ? 'Update' : 'Create'}
              icon={<CheckCircleIcon />}
              htmlType="submit"
            />
            <Link to="/admin/payrolls">
              <SecondaryButton label={'Cancel'} icon={<CancelIcon />} />
            </Link>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default PayrollForm;
