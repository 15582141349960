import React, { useState } from 'react';
import { Collapse } from 'antd';
import './Collapse.scss';
import {MONTH_NAME} from '../../../shared/constants';
import { getYear } from '../../../api/tuitionDays';
import { useGetProfile } from '../../../api/auth';
import { QueryParams } from '../../../interfaces';
import { Parent } from '../../../interfaces/parent';
import { TuitionDays } from '../../../interfaces/tuitionDays';
import {  TOKEN_NAME } from '../../../shared/constants';
import Cookies from 'js-cookie'; 
import TuitionDaysForm from '../../../shared/components/forms/tuitionDays/Form';
import { PAGE_SIZE } from '../../../shared/constants';
import { useMutation, useQuery, useQueryClient } from 'react-query';
const { Panel } = Collapse;
const TuitionDaysPage: React.FC = () => {
  const token = Cookies.get(TOKEN_NAME);
  const currentDate = new Date()
  currentDate.setHours(0, 0, 0, 0)
  const queryClient = useQueryClient();
  const todayDate = currentDate.setDate(currentDate.getDate()).toString()
  const [queryParams, setQueryParams] = useState<QueryParams>({ start: 0, count: PAGE_SIZE,filter:{todayDate} });

  const { data:tuitionDaysYears } = useQuery(['tuitionDays', 'tuitionDaysYear', queryParams], () => getYear({}), {
    staleTime: Infinity,
  });

// const { mutateAsync: mutationDone} = useMutation(paymentMake);

const handleClickPaid = async (values:any) => {
  // await mutationDone({'id':values.parentId,values});
  //   queryClient.invalidateQueries({
  //     queryKey: ['payments', 'list'],
  //   });
};

  return (
    <><div id="user-details-container">
      <div className="page-container">
        <div className="page-content">
        <h2 className="title-page">Tuition Days</h2>
        </div>
        <div className="page-content">
        {tuitionDaysYears?.map((tuitionDays: any) => (
          <Collapse expandIconPosition="end" style={{color:'#939ca3 !important'  ,backgroundColor:'#23395d', alignItems:'self-end',fontSize:'large', borderRadius: '36px' ,marginBottom: '10px'}} >
              <Panel header={tuitionDays.year} key="1" style={{fontSize:'xx-large', fontWeight:'0' , textAlign:'center'}} className="collapse-custom-header">
                <div className="students-container">
                  <div className="page-container" style={{ padding: '10px' , display: "grid"}}>
                    {MONTH_NAME?.map((month: any) => (
                      <>
                      <div className="row" style={{display:'inline-block' ,paddingRight:"20px"}}>
                          <div className="col-sm-12" style={{maxWidth: '100%'}}>
                            {/* <div className="card" style={{width: '100%', borderRadius:'18rem' ,marginLeft:'10px',paddingTop:'0px'}}> */}
                              <Collapse expandIconPosition="end" style={{color:'#939ca3 !important'  ,backgroundColor:'#23395d', alignItems:'self-end',fontSize:'large', borderRadius: '28px 28px' ,marginBottom: '0px'}}>
                                <Panel  header={month.name} key="1" className="collapse-custom-header ant-collapse-header-text" 
                                  style={{fontSize:'x-large',borderRadius:'10px'}}>
                                  <TuitionDaysForm
                                    year={tuitionDays.year}
                                    month={month.name}
                                  />
                                </Panel>
                              </Collapse>
                            {/* </div> */}
                          </div>
                        </div>
                        </>
                    ))}
                  </div>
                </div>
              </Panel>
          </Collapse>
              ))}
        </div>
      </div>
    </div>
    </>
  );
};

export default TuitionDaysPage;
