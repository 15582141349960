import { Form,Button,message  } from 'antd';
import React, { useEffect,useState } from 'react';
import {  useQueryClient,useMutation, } from 'react-query';
import './Profile.scss';
import { DateFormat } from '../../../../utils/global';
import { Parent } from '../../../../interfaces/parent';
import { Props as TableProps } from './Base';
import '../../../../../src/mixins.scss';
import  "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
const appId  = '1207e931-be22-439b-90de-57f198e8bfd7'
import OneSignal from 'react-onesignal';
import { updateParent, } from '../../../../api/parents';
import DangerButton from '../../../../shared/components/buttons/DangerButton';

type Props = TableProps & {
  parent : Parent
};



const ParentView: React.FC<Props> = ({ parent, ...props }) => {

const queryClient = useQueryClient();

useEffect(() => {
  if (Notification.permission === 'granted') {
  getSubscriptionId();
  }
})
const [initialized, setInitialized] = useState(false);

async function getSubscriptionId(){
  if(!parent.mobileToken){
    await OneSignal.init({ appId: appId }).then(() => {
      setInitialized(true);
  })
    let subscriptionId=OneSignal.User.PushSubscription.id
    await updateToken(subscriptionId)
  }
}

async function updateToken(token: any){
  if(token){
    let newValues: any
    const id = parent?.id
    newValues = {mobileToken:token};
    updateMutate({ ...newValues, id });
  }
}

const { mutateAsync: updateMutate } = useMutation(updateParent, {
  onSuccess: () => {
    // message.info('Token update completed.');
    // navigate(`/parent/profile`);
    window.location.reload();
  },
  onError: () => {
    // message.error('Update token failed! Please try again');
  },
  onSettled: () => {
  },
});


async function runOneSignal() {
  try{
        await OneSignal.init({ appId: appId }).then(() => {
          setInitialized(true);
          OneSignal.Slidedown.promptPush()
      })
     
  }catch(e){
    console.log("runOneSignal-error:",e)
  } 
}


  const [form] = Form.useForm();
  useEffect(() => {
    if (parent) {
      form.setFieldsValue(parent);
    }
  }, [parent]);
      return (
    <div className="col-sm-3" style={{ paddingRight:"0px", paddingLeft:"0px"}}>
    <div className="card" style={{width: '18rem', marginLeft:'10px'}}>
    <div className="card-body">
    {parent?.relatioship?.toUpperCase() === "MOTHER" ? (
    <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava2.webp" alt="avatar"
              className="rounded-circle img-fluid" style={{width: '150px'}} />

    ):(
      <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1.webp" alt="avatar"
              className="rounded-circle img-fluid" style={{width: '150px'}} />
    )
  }
    </div>
   <ul className="list-group list-group-flush">

   <h5 className="card-title" style={{textAlign: 'center'}} >{parent?.chineseName}<br></br>{parent?.firstName} {parent?.lastName}</h5>
   <li className="list-group-item">
    {(parent?.contact) ? parent?.contact:'-'} 
   <br></br>
   {(parent?.email) ? parent?.email:'-'}
   </li>
   <li className="list-group-item">
   {(parent?.emergencyName) ? parent?.emergencyName:'-'}  ({(parent?.emergencyContactRelationship) ? parent?.emergencyContactRelationship:'-'})
    <br></br>
    {(parent?.emergencyContact) ? parent?.emergencyContact:'-'}
    </li>
   <li className="list-group-item">{(parent?.address) ? parent?.address:'-'}</li>
   <li className="list-group-item" style={{display:"grid"}}>
   {(parent?.mobileToken) ? <Button disabled={true} style={{color:'white'}} >{parent.mobileToken}</Button> :   <DangerButton content="Do you really want to allow notification for this web?"label="Allow Notification" onOk={() => runOneSignal()} /> }
    
   </li>
    </ul> 
      </div>
      </div>
      )
  }
export default ParentView;