import { Button, Dropdown,message, Modal, Spin, Collapse } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { getMaterialsList, updateMaterial, useGetMaterial} from '../../../api/materials';
import { getStudentsList ,deleteStudent} from '../../../api/students';
import { QueryParams } from '../../../interfaces';
import { Student } from '../../../interfaces/student';
import MaterialsForm from '../../../shared/components/forms/material/Form';
import { getCoachesList } from '../../../api/coaches';
import { PAGE_SIZE } from '../../../shared/constants';
import DangerButton from '../../../shared/components/buttons/DangerButton';
import CourseEnrolledStudents from '../../../shared/components/tables/CourseEnrolledStudents';
import CourseStudentsForEnrollment from '../../../shared/components/modal/CourseStudentsForEnrollment';
import { Link } from 'react-router-dom';
import SecondaryButton from '../../../shared/components/buttons/SecondaryButton';
import AddCircleOutlineIcon from '../../../shared/svgs/AddCircleOutlineIcon';
const { Panel } = Collapse;
const CourseMaterialUpdatePage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [queryParams] = useState<QueryParams>({ start: 0, count: PAGE_SIZE });
  const { id } = useParams<{ id: string }>();
  const { data: material, isLoading:isMaterialFetching } = useGetMaterial(Number(id));

 
  const { mutateAsync: updateMutate } = useMutation(updateMaterial, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['materials', 'list'],
      });
      queryClient.invalidateQueries({
        queryKey: [`materials/${id}`, undefined],
      });
      message.info('Update Material successfully!');
      navigate(`/admin/materials`);
    },
    onError: () => {
      message.error('Update course failed! Please try again');
    },
    onSettled: () => {
      queryClient.prefetchQuery({
        queryKey: ['materials', 'list', { start: 0, count: PAGE_SIZE, filter: undefined }],
        queryFn: () => getMaterialsList({ start: 0, count: PAGE_SIZE, filter: undefined }),
      });
    },
  });

  // const { mutateAsync: mutationUpdate } = useMutation(deleteStudentFromCourse);

  const handleClickDelete = async (courseId : number , studentId : number) => {
    // await deleteStudentFromCourse(courseId,studentId);
    queryClient.invalidateQueries({
      queryKey: ['students', 'list'],
    });
  };

  const handleSubmit = async (values: any) => {
    updateMutate({ ...values, id });
  };
 

  return (
    <Spin spinning={isMaterialFetching}>
      <div className="users-container">
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page">Edit Material</h2>
            </div>
            <MaterialsForm
            MaterialId={Number(material?.id)}
            Material={material}
            handleSubmit={handleSubmit}
            />
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default CourseMaterialUpdatePage;
