// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.App {
  font-family: sans-serif;
  text-align: center;
}

.row {
  margin-top: 10px;
}

.card-body {
  background: #23395d !important;
  color: #fff;
  display: flex !important;
  flex-direction: row !important;
}

.card-imageView {
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card {
  margin-bottom: 10px;
}

∂ .card-text p {
  color: white !important;
}

@media (max-width: 623px) {
  .card-header {
    padding: 0.45rem 0.15rem !important;
  }
  .card {
    margin-left: 0px !important;
  }
}
@media (max-width: 320) {
  .card {
    margin-left: 0px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/parent/dashboard/ClassWorks/ClassWorksList.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,uBAAA;EACA,kBAAA;AAEF;;AACA;EACE,gBAAA;AAEF;;AAEA;EACE,8BAAA;EACA,WAAA;EACA,wBAAA;EACA,8BAAA;AACF;;AAKA;EACE,aAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AAFF;;AAKA;EACE,mBAAA;AAFF;;AAIA;EAEE,uBAAA;AAFF;;AAOA;EAME;IACE,mCAAA;EATF;EAYA;IACE,2BAAA;EAVF;AACF;AAaA;EAEE;IACE,2BAAA;EAZF;AACF","sourcesContent":[".App {\n  font-family: sans-serif;\n  text-align: center;\n}\n\n.row {\n  margin-top:10px\n}\n\n\n.card-body{\n  background: #23395d !important;\n  color: #fff;\n  display: flex !important;\n  flex-direction: row !important;\n  // flex-direction: row;\n  // flex-wrap: nowrap;\n  // justify-content: space-around;\n}\n\n.card-imageView {\n  height: 100px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.card{\n  margin-bottom: 10px\n}\n∂\n.card-text p{\n  color:#ffff !important;\n}\n\n\n\n@media (max-width: 623px) {\n  // .rowmb{\n  //   // box-sizing: content-box !important;\n  //   height: 200px !important;\n  // }\n  \n  .card-header{\n    padding:0.45rem 0.15rem !important;\n  }\n\n  .card{\n    margin-left:0px !important; \n  }\n}\n\n@media (max-width: 320) {\n\n  .card{\n    margin-left:0px !important; \n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
