import { message, Spin } from 'antd';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { deleteUser, getUsersList, updateUser, useGetUser } from '../../../api/users';
import DangerButton from '../../../shared/components/buttons/DangerButton';
import PrimaryButton from '../../../shared/components/buttons/PrimaryButton';
import SecondaryButton from '../../../shared/components/buttons/SecondaryButton';
import { PAGE_SIZE } from '../../../shared/constants';
import CancelIcon from '../../../shared/svgs/CancelIcon';
import DeleteForeverIcon from '../../../shared/svgs/DeleteForeverIcon';
import EditIcon from '../../../shared/svgs/EditIcon';
import PersonIcon from '../../../shared/svgs/PersonIcon';
import { humanizeText } from '../../../utils/global';

const UserDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { data: user, isLoading } = useGetUser(Number(id));

  const { mutateAsync: mutationDelete } = useMutation(deleteUser, {
    onSuccess: async () => {
      message.info('Deleted!');
      queryClient.invalidateQueries({
        queryKey: ['users', 'list'],
      });
      navigate(`/admin/users`);
    },
    onError: () => {
      message.error('The action is failed! Please try again');
    },
    onSettled: () => {
      queryClient.prefetchQuery({
        queryKey: ['users', 'list', { start: 0, count: PAGE_SIZE, filter: undefined }],
        queryFn: () => getUsersList({ start: 0, count: PAGE_SIZE, filter: undefined }),
      });
    },
  });

  const handleDelete = () => {
    mutationDelete(Number(id));
  };

  const { mutateAsync: updateMutate } = useMutation(updateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['users', 'list'],
      });
      queryClient.invalidateQueries({
        queryKey: [`users/${id}`, undefined],
      });
      message.info('Unblocked this user!');
    },
    onError: () => {
      message.error('The action is failed! Please try again');
    },
  });

  const handleUnblockUser = async () => {
    const newValues: any = user;
    newValues.blockedAt = 'null';
    updateMutate({ ...newValues, id });
  };

  return (
    <Spin spinning={isLoading}>
      <div id="user-details-container">
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page">User Details</h2>
            </div>
            <div className="details-container">
              <div className="item">
                <label>Email</label>
                <span className="value">{user?.email}</span>
              </div>
              <div className="item">
                <label>Name</label>
                <span className="value">
                  {user?.firstName} {user?.lastName}
                </span>
              </div>
              <div className="item">
                <label>Role</label>
                <span className="value text-capitalize">{humanizeText(user?.role || '')}</span>
              </div>
              <div className="item">
                <label>Status</label>
                <span className={`value status ${user?.isActive ? 'active' : 'inactive'}`}>
                  {user?.isActive ? 'Active' : 'Inactive'}
                </span>
              </div>
            </div>
            <div className="page-content-footer">
              {user && user.blockedAt !== null && (
                <PrimaryButton
                  icon={<PersonIcon />}
                  label="Unblock"
                  onClick={handleUnblockUser}
                  color="secondary-color"
                />
              )}
              <Link to={`/admin/users/${id}/edit`}>
                <PrimaryButton label="Edit" icon={<EditIcon />} />
              </Link>
              <DangerButton label="Delete" onOk={handleDelete} icon={<DeleteForeverIcon />} />
              <Link to={`/admin/users`}>
                <SecondaryButton label="Cancel" icon={<CancelIcon />} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default UserDetailsPage;
