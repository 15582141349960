import { Form  } from 'antd';
import React, { useEffect,useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import './Profile.scss';
import { getParentExpiredListByParent } from '../../../../api/payments';
import { getStudentComingTuitionDay } from '../../../../api/tuitionDays';
import StudentClassWorks from '../StudentClassWorks/View';
import { QueryParams } from '../../../../interfaces';
import { DateFormat } from '../../../../utils/global';
import { Parent } from '../../../../interfaces/parent';
import { Student } from '../../../../interfaces/student';
import { Course } from '../../../../interfaces/course';
import { Props as TableProps } from './Base';
import dayjs, { Dayjs } from 'dayjs';
import '../../../../mixins.scss';
import  "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import { PAGE_SIZE, DATE_FORMAT_FRONTEND,TOKEN_NAME } from '../../../../shared/constants';
import PrimaryButton from '../../../../shared/components/buttons/PrimaryButton';
import label_en from './en.json';
import label_zh from './zh.json';
import Cookies from 'js-cookie';
var days = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
var onDayStatuses = [
 'Mummy 我今天有上画画班哦',
  'Mummy 我在上画画课了',
 'Mummy 我下课了！可以来画画班载我了',
  'Mummy 我回家了'
];

type Props = TableProps & {
  student : Student
  parentId: number
  lastLogin:string
  mobileToken:string
};

const StudentDashboardView: React.FC<Props> = ({ student,parentId,lastLogin,mobileToken, ...props }) => {
  const date  =new Date()
  // var day = date.getDay()
  // console.log(Cookies.get('languages'))
  const label = Cookies.get('languages') == "ZH" ? label_zh : label_en;
  date.setHours(0, 0, 0, 0)
  const todayDate = date.setDate(date.getDate()).toString()
  const y = date.getFullYear()
      let m :any  = date.getMonth()
      let d :any = date.getDate()
      let day = date.getDay()
      m=Number(m)+1
      if(m<=9){
        m=`0${m}`
      }else{
        m=m
      }
      if(d<=9){
        d = `0${d}`
      }else{
        d=d
      }

  let [queryParams, setQueryParams] = useState<QueryParams>({start: 0, count: PAGE_SIZE,filter:{todayDate} });
  const { data:parentExpiredListByParent, isLoading } = useQuery(['payments', 'getParentExpiredListByParent', queryParams], () => getParentExpiredListByParent({...queryParams,id:parentId}), {
    staleTime: Infinity,
  });

    const { data: studentComingTuitionDay, isLoading:isTuitionDayLoading } = getStudentComingTuitionDay(student?.courses[0].day);


  const [form] = Form.useForm();
  useEffect(() => {
    if (student) {
      form.setFieldsValue(student);
    }
  }, [student]);
      return (
      // <div className="card-row">
    <div className="col-sm-2"  style={{maxWidth:"100%", paddingRight:"0px", paddingLeft:"0px"}}>
    <div className="card" style={{width: '18rem', marginLeft:'10px'}}>
    <div className="card-body">
    {student?.gender?.toUpperCase() === "MALE" ? (
      <img src="/boy.png" alt="avatar"
              className="rounded-circle img-fluid" style={{width: '150px'}} />

    ):(
      <img src="/girl.png" alt="avatar"
              className="rounded-circle img-fluid" style={{width: '150px'}} />
    )
  }
   </div>
   <ul className="list-group list-group-flush">

   <h5 className="card-title" style={{textAlign: 'center'}} >{student?.chineseName}</h5>
   <h6 className="card-title" style={{textAlign: 'center'}} >Last Login:<br></br>{lastLogin ? dayjs(lastLogin).format(DATE_FORMAT_FRONTEND):'-'}</h6>
   <h6 className="card-title" style={{textAlign: 'center'}} >Mobile Token:<br></br>{mobileToken ?  mobileToken:'-'}</h6>
   {student?.courses?.map((course: Course) => (
      <>
      <li className="list-group-item">
       <span>
         {course?.day} : {course?.startAt.substring(0, 5)} - {course?.endAt.substring(0, 5)}
       </span>
       
       {studentComingTuitionDay?.status ? 
       ( 
        student.onDayStatus != 0 ? 
        <h1 style={{color:"rgb(88 175 117)",fontSize:"16px" }}>{label.onDayStatuses[student?.onDayStatus]?.value}</h1>
        :
        <h1 style={{color:"rgb(88 175 117)",fontSize:"16px" }}>Mummy 来临 {course?.day} 我有上画画课哦</h1>
        )
       : 
       (
       <h1 style={{color:"rgb(88 175 117)",fontSize:"16px" }}>Mummy 来临 {course?.day} 画室休息 我没有上课哦 {studentComingTuitionDay?.status }</h1> 
       )
      }
     </li>
       
     </>
  ))}
      <li className="list-group-item">
    {/* {parentExpiredListByParent?.data?.map((parent: Parent) => (
       <>
       <h1 style={{color:"rgb(88 175 117)",fontSize:"16px" }}>Mummy 这个月的学费还没付给画室</h1>
       </>
    ))} */}

        {parentExpiredListByParent?.data.length > 0 ? 
        (<h1 style={{color:"rgb(88 175 117)",fontSize:"16px" }}>Mummy 这个月的学费还没付给画室</h1>)
        :
        (<h1 style={{color:"rgb(88 175 117)",fontSize:"16px" }}>谢谢 Mummy 这个月学费已付款</h1>)
        }
     </li>
    </ul>
    <StudentClassWorks student={student}/>  
      </div>
      </div>
      // </div>
      )
  }
export default StudentDashboardView;