import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Form, Input, Modal, message, Select } from 'antd';
import { MaterialIn } from '../../../../interfaces/materialIn';
import TableBase, { Props as TableProps } from '../../tables/Base';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { NumericFormat } from 'react-number-format';
import { getMaterialsList } from '../../../../api/materials';
import { useNavigate } from 'react-router-dom';
import { QueryParams } from '../../../../interfaces';
import {studentAddMaterialQty} from '../../../../api/students';
import { PASSWORD_TIP, DATE_FORMAT_QUERY } from '../../../constants';
import { Link } from 'react-router-dom';
import CancelIcon from '../../../svgs/CancelIcon';
import CheckCircleIcon from '../../../svgs/CheckCircleIcon';
import PrimaryButton from '../../buttons/PrimaryButton';
import SecondaryButton from '../../buttons/SecondaryButton';
import { PAGE_SIZE } from '../../../../shared/constants';
import MaterialListing from '../../../../shared/components/modal/Student/MaterialListing';
import { updateStudentUpdateByStatus} from '../../../../api/studentAttendances';
import BorderLessButton from '../../buttons/BorderLessButton';

import moment from 'moment';
const { TimePicker } = DatePicker;
type Props = TableProps & {
  modalData:any
  onCancel(_:boolean): void;
};


const StatusForm: React.FC<Props> = ({ modalData, onCancel, ...props }) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [ isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const { mutateAsync: updateMutate } = useMutation(updateStudentUpdateByStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries({
      });
      message.info('Attendance update completed.');
    },
    onError: () => {
      message.error('Update Attendance failed! Please try again');
    },
    onSettled: () => {
    },
  });

  const handleClickSetAttendances = async (id:Number, status:string) => {
    const newValues : any = {status}
    updateMutate({...newValues,id});
    onCancel(true);
  //  setAttendances(attendancesData)
  // setActive(!active);
};

  // const onCancel = () => {
  //   setIsModalVisible(false);
  // };

  const onEditClick = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    form.setFieldsValue({
      attendanceId:modalData.attendanceId,
      // studentId:studentId,
      status:'absent'
    });
  
}, 
);


  const handleOnFinish = (values: any) => {
    values.qty=Number(values.qty)
    values.studentId=Number(values.studentId)
    values.materialId=Number(values.materialId)
    // const  {data:attendancesData}  = await updateStudentAttendances(Number(id), status)
    // onClose(true);
    // onCloseListing(true);
  };


return (
  <><div className="site-form-container">
    <div className="form-content" style={{ paddingBottom: '5px' }}>
      {/* <Button type="primary" color={"#23395d"} icon={<CancelIcon />} onClick={() => handleClickSetAttendances(Number(modalData.attendanceId), 'absent')} />Absent</Button> */}
      <Button type="primary" style={{backgroundColor:'#23395d', width:'100%'}} onClick={() => handleClickSetAttendances(Number(modalData.attendanceId), 'absent')} >Absent</Button>
  </div><div className="form-content" style={{ paddingBottom: '5px' }}>
      {/* <BorderLessButton color={"secondary-color"} label='Attended' icon={<CancelIcon />} onClick={() => handleClickSetAttendances(Number(modalData.attendanceId), 'attended')} /> */}
      <Button type="primary" style={{backgroundColor:'rgb(75 35 93)', width:'100%'}} onClick={() => handleClickSetAttendances(Number(modalData.attendanceId), 'attended')} >Attended</Button>
    </div><div className="form-content" style={{ paddingBottom: '5px' }}>
      {/* <BorderLessButton color={"secondary-color"} label='Done' icon={<CancelIcon />} onClick={() => handleClickSetAttendances(Number(modalData.attendanceId), 'done')} /> */}
      <Button type="primary" style={{backgroundColor:'rgb(35 93 55)', width:'100%'}} onClick={() => handleClickSetAttendances(Number(modalData.attendanceId), 'done')} >Done</Button>
    </div><div className="form-content" style={{ paddingBottom: '5px' }}>
      {/* <BorderLessButton color={"secondary-color"} label='Finish' icon={<CancelIcon />} onClick={() => handleClickSetAttendances(Number(modalData.attendanceId), 'finish')} /> */}
      <Button type="primary" style={{backgroundColor:'grey', width:'100%'}} onClick={() => handleClickSetAttendances(Number(modalData.attendanceId), 'finish')} >Finish</Button>
    </div>

      </div></>
);
};

export default StatusForm;
function Float(price: any): any {
  throw new Error('Function not implemented.');
}

