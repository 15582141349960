import React from 'react';
import Cookies from 'js-cookie';
import ParentLoginForm from '../../shared/components/forms/ParentLoginForm';
import { ParentdoLogin } from '../../api/auth';
import './LoginPage.scss';
import { TOKEN_NAME } from '../../shared/constants';
import { useQueryClient } from 'react-query';
import { LoginI } from '../../interfaces/auth';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import useAuth from '../../shared/hooks/useAuth';

const LoginPage: React.FC = () => {
  const { setAuth }: any = useAuth();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const handleSubmit = async (values: LoginI) => {
    try {
   
      const { data }: AxiosResponse = await ParentdoLogin(values);
      setAuth(data);
      Cookies.set(TOKEN_NAME, data.authToken);
      queryClient.invalidateQueries();
      navigate('/parent/dashboard');
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <div className="login-container">
      <div className="content-page">
        <ParentLoginForm handleSubmit={handleSubmit} />
      </div>
    </div>
  );
};

export default LoginPage;
