export const APP_NAME = 'The Artisan Lab';
export const TOKEN_NAME = '_little_artisan_session';
export const PAGE_SIZE = 50;
export const DATETIME_FORMAT_FRONTEND = 'h:mm A, MMM Do, YYYY';
export const PICKUPDATE_FORMAT_FRONTEND = 'ddd, DD MMM, YYYY';
export const DATE_FORMAT_BACKEND = 'YYYY/MM/DD';
export const DATE_FORMAT_QUERY = 'YYYY-MM-DD';
export const DATETIME_FORMAT_QUERY = 'YYYY-MM-DD hh:mm:ss';
export const TIME_FORMAT_QUERY = 'HH:mm:ss';
export const DATETIME_FORMAT = 'DD-MMM-YY h:mm A';
export const DATE_FORMAT_FRONTEND = 'DD/MM/YYYY';

export const DATE_FORMAT_MONTH = 'MM/YYYY';

export const PASSWORD_TIP =
  'At least 1 uppercase, 1 lowercase, 1 numeric, 1 special character, 12 characters or longer.';

export const SUPER_ADMIN_ACCESS_ROLE = ['super_admin','finance','student'];
export const FINANCE_ACCESS_ROLE = ['super_admin','finance', 'database', 'sale'];
export const PARENT_ACCESS_ROLE = ['parent','dashboard','courses','student'];
export const STUDENT_ACCESS_ROLE = ['dashboard','student','parent','course'];
export const COACH_ACCESS_ROLE = ['dashboard','student','coach','course'];
export const SALE_ACCESS_ROLE = ['sale'];

export const STATUS = [
  { name: 'Done', value: '1' },
  { name: 'Pending', value: '0' },
];
export const USER_ROLES = [
  { name: 'Super Admin', value: 'super_admin' },
  { name: 'Finance', value: 'finance' },
  { name: 'Sale', value: 'sale' },
  { name: 'Database', value: 'database' },
];

export const MONTH_NAME = [
  { index: 1 ,name:"JANUARY", value: 'JANUARY' },
  { index: 2 ,name:"FEBRUARY", value: 'FEBRUARY' },
  { index: 3 ,name:"MARCH", value: 'MARCH' },
  { index: 4 ,name:"APRIL", value: 'APRIL' },
  { index: 5 ,name:"MAY", value: 'MAY' },
  { index: 6 ,name:"JUNE", value: 'JUNE' },
  { index: 7 ,name:"JULY", value: 'JULY' },
  { index: 8 ,name:"AUGUST", value: 'AUGUST' },
  { index: 9 ,name:"SEPTEMBER", value: 'SEPTEMBER' },
  { index: 10 ,name:"OCTOBER", value: 'OCTOBER' },
  { index: 11 ,name:"NOVEMBER", value: 'NOVEMBER' },
  {index: 12 , name:"DECEMBER", value: 'DECEMBER' },
];


export const PAYMENT_METHODS = [
  { name:"CASH", value: 'CASH' },
  { name:"ONLINE BANKING", value: 'ONLINE BANKING' },
];

export const PAYABLE_TYPE = [
  { name:"MATERIALS", value: 'materials' },
  { name:"RENTAL", value: 'rental' },
  { name:"ELECTRICITY BILL", value: 'electricity bill' },
  { name:"WATER BILL", value: 'water bill' },
  { name:"OTHERS", value: 'others' },
  { name:"SALARY", value: 'salary' },
  { name:"RENOVATION", value: 'renovation' },
  { name:"FURNITURES", value: 'furnitures' },
  { name:"DECORATION", value: 'decoration' },
  { name:"SERVER RENTAL", value: 'server rental' },
  { name:"SYSTEM MAINTENANCE", value: 'system maintenance' },
];
