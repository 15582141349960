import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Form, Input, Modal, message } from 'antd';
import { MaterialIn } from '../../../../interfaces/materialIn';
import TableBase, { Props as TableProps } from '../../tables/Base';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { NumericFormat } from 'react-number-format';
import { getMaterialsList } from '../../../../api/materials';
import { useNavigate } from 'react-router-dom';
import { QueryParams } from '../../../../interfaces';
import {studentAddMaterialQty} from '../../../../api/students';
import { PASSWORD_TIP, DATE_FORMAT_QUERY } from '../../../constants';
import { Link } from 'react-router-dom';
import CancelIcon from '../../../svgs/CancelIcon';
import CheckCircleIcon from '../../../svgs/CheckCircleIcon';
import PrimaryButton from '../../buttons/PrimaryButton';
import SecondaryButton from '../../buttons/SecondaryButton';
import { PAGE_SIZE } from '../../../../shared/constants';
import MaterialListing from '../../../../shared/components/modal/Student/MaterialListing';

import moment from 'moment';
const { TimePicker } = DatePicker;
type Props = TableProps & {
  studentId:number;
  modalData:any
  onClose(_:boolean): void;
  onCloseListing(_:boolean): void;
};


const MaterialsInQtyForm: React.FC<Props> = ({ studentId,modalData,onClose,onCloseListing, ...props }) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [ isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const onCancel = () => {
    setIsModalVisible(false);
  };

  const onEditClick = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    form.setFieldsValue({
      materialId:modalData.materialId,
      studentId:studentId,
      qty:1
    });
  
}, 
);


  const handleOnFinish = (values: any) => {
    values.qty=Number(values.qty)
    values.studentId=Number(values.studentId)
    values.materialId=Number(values.materialId)
    createMutate(values)
    onClose(true);
    onCloseListing(true);
  };


  const { mutate: createMutate } = useMutation(studentAddMaterialQty, {
    onSuccess: async () => {
      message.info('Student add material successfully!');
      queryClient.invalidateQueries({
        queryKey:[`students/${studentId}`, undefined],
      });
      navigate(`/admin/students/${studentId}`);
    },
    onError: () => {
      message.error('Student add material failed! Please try again');
    },
  });
// const navigate = useNavigate();

return (
  <div className="site-form-container">
     <div className="form-content">
  <Form form={form} requiredMark={false} colon={false} onFinish={handleOnFinish}>
          <Form.Item hidden label="MaterialId" name="materialId">
            <Input placeholder="Required" />
          </Form.Item>
          <Form.Item hidden label="StudentId" name="studentId">
            <Input placeholder="Required"/>
          </Form.Item>
            <Form.Item label="Quantity" name="qty">
            <Input placeholder="Required" value={1}/>
          </Form.Item>
            <div className="ant-form-item css-dev-only-do-not-override-yp8pcc" style={{display: "flex",justifyContent: "space-around"}}>
            <PrimaryButton 
              label='Add'
              icon={<CheckCircleIcon />}
              htmlType="submit"
            /> 
              {/* <SecondaryButton label={'Cancel'} icon={<CancelIcon />} onClick={handleCancel} />  */}
          </div>
            </Form>
    </div>
      </div>
);
};

export default MaterialsInQtyForm;
function Float(price: any): any {
  throw new Error('Function not implemented.');
}

