import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import Cookies from 'js-cookie';
import { COACH_ACCESS_ROLE, TOKEN_NAME } from '../constants';
import { useGetProfile } from '../../api/auth';
import CoachLayout from './layout/CoachLayout';
import { Spin } from 'antd';

const CoachRequireAuth: React.FC = () => {
  const { auth }: any = useAuth();
  const location = useLocation();
  const token = Cookies.get(TOKEN_NAME);
  const profile = token ? useGetProfile() : undefined;

  if (!token) {
    return <Navigate to="/coach/login" state={{ from: location }} replace />;
  }
  
  if ((auth && Object.values(auth).length > 0) || (profile && profile.isSuccess === true)) {
    if (COACH_ACCESS_ROLE.includes(auth?.role) || COACH_ACCESS_ROLE.includes(profile?.data?.role)) {
      return <CoachLayout currentUser={profile?.data || auth} />;
    } else {
      return <Navigate to="/unauthorized_Parent" state={{ from: location }} replace />;
    }
  } else {
    return (
      <div className="loading-screen">
        <Spin spinning={true}> </Spin>;
      </div>
    );
  }
};

export default CoachRequireAuth;
