import { Form, DatePicker , Input, Select, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import ImageUploader from '../../Upload/Image';
import { convertDateTimeToNumber } from '../../../../utils/global';
import { DATE_FORMAT_FRONTEND } from '../../../constants';
import { QueryParams } from '../../../../interfaces';
import { Payment } from '../../../../interfaces/payment';
import { Parent } from '../../../../interfaces/parent';
import { Student } from '../../../../interfaces/student';
import { Course } from '../../../../interfaces/course';
import { getParentsList } from '../../../../api/parents';
import { getStudentsList} from '../../../../api/students';
import { getCoursesList } from '../../../../api/courses';
import { PASSWORD_TIP } from '../../../constants';
import CancelIcon from '../../../svgs/CancelIcon';
import CheckCircleIcon from '../../../svgs/CheckCircleIcon';
import PrimaryButton from '../../buttons/PrimaryButton';
import SecondaryButton from '../../buttons/SecondaryButton';
import {PAYMENT_METHODS} from '../../../constants';
const { RangePicker } = DatePicker;
type Props = {
  isFetching?: boolean;
  paymentId?: number;
  payment?: Payment;
  handleSubmit(_: any): void;
};

const PayablesForm: React.FC<Props> = ({ payment, paymentId, isFetching, handleSubmit }) => {
  const [form] = Form.useForm();
  const [queryParams, setQueryParams] = useState<QueryParams>();
  const [listOfStudent, setListOfStudents] = useState<any>({});
  const [studentId, setstudentId] = useState('');
  const [files, setFiles] = useState<any>([]);
  const [listOfCourse, setListOfCourses] = useState<any>({});
  const { data:ParentsList, isLoading:isParentFetching } = useQuery(['parents', 'list', queryParams], () => getParentsList({}), {
    staleTime: Infinity,
  });
  const { data:StudentsList, isLoading:isStudentFetching } = useQuery(['students', 'list', queryParams], () => getStudentsList(({}),), {
    staleTime: Infinity,
  });
  const { data:CoursesList, isLoading:isCourseFetching } = useQuery(['courses', 'list', queryParams], () => getCoursesList(({}),), {
    staleTime: Infinity,
  });
  const handleOnFinish = (values: Payment) => {
    const newValues: any = values;
    if(!newValues.paymentType){
      newValues.paymentType = "ONLINE BANKING"
    }
    newValues.amount=Number(newValues.amount)
    newValues.creditClass=Number(newValues.creditClass)
    const periodStartDate =
      values.startEndDateRange && Object.values(values.startEndDateRange).length > 0
        ? convertDateTimeToNumber(dayjs(values.startEndDateRange[0]))
        : null;
    const periodEndDate =
      values.startEndDateRange && Object.values(values.startEndDateRange).length > 0
        ? convertDateTimeToNumber(dayjs(values.startEndDateRange[1]))
        : null;

    newValues.periodStartDate = periodStartDate;
    newValues.periodEndDate = periodEndDate;
    handleSubmit(newValues);
  };


  function findIndexWithDifferentId(data: any[], idArray: number[]) {
    for (let i = 0; i < data.length; i++) {
      if (!idArray.includes(data[i].id)) {
        return i;
      }
    }
    return -1;
  }

  const onParentChange = (key: number) => {
      const student = StudentsList?.data?.filter((student: Student) => student.parentId === key);
      setListOfStudents(student);
      // this.setState({selected:''})
  };

  const onStudentChange = (key: number) => {
    const student = StudentsList?.data?.find((student: Student) => student.id === key);
    const course =[]
      for(const studentCorse of student.courses){
        course.push(CoursesList?.data?.find((course: Course) => course.id === studentCorse.id))
      }
    setListOfCourses(course);
  };


  useEffect(() => {
    if (payment && payment.id === paymentId) {
      const _files = payment?.files;
      // listOfStudent = StudentsList
      const student = StudentsList?.data?.filter((student: Student) => student.parentId === payment.parent.id);
      setListOfStudents(student);
      const course = CoursesList?.data?.filter((course: Course) => course.id === payment.course.id);
      setListOfCourses(course);
      form.setFieldsValue({
        ...payment,
        startEndDateRange:
        payment?.periodStartDate && payment?.periodEndDate ? [dayjs(payment.periodStartDate), dayjs(payment.periodEndDate)] : null,
        parentId:payment.parent.id,
        courseId:payment.course.id,
        studentId:payment.student.id,
        files: { fileList: payment.files?.filter((d: any) => d) },
      });
      setFiles(
        _files?.map((d: any) => {
          return { ...d, url: d.s3Url };
        }),
      );
    }
  }, [payment, StudentsList]); 
  return (
    <div className="payment-form-container">
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        onFinish={handleOnFinish}
        scrollToFirstError
        autoComplete="off"
        initialValues={{ isActive: true }}
      >
        <div className="form-container">
          <div className="form-content">
          <Form.Item label="Parent" name= 'parentId'rules={[{ required: true, message: 'Required' }]}  >
            <Select placeholder="Required" showSearch optionFilterProp="children" loading={isParentFetching} 
             onChange={(value) => onParentChange(value)}>
               {ParentsList?.data?.map((parent: Parent) => (
                                <Select.Option key={parent.id} value={parent.id}>
                                  {parent.firstName}{parent.lastName} ({parent.email})
                                </Select.Option>
                              ))}
                            </Select >
                          </Form.Item>

                          <Form.Item label="Student" name='studentId'rules={[{ required: true, message: 'Required' }]}  >
            <Select placeholder="Required" showSearch optionFilterProp="children"  onChange={(value) => onStudentChange(value)}>
               {Object.values(listOfStudent).map((student: any) => (
                                <Select.Option key={student.id} value={student.id}>
                                  {student.firstName}{student.lastName}({student.email})
                                </Select.Option>))}
                            </Select>
                          </Form.Item>

                          <Form.Item label="Course" name= 'courseId'rules={[{ required: true, message: 'Required' }]}  >
            <Select placeholder="Required" showSearch optionFilterProp="children" loading={isCourseFetching} >
               {Object.values(listOfCourse).map((course: any) => (
                                <Select.Option key={course.id} value={course.id}>
                                  {course.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
           <Form.Item
              name="startEndDateRange"
              label="Start - End Date"
              rules={[{ required: true, message: 'required' }]}
            >
              <RangePicker className="ant" format={DATE_FORMAT_FRONTEND} />
            </Form.Item>
            <Form.Item label="Amount" name="amount">
              <Input placeholder="Optional"  />
            </Form.Item>
            <Form.Item label="Payment Type" name='paymentType'rules={[{ message: 'Required' }]}  >
              <Select placeholder="Payment Type" defaultValue="ONLINE BANKING">
                  {PAYMENT_METHODS.map((item: any, index: number) => (
                    <Select.Option key={item.name} value={item.name}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
            </Form.Item>
            <Form.Item label="Credit Class" name="creditClass">
              <Input placeholder="Optional" />
            </Form.Item>
            <Form.Item label="Remark" name="remark">
              <Input placeholder="Optional" />
            </Form.Item>
            <Form.Item label="Status" name="status" valuePropName="checked">
              <Switch />
            </Form.Item>
            <Form.Item label="File">
              <ImageUploader
                name="posterImage"
                documents={files}
                setDocuments={setFiles}
                multiple={false}
                maxCount={2}
                acceptType="image/png, image/jpeg"
              />
            </Form.Item>
          </div>
          <div className="form-footer">
            <PrimaryButton
              loading={isFetching}
              label={paymentId ? 'Update' : 'Create'}
              icon={<CheckCircleIcon />}
              htmlType="submit"
            />
            <Link to="/admin/payments">
              <SecondaryButton label={'Cancel'} icon={<CancelIcon />} />
            </Link>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default PayablesForm;
