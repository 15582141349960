import { Spin, message } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { deleteStudent, getStudentsList, updateStudent } from '../../../api/students';
import { QueryParams } from '../../../interfaces';
import { Student } from '../../../interfaces/student';
import StudentClassWorks from './StudentClassWorks/View';
import { PAGE_SIZE } from '../../../shared/constants';

const classWorksPage: React.FC = () => {
  const queryClient = useQueryClient();
  const [queryParams, setQueryParams] = useState<QueryParams>({ start: 0, count: PAGE_SIZE ,filter:{isActive:1}});

  const { data, isLoading } = useQuery(['students', 'list', queryParams], () => getStudentsList(queryParams), {
    staleTime: Infinity,
  });

  const currentPage = queryParams.start ? Math.round((Number(queryParams.start) - 1) / PAGE_SIZE + 1) : 1;

  const { mutateAsync: updateMutate } = useMutation(updateStudent, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['students', 'list'],
      });
      message.info('Unblocked this student.');
    },
    onError: () => {
      message.error('Update student failed! Please try again');
    },
  });


  return (
  <Spin spinning={isLoading}>
      <div className="students-container">
        <div className="page-container" style={{padding:'2px'}}>
            {data?.data.map((student: Student) => (
               <StudentClassWorks  
               student={student}
               />  
            ))}
        </div>
      </div> 
    </Spin>
  );
 
};

export default classWorksPage;
