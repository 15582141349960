import { Form, Input, Select } from 'antd';
import React, { useState } from 'react';
import { MONTH_NAME , PAGE_SIZE} from '../../../shared/constants';
import ClearIcon from '../../../shared/svgs/ClearIcon';
import SearchIcon from '../../../shared/svgs/SearchIcon';
import PrimaryButton from '../../../shared/components/buttons/PrimaryButton';
import SecondaryButton from '../../../shared/components/buttons/SecondaryButton';
import { getCoachesList } from '../../../api/coaches';
import { QueryParams } from '../../../interfaces';
import { useQuery } from 'react-query';
type Props = {
  handleFilter(_: any): void;
  handleReset(): void;
};

const CoachLeaveFilterForm: React.FC<Props> = ({ handleFilter, handleReset }) => {
  const [form] = Form.useForm();
  const [queryParams, setQueryParams] = useState<QueryParams>({ start: 0, count: PAGE_SIZE });
  const { data:CoachList, isLoading } = useQuery(['coaches', 'list', queryParams], () => getCoachesList(queryParams), {
    staleTime: Infinity,
  })
  const handleOnFinish = (values: any) => {
    handleFilter(values);
  };

  const handleResetForm = () => {
    form.resetFields();
    handleReset();
  };

  return (
    <div className="users-filter-form-container filter-form-container">
      <Form form={form} requiredMark={false} colon={false} onFinish={handleOnFinish} scrollToFirstError>
        <div className="form-container">
          <div className="form-content">
            <Form.Item name="year">
              <Input placeholder="Year" />
            </Form.Item>
            <Form.Item name="month">
              <Select placeholder="Month">
                {MONTH_NAME.map((item: any, index: number) => (
                  <Select.Option key={index} value={item.index}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="form-footer">
            <PrimaryButton label={'Search'} htmlType="submit" icon={<SearchIcon />} />
            <SecondaryButton label={'Reset'} onClick={handleResetForm} icon={<ClearIcon />} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default CoachLeaveFilterForm;
