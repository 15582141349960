import { DownOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Dropdown, Layout, MenuProps } from 'antd';
import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { TOKEN_NAME } from '../../constants';
import useAuth from '../../hooks/useAuth';
import useDetectScroll, { Direction } from "@smakss/react-scroll-direction";

const { Header } = Layout;

type Props = {
  currentUser?: any;
};

const HeaderMain: React.FC<Props> = ({ currentUser }) => {
  const { setAuth }: any = useAuth();
  const queryClient = useQueryClient();
  function refreshPage() {
    window.location.reload();
  }
  const handleLogout = () => {
    Cookies.remove(TOKEN_NAME);
    setAuth(undefined);
    queryClient.invalidateQueries();
    window.location.href = 'admin/login';
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <Button onClick={handleLogout}>Log Out</Button>,
    },
  ];

  return (
    <Header className="site-header" style={{backgroundColor:"#23395d"}}>
      <div className="logo"></div>
      <Button style={{backgroundColor:"#23395d", width:'100%'}} type="primary" onClick={refreshPage}><ReloadOutlined /></Button>
      <div className="account-info">
        <div className="avatar">
       
          <img
            src={`https://ui-avatars.com/api/?length=1&name=${
              currentUser?.firstName ? `${currentUser?.firstName} ${currentUser?.lastName}` : currentUser?.email
            }&size=32&font-size=0.4&bold=true&background=5f5f5f&color=FFFFFF&rounded=true`}
            alt=""
          />
        </div>
        <div className="info" style={{color:"#ffffff"}} >
          <div className="fullname" style={{color:"#ffffff"}} >{`${currentUser?.firstName} ${currentUser?.lastName}`}</div>
          <Dropdown overlayClassName="header-info-dropdown" menu={{ items }}>
            <div className="email">
              {currentUser?.email} <DownOutlined />
            </div>
          </Dropdown>
        </div>
      </div>
    </Header>
  );
};

export default HeaderMain;
