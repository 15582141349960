import { Button } from 'antd';
import React from 'react';
import './SecondaryButton.scss';

type Props = {
  label?: string;
  icon?: any;
  htmlType?: 'button' | 'submit' | 'reset';
  loading?: boolean;
  color?: 'primary-color' | 'secondary-color' | 'danger-color';
  className?: string;
  onClick?: () => void;
};

const SecondaryButton: React.FC<Props> = ({ label, icon, htmlType, loading, color, className, onClick }) => {
  return (
    <Button
      type="default"
      className={`btn-secondary ${color} ${className}`}
      htmlType={htmlType}
      loading={loading}
      onClick={onClick}
    >
      {label}
      {icon}
    </Button>
  );
};

export default SecondaryButton;
