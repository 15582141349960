import React from 'react';
import { MaterialRequest } from '../../../interfaces/materialRequest';
import { DATE_FORMAT_FRONTEND,MONTH_NAME } from '../../../shared/constants';
import TableBase, { Props as TableProps } from './Base';
import {EditOutlined,CheckCircleOutlined} from '@ant-design/icons';
import dayjs, { Dayjs } from 'dayjs';
import DocumentViewer from '../../../shared/components/Upload/DocumentViewer';
type Props = TableProps & {
  withAction?(_: any): void;
  materialRequest?: any[];
};

const MaterialRequestsTable: React.FC<Props> = ({ materialRequest, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: 'material',
      width: 20,
      render: (materialRequest: MaterialRequest) => {
        return (
           <span className="value" style={{width: '10%'}}>
                {/* {materialRequest.materialId}  */}
                 {materialRequest && <DocumentViewer files={materialRequest?.material?.files} />}
            </span>
        );
      },
   
    },
    {
      title: 'student',
      width: 80,
      render: (materialRequest: MaterialRequest) => {
        return (
           <span className="value" style={{width: '150px'}}>
                {materialRequest.student.chineseName} 
                <br></br><br></br>
                Quantity: {materialRequest.qty} 
                <br></br><br></br>
                Amount :RM {materialRequest.amount}.00 
            </span>
        );
      },
   
    },
    {
      title: 'isPaid',
      dataIndex: 'isPaid',
      width: 25,
      render: (isPaid: boolean) => {
        return (
          <span className={`table-status ${isPaid ? 'active' : 'inactive'}`}>{isPaid ? 'Paid' : 'UnPaid'}</span>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 25,
      render: (status: string) => {
        return (
          <span className={`table-status ${status=="Pending" ? 'inactive' : 'active'}`}>{status}</span>
        );
      },
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 10,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={materialRequest} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default MaterialRequestsTable;
