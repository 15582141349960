import { pathToUrl } from '../utils/router';
import { Payroll } from '../interfaces/payroll';
import { GetListResponse } from '../interfaces';
import { useFetch, usePost } from '../shared/reactQuery';
import { api } from '../shared/api';
import { handleFilterParams } from '../utils/global';
import request from '../utils/request';
export const useGetPayrollsList = () => useFetch<GetListResponse>('payrolls');
export const useGetPayroll = (id: number) => useFetch<Payroll>(`payrolls/${id}`);
export const useCreateUser = (updater: (oldData: Payroll[], newData: Payroll) => Payroll[]) => {
  return usePost<Payroll[], Payroll>(pathToUrl('payrolls'), undefined, updater);
};

export const getPayrollsList = async ({ start, count, filter }: { start?: number; count?: number; filter?: any }) => {
  const newParams = handleFilterParams({
    start,
    count,
    filter,
  });
  const { data: response }: any = await api.get(`payrolls?${newParams}`);
  return response;
};

export const getPaymentsExpiredList = async ({ start, count, filter }: { start?: number; count?: number; filter?: any }) => {
  const newParams = handleFilterParams({
    start,
    count,
    filter,
  });
  const { data: response }: any = await api.get(`payments/getExpiredList?${newParams}`);
  return response;
};

export const getPaymentsReceiptList= async ({ start, count, filter }: { start?: number; count?: number; filter?: any }) => {
  const newParams = handleFilterParams({
    start,
    count,
    filter,
  });
  const { data: response }: any = await api.get(`payments/getReceiptList?${newParams}`);
  return response;
};


export const createPayroll = async (values: Payroll) => {
  const { data: response }: any = await api.post('payrolls', values);
  return response.data;
};

export const updatePayroll = async ({ id, ...values }: { id: string; values: Payroll }) => {
  const { data: response }: any = await api.patch(`payrolls/${id}`, values);
  return response.data;
};

export const deletePayroll = async (id: number) => {
  const { data: response }: any = await api.delete(`payrolls/${id}`);
  return response.data;
};
