import React from 'react';
import ReactDOM from 'react-dom/client';
// import reportWebVitals from './reportWebVitals';
import WebFont from 'webfontloader';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { message } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './shared/contexts/AuthProvider';
import App from './App';
import './global.scss';

function configFonts() {
  WebFont.load({
    google: {
      families: ['Inter'],
    },
  });
}

configFonts();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: 3,
      staleTime: 5 * 60 * 1000,
    },
  },
  queryCache: new QueryCache({
    onError: (error: any, query) => {
      console.log('error', error);
      console.log('query', query);

      if (query.state.data !== undefined) {
        message.error(`Something went wrong: ${error?.message}`);
      }
    },
  }),
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

