import { message } from 'antd';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { createMaterialRequest, getMaterialRequestsList } from '../../../api/materialRequests';
import { MaterialRequest } from '../../../interfaces/materialRequest';
import MaterialRequestForm from './Form';
import { PAGE_SIZE } from '../../../shared/constants';

const MaterialRequestNewPage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: createMutate } = useMutation(createMaterialRequest, {
    onSuccess: async () => {
      message.info('Create coach successfully!');
      queryClient.invalidateQueries({
        queryKey: ['materialRequests', 'list'],
      });
      navigate(`/admin/materialRequests`);
    },
    onError: () => {
      message.error('Create coach failed! Please try again');
    },
    onSettled: () => {
      queryClient.prefetchQuery({
        queryKey: ['materialRequests', 'list', { start: 0, count: PAGE_SIZE, filter: undefined }],
        queryFn: () => getMaterialRequestsList({ start: 0, count: PAGE_SIZE, filter: undefined }),
      });
    },
  });

  const handleSubmit = async (values: MaterialRequest) => {
    createMutate(values);
  };

  return (
    <div className="coaches-container">
      <div className="page-container">
        <div className="page-content">
          <div className="page-content-header">
            <h2 className="title-page">Add New Parent</h2>
          </div>
          <MaterialRequestForm handleSubmit={handleSubmit} />
        </div>
      </div>
    </div>
  );
};

export default MaterialRequestNewPage;
