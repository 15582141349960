import React from 'react';
import { Layout } from 'antd';
import { LoginResponseI } from '../../../interfaces/auth';
import ParentHeaderMain from './ParentHeaderMain';
import { Outlet } from 'react-router-dom';
import ParentSiderContent from '../siderContents/Parent';

type Props = {
  currentUser: LoginResponseI;
};

const ParentLayout: React.FC<Props> = ({ currentUser }) => {
  return (
    <Layout className="database-layout">
      <ParentSiderContent />
      <Layout className="site-layout">
        <ParentHeaderMain currentUser={currentUser} />
        <Outlet />
      </Layout>
    </Layout>
  );
};

export default ParentLayout;
