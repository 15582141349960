import { Button, Dropdown,message, Modal, Spin, Collapse } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetStudent,} from '../../../api/students';
import { useGetStudentAttendances ,deleteStudent} from '../../../api/students';
import { QueryParams } from '../../../interfaces';
import { Attendances } from '../../../interfaces/attendances';
import { File } from '../../../interfaces/file';
import { Course } from '../../../interfaces/course';
import { ClassWorks} from '../../../interfaces/classWorks';
import CourseForm from '../../../shared/components/forms/course/Form';
import { getCoachesList } from '../../../api/coaches';
import ClassWorksCardView from '../../../shared/components/cardViews/ClassWorks/ClassWorksView';
import { DATE_FORMAT_FRONTEND, PAGE_SIZE } from '../../../shared/constants';
import DangerButton from '../../../shared/components/buttons/DangerButton';
import CourseEnrolledStudents from '../../../shared/components/tables/CourseEnrolledStudents';
import CourseStudentAttendances from '../../../shared/components/tables/CourseStudentAttendances';
import { Link } from 'react-router-dom';
import SecondaryButton from '../../../shared/components/buttons/SecondaryButton';
import AddCircleOutlineIcon from '../../../shared/svgs/AddCircleOutlineIcon';
import DocumentFileIcon from '../../../shared/svgs/DocumentFileIcon';
import CancelIcon from '../../../shared/svgs/CancelIcon';
const { Panel } = Collapse;
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
import moment from 'moment';
import { deleteStudentFromCourse } from '../../../api/courses';
const AttendancesPage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [queryParams] = useState<QueryParams>({ start: 0, count: PAGE_SIZE });
  const { id } = useParams<{ id: string }>();
  const { data: studentAttendances, isLoading } = useGetStudentAttendances(Number(id));
  // const { data: coaches, isFetching: isCoachFetching } = useQuery(['coaches', 'list'], () => getCoachesList({}));
  // const { data: studentsData, isFetching: isStudentFetching } = useQuery(['students', 'list', queryParams], () => getStudentsList(queryParams), {
  //   staleTime: Infinity,
  // });

  const currentPage = queryParams.start ? Math.round((Number(queryParams.start) - 1) / PAGE_SIZE + 1) : 1;
  const pagination: any = {
    current: currentPage,
    pageSize: PAGE_SIZE,
    total: studentAttendances?.courses?.studentAttendances?.total || 0,
    showTotal: (total: string) => `${total} Items`,
  };

  // const paginationStudents: any = {
  //   current: currentPage,
  //   pageSize: PAGE_SIZE,
  //   total: studentsData?.students.total || 0,
  //   showTotal: (total: string) => `${total} Items`,
  // };
  const [ isEmergencyContactModalVisible, setIsEmergencyContactModalVisible] = useState<boolean>(false);

  const onEmergencyContactCancel = () => {
    setIsEmergencyContactModalVisible(false);
  };

  const onEditClick = () => {
    setIsEmergencyContactModalVisible(true);
  };
  // const { mutateAsync: mutationUpdate } = useMutation(deleteStudentFromCourse);

  const handleClickDelete = async (courseId : number , studentId : number) => {
    await deleteStudentFromCourse(courseId,studentId);
    queryClient.invalidateQueries({
      queryKey: ['students', 'list'],
    });
  };


  return (
        <Spin spinning={isLoading}>
      <div id="user-details-container">
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page" style={{fontSize:'26px'}}>Class Attendances</h2>
            </div>
            <div className="details-container">
              <div className="item">
                <label>Student Name</label>
                <span className="value">{studentAttendances?.firstName} {studentAttendances?.lastName}</span>
              </div>
              <div className="item">
                <label>Course</label>
                <span className="value">
                  {studentAttendances?.courses[0].name}
                </span>
              </div>
              <div className="item">
                <label>End At</label>
                <span className="value">
                {studentAttendances?.courses[0].startAt} - {studentAttendances?.courses[0].endAt}
                </span>
              </div>
            </div>
          </div>
          {studentAttendances?.courses.map((c: Course) => (
            <div className="page-content">
              <div className="page-content-header">
                <h2 className="title-page" style={{ fontSize: '26px' }}> {c.name}</h2>
              </div>
              <div className="details-container">
                <div className="item">
                  <label>Start At</label>
                  <span className="value">{c.startAt}</span>
                </div>
                <div className="item">
                  <label>End At1</label>
                  <span className="value">
                  {c.endAt}
                  </span>
                </div>
                <><Collapse  expandIconPosition="end" style={{color:'#939ca3 !important'  ,backgroundColor:'#23395d', alignItems:'self-end',fontSize:'large', borderRadius: '36px' ,marginBottom: '10px'}}>
                <Panel style={{ color: 'snow' , textAlign:'center'}} key="1" className="collapse-custom-header" header={"Attendances & Class Work"}>
                {c?.attendances.map((a: Attendances) => (
                 <> <Collapse  expandIconPosition="end" style={{color:'#939ca3 !important'  ,backgroundColor:'#23395d', alignItems:'self-end',fontSize:'large', borderRadius: '36px' ,marginBottom: '10px'}}>
                {/* <><Collapse   collapsible={a.studentAttendances[0].status=='attended' ? "enable" : "disabled"} expandIconPosition="end"> */}
                    <Panel style={{ color: 'snow', textAlign:'left' }} header={a.location + ' - ' + dayjs(a.date).format(DATE_FORMAT_FRONTEND) + ' - ' +' <'+ a.startAt+ '-' + a.endAt + '>  ' + a.studentAttendances[0].status +''+ (a.studentAttendances[0].status=='attended' ? '  ✅...Click To View Class Work':'  ❌')} key="1" className="collapse-custom-header">
                    {a?.classWorks.map((cw: ClassWorks) => (
                        <div className='ant-collapse-content-box'>
                        {cw.files?.map((file: File) => (
                          <div className='ant-collapse-content-box'>
                              <ClassWorksCardView file={file}  />
                            </div>
                            ))}
                        </div>
                        ))}
                    </Panel>
                  </Collapse><div style={{paddingTop:'10px'}}></div></>
                ))}
                  </Panel>
                </Collapse><div style={{paddingTop:'10px'}}></div></>
              
              </div>
              </div>
          ))}
             </div>
          </div>
    </Spin>
  );
};

export default AttendancesPage;
