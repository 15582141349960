import { Form, Input,DatePicker, Select, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { convertDateTimeToNumber } from '../../../../utils/global';
import { Link } from 'react-router-dom';
import { Coach } from '../../../../interfaces/coach';
import { PASSWORD_TIP, USER_ROLES } from '../../../constants';
import CancelIcon from '../../../svgs/CancelIcon';
import CheckCircleIcon from '../../../svgs/CheckCircleIcon';
import PrimaryButton from '../../buttons/PrimaryButton';
import SecondaryButton from '../../buttons/SecondaryButton';
import { DATE_FORMAT_FRONTEND } from '../../../constants';
type Props = {
  isFetching?: boolean;
  coachId?: number;
  coach?: any;
  handleSubmit(_: any): void;
};

const CoachForm: React.FC<Props> = ({ coach, coachId, isFetching, handleSubmit }) => {
  const [password, setPassword] = useState<any>({});
  const [form] = Form.useForm();
  const passwordTips = PASSWORD_TIP;
  function validatePassword(value: string) {
    const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{12,})');
    if (value === '') {
      return {
        validateStatus: 'error',
        errorMsg: 'Required',
      };
    }

    if (!value || strongRegex.test(value)) {
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    }

    return {
      validateStatus: 'error',
      errorMsg: 'Your password too weak',
    };
  }

  const onPasswordChange = (event: any) => {
    const { value } = event.target;
    setPassword({ ...validatePassword(value), value });
  };

  const handleOnFinish = (values: Coach) => {
    const newValues: any = values;
    if (coachId) {
      delete newValues.password;
    }
    newValues.joinDate = convertDateTimeToNumber(dayjs(newValues.joinDate))
    handleSubmit(newValues);
  };

  useEffect(() => {
    if (coach && coach.id === coachId) {
      coach.joinDate = dayjs(coach.joinDate)
      form.setFieldsValue(coach);
    }
  }, [coach]);

  return (
    <div className="coach-form-container">
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        onFinish={handleOnFinish}
        scrollToFirstError
        autoComplete="off"
        initialValues={{ isActive: true }}
      >
        <div className="form-container">
          <div className="form-content">
            <Form.Item
              label="Name"
              name="email"
              rules={[
                { required: true, message: 'Required' },
              ]}
            >
              <Input type="name" placeholder="Required"  />
            </Form.Item>
            <Form.Item label="contact" name="contact">
              <Input placeholder="Optional" />
            </Form.Item> 
            <Form.Item label="First Name" name="firstName">
              <Input placeholder="Optional" />
            </Form.Item>
            <Form.Item label="Last Name" name="lastName">
              <Input placeholder="Optional" />
            </Form.Item>
             <Form.Item
              name="joinDate"
              label="Join Date"
              rules={[{ required: true, message: 'required' }]}
            >
              <DatePicker className="ant" format={DATE_FORMAT_FRONTEND} />
              </Form.Item>
            <Form.Item label="Active" name="isActive" valuePropName="checked">
              <Switch />
            </Form.Item>
          </div>
          <div className="form-footer">
            <PrimaryButton
              loading={isFetching}
              label={coachId ? 'Update' : 'Create'}
              icon={<CheckCircleIcon />}
              htmlType="submit"
            />
            <Link to="/admin/coaches">
              <SecondaryButton label={'Cancel'} icon={<CancelIcon />} />
            </Link>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default CoachForm;
