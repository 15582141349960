import React from 'react';
import { Form,Collapse,Spin  } from 'antd';
import { Course } from '../../../interfaces/course';
import { Student } from '../../../interfaces/student';
import { humanizeText } from '../../../utils/global';
import TableBase, { Props as TableProps } from './Base';
import { Link } from 'react-router-dom';
import PrimaryButton from '../../../shared/components/buttons/PrimaryButton';
import AttendancesListCardView from './AttendancesListCardView';
const { Panel } = Collapse;
type Props = TableProps & {
  withAction?(_: any): void;
  course?: Course;
};

const AttendancesCoursesCardView: React.FC<Props> = ({ course, withAction, ...props }) => {

  const handleDelete = (student :any) => {
    // handleClickDelete(student)
  };

  return (
    // <div className="card-row">maxWidth:"100%", 
  <div className="col-sm-12"  style={{paddingRight:"1px", width:"100%",paddingLeft:"1px"}}>
    <div className="card-row">
  <div className="card" style={{marginLeft:'10px'}}>
  <div className="card-body">
    <img src="/boy.png" alt="avatar"className="rounded-circle img-fluid" style={{width: '150px'}} />
 </div>
 <h4 className="card-title" style={{textAlign:"center"}}>
 <span>{course?.name}</span><br></br>
 <span>{course?.day}</span><br></br>
 {course?.startAt} - {course?.endAt}
 </h4>
 <ul className="list-group list-group-flush">
 <Collapse expandIconPosition="end">
          <Panel style={{ color: '#ffff', textAlign: 'center' }} className="collapse-custom-header" header={`Attendances`} key={Number(course?.id)}>
            {/* <div className='ant-collapse-content-box'> */}
                  <AttendancesListCardView 
                  courseId={course?.id}             
                    />
            {/* </div> */}
            </Panel>
      </Collapse>
  </ul>
    </div>
    </div>
</div>
    )
};

export default AttendancesCoursesCardView;
