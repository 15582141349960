import { Button, Modal } from 'antd';
import React from 'react';
import './DangerButton.scss';
import { ExclamationCircleOutlined } from '@ant-design/icons';

type Props = {
  label?: string;
  content?: string;
  icon?: any;
  htmlType?: 'button' | 'submit' | 'reset';
  loading?: boolean;
  style?: any;
  onCancel?: () => void;
  onOk?: () => void;
};

const DangerButton: React.FC<Props> = ({ label, icon, htmlType, loading, style, content, onOk, onCancel }) => {
  const { confirm } = Modal;
  function showConfirm() {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: content || 'Do you really want to delete this one?',
      onOk() {
        onOk && onOk();
      },
      onCancel() {
        onCancel && onCancel();
      },
    });
  }
  return (
    <Button
      type="default"
      className="btn-danger"
      htmlType={htmlType}
      loading={loading}
      style={style}
      onClick={showConfirm}
    >
      {label}
      {icon}
    </Button>
  );
};

export default DangerButton;
