import { message, Spin } from 'antd';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { getPayablesList, updatePayable, useGetPayable } from '../../../api/payables';
import PayableForm from '../../../shared/components/forms/payable/Form';
import { PAGE_SIZE } from '../../../shared/constants';

const PayableUpdatePage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useGetPayable(Number(id));

  const { mutateAsync: updateMutate } = useMutation(updatePayable, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['payables', 'list'],
      });
      queryClient.invalidateQueries({
        queryKey: [`payables/${id}`, undefined],
      });
      message.info('Update payable successfully!');
      navigate(`/admin/payables`);
    },
    onError: () => {
      message.error('Update payable failed! Please try again');
    },
    onSettled: () => {
      queryClient.prefetchQuery({
        queryKey: ['payables', 'list', { start: 0, count: PAGE_SIZE, filter: undefined }],
        queryFn: () => getPayablesList({ start: 0, count: PAGE_SIZE, filter: undefined }),
      });
    },
  });

  const handleSubmit = async (values: any) => {
    updateMutate({ ...values, id });
  };

  return (
    <Spin spinning={isLoading}>
      <div className="users-container">
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page">Edit User</h2>
            </div>
            <PayableForm handleSubmit={handleSubmit} payable={data} payableId={Number(id)} />
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default PayableUpdatePage;
