import * as openpgp from 'openpgp';
import apiPublicKeyProd from '../configs/pgpKeys/apiPublicKeyProd';

const pgpEncrypt = async (text) => {
  const message = await openpgp.createMessage({ text: text });
  const key = await openpgp.readKey({ armoredKey: apiPublicKeyProd });
  const encrypted = await openpgp.encrypt({
    message,
    encryptionKeys: key,
  });
  return encrypted;
};

export { pgpEncrypt };
