import React from 'react';
import { Collapse  } from 'antd';
import { Course } from '../../../interfaces/course';
import { Student } from '../../../interfaces/student';
import { Props as TableProps } from './Base';
import { Link } from 'react-router-dom';
import PrimaryButton from '../../../shared/components/buttons/PrimaryButton';
const { Panel } = Collapse;
type Props = TableProps & {
  handleClickDelete(_: any): void;
  course?: Course;
};

const CoursesCardView: React.FC<Props> = ({ course, handleClickDelete, ...props }) => {

  const handleDelete = (student :any) => {
    handleClickDelete(student)
  };

  return (
  <div className="col-sm-12"  style={{paddingRight:"1px", width:"100%",paddingLeft:"1px"}}>
    <div className="card-row">
  <div className="card" style={{marginLeft:'10px'}}>
  <div className="card-body">
    <img src="/boy.png" alt="avatar"className="rounded-circle img-fluid" style={{width: '150px'}} />
 </div>
 <h4 className="card-title" style={{textAlign:"center"}}>
 <span>{course?.students.length}</span><br></br>
 <span>{course?.name}</span><br></br>
 <span>{course?.day}</span><br></br>
 {course?.startAt.substring(0, 5)} - {course?.endAt.substring(0, 5)}
 
 </h4>
 <ul className="list-group list-group-flush">
  <li className="list-group-item">
    {course?.students?.map((student: Student) => (
      <span>{student.chineseName}({student.age})&emsp;</span>
    ))}
  </li>
 </ul>
 <Collapse expandIconPosition="end"  style={{color:'#939ca3 !important'  ,backgroundColor:'#23395d',fontSize:'large'}}>
            {/* <Panel style={{ color: 'snow' , textAlign:'center'}}  key={attendances.id}  className="collapse-custom-header" header={`${attendances.name}<${attendances.startAt}-${attendances.endAt}>`}> */}
            <Panel key={Number(course?.id)} style={{ color: 'snow' , textAlign:'center'}} header={"Actions"} className="collapse-custom-header">
            <div style={{width:"100%"}}>
              <Link style={{width:"100%", marginBottom:"10px"}}to={`/admin/courses/${course?.id}`}>
                        <PrimaryButton style={{width:"100%" ,marginBottom:"10px",borderRadius:"20px" }} label="View" />
                  </Link>
                  <Link style={{width:"100%",marginBottom:"10px"}} to={`/admin/courses/${course?.id}/edit`}>
                  <PrimaryButton  style={{width:"100%" ,marginBottom:"10px",borderRadius:"20px" }}label="Edit" />
                  </Link>
                  <PrimaryButton style={{width:"100%" ,color:"red" ,borderRadius:"20px" }} label="Delete" onClick={() => handleDelete(course)} />
                  </div>
            </Panel>
        </Collapse>
    </div>
    </div>
</div>
    )

};

export default CoursesCardView;
