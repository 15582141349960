import React, { useEffect, useState }  from 'react';
import { Form, Input, Select, Spin, Switch } from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import  { Props as TableProps } from '../Base';
import PrimaryButton from '../../../../shared/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../shared/components/buttons/SecondaryButton';
import CheckCircleIcon from '../../../../shared/svgs/CheckCircleIcon';
import CancelIcon from '../../../../shared/svgs/CancelIcon';
import ImageUploader from '../../../../shared/components/Upload/Image';
import DocumentViewer from '../../../../shared/components/Upload/DocumentViewer';
import JoditEditor from 'jodit-react';
const { TextArea } = Input;
type Props = TableProps & {
  modalData:any
   handleCancel(): void;
   handleAddClassWorks(_: any): void;
};


const CourseStudentsUpdateClassWorks: React.FC<Props> = ({modalData,handleCancel,handleAddClassWorks,...props }) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [files, setFiles] = useState<any>([]);
  const [isloading, setLoading] = useState<boolean>(false);
// const navigate = useNavigate();
const handleSubmit = (values: any) => {
  setLoading(true)
  handleAddClassWorks(values);
};



const handleParams = (values: any) => {
  const newValues: any = values;
  newValues.files = files;
  return newValues;
};
const handleOnFinish = (values: any) => {
  const params = handleParams(values);
  handleSubmit(params);
  
  
};
useEffect(() => { 
    form.setFieldsValue({
      courseId:modalData.courseId,
      studentId:modalData.studentId,
      studentAttendanceId:modalData.studentAttendanceId,
      date:modalData.date
    });


}, 
[modalData.studentId,modalData.attendancesId,modalData.courseId]);

return (
  <Spin tip="Loading" spinning={isloading}>
  <div className="site-form-container">
     
  <Form form={form} requiredMark={false} colon={false} onFinish={handleOnFinish} scrollToFirstError>
   
            <Form.Item >
              <ImageUploader name="files" documents={files} setDocuments={setFiles} multiple={true} maxCount={10} />
            </Form.Item>
            <Form.Item label="studentId"  hidden name="studentId">
            <Input placeholder="Required" />
            </Form.Item>
            <Form.Item label="studentAttendanceId"  hidden  name="studentAttendanceId">
            <Input placeholder="Required" />
            </Form.Item>
            <Form.Item label="date"  hidden  name="date">
            <Input placeholder="Required" />
            </Form.Item>
            <Form.Item label="courseId"  hidden  name="courseId">
            <Input placeholder="Required" />
            </Form.Item>
            <Form.Item  name="comment">
              <TextArea rows={8} value="" />
            </Form.Item>
            <div className="ant-form-item css-dev-only-do-not-override-yp8pcc" style={{display: "flex",justifyContent: "space-around"}}>
            <PrimaryButton 
              label='Submit'
              icon={<CheckCircleIcon />}
              htmlType="submit"
           
            /> 
              <SecondaryButton label={'Cancel'} icon={<CancelIcon />} onClick={handleCancel} /> 
          </div>
            </Form>
    </div>

        
    </Spin>
);
};

export default CourseStudentsUpdateClassWorks;
