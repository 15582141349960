import { pathToUrl } from '../utils/router';
import { Parent } from '../interfaces/parent';
import { Student } from '../interfaces/student';
import { GetListResponse } from '../interfaces';
import { useFetch, usePost } from '../shared/reactQuery';
import { api } from '../shared/api';
import { handleFilterParams } from '../utils/global';

export const useGetParentsList = () => useFetch<GetListResponse>('parents');

export const useGetParent = (id: number) => useFetch<Parent>(`parents/${id}`);

export const useGetParentDashboard = (id: number) => useFetch<Parent>(`parents/${id}/dashboard`);

// export const useGetAllParentsDashboard = () => useFetch<Parent>(`parents/allParentsdashboard`);

export const useGetParentStudents = (id: number) => useFetch<Student>(`parents/${id}/studentDetails`);


export const useCreateUser = (updater: (oldData: Parent[], newData: Parent) => Parent[]) => {
  return usePost<Parent[], Parent>(pathToUrl('parents'), undefined, updater);
};

export const useGetAllParentsDashboard = async ({ start, count, filter }: { start?: number; count?: number; filter?: any }) => {
  const newParams = handleFilterParams({
    start,
    count,
    filter,
  });
  const { data: response }: any = await api.get(`parents/allParentsdashboard?${newParams}`);
  return response;
};

export const getParentsList = async ({ start, count, filter }: { start?: number; count?: number; filter?: any }) => {
  const newParams = handleFilterParams({
    start,
    count,
    filter,
  });
  const { data: response }: any = await api.get(`parents?${newParams}`);
  return response;
};

export const createParent = async (values: Parent) => {
  const { data: response }: any = await api.post('parents', values);
  return response.data;
};

export const updateParent = async ({ id, ...values }: { id: string; values: Parent }) => {
  const { data: response }: any = await api.patch(`parents/${id}`, values);
  return response.data;
};

export const deleteParent = async (id: number) => {
  const { data: response }: any = await api.delete(`parents/${id}`);
  return response.data;
};
