import React, { useEffect, useState }  from 'react';
import { message, Spin } from 'antd';
// import { StudentAttendances } from '../../../interfaces/studentAttendances';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import TableBase, { Props as TableProps } from '../Base';
import { QueryParams } from '../../../../interfaces';
import { useNavigate } from 'react-router-dom';
import {useGetAttendancesList, updateStudentAttendances} from '../../../../api/studentAttendances';
import {StudentAttendances} from '../../../../interfaces/studentAttendances';
import BorderLessButton from '../../buttons/BorderLessButton';
import DisabledBorderLessButton from '../../buttons/DisabledBorderLessButton';
import AddCircleOutlineIcon from '../../../svgs/AddCircleOutlineIcon';
import CancelIcon from '../../../svgs/CancelIcon';
import { PAGE_SIZE } from '../../../constants';
import CheckCircleIcon from '../../../svgs/CheckCircleIcon';
type Props = TableProps & {
  withAction?(_: any): void;
  attendancesId:number
  editable:boolean
  modalClick(_: any): void;
};


const CourseStudentAttendancesList: React.FC<Props> = ({ attendancesId , withAction,editable,modalClick, ...props }) => {
  const queryClient = useQueryClient();
  const [queryParams, setQueryParams] = useState<QueryParams>({ start: 0, count: PAGE_SIZE , filter:{'attendancesId':attendancesId}});
  const { data:attendancesData, isLoading } = useQuery(['studentAttendances', 'list', queryParams], () => useGetAttendancesList(queryParams), {
    staleTime: Infinity,
  });
  const [data, setAttendances] = React.useState<any>([]);
  const [active, setActive] = useState(false);
// const navigate = useNavigate();
const handleClickSetAttendances = async (id:Number) => {
  //   const  {data:attendancesData}  = await updateStudentAttendances(Number(id), status)
  //   message.info('Attendances Updated');
  //  setAttendances(attendancesData)
  // setActive(!active);
  modalClick({'attendanceId':Number(id)})
};
useEffect(() => { 
  setAttendances(attendancesData?.data)
}, [attendancesData]);

  const COLUMNS = [
    {
      title: 'Name',
      width: 150,
      render: (data: any) => {
        if(data.status == "absent"){
          return (
            <BorderLessButton color={"danger-color"} label={data.students.firstName+' '+data.students.lastName +' <'+data.status+'> '} icon={<AddCircleOutlineIcon />}  onClick={() => handleClickSetAttendances(Number(data.id))} />
          );
        }else if(data.status == "attended"){
          return (
            <BorderLessButton color={"secondary-color"} label={data.students.firstName+' '+data.students.lastName+' <'+data.status+'> '} icon={<CancelIcon />}  onClick={() => handleClickSetAttendances(Number(data.id))} />
          );
        }else{
          return (
            <BorderLessButton  label={data.students.firstName+' '+data.students.lastName+' <'+data.status+'> '} icon={<CancelIcon />}  onClick={() => handleClickSetAttendances(Number(data.id))} />
          );
        }
          
      },
      
    }
  ];
  return <TableBase dataSource={data} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default CourseStudentAttendancesList;
