import { notification } from 'antd';
import axios from 'axios';
import Cookies from 'js-cookie';
import { TOKEN_NAME } from './shared/constants';
import { handleEncryptData } from './utils/global';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URI,
});

axiosInstance.interceptors.request.use(
  async function (req) {
    if (req.data) {
      const payloadEncryted = await handleEncryptData(req.data);
      req.data = payloadEncryted;
    }
    return req;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (responseError) {
    const originalRequest = responseError.config;
    const { data } = responseError?.response;
    const { error } = data;
    const status = responseError?.response.status;

    if (error.includes('Cannot decrypt authen code') && !originalRequest._retry) {
      originalRequest._retry = true;
      // Cookies.remove(TOKEN_NAME);
      navigate(`/parent/login`);
      notification.error({
        message: 'Authen token is expired. Please login again.',
        placement: 'bottomRight',
      });
      // window.location.href = '/login';
      return axiosInstance(originalRequest);
    }

    if (status === 520 || status === 413) {
      notification.error({
        message: 'Could not do this action!',
        placement: 'bottomRight',
      });
    }

    notification.error({
      message: error,
      placement: 'bottomRight',
    });
    return Promise.reject(error);
  },
);

export default axiosInstance;
