import { Form, DatePicker , Input, Select, Switch  } from 'antd';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import ImageUploader from '../../Upload/Image';
import { convertDateTimeToNumber } from '../../../../utils/global';
import { DATE_FORMAT_FRONTEND } from '../../../constants';
import { QueryParams } from '../../../../interfaces';
import { Payable } from '../../../../interfaces/payable';
import { Parent } from '../../../../interfaces/parent';
import { Student } from '../../../../interfaces/student';
import { Course } from '../../../../interfaces/course';
import { getParentsList } from '../../../../api/parents';
import { getStudentsList} from '../../../../api/students';
import { getCoursesList } from '../../../../api/courses';
import { PASSWORD_TIP } from '../../../constants';
import CancelIcon from '../../../svgs/CancelIcon';
import CheckCircleIcon from '../../../svgs/CheckCircleIcon';
import PrimaryButton from '../../buttons/PrimaryButton';
import SecondaryButton from '../../buttons/SecondaryButton';
import {PAYABLE_TYPE} from '../../../constants';
const { RangePicker } = DatePicker;
const { TextArea } = Input;
type Props = {
  isFetching?: boolean;
  payableId?: number;
  payable?: Payable;
  handleSubmit(_: any): void;
};

const PayableForm: React.FC<Props> = ({ payable, payableId, isFetching, handleSubmit }) => {
  const [form] = Form.useForm();
  const [queryParams, setQueryParams] = useState<QueryParams>();
  const [listOfStudent, setListOfStudents] = useState<any>({});
  const [studentId, setstudentId] = useState('');
  const [files, setFiles] = useState<any>([]);
  const [listOfCourse, setListOfCourses] = useState<any>({});
  const handleOnFinish = (values: Payable) => {
    const newValues: any = values;
    if(!newValues.type){
      newValues.type = "materials"
    }
    newValues.amount=Number(newValues.amount).toString()
    const date =
      values.date && Object.values(values.date).length > 0
        ? convertDateTimeToNumber(dayjs(values.date))
        : null;
    newValues.date= date
    newValues.files = files
console.log(newValues)
    handleSubmit(newValues);
  };


  function findIndexWithDifferentId(data: any[], idArray: number[]) {
    for (let i = 0; i < data.length; i++) {
      if (!idArray.includes(data[i].id)) {
        return i;
      }
    }
    return -1;
  }

  useEffect(() => {
    if (payable && payable.id === payableId) {
      const _files = payable?.files;
      // listOfStudent = StudentsList
      form.setFieldsValue({
        ...payable,
        date : dayjs(payable.date),
        files: { fileList: payable.files?.filter((d: any) => d) },
      })
      setFiles(
        _files?.map((d: any) => {
          return { ...d, url: d.s3Url };
        }),
      );
    }
  }, [payable]); 
  return (
    <div className="payable-form-container">
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        onFinish={handleOnFinish}
        scrollToFirstError
        autoComplete="off"
        initialValues={{ isActive: true }}
      >
        <div className="form-container">
          <div className="form-content">
           <Form.Item
              name="date"
              label="Date"
              rules={[{ required: true, message: 'required' }]}
            >
              <DatePicker className="ant" format={DATE_FORMAT_FRONTEND} />
            </Form.Item>
            <Form.Item label="Amount" name="amount">
              <Input placeholder="Optional"  />
            </Form.Item>
            <Form.Item label="Payable Type" name='type'rules={[{ message: 'Required' }]}  >
              <Select placeholder="Payable Type" defaultValue="materials">
                  {PAYABLE_TYPE.map((item: any, index: number) => (
                    <Select.Option key={item.name} value={item.value}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
            </Form.Item>
            <Form.Item label="Remark" name="remark">
              <TextArea rows={8} placeholder="Optional" />
            </Form.Item>
            <Form.Item label="Image">
              <ImageUploader  name="files" documents={files} setDocuments={setFiles} multiple={true} maxCount={10} />
            </Form.Item>
          </div>
          <div className="form-footer">
            <PrimaryButton
              loading={isFetching}
              label={payableId ? 'Update' : 'Create'}
              icon={<CheckCircleIcon />}
              htmlType="submit"
            />
            <Link to="/admin/payables">
              <SecondaryButton label={'Cancel'} icon={<CancelIcon />} />
            </Link>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default PayableForm;
