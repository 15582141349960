import React from 'react';
import { DateFormat } from '../../../utils/global';
import { Payroll } from '../../../interfaces/payroll';
import TableBase, { Props as TableProps } from './Base';

type Props = TableProps & {
  withAction?(_: any): void;
  dataSource?: Payroll[];
};

const PayrollsTable: React.FC<Props> = ({ dataSource, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: 'Parent',
      // dataIndex: 'parent.email',
      width: 150,
      render: (payroll: Payroll) => {
        return (
          <span className="text-capitalize">
            {payroll?.coach.firstName} {payroll?.coach.lastName}
          </span>
        );
      },
    },
    {
      title: 'Month',
      width: 150,
      dataIndex:'month'
    },
    {
      title: 'Amount(RM)',
      width: 150,
      dataIndex:'amount'
    },
    {
      title: 'Payment Type',
      width: 150,
      dataIndex: 'paymentType',
    },{
      title: 'Remark',
      width: 150,
      dataIndex: 'remark',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      width: 150
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 50,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default PayrollsTable;
