import { pathToUrl } from '../utils/router';
import { Coach } from '../interfaces/coach';
import { GetListResponse } from '../interfaces';
import { useFetch, usePost } from '../shared/reactQuery';
import { api } from '../shared/api';
import { handleFilterParams } from '../utils/global';

export const useGetCoachesList = () => useFetch<GetListResponse>('coaches');

export const useGetCoach = (id: number) => useFetch<Coach>(`coaches/${id}`);

export const useCreateUser = (updater: (oldData: Coach[], newData: Coach) => Coach[]) => {
  return usePost<Coach[], Coach>(pathToUrl('coaches'), undefined, updater);
};

export const getCoachesList = async ({ start, count, filter }: { start?: number; count?: number; filter?: any }) => {
  const newParams = handleFilterParams({
    start,
    count,
    filter,
  });
  const { data: response }: any = await api.get(`coaches?${newParams}`);
  return response;
};

export const createCoach = async (values: Coach) => {
  const { data: response }: any = await api.post('coaches', values);
  return response.data;
};

export const updateCoach = async ({ id, ...values }: { id: string; values: Coach }) => {
  const { data: response }: any = await api.patch(`coaches/${id}`, values);
  return response.data;
};

export const deleteCoach = async (id: number) => {
  const { data: response }: any = await api.delete(`coaches/${id}`);
  return response.data;
};
