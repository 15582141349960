import React from 'react';
import { CoachLeave } from '../../../interfaces/coachLeave';
import { DATE_FORMAT_FRONTEND,MONTH_NAME } from '../../../shared/constants';
import TableBase, { Props as TableProps } from './Base';
import {EditOutlined,CheckCircleOutlined} from '@ant-design/icons';
import dayjs, { Dayjs } from 'dayjs';
type Props = TableProps & {
  withAction?(_: any): void;
  dataSource?: any[];
};

const CoachLeavesTable: React.FC<Props> = ({ dataSource, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: 'Coach',
      width: 80,
      render: (coachLeave: CoachLeave) => {
        return (
           <span className="value" style={{width: '150px'}}>
                {coachLeave?.coach?.firstName} {coachLeave?.coach?.lastName} |&emsp;
                ({dayjs(coachLeave.date).format(DATE_FORMAT_FRONTEND) })&emsp;
                {MONTH_NAME[coachLeave.month-1].name} | &emsp;
                {coachLeave.remark}
                <br></br><br></br>
                <EditOutlined />{dayjs(coachLeave.updatedAt).format(DATE_FORMAT_FRONTEND) }&emsp; 
                <CheckCircleOutlined />{dayjs(coachLeave.createdAt).format(DATE_FORMAT_FRONTEND) }
            </span>
        );
      },
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 30,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default CoachLeavesTable;
