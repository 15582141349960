import { Form, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import './Card.scss';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Parent } from '../../../../interfaces/parent';
import { Student } from '../../../../interfaces/student';
import { Props as TableProps } from '../Base';
import "bootstrap-css-only/css/bootstrap.min.css";
import '../../../../mixins.scss';
import BorderLessButton from '../../buttons/BorderLessButton';
import CancelIcon from '../../../svgs/CancelIcon';
import { DATE_FORMAT_FRONTEND } from '../../../../shared/constants';
import dayjs, { Dayjs } from 'dayjs';
type Props = TableProps & {
  parent : Parent
  handleClickPaid?: (_: any)=> void;
  color:any
  
};

const StudentsCardView: React.FC<Props> = ({ parent,handleClickPaid,color, ...props }) => {
  const [parentId, setModalData] = useState<number>();
  const [ isCreateAttendancestModalVisible, setCreateAttendancestModalVisible] = useState<boolean>(false);
  const onEmergencyContactCancel = () => {
    setCreateAttendancestModalVisible(false);
  };

  const onEditClick = (parentId:number) => {
    setModalData(parentId)
    setCreateAttendancestModalVisible(true);
  };
  function showConfirm(values:any) {
    const students = parent.students.map((student) => student.chineseName);
    // console.log(students)
    const { confirm } = Modal;
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: `confirm mark ${students.toString()} tuition fee for ${values.month} month`,
      onOk() {
        onMonthClick(values);
      },
      onCancel() {
      },
    });
  }
  const onMonthClick = (values:any) => {
    handleClickPaid?.(values)
    onEmergencyContactCancel();
    
  };

// const CSS2 = CSS.Properties
// console.log(parent.id)
  const [form] = Form.useForm();
  useEffect(() => {
    if (parent) {
      form.setFieldsValue(parent);
    }
  }, [parent]);
 
      return (
        <>
        
        <div className="row" style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => onEditClick(parent.id)}>
          <div className="col-sm-12">
            <div className="card" style={{ width: '18rem', marginLeft: '10px' }}>
              <div  style={{backgroundColor: color ,borderRadius:"10px"}}>
                <h5 className="card-title1" style={{ textAlign: 'center' ,color:"#ffff"}}>({parent.chineseName})</h5>
                <h5 className="card-title1" style={{ textAlign: 'center',color:"#ffff" }}>{parent.firstName}{parent.lastName}</h5>
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  {parent.students?.map((student: Student) => (
                    <>{student.chineseName}&nbsp;</>
                  ))}
                  <br></br>
                  {dayjs(parent?.payments[0]?.periodEndDate).format(DATE_FORMAT_FRONTEND)}
                </li>
                
              </ul>
            </div>
          </div>
        </div><Modal
          visible={isCreateAttendancestModalVisible}
          onOk={onEmergencyContactCancel}
          onCancel={onEmergencyContactCancel}
          footer={null}
          width={250}
        >
          <div className="students-container">
    <div className="page-container" style={{padding:'2px'}}>
 <div className="row" style={{ display: 'inline-block', cursor: 'pointer' }}>
        <div className="col-sm-12">
            <div className="card" style={{ width: '10rem', marginLeft: '10px' }}>
              <div className="card-body">
                <h5 className="card-title" style={{ textAlign: 'center' }}onClick={() => showConfirm?.({parentId,'month':1})}>1 Month</h5>
              </div>
            </div>
          </div>
          
        </div>

        <div className="row" style={{ display: 'inline-block', cursor: 'pointer' }}>
        <div className="col-sm-12">
            <div className="card" style={{ width: '10rem', marginLeft: '10px' }}>
              <div className="card-body">
                <h5 className="card-title" style={{ textAlign: 'center' }} onClick={() => showConfirm?.({parentId,'month':2})}>2 Months</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ display: 'inline-block', cursor: 'pointer' }}>
        <div className="col-sm-12">
            <div className="card" style={{ width: '10rem', marginLeft: '10px' }}>
              <div className="card-body">
                <h5 className="card-title" style={{ textAlign: 'center' }} onClick={() => showConfirm?.({parentId,'month':3})}>3 Months</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ display: 'inline-block', cursor: 'pointer' }}>
        <div className="col-sm-12">
            <div className="card" style={{ width: '10rem', marginLeft: '10px' }}>
              <div className="card-body">
                <h5 className="card-title" style={{ textAlign: 'center' }} onClick={() => showConfirm?.({parentId,'month':4})}>4 Months</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ display: 'inline-block', cursor: 'pointer' }}>
        <div className="col-sm-12">
            <div className="card" style={{ width: '10rem', marginLeft: '10px' }}>
              <div className="card-body">
                <h5 className="card-title" style={{ textAlign: 'center' }} onClick={() => showConfirm?.({parentId,'month':5})}>5 Months</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ display: 'inline-block', cursor: 'pointer' }}>
        <div className="col-sm-12">
            <div className="card" style={{ width: '10rem', marginLeft: '10px' }}>
              <div className="card-body">
                <h5 className="card-title" style={{ textAlign: 'center' }} onClick={() => showConfirm?.({parentId,'month':6})}>6 Months</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ display: 'inline-block', cursor: 'pointer' }}>
        <div className="col-sm-12">
            <div className="card" style={{ width: '10rem', marginLeft: '10px' }}>
              <div className="card-body">
                <h5 className="card-title" style={{ textAlign: 'center' }} onClick={() => showConfirm?.({parentId,'month':12})}>12 Months</h5>
              </div>
            </div>
          </div>
        </div>
        </div>
        </div>
          </Modal>
          </>
      ); 
  }
export default StudentsCardView;