import { DatePicker,Form, Input, Select } from 'antd';
import React ,{useState}from 'react';
import {useQuery } from 'react-query';
import { USER_ROLES } from '../../../constants';
import ClearIcon from '../../../svgs/ClearIcon';
import SearchIcon from '../../../svgs/SearchIcon';
import debounce from 'lodash/debounce';
import { QueryParams } from '../../../../interfaces';
import { Parent } from '../../../../interfaces/parent';
import { Student } from '../../../../interfaces/student';
import {PAYABLE_TYPE} from '../../../constants';
import PrimaryButton from '../../buttons/PrimaryButton';
import SecondaryButton from '../../buttons/SecondaryButton';
import { DATE_FORMAT_FRONTEND } from '../../../constants';
import dayjs, { Dayjs } from 'dayjs';
const { RangePicker } = DatePicker;
type Props = {
  handleFilter(_: any): void;
  handleReset(): void;
};

interface filterI {
  startDate?: number;
  endDate?: number;
  type?: number;
}


const PayablesFilterForm: React.FC<Props> = ({ handleFilter, handleReset }) => {
  const [form] = Form.useForm();
  const [queryParams, setQueryParams] = useState<QueryParams>();
  const [filterFields, setFilterFields] = useState<filterI>();

  const handleOnFinish = debounce(async () => {
    handleFilter(filterFields);
  }, 800);

  const onTypeChange = (event: any) => {
    // const { value } = event.target;
    setFilterFields({ ...filterFields, type: event });
  };

  const handleResetForm = () => {
    form.resetFields();
    handleReset();
  };

  const onDateChange = (dates: null | (Dayjs | null)[]) => {
    let startDate = 0;
    let endDate = 0;
    if (dates) {
      startDate = (dates[0] && dates[0].valueOf()) || 0;
      endDate = (dates[1] && dates[1].valueOf()) || 0;
    }
    setFilterFields({ startDate, endDate });
  };


  return (
    <div className="users-filter-form-container filter-form-container">
      <Form form={form} requiredMark={false} colon={false} onFinish={handleOnFinish} scrollToFirstError>
        <div className="form-container">
          <div className="form-content">
            <Form.Item name="startDate">
              <RangePicker
                className="ant"
                format={DATE_FORMAT_FRONTEND}
                onChange={(dates) => onDateChange(dates)}
              />
            </Form.Item>
            <Form.Item  name= 'type'  >
            <Select placeholder="Payable type" showSearch optionFilterProp="children"
             onChange={(value) => onTypeChange(value)}>
               {PAYABLE_TYPE?.map((type: any) => (
                                <Select.Option value={type.value}>
                                  {type.name}
                                </Select.Option>
                              ))}
                            </Select >
                          </Form.Item>
          </div>
          <div className="form-footer">
            <PrimaryButton label={'Search'} htmlType="submit" icon={<SearchIcon />} />
            <SecondaryButton label={'Reset'} onClick={handleResetForm} icon={<ClearIcon />} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default PayablesFilterForm;
