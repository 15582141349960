import { Button, Dropdown,message, Modal, Spin, Collapse } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { getClassReplacesList, updateClassReplaces, useGetClassReplaces, deleteClassReplaces} from '../../../api/classReplaces';
import { getStudentsList ,deleteStudent} from '../../../api/students';
import { QueryParams } from '../../../interfaces';
import { Student } from '../../../interfaces/student';
import ClassReplacesForm from './classReplaces/Form';
import { getCoachesList } from '../../../api/coaches';
import { PAGE_SIZE } from '../../../shared/constants';
import DangerButton from '../../../shared/components/buttons/DangerButton';
import CourseEnrolledStudents from '../../../shared/components/tables/CourseEnrolledStudents';
import CourseStudentsForEnrollment from '../../../shared/components/modal/CourseStudentsForEnrollment';
import { Link } from 'react-router-dom';
import SecondaryButton from '../../../shared/components/buttons/SecondaryButton';
import AddCircleOutlineIcon from '../../../shared/svgs/AddCircleOutlineIcon';
const { Panel } = Collapse;

const ClassReplacementsUpdatePage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [queryParams] = useState<QueryParams>({ start: 0, count: PAGE_SIZE });
  const { id } = useParams<{ id: string }>();
  const { data: data, isLoading } = useGetClassReplaces(Number(id));

  const currentPage = queryParams.start ? Math.round((Number(queryParams.start) - 1) / PAGE_SIZE + 1) : 1;
   

  // const paginationStudents: any = {
  //   current: currentPage,
  //   pageSize: PAGE_SIZE,
  //   total: studentsData?.students.total || 0,
  //   showTotal: (total: string) => `${total} Items`,
  // };
  const [ isEmergencyContactModalVisible, setIsEmergencyContactModalVisible] = useState<boolean>(false);

  const onEmergencyContactCancel = () => {
    setIsEmergencyContactModalVisible(false);
  };

  const onEditClick = () => {
    setIsEmergencyContactModalVisible(true);
  };
 
  const { mutateAsync: updateMutate } = useMutation(updateClassReplaces, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['classReplaces', 'list'],
      });
      queryClient.invalidateQueries({
        queryKey: [`classReplaces/${id}`, undefined],
      });
      message.info('Update classReplaces successfully!');
      navigate(`/admin/classReplaces`);
    },
    onError: () => {
      message.error('Update classReplaces failed! Please try again');
    },
    onSettled: () => {
      queryClient.prefetchQuery({
        queryKey: ['classReplaces', 'list', { start: 0, count: PAGE_SIZE, filter: undefined }],
        queryFn: () => getClassReplacesList({ start: 0, count: PAGE_SIZE, filter: undefined }),
      });
    },
  });

  // const { mutateAsync: mutationUpdate } = useMutation(deleteStudentFromCourse);

  const handleClickDelete = async (classReplacesId : number) => {
    await deleteClassReplaces(classReplacesId);
    queryClient.invalidateQueries({
      queryKey: ['classReplaces', 'list'],
    });
  };

  const handleSubmit = async (values: any) => {
    updateMutate({ ...values, id });
  };

  const withAction = (record: any) => {
    return (
      <span className="action" style={{ display: 'flex' }}>
        <Dropdown.Button
          overlayClassName="table-action-menu"
          menu={{
            items: [
              {
                key: '1',
                label: (
                  <Link to={`/admin/classReplaces/${record?.id}`}>
                    <Button className="btn-table-action-primary" type="link">
                      View
                    </Button>
                  </Link>
                ),
              },
              {
                key: '4',
                label: <DangerButton label="Delete" onOk={() => handleClickDelete(Number(id))} />,
              },
            ],
          }}
        ></Dropdown.Button>
      </span>
    );
  };

  return (
    <Spin spinning={isLoading}>
      <div className="users-container">
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page">Edit Course</h2>
            </div>
            <ClassReplacesForm 
            classReplacesId={Number(id)}
            handleSubmit={handleSubmit}
            data={data}
            />
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default ClassReplacementsUpdatePage;
