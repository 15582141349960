import { Form, Input, Select } from 'antd';
import React from 'react';
import { USER_ROLES } from '../../../constants';
import ClearIcon from '../../../svgs/ClearIcon';
import SearchIcon from '../../../svgs/SearchIcon';
import PrimaryButton from '../../buttons/PrimaryButton';
import SecondaryButton from '../../buttons/SecondaryButton';

type Props = {
  handleFilter(_: any): void;
  handleReset(): void;
};

const CoachesFilterForm: React.FC<Props> = ({ handleFilter, handleReset }) => {
  const [form] = Form.useForm();

  const handleOnFinish = (values: any) => {
    handleFilter(values);
  };

  const handleResetForm = () => {
    form.resetFields();
    handleReset();
  };

  return (
    <div className="users-filter-form-container filter-form-container">
      <Form form={form} requiredMark={false} colon={false} onFinish={handleOnFinish} scrollToFirstError>
        <div className="form-container">
          <div className="form-content">
            <Form.Item name="email">
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item name="name">
              <Input placeholder="Name" />
            </Form.Item>
            <Form.Item name="role">
              <Select placeholder="Role">
                {USER_ROLES.map((item: any, index: number) => (
                  <Select.Option key={index} value={item.value}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="form-footer">
            <PrimaryButton label={'Search'} htmlType="submit" icon={<SearchIcon />} />
            <SecondaryButton label={'Reset'} onClick={handleResetForm} icon={<ClearIcon />} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default CoachesFilterForm;
