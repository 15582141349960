import { message, Spin,Form, Input, Button, DatePicker } from 'antd';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { deleteCoach, getCoachesList, updateCoach, useGetCoach } from '../../../api/coaches';
import DangerButton from '../../../shared/components/buttons/DangerButton';
import PrimaryButton from '../../../shared/components/buttons/PrimaryButton';
import SecondaryButton from '../../../shared/components/buttons/SecondaryButton';
import { PAGE_SIZE } from '../../../shared/constants';
import CancelIcon from '../../../shared/svgs/CancelIcon';
import DeleteForeverIcon from '../../../shared/svgs/DeleteForeverIcon';
import EditIcon from '../../../shared/svgs/EditIcon';
import PersonIcon from '../../../shared/svgs/PersonIcon';
import { humanizeText } from '../../../utils/global';
import { Course } from '../../../interfaces/course';
import { DATE_FORMAT_FRONTEND } from '../../../shared/constants';

const CoachDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { data: coach, isLoading } = useGetCoach(Number(id));

  const { mutateAsync: mutationDelete } = useMutation(deleteCoach, {
    onSuccess: async () => {
      message.info('Deleted!');
      queryClient.invalidateQueries({
        queryKey: ['coaches', 'list'],
      });
      navigate(`/admin/coaches`);
    },
    onError: () => {
      message.error('The action is failed! Please try again');
    },
    onSettled: () => {
      queryClient.prefetchQuery({
        queryKey: ['coaches', 'list', { start: 0, count: PAGE_SIZE, filter: undefined }],
        queryFn: () => getCoachesList({ start: 0, count: PAGE_SIZE, filter: undefined }),
      });
    },
  });

  const handleDelete = () => {
    mutationDelete(Number(id));
  };

  const { mutateAsync: updateMutate } = useMutation(updateCoach, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['coaches', 'list'],
      });
      queryClient.invalidateQueries({
        queryKey: [`coaches/${id}`, undefined],
      });
      message.info('Unblocked this coach!');
    },
    onError: () => {
      message.error('The action is failed! Please try again');
    },
  });

  const handleUnblockUser = async () => {
    const newValues: any = coach;
    newValues.blockedAt = 'null';
    updateMutate({ ...newValues, id });
  };

  const handleOnFinish = () => {
  };

  return (
    <Spin spinning={isLoading}>
      <div id="user-details-container">
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page">Coach Details</h2>
            </div>
            <div className="details-container">
              <div className="item">
                <label>Email</label>
                <span className="value">{coach?.email}</span>
              </div>
              <div className="item">
                <label>Name</label>
                <span className="value">
                  {coach?.firstName} {coach?.lastName}
                </span>
              </div>
              <div className="item">
                <label>Contact</label>
                <span className="value">
                  {coach?.contact} 
                </span>
              </div>
              <div className="item">
                <label>Status</label>
                <span className={`value status ${coach?.isActive ? 'active' : 'inactive'}`}>
                  {coach?.isActive ? 'Active' : 'Inactive'}
                </span>
              </div>
            </div>
            <div className="page-content-footer">
              {coach && coach.blockedAt !== null && (
                <PrimaryButton
                  icon={<PersonIcon />}
                  label="Unblock"
                  onClick={handleUnblockUser}
                  color="secondary-color"
                />
              )}
              <Link to={`/admin/coaches/${id}/edit`}>
                <PrimaryButton label="Edit" icon={<EditIcon />} />
              </Link>
              <DangerButton label="Delete" onOk={handleDelete} icon={<DeleteForeverIcon />} />
              <Link to={`/admin/coaches`}>
                <SecondaryButton label="Cancel" icon={<CancelIcon />} />
              </Link>
            </div>
          </div>
          

          <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page">Courses Details</h2>
            </div>
            {coach?.courses?.map((course: Course) => (
               <div className="page-content" style={{boxShadow:'#23395d !important' }}>
            <div className="details-container">
              <div className="item">
                <label>Student Name</label>
                <span className="value">{course?.name}</span>
              </div>
              <div className="item">
                <label>Start At</label>
                <span className="value">
                  {course?.startAt}
                </span>
              </div>
              <div className="item">
                <label>End At</label>
                <span className="value">
                {course?.endAt}
                </span>
              </div>
            </div>
            </div>
             ))}

          </div>
        </div>
      </div>
      <div id="user-details-container">
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page">Coach Details</h2>
            </div>
<div className="coach-form-container">
<Form
  requiredMark={false}
  colon={false}
  onFinish={handleOnFinish}
  scrollToFirstError
  autoComplete="off"
  initialValues={{ isActive: true }}
>
  <div className="form-container">
    <div className="form-content">
      <Form.Item
        label="Email"
        name="email"
        rules={[
          { type: 'email', message: 'Invalid Email' },
          { required: true, message: 'Required' },
        ]}
      >
        {/* <Input type="email" placeholder="Required" autoComplete="off" /> */}
        <DatePicker className="ant" format={DATE_FORMAT_FRONTEND} />
       <SecondaryButton label="email" />
        <SecondaryButton label="email" />
        <SecondaryButton label="email" />
      </Form.Item>
      
  </div>
  </div>
</Form>
</div>
</div>
</div>
</div>
     


</Spin>
  );
};

export default CoachDetailsPage;
