import React, { useEffect, useState }  from 'react';
import { Form, Input, Select, Switch } from 'antd';
import  { Props as TableProps } from '../Base';
import {updateFile} from '../../../../api/files';
import { File } from './../../../../interfaces/file';
import PrimaryButton from '../../../../shared/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../shared/components/buttons/SecondaryButton';
import CheckCircleIcon from '../../../../shared/svgs/CheckCircleIcon';
import CancelIcon from '../../../../shared/svgs/CancelIcon';
import ImageUploader from '../../../../shared/components/Upload/Image';
import JoditEditor from 'jodit-react';
const { TextArea } = Input;
type Props = TableProps & {
  fileId:number;
  file:any;
  handleCancel(): void;
};


const CourseStudentsUpdateClassWorks: React.FC<Props> = ({fileId,file,handleCancel, ...props }) => {
  const [form] = Form.useForm();
  const [files, setFiles] = useState<any>([]);
// const navigate = useNavigate();
const handleSubmit = (values: any) => {
  updateFile({ id: values.fileId, ...values});
};

const handleParams = (values: File) => {
  // const newValues: any = values;
  // newValues.files = files;
  return values;
};
const handleOnFinish = (values: File) => {
  const params = handleParams(values);
  handleSubmit(values);
  handleCancel()
  
};
useEffect(() => { 
    form.setFieldsValue({
      fileId:fileId,
      comment:file.comment,
      files:  [file]?.filter((d: any) => d)  ,
    });
    setFiles(
      [file]?.map((d: any) => {
        return { ...d, url: d.s3Url };
      }),
    );
}, 
[fileId]);

return (
  <div className="site-form-container">
  <Form form={form} requiredMark={false} colon={false} onFinish={handleOnFinish} scrollToFirstError>
   
            <Form.Item >
              <ImageUploader name="files" documents={files} setDocuments={setFiles} multiple={true} maxCount={10} />
            </Form.Item>
            <Form.Item label="id" hidden name="fileId">
            <Input placeholder="Required" />
            </Form.Item>
            <Form.Item  name="comment">
            <TextArea rows={8} value="" />
            </Form.Item>
            <div className="ant-form-item css-dev-only-do-not-override-yp8pcc" style={{display: "flex",justifyContent: "space-around"}}>
            <PrimaryButton 
              label='Update'
              icon={<CheckCircleIcon />}
              htmlType="submit"
            /> 
              <SecondaryButton label={'Cancel'} icon={<CancelIcon />} onClick={handleCancel} /> 
          </div>
            </Form>
    </div>

);
};

export default CourseStudentsUpdateClassWorks;
