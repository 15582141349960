import { DownOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Dropdown, Layout, MenuProps } from 'antd';
import Cookies from 'js-cookie';
import React from 'react';
import { useQueryClient } from 'react-query';
import { useGetProfile } from '../../../api/auth';
import { TOKEN_NAME } from '../../constants';
import useAuth from '../../hooks/useAuth';

import OneSignal from 'react-onesignal';
import { useEffect } from 'react';
const appId  = '1207e931-be22-439b-90de-57f198e8bfd7'

const { Header } = Layout;

type Props = {
  currentUser?: any;
};

const HeaderMain: React.FC<Props> = ({ currentUser }) => {
  const { setAuth }: any = useAuth();
  const queryClient = useQueryClient();
  console.log(currentUser)
  Cookies.set('languages', currentUser.language);
  // const { data: parent, isLoading:isParentLoading } = useGetParentDashboard(Number(profile?.data?.id));
  function refreshPage() {
    window.location.reload();
  }

  const handleLogout = () => {
    Cookies.remove(TOKEN_NAME);
    setAuth(undefined);
    queryClient.invalidateQueries();
    window.location.href = '/parent/login';
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <Button onClick={handleLogout}>Log Out</Button>,
    },
  ];

  return (
    <Header className="site-header" style={{backgroundColor:"#23395d"}}>
      <div className="logo"></div>
      <Button style={{backgroundColor:"#23395d", width:'100%'}} type="primary" onClick={refreshPage}><ReloadOutlined /></Button>
      <div className="account-info">
    
        <div className="avatar"   style={{width:"58px",height:"58px" }}>
          {/* <img
            src={(currentUser?.files[0] ? currentUser?.files[0].s3Url:`https://ui-avatars.com/api/?length=1&name=${
                currentUser?.firstName ? `${currentUser?.firstName} ${currentUser?.lastName}` : currentUser?.email
                }&size=32&font-size=0.4&bold=true&background=5f5f5f&color=FFFFFF&rounded=true`)}
            alt=""
          /> */}
            {currentUser?.relationship?.toUpperCase() === "MOTHER" ? (
                <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava2.webp" alt="avatar"
                          className="rounded-circle img-fluid" style={{width: '100px'}} />

                ):(
                  <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1.webp" alt="avatar"
                          className="rounded-circle img-fluid" style={{width: '100px'}} />
            )}
        </div>
        <div className="info" style={{color:"#ffffff"}} >
          <div className="fullname">{`${currentUser?.firstName} ${currentUser?.lastName}`}</div>
          <Dropdown overlayClassName="header-info-dropdown" menu={{ items }}>
            <div className="email">
              {currentUser?.email} <DownOutlined />
            </div>
          </Dropdown>
        </div>
      </div>
    </Header>
  );
};

export default HeaderMain;
