import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import './DocumentViewer.less';
import DocumentFileIcon from '../../svgs/DocumentFileIcon';

type Props = {
  files: any;
};

const DocumentViewer: React.FC<Props> = ({ files }) => {
  const [previewImage, setPreviewImage] = useState<string>('');
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);

  const previewClose = () => {
    setPreviewVisible(false);
    setPreviewImage('');
  };

  const handlePreviewClick = (file: any) => {
    setPreviewImage(file.s3Url);
    setPreviewVisible(true);
  };
  return (
    <>
      {files && files?.length !== 0 ? (
        files.length !== undefined ? (
          <div className="document-viewer-section">
            {files.map((file: any) =>
              file.type.includes('image') || file.type.includes('video') ? (
                <Button
                  key={file.id}
                  className="image-preview-link document-item"
                  onClick={() => handlePreviewClick(file)}
                  style={{width:'-webkit-fill-available',backgroundColor: "#23395d !important"}}
                >
                  <div className="image-file" style={{display:"flex",flexDirection:"column",backgroundColor: "#23395d !important"}}>
                    <img src={file.s3Url} style={{maxWidth:'100%'}} alt="" />
                  </div>
                  <div className="file-name-section">
                    {/* {file?.name?.length > 20 ? `${file?.name.substring(0, 20)}...` : file?.name} */}
                  </div>
                </Button>
              ) : (
                <a
                  className="document-link document-item"
                  key={file.id}
                  href={file.s3Url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="document-file">
                    <DocumentFileIcon />
                  </div>
                  <div className="file-name-section">
                    {file?.name?.length > 20 ? `${file?.name?.substring(0, 20)}...` : file?.name}
                  </div>
                </a>
              ),
            )}
          </div>
        ) : !files.type.includes('image') || files.s3Url.includes('image') ? (
          <a
            className="document-link document-item"
            key={files.id}
            href={files.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="document-file">
              <DocumentFileIcon />
            </div>
          </a>
        ) : (
          <Button   style={{width:'-webkit-fill-available'}} className="image-preview-link" onClick={() => handlePreviewClick(files)}>
            <img   style={{width:'-webkit-fill-available' , maxWidth:'800px'}} src={files.s3Url} alt="" />
          </Button>
        )
      ) : (
        'N/A'
      )}
      <Modal
        visible={previewVisible}
        footer={null}
        onCancel={previewClose}
        className="document-preview-modal"
        
      >
        {<img src={previewImage}  style={{width:'-webkit-fill-available' , maxWidth:'800px'}} alt="" />}
      </Modal>
    </>
  );
};

export default DocumentViewer;
