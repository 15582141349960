import { message,Collapse,Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import './Card.scss';
import { Props as TableProps } from './Base';
import { useQuery, useQueryClient,useMutation } from 'react-query';
import { updateStatus} from '../../../api/classWorks';
import "bootstrap-css-only/css/bootstrap.min.css";
import {FileDoneOutlined,AlertOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Attendances } from '../../../interfaces/attendances';
import { StudentAttendances } from '../../../interfaces/studentAttendances';
import ClassWorksCardList from './ClassWorksList';
import { useGetStudentAttendances} from '../../../api/studentAttendances';
const { Panel } = Collapse;

type Props = TableProps & {
  attendances:any
  studentId:number
  courseId:number
};

const ClassWorksAttendancesList: React.FC<Props> = ({attendances,studentId,courseId,...props }) => {
  const { data: dataStudentAttendances, isLoading } = useGetStudentAttendances(Number(studentId));
  const [modalData, setModalData] = useState(null);
  const queryClient = useQueryClient();
  let [openPanels, setOpenPanels] = React.useState<any>([]);

  const onChange = (key: string | string[],modalData:any,classWorks:any) => {
    const newKey = [key[key.length-1]]
    setOpenPanels([]) //close all
    setOpenPanels(newKey) //open selected
    if(key.length>0){
      setModalData(modalData)
    //  updateClassWorksStatus(classWorks)
    }
  };

  const updateClassWorksStatus = async (classWorks:any)=>{
    if(classWorks.isRead === false){
      const id = classWorks.id
      updateMutate({...classWorks,id})
    }
  };

  const { mutateAsync: updateMutate } = useMutation(updateStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries({
      });
    },
    onError: () => {
      message.error('Update Attendance failed! Please try again');
    },
  });

      return (
        <>
        <Spin spinning={isLoading}>
         {/* {attendance.classWorks[0]?.isRead===false ? (expandIcon={({ isActive }) =><BellOutlined/>})} */}
       {dataStudentAttendances?.map((studentAttendances: StudentAttendances) => (
         <Collapse activeKey={openPanels} expandIcon={studentAttendances.isDone ? (() =><FileDoneOutlined style={{fontSize:"20px",color:"green"}}/>):(() =><AlertOutlined style={{fontSize:"20px",color:"red"}}/>)} 
         expandIconPosition="end" onChange={e => onChange(e, {'date':studentAttendances.date,'studentId':Number(studentId),'studentAttendanceId':Number(studentAttendances.id),'courseId':Number(courseId)},{"id":studentAttendances?.id,"isRead":studentAttendances?.isRead})} 
         style={{color:'#939ca3 !important'  ,backgroundColor:'#23395d',fontSize:'large'}}>
           
          <Panel   key={studentAttendances.id} style={{ color: 'snow' , textAlign:'center', backgroundColor:(studentAttendances.status=='absent' ? 'grey':'#23395d')}}  className="collapse-custom-header" header={`${studentAttendances.date}`}>
               <div className='ant-collapse-content-box'>
                  <ClassWorksCardList 
                  classWorkInfo={modalData}                   
                  />
            </div>
          </Panel>
        </Collapse>
         ))}

          
        </Spin>
     </>
      ); 
  }
export default ClassWorksAttendancesList;