import { Menu, MenuProps } from 'antd';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import DashboardIcon from '../../svgs/menu/DashboardIcon';
import FormIcon from '../../svgs/menu/FormIcon';

const ROOT_PATH_REGEX = /(^[/portal]+[^/]+).*/;

type Props = {
  onTriggerClick(): void;
};

const CoachMenu: React.FC  <Props> =({onTriggerClick})=> {
  const { pathname } = useLocation();
  let selectedKey = pathname.replace(ROOT_PATH_REGEX, '$1');
  let openKey = '';

  if (selectedKey.includes('coaches')) {
    selectedKey = '/coaches';
  } else {
    selectedKey = '/';
  }

  const onSelect = (item: any) => {
    if (item.key === '/coaches') {
      openKey = '/coaches';
    } else {
      openKey = item.key;
    }
  };

  type MenuItem = Required<MenuProps>['items'][number];

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label: !children ? <Link to={`${key}`}>{label}</Link> : label,
      type,
    } as MenuItem;
  }

  const items = [
    getItem('Dashboard', '/coach/dashboard', <DashboardIcon />),
    getItem('Leaves', '/coach/CoachLeaves', <FormIcon />),
  ];

  return (
    <Menu
      className="main-menu"
      triggerSubMenuAction="hover"
      mode="inline"
      defaultSelectedKeys={[selectedKey]}
      onSelect={onSelect}
      defaultOpenKeys={[openKey]}
      items={items}
    />
  );
};

export default CoachMenu;
