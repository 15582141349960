import { message } from 'antd';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { createUser, getUsersList } from '../../../api/users';
import { User } from '../../../interfaces/user';
import UserForm from '../../../shared/components/forms/user/Form';
import { PAGE_SIZE } from '../../../shared/constants';

const UserNewPage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: createMutate } = useMutation(createUser, {
    onSuccess: async () => {
      message.info('Create user successfully!');
      queryClient.invalidateQueries({
        queryKey: ['users', 'list'],
      });
      navigate(`/admin/users`);
    },
    onError: () => {
      message.error('Create user failed! Please try again');
    },
    onSettled: () => {
      queryClient.prefetchQuery({
        queryKey: ['users', 'list', { start: 0, count: PAGE_SIZE, filter: undefined }],
        queryFn: () => getUsersList({ start: 0, count: PAGE_SIZE, filter: undefined }),
      });
    },
  });

  const handleSubmit = async (values: User) => {
    createMutate(values);
  };

  return (
    <div className="users-container">
      <div className="page-container">
        <div className="page-content">
          <div className="page-content-header">
            <h2 className="title-page">Add New User</h2>
          </div>
          <UserForm handleSubmit={handleSubmit} />
        </div>
      </div>
    </div>
  );
};

export default UserNewPage;
