import React from 'react';
import { CourseMaterial } from '../../../../interfaces/courseMaterial';
import TableBase, { Props as TableProps } from '../Base';

type Props = TableProps & {
  withAction?(_: any): void;
  dataSource?: any[];
};

const CourseMaterialsTable: React.FC<Props> = ({ dataSource, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: 150,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: 150,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      width: 150,
    },
    {
      title: 'Age Range',
      dataIndex: 'ageRange',
      width: 150,
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 50,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default CourseMaterialsTable;
