import { Form, message, Select, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { QueryParams } from '../../../../interfaces';
import { PAGE_SIZE } from '../../../../shared/constants';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getTuitionDaysList, updateTuitionDays} from '../../../../api/tuitionDays';
import { useNavigate, useParams } from 'react-router-dom';
import { TuitionDays } from '../../../../interfaces/tuitionDays';
import { PASSWORD_TIP, USER_ROLES } from '../../../constants';
import CancelIcon from '../../../svgs/CancelIcon';
import CheckCircleIcon from '../../../svgs/CheckCircleIcon';
import PrimaryButton from '../../buttons/PrimaryButton';
import SecondaryButton from '../../buttons/SecondaryButton';

type Props = {
  year:string;
  month:string
};

const TuitionDaysForm: React.FC<Props> = ({ year, month }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [queryParams, setQueryParams] = useState<QueryParams>({ start: 0, count: PAGE_SIZE ,filter:{year,month}});

  const [password, setPassword] = useState<any>({});
  const [form] = Form.useForm();
  const { data, isLoading } = useQuery(['tuitionDays', 'tuitionDayslist', queryParams], () => getTuitionDaysList(queryParams), {
    staleTime: Infinity,
  });

  const { mutateAsync: updateMutate } = useMutation(updateTuitionDays, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['tuitionDays', 'tuitionDayslist'],
      });
      queryClient.invalidateQueries({
        // queryKey: [`tuitionDays/${id}`, undefined],
      });
      message.info('Update student successfully!');
      navigate(`/admin/tuitionDays`);
    },
    onError: () => {
      message.error('Update student failed! Please try again');
    },
    onSettled: () => {
      queryClient.prefetchQuery({
        queryKey: ['tuitionDays', 'tuitionDayslist', { start: 0, count: PAGE_SIZE, filter:{year,month} }],
        queryFn: () => getTuitionDaysList({ start: 0, count: PAGE_SIZE, filter:{year,month} }),
      });
    },
  });

  const onChange = (key: any | any[],values:any) => { 
    const id =values.id
    values.status = key
       updateMutate({...values, id }); 
       if(values.status==true){
        alert(`Make date ${values.date} on month ${values.month} at day ${values.day} as work day`);
       }else{
        alert(`Make date ${values.date} on month ${values.month} at day ${values.day} as off day`);
       }
      
  };
  return (
    <div className="user-form-container">
        <div className="form-container" >
        {data?.data?.map((d: TuitionDays) => (
            <>
            <Form.Item label={d.day +'-'+d.date} style={{flexFlow:'nowrap'}} >
            <Switch 
            disabled= {(d.isDone ?   true: false)}
            checkedChildren="On" 
            unCheckedChildren="Off" checked={(Number(d.status) ==1) ? true:false} onChange={e => onChange(e, d)}/>
            </Form.Item>
            </>
               ))}
        </div>
    </div>
  );
};

export default TuitionDaysForm;
