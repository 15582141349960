// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  font-family: sans-serif;
  text-align: center;
}

.card-container {
  margin-top: 10px;
}

.card-body {
  padding: 0.5rem;
}

.primary-hr {
  background: rgba(35, 57, 93, 0.231372549);
}

@media (max-width: 623px) {
  .card-header {
    padding: 0.45rem 0.15rem !important;
  }
  .card {
    margin-left: 0px !important;
  }
}
@media (max-width: 320) {
  .card {
    margin-left: 0px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/parent/profile/StudentProfile/Profile.scss"],"names":[],"mappings":"AAEA;EACE,uBAAA;EACA,kBAAA;AADF;;AAIA;EACE,gBAAA;AADF;;AAUA;EACE,eAAA;AAPF;;AAUA;EACE,yCAAA;AAPF;;AAaA;EAME;IACE,mCAAA;EAfF;EAkBA;IACE,2BAAA;EAhBF;AACF;AAmBA;EAEE;IACE,2BAAA;EAlBF;AACF","sourcesContent":["@import '../../../../../src/mixins.scss';\n\n.App {\n  font-family: sans-serif;\n  text-align: center;\n}\n\n.card-container{\n  margin-top:10px;\n  // display: flex;\n  // flex-direction: row;\n  // justify-content: space-evenly;\n  // flex-wrap: nowrap;\n  // align-items: flex-start;\n}\n\n\n.card-body{\n  padding:0.5rem;\n}\n\n.primary-hr{\n  background:#23395d3b;\n  // margin-top: 0rem !important;\n  // margin-bottom: 0rem !important;\n  // border: 1px solid $primary-color !important;\n}\n\n@media (max-width: 623px) {\n  // .rowmb{\n  //   // box-sizing: content-box !important;\n  //   height: 200px !important;\n  // }\n  \n  .card-header{\n    padding:0.45rem 0.15rem !important;\n  }\n\n  .card{\n    margin-left:0px !important; \n  }\n}\n\n@media (max-width: 320) {\n\n  .card{\n    margin-left:0px !important; \n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
