import { message, Spin } from 'antd';
import React from 'react';
import { DateFormat } from '../../../utils/global';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { deletePayroll, getPayrollsList, updatePayroll, useGetPayroll } from '../../../api/payrolls';
import DangerButton from '../../../shared/components/buttons/DangerButton';
import PrimaryButton from '../../../shared/components/buttons/PrimaryButton';
import SecondaryButton from '../../../shared/components/buttons/SecondaryButton';
import { PAGE_SIZE } from '../../../shared/constants';
import CancelIcon from '../../../shared/svgs/CancelIcon';
import DeleteForeverIcon from '../../../shared/svgs/DeleteForeverIcon';
import EditIcon from '../../../shared/svgs/EditIcon';
import PersonIcon from '../../../shared/svgs/PersonIcon';
import DocumentViewer from '../../../shared/components/Upload/DocumentViewer';
import { Payroll } from '../../../interfaces/payroll';

const PaymentDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { data: payroll, isLoading } = useGetPayroll(Number(id));

  const { mutateAsync: mutationDelete } = useMutation(deletePayroll, {
    onSuccess: async () => {
      message.info('Deleted!');
      queryClient.invalidateQueries({
        queryKey: ['payrolls', 'list'],
      });
      navigate(`/admin/payrolls`);
    },
    onError: () => {
      message.error('The action is failed! Please try again');
    },
    onSettled: () => {
      queryClient.prefetchQuery({
        queryKey: ['payrolls', 'list', { start: 0, count: PAGE_SIZE, filter: undefined }],
        queryFn: () => getPayrollsList({ start: 0, count: PAGE_SIZE, filter: undefined }),
      });
    },
  });

  const handleDelete = () => {
    mutationDelete(Number(id));
  };

  const { mutateAsync: updateMutate } = useMutation(updatePayroll, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['payrolls', 'list'],
      });
      queryClient.invalidateQueries({
        queryKey: [`payrolls/${id}`, undefined],
      });
    },
    onError: () => {
      message.error('The action is failed! Please try again');
    },
  });

  const handleUnblockUser = async () => {
    const newValues: any = payroll;
    newValues.blockedAt = 'null';
    updateMutate({ ...newValues, id });
  };

  return (
    <Spin spinning={isLoading}>
      <div id="user-details-container">
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page">Payroll Details</h2>
            </div>
            <div className="details-container">
              <div className="item">
                <label>Coach</label>
                <span className="value">
                {payroll?.coach?.firstName} {payroll?.coach?.lastName}
                </span>
              </div>
              <div className="item">
                <label>Amount</label>
                <span className="value">
                  {payroll?.amount} 
                </span>
              </div>
              <div className="item">
                <label>Payroll Type</label>
                <span className="value">
                  {payroll?.paymentType} 
                </span>
              </div>
              <div className="item">
                <label>Remark</label>
                <span className="value">
                  {payroll?.remark} 
                </span>
              </div>
              <div className="item">
                  <label>receipt</label>
                  <span className="value">
                {payroll && <DocumentViewer files={payroll?.files} />}
            </span>
          </div>
            </div>
            <div className="page-content-footer">
              {/* <Link to={`/admin/payrolls/${id}/edit`}>
                <PrimaryButton label="Edit" icon={<EditIcon />} />
              </Link>
              <DangerButton label="Delete" onOk={handleDelete} icon={<DeleteForeverIcon />} /> */}
              <Link to={`/admin/payrolls`}>
                <SecondaryButton label="Cancel" icon={<CancelIcon />} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default PaymentDetailsPage;
