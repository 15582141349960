// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-image-section .image-uploader {
  max-width: 100%;
  height: 100%;
  min-height: 110px;
}
.upload-image-section .image-uploader.ant-upload-disabled {
  border: none !important;
}
.upload-image-section .ant-form-item-control-input {
  min-height: auto;
}
.upload-image-section .is-preview-image .ant-form-item-control-input-content {
  position: relative;
}
.upload-image-section .is-preview-image .ant-form-item-control-input-content > span {
  min-width: 100%;
}
.upload-image-section .is-preview-image .ant-upload-list-picture-card {
  min-width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.upload-image-section .is-preview-image .ant-upload-list-picture-card .ant-upload-list-picture-card-container {
  min-width: 100%;
  margin: 0;
  height: 100%;
}
.upload-image-section .is-preview-image .ant-upload-list-picture-card .ant-upload-list-item-list-type-picture-card {
  padding: 0;
}
.upload-image-section .view-image {
  width: 100%;
  min-height: 110px;
}`, "",{"version":3,"sources":["webpack://./src/shared/components/Upload/Image.scss"],"names":[],"mappings":"AAGE;EACE,eAAA;EACA,YAAA;EACA,iBAAA;AAFJ;AAII;EACE,uBAAA;AAFN;AAME;EACE,gBAAA;AAJJ;AAQI;EACE,kBAAA;AANN;AAQM;EACE,eAAA;AANR;AASI;EACE,eAAA;EACA,YAAA;EACA,kBAAA;EACA,OAAA;EACA,MAAA;AAPN;AASM;EACE,eAAA;EACA,SAAA;EACA,YAAA;AAPR;AAUM;EACE,UAAA;AARR;AAaE;EACE,WAAA;EACA,iBAAA;AAXJ","sourcesContent":["@import '../../../mixins.scss';\n\n.upload-image-section {\n  .image-uploader {\n    max-width: 100%;\n    height: 100%;\n    min-height: 110px;\n\n    &.ant-upload-disabled {\n      border: none !important;\n    }\n  }\n\n  .ant-form-item-control-input {\n    min-height: auto;\n  }\n\n  .is-preview-image {\n    .ant-form-item-control-input-content {\n      position: relative;\n\n      & > span {\n        min-width: 100%;\n      }\n    }\n    .ant-upload-list-picture-card {\n      min-width: 100%;\n      height: 100%;\n      position: absolute;\n      left: 0;\n      top: 0;\n      \n      .ant-upload-list-picture-card-container {\n        min-width: 100%;\n        margin: 0;\n        height: 100%;\n      }\n\n      .ant-upload-list-item-list-type-picture-card {\n        padding: 0;\n      }\n    }\n  }\n\n  .view-image {\n    width: 100%;\n    min-height: 110px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
