import { message , Spin} from 'antd';
import React, {useState} from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { deleteCourseMaterial, getCourseMaterialsList, updateCourseMaterial, useGetCourseMaterial } from '../../../api/courseMaterial';
import { QueryParams } from '../../../interfaces';
import PrimaryButton from '../../../shared/components/buttons/PrimaryButton';
import DangerButton from '../../../shared/components/buttons/DangerButton';
import DocumentViewer from '../../../shared/components/Upload/DocumentViewer';
import DeleteForeverIcon from '../../../shared/svgs/DeleteForeverIcon';
import EditIcon from '../../../shared/svgs/EditIcon';
import CancelIcon from '../../../shared/svgs/CancelIcon';
import SecondaryButton from '../../../shared/components/buttons/SecondaryButton';
import { PAGE_SIZE } from '../../../shared/constants';

const CourseMaterialDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: courseMaterial, isLoading } = useGetCourseMaterial(Number(id));

  const { mutateAsync: mutationDelete } = useMutation(deleteCourseMaterial, {
    onSuccess: async () => {
      message.info('Deleted!');
      queryClient.invalidateQueries({
        queryKey: ['courses', 'list'],
      });
      navigate(`/admin/courseMaterials`);
    },
    onError: () => {
      message.error('The action is failed! Please try again');
    },
    onSettled: () => {
      queryClient.prefetchQuery({
        queryKey: ['courseMaterials', 'list', { start: 0, count: PAGE_SIZE, filter: undefined }],
        queryFn: () => getCourseMaterialsList({ start: 0, count: PAGE_SIZE, filter: undefined }),
      });
    },
  });

  const handleDelete = () => {
    mutationDelete(Number(id));
  };

  // const { mutateAsync: mutationUpdate } = useMutation(deleteStudentFromCourse);

  const handleClickDelete = async (courseId : number , studentId : number) => {
    // await deleteStudentFromCourse(courseId,studentId);
    // queryClient.invalidateQueries({
    //   queryKey: ['students', 'list'],
    // });
  };

  return (
    <Spin spinning={isLoading}>
  <div id="user-details-container">
    <div className="page-container">
      <div className="page-content">
        <div className="page-content-header">
          <h2 className="title-page">Course Matertial Details</h2>
        </div>
        <div className="page-header">
          <div></div> 
        </div>
        <div className="details-container">
          <div className="item">
            <label>Course Material Name</label>
            <span className="value">{courseMaterial?.name}</span>
          </div>
          <div className="item">
            <label>Type</label>
            <span className="value">
              {courseMaterial?.type}
            </span>
          </div>
          <div className="item">
            <label>Description</label>
            <span className="value">
              {courseMaterial?.description} 
            </span>
          </div>
          <div className="item">
            <label>Category</label>
            <span className="value">
              {courseMaterial?.category} 
            </span>
          </div>
          <div className="item">
            <label>Age Range</label>
            <span className="value">
              {courseMaterial?.ageRange} 
            </span>
          </div>
          <div className="item">
            <label>Age Range</label>
            <span className="value">
              {courseMaterial?.ageRange} 
            </span>
          </div>
          <div className="item">
                  <label>Files/Images</label>
                  <span className="value">
                {courseMaterial && <DocumentViewer files={courseMaterial?.files} />}
            </span>
          </div>
        </div>
        <div className="page-content-footer"> 
              <Link to={`/admin/courseMaterials/${id}/edit`}>
                <PrimaryButton label="Edit" icon={<EditIcon />} />
              </Link>
              <DangerButton label="Delete" onOk={handleDelete} icon={<DeleteForeverIcon />} />
              <Link to={`/admin/courseMaterials`}>
                <SecondaryButton label="Cancel" icon={<CancelIcon />} />
              </Link>
            </div>
      </div>
    </div>
  </div>
</Spin>
);
};

export default CourseMaterialDetailsPage;
