import { pathToUrl } from './../utils/router';
import { Student } from './../interfaces/student';
import { GetListResponse } from '../interfaces';
import { useFetch, usePost } from '../shared/reactQuery';
import { api } from '../shared/api';
import { handleFilterParams } from '../utils/global';

export const useGetStudentsList = () => useFetch<GetListResponse>('students');

export const useGetStudent = (id: number) => useFetch<Student>(`students/${id}`);
export const useGetOnDayStatus = (id: number) => useFetch<Student>(`students/${id}/onDayStatus`);

export const useGetStudentAttendances = (id: number) => useFetch<Student>(`students/${id}/attendances`);
export const useGetStudentClassWork = (id: number) => useFetch<Student>(`students/${id}/classWorks`);

export const useCreateStudent = (updater: (oldData: Student[], newData: Student) => Student[]) => {
  return usePost<Student[], Student>(pathToUrl('students'), undefined, updater);
};

export const getStudentsList = async ({ start, count, filter }: { start?: number; count?: number; filter?: any }) => {
  const newParams = handleFilterParams({
    start,
    count,
    filter,
  });
  const { data: response }: any = await api.get(`students?${newParams}`);
  return response;
};

export const studentNotification = async (values: Student) => {
  const { data: response }: any = await api.post('students/notification', values);
  return response.data;
};

export const createStudent = async (values: Student) => {
  const { data: response }: any = await api.post('students', values);
  return response.data;
};

export const studentAddMaterialQty = async (values: any) => {
  const { data: response }: any = await api.post('students/addMaterial', values);
  return response.data;
};

export const updateStudent = async ({ id, ...values }: { id: string; values: Student }) => {
  const { data: response }: any = await api.patch(`students/${id}`, values);
  return response.data;
};

export const deleteStudent = async (id: number) => {
  const { data: response }: any = await api.delete(`students/${id}`);
  return response.data;
};
