import { Spin, Form, Input, Select, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Course } from '../../../../interfaces/course';
import { Student } from '../../../../interfaces/student';
import { TuitionDays } from '../../../../interfaces/tuitionDays';
import { useQuery } from 'react-query';
import { ClassReplaces } from '../../../../interfaces/classReplaces';
import CancelIcon from '../../../../shared/svgs/CancelIcon';
import CheckCircleIcon from '../../../../shared/svgs/CheckCircleIcon';
import PrimaryButton from '../../../../shared/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../shared/components/buttons/SecondaryButton';
import { useGetStudentsList} from '../../../../api/students';
import { useGetCoursesList} from '../../../../api/courses';
import { getTuitionDaysList} from '../../../../api/tuitionDays';
import { QueryParams } from '../../../../interfaces';
import { PAGE_SIZE } from '../../../../shared/constants';
import { useGetClassReplaces } from '../../../../api/classReplaces';

type Props = {
  modalData?: any;
  handleSubmit(_: any): void;
  onCancel(): void;
};

const ClassReplacesForm: React.FC<Props> = ({ modalData,handleSubmit,onCancel }) => {
  
  const [queryParams, setQueryParams] = useState<QueryParams>({ start: 0, count: 1000 });
  const [listOfCourses, setListOfCourses] = useState<any>({});
  const [listOfTuitionDays, setListOfTuitionDays] = useState<any>({});
  const [stateSelectedStudent, setselectedStudent] = useState<any>({});
  
  const { data: dataClassReplaces, isLoading:isClassReplacesLoading } = useGetClassReplaces(Number(modalData.id));
  // const { data: studnetListing, isLoading:isStudentListingLoading } = useGetStudentsList();
  const { data: courseListing, isLoading:isCourseListingLoading } = useGetCoursesList();
  const { data: tuitionDayListing,isLoading:isTuitionDaysLoading } = useQuery(['tuitionDays', 'list', queryParams], () => getTuitionDaysList(queryParams), {
    staleTime: Infinity,
  });
  
  const [form] = Form.useForm();
  const handleOnFinish = (values: ClassReplaces) => {
    const newValues: any = { ...values,id:modalData.id,sutdentId:modalData.studentId };

    handleSubmit(newValues);
  };


  const onReplaceClassChange = (value: any,event:any) => {
    const courseOfListing = courseListing?.data?.filter((course: Course) => course.day === event.label);
    setListOfCourses(courseOfListing);
};
  useEffect(() => {
      form.setFieldsValue({
        ...dataClassReplaces,
        ...dataClassReplaces?.student
      })
  }, [dataClassReplaces,dataClassReplaces?.student]);

  return (
    <Spin spinning={isTuitionDaysLoading}>
    <div className="course-form-container">
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        onFinish={handleOnFinish}
        scrollToFirstError
        autoComplete="off"
        initialValues={{ isActive: true }}
      >
        <div className="form-container">
          <div className="form-content">
           <Form.Item label="Student" name= 'chineseName' >
            <Input  autoComplete="off" disabled/>
          </Form.Item>

          <Form.Item label="Absent Date" name= 'absentDate' >
            <Input  autoComplete="off" disabled/>
          </Form.Item>
          <Form.Item label="Absent Day" name= 'absentDay' >
            <Input  autoComplete="off" disabled/>
          </Form.Item>

           <Form.Item label="Replace Class" name= 'replaceClassId' >
            <Select showSearch optionFilterProp="children" loading={isTuitionDaysLoading}  
             onSelect={(value, event) => onReplaceClassChange(value, event)}>
                             {/* {Object.values(listOfTuitionDays).map((tuitionDays: any) => ( */}
                              {tuitionDayListing?.data.filter((course: any) => course.isDone === false).map((tuitionDays: any) => (
                                <Select.Option key={tuitionDays.id} label={tuitionDays.day} value={tuitionDays.id}>
                              {tuitionDays.date} [{tuitionDays.day}]
                                </Select.Option>
                              ))}
                            </Select>
           </Form.Item>

           <Form.Item label="Replace time" name= 'replaceCourseId'>
            <Select  showSearch optionFilterProp="children" loading={isTuitionDaysLoading} >
                              {/* {courseListing?.data?.map((course: Course) => ( */}
                          {Object.values(listOfCourses).map((course: any ) => (
                                <Select.Option key={course.id} value={course.id}>
                              {/* {course.startAt.substring(0,5)}-{course?.endAt.substring(0, 5)}  */}
                              [{course.day}] {course.name} {course.startAt}-{course.endAt}
                                </Select.Option>
                              ))}
                            </Select>
           </Form.Item>


            <Form.Item
            label="Remarks"
            name= 'remarks'
            >
            <Input  autoComplete="off" />
            </Form.Item>
            <Form.Item
            label="absentClassId"
            name= 'absentClassId'
            hidden
            >
            <Input  autoComplete="off" />
            </Form.Item>
            <Form.Item label="Done" name="isDone" valuePropName="checked">
              <Switch />
            </Form.Item> 
          <div className="form-footer">
            <PrimaryButton
           
              label={modalData.id ? 'Update' : 'Create'}
              icon={<CheckCircleIcon />}
              htmlType="submit"
            />
            <Link to="/admin/classReplaces"> 
              <SecondaryButton label={'Add Class Works'} icon={<CancelIcon />}
      onClick={() => onCancel()} />
            </Link>
          </div>
           
          </div> 
        </div>
      </Form>
    </div>
    </Spin>
  );
};

export default ClassReplacesForm;
