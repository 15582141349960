import React, { useState } from 'react';
import { Checkbox, message} from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import EditIcon from '../../../../shared/svgs/EditIcon';
import { Material } from '../../../../interfaces/material';
import { humanizeText } from '../../../../utils/global';
import { getMaterialsList, updateMaterial } from '../../../../api/materials';
import TableBase, { Props as TableProps } from '../Base';
import { QueryParams } from '../../../../interfaces';
import DocumentViewer from '../../../../shared/components/Upload/DocumentViewer';
import { PAGE_SIZE } from '../../../../shared/constants';
import PrimaryButton from '../../../../shared/components/buttons/PrimaryButton';
import DangerButton from '../../../../shared/components/buttons/DangerButton';
import SecondaryButton from '../../../../shared/components/buttons/SecondaryButton';
import CancelIcon from '../../../../shared/svgs/CancelIcon';


type Props = TableProps & {
  onMaterialChildsCancel(): void;
  materialChilds:any;
  materialId:number;
};
const MaterialChildslist: React.FC<Props> = ({onMaterialChildsCancel,materialChilds,materialId, ...props }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [queryParams, setQueryParams] = useState<QueryParams>({ start: 0, count: PAGE_SIZE });

  const { data:material, isLoading } = useQuery(['materials', 'list', queryParams], () => getMaterialsList(queryParams), {
    staleTime: Infinity,
  });

 const onMaterialChildsSave = async () =>{
   let values:any = {id:materialId, childIds:materialChilds}
  const id = materialId
  updateMutate({...values, id });
 } 

 const { mutateAsync: updateMutate } = useMutation(updateMaterial, {
  onSuccess: () => {
    queryClient.invalidateQueries({
      queryKey: ['materials', 'list'],
    });
    queryClient.invalidateQueries({
      queryKey: [`materials/${materialId}`, undefined],
    });
    message.info('Update Material child successfully!');
    navigate(`/admin/materials`);
  },
  onError: () => {
    message.error('Update  Material child failed! Please try again');
  },
  onSettled: () => {
    queryClient.prefetchQuery({
      queryKey: ['materials', 'list', { start: 0, count: PAGE_SIZE, filter: undefined }],
      queryFn: () => getMaterialsList({ start: 0, count: PAGE_SIZE, filter: undefined }),
    });
  },
});

  const onCheckboxChange = (event: any) => {
    const { value, checked } = event.target;
    if(checked==true){
      if(!materialChilds.includes(value)){
        materialChilds.push(value)
      }
    }else if(checked==false){
      const index = materialChilds.indexOf(value);
      if (index > -1) { // only splice array when item is found
        materialChilds.splice(index, 1); // 2nd parameter means remove one item only
      }
    }
  };
  const COLUMNS = [
    {
      render: (material: Material) => {
        return (
        (materialChilds.includes(material.id) ?
        (<Checkbox value={material.id} defaultChecked={true} onChange={onCheckboxChange}></Checkbox>):
        (<Checkbox value={material.id} onChange={onCheckboxChange} ></Checkbox>))
        )
      },
    },
    {
      title: 'Images',
      render: (material: Material) => {
        return (
           <span className="value" style={{width: '10px'}}>
                {material && <DocumentViewer files={material?.files} />}
                {material.name}
            </span>
        );
      },
    },
  ];
  return (
    <>
    <div id="user-details-container">
    <div className="page-container">
    <TableBase dataSource={material?.data} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} /><div className="page-content-footer" style={{ paddingBottom: '10px' }}>
      <div className="page-content-footer" style={{paddingBottom:'10px'}}> 
      <Link to={`/admin/materials/`}>
        <PrimaryButton label="Save" icon={<EditIcon />} onClick={onMaterialChildsSave}/>
      </Link>
        <SecondaryButton label="Cancel" icon={<CancelIcon />} onClick={onMaterialChildsCancel} />
   
    </div>
    </div>
    </div>
    </div>
    </>
    );
};

export default MaterialChildslist;
