import React from 'react';
import './cardview.scss'
import { Link } from 'react-router-dom';
import { Collapse  } from 'antd';
import { Parent } from '../../../interfaces/parent';
import { Student } from '../../../interfaces/student'
import { DATE_FORMAT_FRONTEND } from '../../../shared/constants';
import TableBase, { Props as TableProps } from './Base';
import PrimaryButton from '../../../shared/components/buttons/PrimaryButton';
import dayjs, { Dayjs } from 'dayjs';

const { Panel } = Collapse;
type Props = TableProps & {
  handleClickDelete(_: any): void;
  parent?: Parent;
};

const ParentsCardView: React.FC<Props> = ({ parent, handleClickDelete, ...props }) => {
  const handleDelete = (parent :any) => {
    handleClickDelete(parent)
  };
  return (
    // <div className="card-row">maxWidth:"100%", 
  <div className="col-sm-4"  style={{paddingRight:"1px", width:"100%",paddingLeft:"1px"}}>
    <div className="card-row">
  <div className="card" style={{marginLeft:'10px'}}>
  <div className="card-body">
  {parent?.relatioship.toUpperCase() === "FATHER" ? (
    <img src="/boy.png" alt="avatar"
            className="rounded-circle img-fluid" style={{width: '150px'}} />

  ):(
    <img src="/girl.png" alt="avatar"
            className="rounded-circle img-fluid" style={{width: '150px'}} />
  )
}
 </div>
 <span style={{color:"grey",textAlign:'center'}}> {parent?.lastLogin ?  dayjs(parent?.lastLogin).format(DATE_FORMAT_FRONTEND): '-'}</span>

 <h4 className="card-title" style={{textAlign:"center"}}>
 <span>{parent?.chineseName}</span><br></br>
 {parent?.firstName} {parent?.lastName}
 </h4>
 <ul className="list-group list-group-flush">

 <li className="list-group-item">
  {parent?.students.map((student: Student) => (
    <><span>{student.chineseName}</span>&emsp;</>
  ))}
  </li>
 <li className="list-group-item">
  <span>{parent?.email}</span><br></br>
  <span>{parent?.contact}</span><br></br>
  <span style={{color:'#ff4242'}}>{parent?.emergencyContactRelationship}({parent?.emergencyContact})</span>
  </li>
  <li className="list-group-item" style={{minHeight:"65px"}}>
  <span>{parent?.address}</span>
</li>
  </ul>
  <Collapse expandIconPosition="end"  style={{color:'#939ca3 !important'  ,backgroundColor:'#23395d',fontSize:'large'}}>
            {/* <Panel style={{ color: 'snow' , textAlign:'center'}}  key={attendances.id}  className="collapse-custom-header" header={`${attendances.name}<${attendances.startAt}-${attendances.endAt}>`}> */}
            <Panel key={Number(parent?.id)} style={{ color: 'snow' , textAlign:'center'}} header={"Actions"} className="collapse-custom-header">
            <div style={{width:"100%"}}>
              <Link style={{width:"100%", marginBottom:"10px"}}to={`/admin/parents/${parent?.id}`}>
                        <PrimaryButton style={{width:"100%" ,marginBottom:"10px",borderRadius:"20px" }} label="View" />
                  </Link>
                  <Link style={{width:"100%",marginBottom:"10px"}} to={`/admin/parents/${parent?.id}/edit`}>
                  <PrimaryButton  style={{width:"100%" ,marginBottom:"10px",borderRadius:"20px" }}label="Edit" />
                  </Link>
                  <PrimaryButton style={{width:"100%" ,color:"red" ,borderRadius:"20px" }} label="Delete" onClick={() => handleDelete(parent)} />
                  </div>
            </Panel>
        </Collapse>
    </div>
    </div>
</div>
    )
};

export default ParentsCardView;
