import { Spin } from 'antd';
import './ClassWorksList.scss';
import { Props as TableProps } from './Base';
import { useGetAttendanceByStudent} from '../../../../api/attendances';
import "bootstrap-css-only/css/bootstrap.min.css";
// import '../../../../../mixins.scss';
import { useNavigate } from 'react-router-dom';
import { ClassWorks } from '../../../../interfaces/classWorks';
import { File } from '../../../../interfaces/file';

import htmr from 'htmr';
import { useQueryClient } from 'react-query';
import DocumentViewer from '../../../../shared/components/Upload/DocumentViewer';
import ReactPlayer from 'react-player'
import React from 'react';
const videoType ='mp4'

type Props = TableProps & {
  files:[File]
};

const ClassWorksCardList: React.FC<Props> = ({files,...props }) => {
  // const { data: attendance, isLoading } = useGetAttendanceByStudent(Number(classWorkInfo.attendancesId),Number(classWorkInfo.studentId));



return (
  <>
    {/* <Spin spinning={isLoading}> */}
      {/* {attendance?.classWorks.map((classWorks: ClassWorks) => ( */}
        {/* {files.map((classWorks: ClassWorks) => ( */}
          
            <div className="col-sm-4" style={{ maxWidth: '100%' }}>
            {files.map((file: File) => (
              <div className="card" style={{width:"100% !important", height:"100%  !important"}}>
                {
                  file.name.toUpperCase().split('.').includes(videoType.toUpperCase()) ? 
                  (<div className="card-body">
               <ReactPlayer loop={true}  muted={true}  controls={true} url={file.s3Url}/>
               </div>):(
                   <DocumentViewer files={file} />)
                }
                
                <div className="card-body">
                  <p className="card-text" style={{color:"#ffff"}}> {file && htmr(file.comment) }</p>
                </div>
              </div>
         
                  ))}
            </div>
         

    {/* </Spin> */}
      </>
      ); 
  }
export default ClassWorksCardList;