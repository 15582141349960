import { message, Spin } from 'antd';
import React from 'react';
import { DateFormat } from '../../../utils/global';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { deletePayment, getPaymentsList, updatePayment, useGetPayment } from '../../../api/payments';
import DangerButton from '../../../shared/components/buttons/DangerButton';
import PrimaryButton from '../../../shared/components/buttons/PrimaryButton';
import SecondaryButton from '../../../shared/components/buttons/SecondaryButton';
import { PAGE_SIZE } from '../../../shared/constants';
import CancelIcon from '../../../shared/svgs/CancelIcon';
import DeleteForeverIcon from '../../../shared/svgs/DeleteForeverIcon';
import EditIcon from '../../../shared/svgs/EditIcon';
import PersonIcon from '../../../shared/svgs/PersonIcon';
import DocumentViewer from '../../../shared/components/Upload/DocumentViewer';
import { Payment } from '../../../interfaces/payment';

const PaymentDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { data: payment, isLoading } = useGetPayment(Number(id));

  const { mutateAsync: mutationDelete } = useMutation(deletePayment, {
    onSuccess: async () => {
      message.info('Deleted!');
      queryClient.invalidateQueries({
        queryKey: ['payments', 'list'],
      });
      navigate(`/admin/payments`);
    },
    onError: () => {
      message.error('The action is failed! Please try again');
    },
    onSettled: () => {
      queryClient.prefetchQuery({
        queryKey: ['payments', 'list', { start: 0, count: PAGE_SIZE, filter: undefined }],
        queryFn: () => getPaymentsList({ start: 0, count: PAGE_SIZE, filter: undefined }),
      });
    },
  });

  const handleDelete = () => {
    mutationDelete(Number(id));
  };

  const { mutateAsync: updateMutate } = useMutation(updatePayment, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['payments', 'list'],
      });
      queryClient.invalidateQueries({
        queryKey: [`payments/${id}`, undefined],
      });
    },
    onError: () => {
      message.error('The action is failed! Please try again');
    },
  });

  const handleUnblockUser = async () => {
    const newValues: any = payment;
    newValues.blockedAt = 'null';
    updateMutate({ ...newValues, id });
  };

  return (
    <Spin spinning={isLoading}>
      <div id="user-details-container">
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page">Payment Details</h2>
            </div>
            <div className="details-container">
              <div className="item">
                <label>Parent</label>
                <span className="value">
                {payment?.parent?.firstName} {payment?.parent?.lastName}
                </span>
              </div>
              <div className="item">
                <label>Student</label>
                <span className="value">
                  {payment?.student?.firstName} {payment?.student?.lastName}
                </span>
              </div>
              <div className="item">
                <label>Course</label>
                <span className="value">
                  {payment?.course?.name} 
                </span>
              </div>
              <div className="item">
                <label>Period</label>
                <span className="value">
                  {DateFormat(payment?.periodStartDate)} - {DateFormat(payment?.periodEndDate)} 
                </span>
              </div>
              <div className="item">
                <label>Amount</label>
                <span className="value">
                  {payment?.amount} 
                </span>
              </div>
              <div className="item">
                <label>Payment Type</label>
                <span className="value">
                  {payment?.paymentType} 
                </span>
              </div>
              <div className="item">
                <label>Remark</label>
                <span className="value">
                  {payment?.remark} 
                </span>
              </div>
              <div className="item">
                <label>Status</label>
                <span className={`value status ${payment?.status ? 'paid' : 'unpaid'}`}>
                  {payment?.status ? 'paid' : 'unpaid'}
                </span>
              </div>
              <div className="item">
                  <label>receipt</label>
                  <span className="value">
                {payment && <DocumentViewer files={payment?.files} />}
            </span>
          </div>
            </div>
            <div className="page-content-footer">
              {/* <Link to={`/admin/payments/${id}/edit`}>
                <PrimaryButton label="Edit" icon={<EditIcon />} />
              </Link>
              <DangerButton label="Delete" onOk={handleDelete} icon={<DeleteForeverIcon />} /> */}
              <Link to={`/admin/payments`}>
                <SecondaryButton label="Cancel" icon={<CancelIcon />} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default PaymentDetailsPage;
