import React from 'react';
import ForgotPasswordForm from '../../shared/components/forms/ForgotPassword';
import { doForgotPassword } from '../../api/auth';
import { useMutation } from 'react-query';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ForgetPasswordParamsI, ForgetPasswordResponseI } from '../../interfaces/auth';

const ForgotPasswordPage: React.FC = () => {
  const navigate = useNavigate();

  const { mutate: createMutate } = useMutation(doForgotPassword, {
    onSuccess: async (data: ForgetPasswordResponseI) => {
      if (data.requireOTP) {
        navigate(`/reset-password?token=${data.requireOTP}`);
      }
    },
    onError: () => {
      message.error('Your action has failed! Please try again');
    },
  });

  const handleSubmit = async (values: ForgetPasswordParamsI) => {
    createMutate(values);
  };

  return (
    <div className="login-container">
      <div className="content-page">
        <ForgotPasswordForm handleSubmit={handleSubmit} />
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
