import React, {useState}  from 'react';
import { ClassReplaces } from '../../../../interfaces/classReplaces';
import { useMutation, useQueryClient} from 'react-query';
import { Student } from '../../../../interfaces/student';
import { Course } from '../../../../interfaces/course';
import PrimaryButton from '../../../../shared/components/buttons/PrimaryButton';
import DisabledBorderLessButton from '../../../../shared/components/buttons/DisabledBorderLessButton';
import {EditOutlined,CheckCircleOutlined} from '@ant-design/icons';
import TableBase, { Props as TableProps } from '.././Base';
import DeleteForeverIcon from '../../../../shared/svgs/DeleteForeverIcon';
import { Dropdown, Button } from 'antd';
import file from 'react-player/file';
import { deleteClassReplaces } from '../../../../api/classReplaces';
import { Link } from 'react-router-dom';
import DangerButton from '../../buttons/DangerButton';
type Props = TableProps & {
  withAction?(_: any): void;
  assignClass(_: any): void;
  dataSource?: any[];
};

const ClassReplacesTable: React.FC<Props> = ({ dataSource, withAction,assignClass, ...props }) => {
  const queryClient = useQueryClient();
  const [ isEmergencyContactModalVisible, setIsEmergencyContactModalVisible] = useState<boolean>(false);
  const { mutateAsync: mutationDelete } = useMutation(deleteClassReplaces);

  const onEditClick = () => {
    setIsEmergencyContactModalVisible(true);
  };

  const handleClickDelete = async (course: any) => {
    await mutationDelete(course.id);
    queryClient.invalidateQueries({
      queryKey: ['classReplaces', 'list'],
    });
  };

  const COLUMNS = [
    {
      
      title: 'Student',
      width: 100,
      render: (classReplaces: ClassReplaces) => {
        return (
          <><Dropdown.Button style={{ position:"absolute" ,display: 'flex', flexDirection: 'row-reverse' }}
            menu={{
              items: [
                {
                  key: '1',
                  label: (
                    <Link to={`/admin/classReplaces/${classReplaces?.id}`}>
                      <Button className="btn-table-action-primary" type="link">
                        View
                      </Button>
                    </Link>
                  ),
                },
                {
                  key: '2',
                  label: (
                    <Link to={`/admin/classReplaces/${classReplaces?.id}/edit`}>
                      <Button className="btn-table-action-primary" type="link">
                        Edit
                      </Button>
                    </Link>
                  ),
                },
                {
                  key: '3',
                  label: <DangerButton label="Delete" onOk={() => handleClickDelete(classReplaces)} />,
                },
              ],
            }}
          ></Dropdown.Button><><>
          <span className="text-capitalize" style={{ fontSize: '16px' }}><b>
            {classReplaces?.student.lastName}{classReplaces?.student.firstName} ({classReplaces?.student.chineseName})
          </b></span>
            <br></br><br></br>
            <span className="text-capitalize" style={{ fontSize: '12px' }}>
              <b>
                Absent Class:
              </b>
            </span>
            <br></br>
            <span style={{ fontSize: '10px' }}>{classReplaces.remarks}</span></><span className="text-capitalize">
                {classReplaces?.absentDate}({classReplaces?.absentDay})
                <br></br>
                {classReplaces?.absentCourse?.id ?
                  `${classReplaces?.absentCourse?.startAt.substring(0, 5)}-${classReplaces?.absentCourse?.endAt.substring(0, 5)}`
                  : '-'}

                <br></br><br></br>
                <span className="text-capitalize" style={{ fontSize: '12px' }}>
                  <b>
                    Replace Class:
                  </b>
                </span>
                <br></br>
              </span><span className="text-capitalize">
                {classReplaces?.replaceDate}({classReplaces?.replaceDay})
                <br></br>
                {classReplaces?.replaceCourse?.id ?
                  `${classReplaces?.replaceCourse?.startAt.substring(0, 5)}-${classReplaces?.replaceCourse?.endAt.substring(0, 5)}`
                  : '-'}
              </span>

              <br></br><br></br>
              <span className="text-capitalize" style={{ fontSize: '12px' }}>
                  <b>
                    Status:
                  </b>
                </span>
              <span style={{ width: '100%', color: '#ffff', background: `${classReplaces.isDone ? 'green' : 'red'}` }} className={`table-status ${classReplaces.isDone ? 'done' : 'inactive'}`}>{classReplaces.isDone ? 'Done' : 'Pending'}</span>

              <br></br>
              <span> {classReplaces.isDone == false ? (
                <PrimaryButton
                  icon={<EditOutlined />}
                  label="Assign Class"
                  style={{ width: '100%', background: 'grey' }}
                  disabled={(classReplaces.isDone ? true : false)}
                  onClick={() => assignClass({ 'id': classReplaces.id, 'studentId': classReplaces.studentId })} />) : (
                <DisabledBorderLessButton
                  icon={<CheckCircleOutlined />}
                  label="Done"
                ></DisabledBorderLessButton>
              )}
              </span>
            </></>
        );
      },
    },
    
  ];

  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default ClassReplacesTable;
