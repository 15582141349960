import { pathToUrl } from './../utils/router';
import { ClassWorks } from './../interfaces/classWorks';
import { GetListResponse } from '../interfaces';
import { useFetch, usePost } from '../shared/reactQuery';
import { api } from '../shared/api';
import { handleFilterParams } from '../utils/global';

export const useGetStudentsList = () => useFetch<GetListResponse>('classWorks');

export const useGetStudent = (id: number) => useFetch<ClassWorks>(`classWorks/${id}`);
export const useGetByStudent = (studentId: number) => useFetch<ClassWorks>(`classWorks/getStudent/${studentId}`);
export const useGetByStudentAttendances = (studentAttendancesId: number) => useFetch<ClassWorks>(`classWorks/getStudentAttendances/${studentAttendancesId}`);

export const useCreateStudent = (updater: (oldData: ClassWorks[], newData: ClassWorks) => ClassWorks[]) => {
  return usePost<ClassWorks[], ClassWorks>(pathToUrl('classWorks'), undefined, updater);
};

export const getStudentsList = async ({ start, count, filter }: { start?: number; count?: number; filter?: any }) => {
  const newParams = handleFilterParams({
    start,
    count,
    filter,
  });
  const { data: response }: any = await api.get(`classWorks?${newParams}`);
  return response;
};

export const createClassWorks= async (values: any) => {
  const { data: response }: any = await api.post('classWorks', values.body);
  return response.data;
};

export const updateStatus = async ({ id}: { id: string;}) => {
  const { data: response }: any = await api.patch(`classWorks/${id}/isRead`,{});
  return response.data;
};

export const updateStudent = async ({ id, ...values }: { id: string; values: ClassWorks }) => {
  const { data: response }: any = await api.patch(`classWorks/${id}`, values);
  return response.data;
};

export const deleteStudent = async (id: number) => {
  const { data: response }: any = await api.delete(`students/${id}`);
  return response.data;
};
