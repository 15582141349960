import React, { createContext, useMemo, useState } from 'react';

type Props = {
  children: any;
};

const AuthContext = createContext({});

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [auth, setAuth] = useState({});
  const value = useMemo(() => ({ auth, setAuth }), [auth]);
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContext;
