import React, { useEffect, useState }  from 'react';
import { Form, Input, DatePicker, Switch } from 'antd';
import  { Props as TableProps } from '../tables/Base';
import {createCourseAttendances} from '../../../api/attendances';
import { DATE_FORMAT_QUERY } from '../../constants';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import CheckCircleIcon from '../../svgs/CheckCircleIcon';
import CancelIcon from '../../svgs/CancelIcon';
import ImageUploader from '../Upload/Image';
import JoditEditor from 'jodit-react';
type Props = TableProps & {
  courseName:any
  courseId:number,
  startAt:any,
  endAt:any,
  studentCount:any
  setCreateAttendancestModalVisible(_: boolean): void;
};


const CourseStudentsAddAttendances: React.FC<Props> = ({courseName,courseId,startAt,endAt, studentCount,setCreateAttendancestModalVisible, ...props }) => {
  const [form] = Form.useForm();
// const navigate = useNavigate();
const handleSubmit = (values: any) => {
  createCourseAttendances(values)
};

const handleParams = (values: any) => {
  const newValues: any = values;
  newValues.courseId=Number(newValues.courseId)
  newValues.date=newValues.date.format(DATE_FORMAT_QUERY)
  return newValues;
};
const handleOnFinish = (values: any) => {
  const params = handleParams(values);
  handleSubmit(params);
  setCreateAttendancestModalVisible(false)
  
};
useEffect(() => { 
    form.setFieldsValue({
      courseId:courseId,
      startAt:startAt,
      endAt:endAt,
      courseName:courseName,
      studentCount:studentCount,
      location:'PAGOH'
    });
}, 
[{courseId},{startAt},{endAt},{courseName},{studentCount},{location}]);

return (
  <div className="users-container">
      <div className="page-container">
        <div className="page-content">
          <div className="page-content-header">
            <h2 className="title-page">Add New Attendances</h2>
          </div>
          <div className="user-form-container">
  <Form form={form} requiredMark={false} colon={false} onFinish={handleOnFinish} scrollToFirstError>
  <div className="form-container">
          <div className="form-content">
            <Form.Item label="Course Name"  name="courseName">
            <Input disabled placeholder="Required" />
            </Form.Item>
            <Form.Item label="id"  name="courseId">
            <Input disabled placeholder="Required" />
            </Form.Item>
            <Form.Item  label="startAt"  name="startAt">
              <Input disabled value="" />
            </Form.Item>
            <Form.Item label="endAt"  name="endAt">
              <Input disabled value="" />
            </Form.Item>
            <Form.Item label="Student Count"  name="studentCount">
              <Input disabled value="" />
            </Form.Item>
            <Form.Item  label="location" name="location" rules={[{ required: true, message: 'Required' }]}>
              <Input disabled placeholder="Required" value="" />
            </Form.Item>
            <Form.Item label="date" name="date" rules={[{ required: true, message: 'Required' }]}>
            <DatePicker placeholder="Required"  format={DATE_FORMAT_QUERY} />
            </Form.Item>
            
            <div className="ant-form-item css-dev-only-do-not-override-yp8pcc" style={{display: "flex",justifyContent: "space-around"}}>
            <PrimaryButton 
              label='Create'
              icon={<CheckCircleIcon />}
              htmlType="submit"
            /> 
          </div>
          </div>
          </div>
            </Form>
    </div>
    </div>
    </div>
    </div>

);
};

export default CourseStudentsAddAttendances;
