import { Button, DatePicker, Form, Input, Select, Switch } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { File } from '../../../../interfaces/file';
import { Material } from '../../../../interfaces/material';
import ImageUploader from '../../Upload/Image';
import CancelIcon from '../../../svgs/CancelIcon';
import CheckCircleIcon from '../../../svgs/CheckCircleIcon';
import PrimaryButton from '../../buttons/PrimaryButton';
import SecondaryButton from '../../buttons/SecondaryButton';
import moment from 'moment'; 
type Props = {
  MaterialId:number;
  Material: any;
  handleSubmit(_: any): void;
};

const MaterialsForm: React.FC<Props> = ({ MaterialId,Material , handleSubmit }) => {
  const [form] = Form.useForm();
  const [filesImg, setfilesImg] = useState<any>([]);
  const handleOnFinish = (values: Material) => {
    const newValues: any = values ;
    newValues.supplirPrice=Number( newValues.supplirPrice)
    newValues.sellingPrice=Number( newValues.sellingPrice)
    newValues.qty=Number( newValues.qty)
    newValues.files = filesImg
    handleSubmit(newValues);
  };

  useEffect(() => {
    if (Material?.id) {
      const _files = Material?.files;
      form.setFieldsValue({
        ...Material,
        files: { fileList: Material.files },
        MaterialId : MaterialId,
      }); 


      setfilesImg(
        Material.files?.map((d: any) => {
          return { ...d, url: d.s3Url };
        }),
      );
    }
  }, [Material]);
  
  const onStartTimeSelect = (time: any) => {
    form.setFieldsValue({
      startAt: moment(time, 'HH:mm:ss'),
    });
  };

  const onEndTimeSelect = (time: any) => {
    form.setFieldsValue({
      endAt: moment(time, 'HH:mm:ss'),
    });
  };

  return (
    <div className="course-form-container">
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        onFinish={handleOnFinish}
        scrollToFirstError
        autoComplete="off"
        initialValues={{ isActive: true }}
      >
        <div className="form-container">
          <Form.Item
            label="Name"
            name= 'name'
            rules={[{ required: true, message: 'Required' }]}
            >
            <Input placeholder="Required" autoComplete="off" />
            </Form.Item>
            <Form.Item
            label="Supplier Source"
            name= 'supplierSource'
            rules={[{ required: true, message: 'Required' }]}
            >
            <Input placeholder="Required" autoComplete="off" />
            </Form.Item>
            <Form.Item
            label="Supplir Price"
            name= 'supplirPrice'
            rules={[{ required: true, message: 'Required' }]}
            >
            <Input placeholder="Required" autoComplete="off" />
            </Form.Item>
            <Form.Item
            label="Selling Price"
            name= 'sellingPrice'
            rules={[{ required: true, message: 'Required' }]}
            >
            <Input placeholder="Required" autoComplete="off" />
            </Form.Item>
            <Form.Item
            label="Quantity"
            name= 'qty'
            rules={[{ required: true, message: 'Required' }]}
            >
            <Input placeholder="Required" autoComplete="off" />
            </Form.Item>
            <Form.Item
            label="Remark"
            name= 'remark'
            rules={[{ required: true, message: 'Required' }]}
            >
            <Input placeholder="Required" autoComplete="off" />
            </Form.Item>
           <Form.Item label="Image">
              <ImageUploader  name="files" documents={filesImg} setDocuments={setfilesImg} multiple={true} maxCount={10} />
            </Form.Item>
          
          <div className="form-footer">
            <PrimaryButton
              label={MaterialId ? 'Update' : 'Create'}
              icon={<CheckCircleIcon />}
              htmlType="submit"
            />
            <Link to="/admin/materials">
              <SecondaryButton label={'Cancel'} icon={<CancelIcon />} />
            </Link>
            </div>
          </div>
      </Form>
      
    </div>
  );
};

export default MaterialsForm;
