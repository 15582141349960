import Cookies from 'js-cookie';
import { TOKEN_NAME } from './constants';
import axiosInstance from '../axiosInstance';

const baseUri = process.env.REACT_APP_API_URI || '';

export const api = {
  get: <T>(url: string, params?: Record<string, unknown>) =>
    axiosInstance.get<T>(`${baseUri}/${url}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get(TOKEN_NAME)}`,
        'Content-Type': 'application/json',
      },
      ...params,
    }),
  post: <T>(url: string, data: any) =>
    axiosInstance.post<T>(`${baseUri}/${url}`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get(TOKEN_NAME)}`,
        'Content-Type': 'application/json',
      },
    }),
  patch: <T>(url: string, data: any) =>
    axiosInstance.patch<T>(`${baseUri}/${url}`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get(TOKEN_NAME)}`,
        'Content-Type': 'application/json',
      },
    }),
  delete: <T>(url: string) =>
    axiosInstance.delete<T>(`${baseUri}/${url}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get(TOKEN_NAME)}`,
        'Content-Type': 'application/json',
      },
    }),
};
