import React from 'react';
import { Collapse,Button  } from 'antd';
import { Material } from '../../../interfaces/material';
import { Student } from '../../../interfaces/student';
import DocumentViewer from '../../../shared/components/Upload/DocumentViewer';
import { Props as TableProps } from './Base';
import { Link } from 'react-router-dom';
import PrimaryButton from '../../../shared/components/buttons/PrimaryButton';
import MaterialsStudentsTable from '../../../shared/components/tables/MaterialsStudents';
import DangerButton from '../../../shared/components/buttons/DangerButton';
const { Panel } = Collapse;
type Props = TableProps & {
  handleClickDelete(_: any): void;
  material?: Material;
};

const materialCardView: React.FC<Props> = ({ material, handleClickDelete, ...props }) => {
console.log(material)
  const handleDelete = (student :any) => {
    handleClickDelete(student)
  };

  return (
  <div className="col-sm-12"  style={{paddingRight:"1px", width:"100%",paddingLeft:"1px"}}>
    <div className="card-row">
  <div className="card" style={{marginLeft:'10px'}}>
  <div className="card-body" style={{width:"20%"}}>
  <div className="material-card-body" style={{width:"20%"}}>
  <DocumentViewer files={material?.files} />
  </div>
 </div>
 <h4 className="card-title" style={{textAlign:"center"}}>
 <span>{material?.name}</span><br></br>
 <span>{material?.qty}</span><br></br>
 </h4>
 <ul className="list-group list-group-flush">
  <li className="list-group-item">
      <span>Selling Price:({material?.sellingPrice})&emsp;</span>
      <span>Supplir Price:{material?.supplirPrice}</span>
  </li>
  <li className="list-group-item">
  <span>Qty:({material?.qty})&emsp;</span>
      <span>totalQty:{material?.totalQty}</span>
  </li>
  <li className="list-group-item">
    <span>Supplier Source:{material?.supplierSource}&emsp;</span>
  </li>
 </ul>

 <><Collapse  expandIconPosition="end"  style={{color:'#939ca3 !important'  ,backgroundColor:'#23395d',fontSize:'large'}}>
              <Panel className="collapse-custom-header" header='Student Taken' style={{ color: 'snow' , textAlign:'center'}}  key="2" >
                <div className="page-header-actions" style={{flexDirection:'row-reverse',paddingBottom:'10px'}}>
                    </div>
                      <MaterialsStudentsTable
                      students={material?.students}
                    />
              </Panel>
            </Collapse></>

 <Collapse expandIconPosition="end"  style={{color:'#939ca3 !important'  ,backgroundColor:'#23395d',fontSize:'large'}}>
            {/* <Panel style={{ color: 'snow' , textAlign:'center'}}  key={attendances.id}  className="collapse-custom-header" header={`${attendances.name}<${attendances.startAt}-${attendances.endAt}>`}> */}
            <Panel key={Number(material?.id)} style={{ color: 'snow' , textAlign:'center'}} header={"Actions"} className="collapse-custom-header">
            <div style={{width:"100%"}}>
              <Link style={{width:"100%", marginBottom:"10px"}}to={`/admin/materials/${material?.id}`}>
                        <PrimaryButton style={{width:"100%" ,marginBottom:"10px",borderRadius:"20px" }} label="View" />
                  </Link>
                  <Link style={{width:"100%",marginBottom:"10px"}} to={`/admin/materials/${material?.id}/edit`}>
                  <PrimaryButton  style={{width:"100%" ,marginBottom:"10px",borderRadius:"20px" }}label="Edit" />
                  </Link>
                  <DangerButton style={{width:"100%" ,color:"red" ,borderRadius:"20px" }} label="Delete" onOk={() => handleDelete(material)} />
                  </div>
            </Panel>
        </Collapse>

        
    </div>
    </div>
</div>
    )

};

export default materialCardView;
