import React from 'react';
import { Button, DatePicker, Form, Input, Select, message } from 'antd';
import { MaterialIn } from '../../../interfaces/materialIn';
import TableBase, { Props as TableProps } from '../tables/Base';
import { NumericFormat } from 'react-number-format';
import { useMutation, useQueryClient,useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {createMaterialIn,updateMaterialIn} from '../../../api/materialIns';
import { PASSWORD_TIP, DATE_FORMAT_QUERY } from '../../constants';
import { Link } from 'react-router-dom';
import CancelIcon from '../../svgs/CancelIcon';
import CheckCircleIcon from '../../svgs/CheckCircleIcon';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import moment from 'moment';
const { TimePicker } = DatePicker;
type Props = TableProps & {
  MaterialId:number;
  handleSubmit(_: any): void;
  onMaterialTransCancel(): void;
};


const MaterialsInForm: React.FC<Props> = ({ MaterialId, handleSubmit,onMaterialTransCancel, ...props }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleOnFinish = (values: MaterialIn) => {
    const newValues: any = { ...values };
    newValues.materialId=MaterialId
    newValues.quantity=Number(newValues.quantity)
    newValues.dateIn=Number( newValues.dateIn)
    createMutate(newValues);
  };

  const onDateSelect = (date: any) => {
    form.setFieldsValue({
      dateIn: moment(date, 'HH:mm:ss'),
    });
  };

  const { mutate: createMutate } = useMutation(createMaterialIn, {
    onSuccess: async () => {
      message.info('Create materialIns successfully!');
      queryClient.invalidateQueries({
        queryKey: ['materialIns', 'list'],
      });
      navigate(`/admin/materials`);
    },
    onError: () => {
      message.error('Create materialIns failed! Please try again');
    },
  });
// const navigate = useNavigate();

return (
  <div className="course-form-container">
    <Form
      form={form}
      requiredMark={false}
      colon={false}
      onFinish={handleOnFinish}
      scrollToFirstError
      autoComplete="off"
      initialValues={{ isActive: true }}
    >
      <div className="form-container">
        <div className="form-content">
          <Form.Item
          label="Supplier Source"
          name= 'supplierSource'
          rules={[{ required: true, message: 'Required' }]}
          >
          <Input placeholder="Required" autoComplete="off" />
          </Form.Item>

          
          <Form.Item
          label="Supplir Price"
          name= 'price'
          rules={[{ required: true, message: 'Required' }]}
          >
          <NumericFormat
                className="ant-input"
                placeholder="Required"
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
              />
          </Form.Item>

          <Form.Item
          label="Price per unit"
          name= 'pricePerUnit'
          rules={[{ required: true, message: 'Required' }]}
          >
          <NumericFormat
                className="ant-input"
                placeholder="Required"
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
              />
          </Form.Item>

          <Form.Item
          label="Delivery Fee"
          name= 'deliveryFee'
          rules={[{ required: true, message: 'Required' }]}
          >
          <NumericFormat
                className="ant-input"
                placeholder="Required"
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
              />
          </Form.Item>



          <Form.Item
              name="dateIn"
              label="Date In"
              rules={[{ required: true, message: 'Required' }]}
            >
              <DatePicker className="ant" format={DATE_FORMAT_QUERY} onChange={onDateSelect}/>
            </Form.Item>

          <Form.Item
          label="Quantity"
          name= 'quantity'
          rules={[{ required: true, message: 'Required' }]}
          >
          <NumericFormat
                className="ant-input"
                placeholder="Required"
                thousandSeparator={true}
                decimalScale={0}
                fixedDecimalScale={true}
              />
          </Form.Item>
          <Form.Item
          label="Remark"
          name= 'remark'
          rules={[{ required: true, message: 'Required' }]}
          >
          <Input placeholder="Required" autoComplete="off" />
          </Form.Item>
        <div className="form-footer">
          <PrimaryButton
            label='Create'
            icon={<CheckCircleIcon />}
            htmlType="submit"
          />
          <Link to={`/admin/materials/${MaterialId}`}>
            <SecondaryButton label={'Cancel'} icon={<CancelIcon />}  onClick={onMaterialTransCancel} />
          </Link>
        </div>
        </div> 
      </div>
    </Form>
  </div>
);
};

export default MaterialsInForm;
function Float(price: any): any {
  throw new Error('Function not implemented.');
}

