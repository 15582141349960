import { pathToUrl } from './../utils/router';
import { File } from './../interfaces/file';
import { GetListResponse } from '../interfaces';
import { useFetch, usePost } from '../shared/reactQuery';
import { api } from '../shared/api';
import { handleFilterParams } from '../utils/global';

// export const useGetStudentsList = () => useFetch<GetListResponse>('students');

// export const useGetStudent = (id: number) => useFetch<Student>(`students/${id}`);

// export const useCreateStudent = (updater: (oldData: Student[], newData: Student) => Student[]) => {
//   return usePost<Student[], Student>(pathToUrl('students'), undefined, updater);
// };

// export const getStudentsList = async ({ start, count, filter }: { start?: number; count?: number; filter?: any }) => {
//   const newParams = handleFilterParams({
//     start,
//     count,
//     filter,
//   });
//   const { data: response }: any = await api.get(`students?${newParams}`);
//   return response;
// };

// export const createStudent = async (values: Student) => {
//   const { data: response }: any = await api.post('students', values);
//   return response.data;
// };

export const updateFile = async ({ id, ...values }: { id: string; values: File }) => {
  const { data: response }: any = await api.patch(`files/${id}`, values);
  return response.data;
};

export const deleteStudent = async (id: number) => {
  const { data: response }: any = await api.delete(`files/${id}`);
  return response.data;
};
