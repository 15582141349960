import { pathToUrl } from '../utils/router';
import { MaterialIn } from '../interfaces/materialIn';
import { GetListResponse } from '../interfaces';
import { useFetch, usePost } from '../shared/reactQuery';
import { api } from '../shared/api';
import { handleFilterParams } from '../utils/global';

export const useGetMaterialInList = () => useFetch<GetListResponse>('materialIns');

export const useGetMaterialIn = (id: number) => useFetch<MaterialIn>(`materialIns/${id}`);

export const useCreateMaterialIn = (updater: (oldData: MaterialIn[], newData: MaterialIn) => MaterialIn[]) => {
  return usePost<MaterialIn[], MaterialIn>(pathToUrl('materialIns'), undefined, updater);
};

export const getMaterialInList = async ({ start, count, filter }: { start?: number; count?: number; filter?: any }) => {
  const newParams = handleFilterParams({
    start,
    count,
    filter,
  });
  const { data: response }: any = await api.get(`materialIns?${newParams}`);
  return response;
};

export const createMaterialIn = async (values: MaterialIn) => {
  const { data: response }: any = await api.post('materialIns', values);
  return response.data;
};

export const updateMaterialIn = async ({ id, ...values }: { id: string; values: MaterialIn }) => {
  const { data: response }: any = await api.patch(`materialIns/${id}`, values);
  return response.data;
};

export const deleteMaterialIn = async (id: number) => {
  const { data: response }: any = await api.delete(`materialIns/${id}`);
  return response.data;
};
