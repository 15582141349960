import { pathToUrl } from '../utils/router';
import { Attendances } from '../interfaces/attendances';
import { GetListResponse } from '../interfaces';
import { useFetch, usePost } from '../shared/reactQuery';
import { api } from '../shared/api';
import { handleFilterParams } from '../utils/global';

export const useGetCourseAttendances = (id: number) => useFetch<Attendances>(`courses/${id}/attendances`);

export const useGetAttendance = (id: number) => useFetch<Attendances>(`attendances/${id}`);
export const useGetAttendanceByStudent = (id: number,studentId:number) => useFetch<any>(`attendances/${id}/studentId/${studentId}`);

export const useGetAttendanceByToday = async ({ start, count, filter }: { start?: number; count?: number; filter?: any }) => {
  const newParams = handleFilterParams({
    start,
    count,
    filter,
  });
  const { data: response }: any = await api.get(`attendances/todayAttendances`);
  return response;
};

// export const useGetAttendanceByToday = async () => useFetch<Attendances>(`attendances/todayAttendances`);


export const useGetAttendancesList = async ({ start, count, filter }: { start?: number; count?: number; filter?: any }) => {
  const newParams = handleFilterParams({
    start,
    count,
    filter,
  });
  const { data: response }: any = await api.get(`attendances?${newParams}`);
  return response;
};
export const createCourseAttendances = async (values: Attendances) => {
  const { data: response }: any = await api.post('attendances', values);
  return response.data;
};
