import { message, Spin,Form, Input, Button, DatePicker } from 'antd';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { deleteCoachLeave, getCoachLeavesList, updateCoachLeaves, useGetCoachLeaves } from '../../../api/CoachLeaves';
import DangerButton from '../../../shared/components/buttons/DangerButton';
import PrimaryButton from '../../../shared/components/buttons/PrimaryButton';
import SecondaryButton from '../../../shared/components/buttons/SecondaryButton';
import { PAGE_SIZE } from '../../../shared/constants';
import CancelIcon from '../../../shared/svgs/CancelIcon';
import DeleteForeverIcon from '../../../shared/svgs/DeleteForeverIcon';
import EditIcon from '../../../shared/svgs/EditIcon';
import { DATE_FORMAT_FRONTEND } from '../../../shared/constants';
import dayjs, { Dayjs } from 'dayjs';

const CoachDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { data: coachLeave, isLoading } = useGetCoachLeaves(Number(id));

  const { mutateAsync: mutationDelete } = useMutation(deleteCoachLeave, {
    onSuccess: async () => {
      message.info('Deleted!');
      queryClient.invalidateQueries({
        queryKey: ['coachLeaves', 'list'],
      });
      navigate(`/admin/coachLeaves`);
    },
    onError: () => {
      message.error('The action is failed! Please try again');
    },
    onSettled: () => {
      queryClient.prefetchQuery({
        queryKey: ['coachLeaves', 'list', { start: 0, count: PAGE_SIZE, filter: undefined }],
        queryFn: () => getCoachLeavesList({ start: 0, count: PAGE_SIZE, filter: undefined }),
      });
    },
  });

  const handleDelete = () => {
    mutationDelete(Number(id));
  };

  const { mutateAsync: updateMutate } = useMutation(updateCoachLeaves, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['coachLeaves', 'list'],
      });
      queryClient.invalidateQueries({
        queryKey: [`coachLeaves/${id}`, undefined],
      });
      message.info('Unblocked this coach Leaves!');
    },
    onError: () => {
      message.error('The action is failed! Please try again');
    },
  });

  const handleUnblockUser = async () => {
    const newValues: any = coachLeave;
    newValues.blockedAt = 'null';
    updateMutate({ ...newValues, id });
  };

  const handleOnFinish = () => {
  };

  return (
    <Spin spinning={isLoading}>
      <div id="user-details-container">
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page">Coach Details</h2>
            </div>
            <div className="details-container">
              <div className="item">
                <label>Coach</label>
                <span className="value">{coachLeave?.coach?.firstName}{coachLeave?.coach?.lastName}</span>
              </div>
              <div className="item">
                <label>Name</label>
                <span className="value">
                {dayjs(coachLeave?.date).format(DATE_FORMAT_FRONTEND)}
                </span>
              </div>
              <div className="item">
                <label>Contact</label>
                <span className="value">
                  {coachLeave?.remark} 
                </span>
              </div>
            </div>
            <div className="page-content-footer">
              <Link to={`/admin/coachLeaves/${id}/edit`}>
                <PrimaryButton label="Edit" icon={<EditIcon />} />
              </Link>
              <DangerButton label="Delete" onOk={handleDelete} icon={<DeleteForeverIcon />} />
              <Link to={`/admin/coachLeaves`}>
                <SecondaryButton label="Cancel" icon={<CancelIcon />} />
              </Link>
            </div>
          </div>
        </div>
      </div>
     


</Spin>
  );
};

export default CoachDetailsPage;
