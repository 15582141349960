import React from 'react';
import { useGetProfile } from '../../../api/auth';
import { useGetParent } from '../../../api/parents';
import {  TOKEN_NAME } from '../../../shared/constants';
import { Student } from '../../../interfaces/student';
import Cookies from 'js-cookie';
import ParentView from './ParentProfile/View';
import StudentView from './StudentProfile/View';

const ParentProfilePage: React.FC = () => {const token = Cookies.get(TOKEN_NAME);
  const profile = token ? useGetProfile() : undefined;
  const { data: parent, isLoading:isParentLoading } = useGetParent(Number(profile?.data.id));
 
  return (
    <div className="students-container">
    <div className="page-container" style={{padding:'2px',display: "flex",flexDirection: "column",alignItems: "center"}}>
        <ParentView 
        parent={profile?.data}
        />  
      </div>
      <div className="card-container" style={{padding:'2px'}}>
        {parent?.students.map((student: Student) => (
          <StudentView 
          student={student}
          />  
        ))}
      </div>
    </div>
  );
};

export default ParentProfilePage;
