import { Form, Input,DatePicker, Select, Switch } from 'antd';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Student } from '../../../../interfaces/student';
import { Parent } from '../../../../interfaces/parent';
import { DATE_FORMAT_FRONTEND } from '../../../constants';
import CancelIcon from '../../../svgs/CancelIcon';
import CheckCircleIcon from '../../../svgs/CheckCircleIcon';
import PrimaryButton from '../../buttons/PrimaryButton';
import SecondaryButton from '../../buttons/SecondaryButton';
import { convertDateTimeToNumber } from '../../../../utils/global';
import dayjs from 'dayjs';

type Props = {
  isFetching?: boolean;
  isParentsFetching?: boolean;
  parentsList?: any;
  studentId?: number;
  student?: any;
  handleSubmit(_: any): void;
};

const StudentForm: React.FC<Props> = ({ student, studentId, isFetching,parentsList,isParentsFetching, handleSubmit }) => {
  const [form] = Form.useForm();

  const handleOnFinish = (values: Student) => {
    const newValues: any = values;
    if (studentId) {
      delete newValues.password;
    }
    newValues.dateOfBirth = convertDateTimeToNumber(newValues.dateOfBirth);
    newValues.age = Number(newValues.age)
    handleSubmit(newValues);
  };

  useEffect(() => {
    if (student && student.id === studentId) {
      form.setFieldsValue({
        ...student,
        dateOfBirth: student?.dateOfBirth ? dayjs(student.dateOfBirth) : null,
      });
    }
  }, [student]);

  return (
    <div className="user-form-container">
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        onFinish={handleOnFinish}
        scrollToFirstError
        autoComplete="off"
        initialValues={{ isActive: true }}
      >
        <div className="form-container">
          <div className="form-content">
           <Form.Item label="Parent" name= 'parentId' rules={[{ required: true, message: 'Required' }]}>
            <Select placeholder="Required" showSearch optionFilterProp="children" loading={isParentsFetching} >
                              {parentsList?.map((item: Parent) => (
                                <Select.Option key={item.id} value={item.id}>
                                  {item.firstName}{item.lastName}({item.email})
                                </Select.Option>
                              ))}
                            </Select>
           </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { type: 'email', message: 'Invalid Email' },
                { required: true, message: 'Required' },
              ]}
            >
              <Input type="email" placeholder="Required" autoComplete="off" />
            </Form.Item>
            <Form.Item label="Contact" name="contact">
              <Input placeholder="Optional" />
            </Form.Item>
            <Form.Item label="First Name" name="firstName">
              <Input placeholder="Optional" />
            </Form.Item>
            <Form.Item label="Last Name" name="lastName">
              <Input placeholder="Optional" />
            </Form.Item>

            <Form.Item label="Age" name="age">
              <Input placeholder="Optional" />
            </Form.Item>
            <Form.Item label="Gender" name="gender">
              <Input placeholder="Optional" />
            </Form.Item>

            <Form.Item label="Chinese Name" name="chineseName">
              <Input placeholder="Optional" />
            </Form.Item>

            <Form.Item name="dateOfBirth" label="Date Of Birth">
              <DatePicker className="ant" format={DATE_FORMAT_FRONTEND} />
            </Form.Item>

            <Form.Item label="Foundation" name="foundation">
              <Input placeholder="Optional" />
            </Form.Item>
            <Form.Item label="Expectation" name="expectation">
              <Input placeholder="Optional" />
            </Form.Item>
            <Form.Item label="Remarks" name="remarks">
              <Input placeholder="Optional" />
            </Form.Item>
            <Form.Item label="Active" name="isActive" valuePropName="checked">
              <Switch />
            </Form.Item>
            
          </div>
          <div className="form-footer">
            <PrimaryButton
              loading={isFetching}
              label={studentId ? 'Update' : 'Create'}
              icon={<CheckCircleIcon />}
              htmlType="submit"
            />
            <Link to="/admin/students">
              <SecondaryButton label={'Cancel'} icon={<CancelIcon />} />
            </Link>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default StudentForm;
