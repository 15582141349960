import { pathToUrl } from '../utils/router';
import { Material } from '../interfaces/material';
import { GetListResponse } from '../interfaces';
import { useFetch, usePost } from '../shared/reactQuery';
import { api } from '../shared/api';
import { handleFilterParams } from '../utils/global';

export const useGetMaterialsList = () => useFetch<GetListResponse>('materials');

export const useGetMaterial = (id: number) => useFetch<Material>(`materials/${id}`);

export const useCreateMaterial = (updater: (oldData: Material[], newData: Material) => Material[]) => {
  return usePost<Material[], Material>(pathToUrl('materials'), undefined, updater);
};

export const getMaterialsList = async ({ start, count, filter }: { start?: number; count?: number; filter?: any }) => {
  const newParams = handleFilterParams({
    start,
    count,
    filter,
  });
  const { data: response }: any = await api.get(`materials?${newParams}`);
  return response;
};

export const createMaterial = async (values: Material) => {
  const { data: response }: any = await api.post('materials', values);
  return response.data;
};

export const updateMaterial = async ({ id, ...values }: { id: string; values: Material }) => {
  const { data: response }: any = await api.patch(`materials/${id}`, values);
  return response.data;
};

export const deleteMaterial = async (id: number) => {
  const { data: response }: any = await api.delete(`materials/${id}`);
  return response.data;
};
