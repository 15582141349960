import { Button, Dropdown, Menu, message } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { deleteCoachLeave, getCoachLeavesList, updateCoachLeaves } from '../../../api/CoachLeaves';
import { QueryParams } from '../../../interfaces';
import { CoachLeave } from '../../../interfaces/coachLeave';
import DangerButton from '../../../shared/components/buttons/DangerButton';
import PrimaryButton from '../../../shared/components/buttons/PrimaryButton';
import CoachLeaveFilterForm from './Filter';
import CoachLeavesTable from './CoachLeavesTable';
import { PAGE_SIZE } from '../../../shared/constants';
import AddCircleOutlineIcon from '../../../shared/svgs/AddCircleOutlineIcon';
import { useGetProfile } from '../../../api/auth';
import Cookies from 'js-cookie';
import {  TOKEN_NAME } from '../../../shared/constants';

const CoachLeavesPage: React.FC = () => {
  const token = Cookies.get(TOKEN_NAME);
  const queryClient = useQueryClient();
  const profile = token ? useGetProfile() : undefined;
  const [queryParams, setQueryParams] = useState<QueryParams>({ start: 0, count: PAGE_SIZE , filter:{'coachId':profile?.data.id}});

  const { data, isLoading } = useQuery(['coachLeaves', 'list', queryParams], () => getCoachLeavesList(queryParams), {
    staleTime: Infinity,
  });

  const currentPage = queryParams.start ? Math.round((Number(queryParams.start) - 1) / PAGE_SIZE + 1) : 1;

  const pagination: any = {
    current: currentPage,
    pageSize: PAGE_SIZE,
    total: data?.total || 0,
    showTotal: (total: string) => `${total} Items`,
  };

  const handleFilter = (params: any) => {
    const currentPage = params.page;
    const offset = currentPage ? (currentPage === 1 ? 0 : (Number(currentPage) - 1) * PAGE_SIZE) : 0;
    setQueryParams({ start: Number(offset), count: PAGE_SIZE , filter:{'coachId':profile?.data.id}});
  };

  const { mutateAsync: mutationDelete } = useMutation(deleteCoachLeave);

  const handleClickDelete = async (coachLeave: CoachLeave) => {
    await mutationDelete(coachLeave.id);
    queryClient.invalidateQueries({
      queryKey: ['coachLeaves', 'list'],
    });
  };

  const handleFilterSubmit = (values: any) => {
    values.coachId=profile?.data.id
    setQueryParams({ start: 0, count: PAGE_SIZE, filter: values });
  };

  const handleResetFilter = () => {
    setQueryParams({ start: 0, count: PAGE_SIZE, filter:{'coachId':profile?.data.id} });
  };

  const { mutateAsync: updateMutate } = useMutation(updateCoachLeaves, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['coachLeaves', 'list'],
      });
      message.info('Unblocked this coachLeave.');
    },
    onError: () => {
      message.error('Update coachLeave failed! Please try again');
    },
  });

  const handleUnblockCoach = async (values: any) => {
    const newValues: any = values;
    const id = values.id;
    delete values.blockedAt;
    newValues.blockedAt = 'null';
    updateMutate({ ...newValues, id });
  };

  const withAction = (record: any) => {
    return (
      <span className="action" style={{ display: 'flex' }}>
        <Dropdown.Button
          overlayClassName="table-action-menu"
          menu={{
            items: [
              {
                key: '1',
                label: (
                  <Link to={`/coach/coachLeaves/${record?.id}`}>
                    <Button className="btn-table-action-primary" type="link">
                      View
                    </Button>
                  </Link>
                ),
              },
              {
                key: '2',
                label: (
                  <Link to={`/coach/coachLeaves/${record?.id}/edit`}>
                    <Button className="btn-table-action-primary" type="link">
                      Edit
                    </Button>
                  </Link>
                ),
              },
              {
                key: '4',
                label: <DangerButton label="Delete" onOk={() => handleClickDelete(record)} />,
              },
            ],
          }}
        ></Dropdown.Button>
      </span>
    );
  };

  return (
    <div className="coaches-container">
      <div className="page-container">
      <div className="page-content-header">
          <h2 className="title-page">Leaves</h2>
        </div>
        <div className="page-header">
          <div></div>
          <div className="page-header-actions">
            <Link to="/coach/coachLeaves/new">
              <PrimaryButton label="Create Coach" icon={<AddCircleOutlineIcon />} />
            </Link>
          </div>
        </div>
        <div className="page-content">
          <CoachLeaveFilterForm handleFilter={handleFilterSubmit} handleReset={handleResetFilter} />
          <CoachLeavesTable
            rowKey="id"
            loading={isLoading}
            pagination={pagination}
            dataSource={data?.data}
            withAction={withAction}
            onChange2={handleFilter}
          />
        </div>
      </div>
    </div>
  );
};

export default CoachLeavesPage;
