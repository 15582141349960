// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  font-family: sans-serif;
  text-align: center;
}

.card-row {
  margin-top: 10px;
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start !important;
  flex-wrap: wrap !important;
  align-items: center;
}

.card-body {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
}

.primary-hr {
  background: rgba(35, 57, 93, 0.231372549);
}

@media (max-width: 623px) {
  .card-header {
    padding: 0.45rem 0.15rem !important;
  }
  .card {
    margin-left: 0px !important;
  }
}
@media (max-width: 320) {
  .card {
    margin-left: 0px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/parent/profile/ParentProfile/Profile.scss"],"names":[],"mappings":"AAEA;EACE,uBAAA;EACA,kBAAA;AADF;;AAIA;EACE,gBAAA;EACE,wBAAA;EACA,sBAAA;EACA,sCAAA;EACA,0BAAA;EACA,mBAAA;AADJ;;AAKA;EACE,eAAA;EACA,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,6BAAA;EACA,mBAAA;AAFF;;AAKA;EACE,yCAAA;AAFF;;AAQA;EAME;IACE,mCAAA;EAVF;EAaA;IACE,2BAAA;EAXF;AACF;AAcA;EAEE;IACE,2BAAA;EAbF;AACF","sourcesContent":["@import '../../../../../src/mixins.scss';\n\n.App {\n  font-family: sans-serif;\n  text-align: center;\n}\n\n.card-row{\n  margin-top:10px;\n    display: flex !important;\n    flex-direction: column;\n    justify-content: flex-start !important;\n    flex-wrap: wrap !important;\n    align-items: center;\n}\n\n\n.card-body{\n  padding:0.5rem;\n  display: flex;\n  flex-direction: column;\n  flex-wrap: nowrap;\n  justify-content: space-around;\n  align-items: center;\n}\n\n.primary-hr{\n  background:#23395d3b;\n  // margin-top: 0rem !important;\n  // margin-bottom: 0rem !important;\n  // border: 1px solid $primary-color !important;\n}\n\n@media (max-width: 623px) {\n  // .rowmb{\n  //   // box-sizing: content-box !important;\n  //   height: 200px !important;\n  // }\n  \n  .card-header{\n    padding:0.45rem 0.15rem !important;\n  }\n\n  .card{\n    margin-left:0px !important; \n  }\n}\n\n@media (max-width: 320) {\n\n  .card{\n    margin-left:0px !important; \n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
