import { Button,message,Dropdown, Spin } from 'antd';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { deleteCourse, getCoursesList, updateCourse, useGetCourse, deleteStudentFromCourse } from '../../../api/courses';
import DangerButton from '../../../shared/components/buttons/DangerButton';
import PrimaryButton from '../../../shared/components/buttons/PrimaryButton';
import SecondaryButton from '../../../shared/components/buttons/SecondaryButton';
import { PAGE_SIZE } from '../../../shared/constants';
import CancelIcon from '../../../shared/svgs/CancelIcon';
import StudentsTable from '../../../shared/components/tables/Students';
import DeleteForeverIcon from '../../../shared/svgs/DeleteForeverIcon';
import EditIcon from '../../../shared/svgs/EditIcon';

const CourseDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { data: course, isLoading } = useGetCourse(Number(id));

  const { mutateAsync: mutationDelete } = useMutation(deleteCourse, {
    onSuccess: async () => {
      message.info('Deleted!');
      queryClient.invalidateQueries({
        queryKey: ['courses', 'list'],
      });
      navigate(`/admin/courses`);
    },
    onError: () => {
      message.error('The action is failed! Please try again');
    },
    onSettled: () => {
      queryClient.prefetchQuery({
        queryKey: ['courses', 'list', { start: 0, count: PAGE_SIZE, filter: undefined }],
        queryFn: () => getCoursesList({ start: 0, count: PAGE_SIZE, filter: undefined }),
      });
    },
  });

  const handleDelete = () => {
    mutationDelete(Number(id));
  };

  const handleClickDelete = async (courseId : number , studentId : number) => {
    await deleteStudentFromCourse(courseId,studentId);
    queryClient.invalidateQueries({
      queryKey: ['students', 'list'],
    });
  };

  const withAction = (record: any) => {
    return (
      <span className="action" style={{ display: 'flex' }}>
        <Dropdown.Button
          overlayClassName="table-action-menu"
          menu={{
            items: [
              {
                key: '1',
                label: (
                  <Link to={`/admin/students/${record?.id}`}>
                    <Button className="btn-table-action-primary" type="link">
                      View
                    </Button>
                  </Link>
                ),
              },
              {
                key: '4',
                label: <DangerButton label="Delete" onOk={() => handleClickDelete(Number(id),Number(record?.id))} />,
              },
            ],
          }}
        ></Dropdown.Button>
      </span>
    );
  };

  // const withAction = (record: any) => {
  //   return (
  //     <span className="action" style={{ display: 'flex' }}>
  //       <Dropdown.Button
  //         overlayClassName="table-action-menu"
  //         menu={{
  //           items: [
  //             {
  //               key: '1',
  //               label: (
  //                 <Link to={`/admin/users/${record?.id}`}>
  //                   <Button className="btn-table-action-primary" type="link">
  //                     View
  //                   </Button>
  //                 </Link>
  //               ),
  //             },
  //             {
  //               key: '4',
  //               label: <DangerButton label="Delete" onOk={() => handleDelete()} />,
  //             },
  //           ],
  //         }}
  //       ></Dropdown.Button>
  //     </span>
  //   );
  // };

  const { mutateAsync: updateMutate } = useMutation(updateCourse, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['courses', 'list'],
      });
      queryClient.invalidateQueries({
        queryKey: [`courses/${id}`, undefined],
      });
      message.info('Unblocked this course!');
    },
    onError: () => {
      message.error('The action is failed! Please try again');
    },
  });

  return (
    <Spin spinning={isLoading}>
      <div id="user-details-container">
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page">Course Details</h2>
            </div>
            <div className="details-container">
              <div className="item">
                <label>Course Name</label>
                <span className="value">{course?.name}</span>
              </div>
              <div className="item">
                <label>Start At</label>
                <span className="value">
                  {course?.startAt}
                </span>
              </div>
              <div className="item">
                <label>End At</label>
                <span className="value">
                  {course?.endAt} 
                </span>
              </div>
              <div className="item">
                <label>Status</label>
                <span className={`value status ${course?.isActive ? 'active' : 'inactive'}`}>
                  {course?.isActive ? 'Active' : 'Inactive'}
                </span>
              </div>
            </div>
            <div className="page-content-footer"> 
              <Link to={`/admin/courses/${id}/edit`}>
                <PrimaryButton label="Edit" icon={<EditIcon />} />
              </Link>
              <DangerButton label="Delete" onOk={handleDelete} icon={<DeleteForeverIcon />} />
              <Link to={`/admin/courses`}>
                <SecondaryButton label="Cancel" icon={<CancelIcon />} />
              </Link>
            </div>
          </div>


          <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page">Coach Details</h2>
            </div>
            <div className="details-container">
              <div className="item">
                <label>Coach Name</label>
                <span className="value">{course?.coach?.firstName}{course?.coach?.lastName}</span>
              </div>
              <div className="item">
                <label>Email</label>
                <span className="value">
                  {course?.coach?.email}
                </span>
              </div>
              <div className="item">
                <label>Contact</label>
                <span className="value">
                {course?.coach?.contact}
                </span>
              </div>
            </div>
            
          </div>

          <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page">Student List</h2>
            </div>
            <StudentsTable
            withAction={withAction}
            students={course?.students}
          />
             </div>
        </div>
      </div>
      
    </Spin>
  );
};

export default CourseDetailsPage;
