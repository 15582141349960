import { useContext, useDebugValue } from 'react';
import AuthContext from '../contexts/AuthProvider';
export {};

const useAuth = () => {
  const { auth }: any = useContext(AuthContext);
  useDebugValue(auth, (element) => (element?.user ? 'Logged In' : 'Logged Out'));
  return useContext(AuthContext);
};

export default useAuth;
