import React from 'react';
import { MaterialIn } from '../../../interfaces/materialIn';
import TableBase, { Props as TableProps } from './Base';
import { DateFormat } from '../../../utils/global';
import { Material } from '../../../interfaces/material';
import DocumentViewer from '../Upload/DocumentViewer';

type Props = TableProps & {
  withAction?(_: any): void;
  materialChild?: any[];
};

const MaterialChildsTable: React.FC<Props> = ({ materialChild, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: 'Images',
      width: 150,
      render: (materialChild: Material) => {
        return (
           <span className="value" style={{width: '150px'}}>
                {materialChild && <DocumentViewer files={materialChild?.files} />}
            </span>
        );
      },
    },
    {
      title: 'Material Name',
      width: 150,
      render: (materialChild: Material) => {
        return (
          <div style={{background:materialChild.qty <=2 ? 'red' : ''}}>
              <span className="text-capitalize">
              Name : {materialChild.name}
              <br></br><br></br>
              Selling Price :{materialChild.sellingPrice}
              <br></br>
              Supplir Price :{materialChild.supplirPrice}
            </span >
          </div>
         
        );
      },
    },
    {
      title: 'Quantity',
      width: 110,
      render: (materialChild: Material) => {
        return (
          <div style={{background:materialChild.qty <=2 ? 'red' : ''}}>
              <span className="text-capitalize">
              Quantity : {materialChild.qty}
              <br></br>
              Total Quantity :{materialChild.totalQty}
            </span >
          </div>
         
        );
      },
    },
    {
      title: 'Supplier Source',
      dataIndex: 'supplierSource',
      width: 150,
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      width: 150,
    }
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 50,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={materialChild} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default MaterialChildsTable;
