import { Button, DatePicker, Form, Input, Select, Switch } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Course } from '../../../../interfaces/course';
import { Coach } from '../../../../interfaces/coach';
import { DATE_FORMAT_FRONTEND, TIME_FORMAT_QUERY } from '../../../constants';
import CancelIcon from '../../../svgs/CancelIcon';
import CheckCircleIcon from '../../../svgs/CheckCircleIcon';
import PrimaryButton from '../../buttons/PrimaryButton';
import SecondaryButton from '../../buttons/SecondaryButton';
import moment from 'moment';
const { TimePicker } = DatePicker;
type Props = {
  isFetching?: boolean;
  course?: any;
  courseId?: number;
  handleSubmit(_: any): void;
};

const CourseAttendancesForm: React.FC<Props> = ({ course, courseId, isFetching, handleSubmit }) => {
  const [form] = Form.useForm();
  const handleOnFinish = (values: Course) => {
    const newValues: any = { ...values };
    newValues.startAt=moment(values?.startAt).format('HH:mm:ss');
    newValues.endAt=moment(values?.endAt).format('HH:mm:ss');
    handleSubmit(newValues);
  };

  useEffect(() => {
    if (course && course.id === courseId) {
      // const coachId= course.coach.firstName+ course.coach.lastName+'('+course.coach.email+')';
      form.setFieldsValue({
        ...course,
        // startTime: '2023-05-02T16:00:00.375Z',
        startAt: moment(course.startAt, 'HH:mm:ss'),
        endAt: moment(course.endAt, 'HH:mm:ss'),
        courseId:courseId,
        location:"Pagoh",
        // coachId
      })
    }
  }, [course]);

  const onStartTimeSelect = (time: any) => {
    form.setFieldsValue({
      startAt: moment(time, 'HH:mm:ss'),
    });
  };

  const onEndTimeSelect = (time: any) => {
    form.setFieldsValue({
      endAt: moment(time, 'HH:mm:ss'),
    });
  };

  return (
    <div className="course-form-container">
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        onFinish={handleOnFinish}
        scrollToFirstError
        autoComplete="off"
        initialValues={{ isActive: true }}
      >
        <div className="form-container">
          <div className="form-content">
          <Form.Item  label="Course Id"  name= 'courseId' rules={[{ required: true, message: 'Required' }]} >
              <Input  placeholder="Required" autoComplete="off" />
          </Form.Item>
          <Form.Item label="Course Name"  name= 'name' rules={[{ required: true, message: 'Required' }]} >
              <Input disabled placeholder="Required" autoComplete="off" />
          </Form.Item>
          <Form.Item name="date" label="date">
              <DatePicker className="ant" format={DATE_FORMAT_FRONTEND} />
            </Form.Item>
            <Form.Item name="startAt" label="Course Start Time" rules={[{ required: true, message: 'Required' }]}>
              {/* <TimePicker disabled className="ant" format={TIME_FORMAT_QUERY} onSelect={onEndTimeSelect}/> */}
            </Form.Item>
            <Form.Item name="endAt" label="Course End Time" rules={[{ required: true, message: 'Required' }]}>
              {/* <TimePicker disabled className="ant" format={TIME_FORMAT_QUERY} onSelect={onEndTimeSelect}/> */}
            </Form.Item>
            <Form.Item name="location" label="Venue" rules={[{ required: true, message: 'Required' }]}>
              <Input disabled placeholder="Required" autoComplete="off" />
            </Form.Item>
          <div className="form-footer">
            <PrimaryButton
             loading={isFetching}
              label={courseId ? 'Update' : 'Create'}
              icon={<CheckCircleIcon />}
              htmlType="submit"
            />
            <Link to="/admin/attendances">
              <SecondaryButton label={'Cancel'} icon={<CancelIcon />} />
            </Link>
          </div>
           
          </div> 
        </div>
      </Form>
    </div>
  );
};

export default CourseAttendancesForm;
