import { pathToUrl } from '../utils/router';
import { CoachLeave } from '../interfaces/coachLeave';
import { GetListResponse } from '../interfaces';
import { useFetch, usePost } from '../shared/reactQuery';
import { api } from '../shared/api';
import { handleFilterParams } from '../utils/global';

export const useGetCoachLeavesList = () => useFetch<GetListResponse>('coachLeaves');

export const useGetCoachLeaves = (id: number) => useFetch<CoachLeave>(`coachLeaves/${id}`);

export const getCoachLeavesList = async ({ start, count, filter }: { start?: number; count?: number; filter?: any }) => {
  const newParams = handleFilterParams({
    start,
    count,
    filter,
  });
  const { data: response }: any = await api.get(`coachLeaves?${newParams}`);
  return response;
};

export const createCoachLeaves = async (values: CoachLeave) => {
  const { data: response }: any = await api.post('coachLeaves', values);
  return response.data;
};

export const updateCoachLeaves = async ({ id, ...values }: { id: string; values: CoachLeave }) => {
  const { data: response }: any = await api.patch(`coachLeaves/${id}`, values);
  return response.data;
};

export const deleteCoachLeave = async (id: number) => {
  const { data: response }: any = await api.delete(`coachLeaves/${id}`);
  return response.data;
};
