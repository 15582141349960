import React, { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import {getStudentsList } from '../../../api/students';
import { useGetProfile } from '../../../api/auth';
import { useGetAllParentsDashboard } from '../../../api/parents';
import { QueryParams } from '../../../interfaces';
import { Student } from '../../../interfaces/student';
import { Parent } from '../../../interfaces/parent';
import ParentsFilterForm from './ParentsFilter';
import StudentDashboardView from './StudentDashboard/View';

import { PAGE_SIZE } from '../../../shared/constants';

const ParentsDashboardPage: React.FC = () => {
  const [queryParams, setQueryParams] = useState<QueryParams>({ start: 0, count: PAGE_SIZE });

  // const { data: parents, isLoading:isParentsLoading } = useGetAllParentsDashboard(queryParams);
  const { data: parents,  isLoading:isParentsLoading } = useQuery(['allParentsDashboard', 'list', queryParams], () => useGetAllParentsDashboard(queryParams), {
    staleTime: Infinity,
  });

  const handleFilterSubmit = (values: any) => {
    setQueryParams({ start: 0, count: PAGE_SIZE, filter: values });
  };

  const handleResetFilter = () => {
    setQueryParams({ start: 0, count: PAGE_SIZE, filter: undefined });
  };

  return (
   <div className="courses-container">
    <div className="page-container">
    <div className="page-content-header">
          <h2 className="title-page">Parent Dashboard Listing</h2>
        </div>
        <div className="page-content-header">
          <div className="page-header" style={{marginBottom:"10px"}}>
            </div>
            <div className="page-content">
            <ParentsFilterForm handleFilter={handleFilterSubmit} handleReset={handleResetFilter} />
            </div>
          {/* <div className="page-header"> */}
    {parents?.data?.map((parent: Parent) => (
          <div>
            {parent?.students?.map((student: Student) => (
                 <StudentDashboardView 
                 student={student}
                 parentId={Number(parent.id)}
                 lastLogin={parent.lastLogin}
                 mobileToken={parent.mobileToken}
                 />  
              ))}
             </div>
          ))}
     {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default ParentsDashboardPage;
