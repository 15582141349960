import { Menu, MenuProps } from 'antd';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import PeopleIcon from '../../svgs/menu/PeopleIcon';
import StudentIcon from '../../svgs/menu/StudentIcon';
import LicenseIcon from '../../svgs/menu/LicenseIcon';
import RightIcon from '../../svgs/menu/RightIcon';
import PaymentIcon from '../../svgs/menu/PaymentIcon';
import JobIcon from '../../svgs/menu/JobIcon';
import NoticeIcon from '../../svgs/menu/NoticeIcon';
import DashboardIcon from '../../svgs/menu/DashboardIcon';

type Props = {
  onTriggerClick(): void;
};

const AdminMenu: React.FC <Props> =({onTriggerClick}) => {
  const { pathname } = useLocation();
  let selectedKey = pathname;
  let openKey = '';

  if (selectedKey.includes('admin/users')) {
    selectedKey = '/admin/users';
  } else if (selectedKey.includes('admin/rights')) {
    selectedKey = '/admin/rights';
  } else {
    selectedKey = '/admin';
  }

  const onSelect = (item: any) => {
    if (item.key === '/admin/users') {
      openKey = '/admin/users';
    } else if (item.key === '/admin/rights') {
      openKey = '/admin/rights';
    } else {
      openKey = item.key;
    }
    onTriggerClick()
  };

  type MenuItem = Required<MenuProps>['items'][number];

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label: !children ? <Link to={`${key}`}>{label}</Link> : label,
      type,
    } as MenuItem;
  }

  const items = [
    getItem('Dashboard', '/admin/dashboard', <DashboardIcon />),
    getItem('Users', '/admin/users', <PeopleIcon />),
    getItem('Coaches', '/admin/Coaches', <RightIcon />),
    getItem('Coach Leave', '/admin/CoachLeaves', <RightIcon />),
    getItem('Parents Dashboard', '/admin/parentsDashboard', <RightIcon />),
    getItem('Parents', '/admin/parents', <RightIcon />),
    getItem('Students', '/admin/students', <StudentIcon />),
    getItem('Course', '/admin/courses', <JobIcon />),
    getItem('Attendances', '/admin/attendances', <NoticeIcon />),
    getItem('Class Replaces', '/admin/classReplaces', <RightIcon />),
    getItem('ClassWorks', '/admin/classWorks', <RightIcon />),
    getItem('Materials', '/admin/materials', <RightIcon />),
    getItem('Material Requests', '/admin/materialRequests', <RightIcon />),
    getItem('Course Materials', '/admin/courseMaterials', <RightIcon />),
    getItem('Payroll', '/admin/payrolls', <PaymentIcon />),
    getItem('Payable', '/admin/payables', <PaymentIcon />),
    getItem('Payment', '/admin/payments', <PaymentIcon />),
    getItem('Payment Expired Soon', '/admin/paymentsexpiredsoon', <PaymentIcon />),
    getItem('Payment Receipt Ready', '/admin/paymentreceiptready', <PaymentIcon />),
    getItem('Tuition Fee', '/admin/tuitionFee', <RightIcon />),
    getItem('Tuition Days', '/admin/tuitionDays', <RightIcon />),
  ];
  return (
    <Menu
      className="main-menu"
      triggerSubMenuAction="hover"
      mode="inline"
      defaultSelectedKeys={[selectedKey]}
      onSelect={onSelect}
      defaultOpenKeys={[openKey]}
      items={items}
    />
  );
};

export default AdminMenu;
