import { Button, Dropdown, Modal, message } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { deleteClassReplaces, getClassReplacesList, updateClassReplaces } from '../../../api/classReplaces';
import { QueryParams } from '../../../interfaces';
import { Course } from '../../../interfaces/course';
import DangerButton from '../../../shared/components/buttons/DangerButton';
import PrimaryButton from '../../../shared/components/buttons/PrimaryButton';
import CoachesFilterForm from './ClassReplaceFilter';
import ClassReplacesTable from '../../../shared/components/tables/ClassReplaces/ClassReplaces';
import AssignClassForm from '../../../shared/components/modal/ClassReplaces/AssignClassForm';
import { PAGE_SIZE } from '../../../shared/constants';
import AddCircleOutlineIcon from '../../../shared/svgs/AddCircleOutlineIcon';

const ClassReplacementsPage: React.FC = () => {
  const queryClient = useQueryClient();
  const [queryParams, setQueryParams] = useState<QueryParams>({ start: 0, count: PAGE_SIZE });

  const { data, isLoading } = useQuery(['classReplaces', 'list', queryParams], () => getClassReplacesList(queryParams), {
    staleTime: Infinity,
  });

  const currentPage = queryParams.start ? Math.round((Number(queryParams.start) - 1) / PAGE_SIZE + 1) : 1;
  const [modalData, setModalData] = useState<any>();


  const pagination: any = {
    current: currentPage,
    pageSize: PAGE_SIZE,
    total: data?.total || 0,
    showTotal: (total: string) => `${total} Items`,
  };

  const handleFilter = (params: any) => {
    const currentPage = params.page;
    const offset = currentPage ? (currentPage === 1 ? 0 : (Number(currentPage) - 1) * PAGE_SIZE) : 0;
    setQueryParams({ start: Number(offset), count: PAGE_SIZE });
  };

  const { mutateAsync: mutationDelete } = useMutation(deleteClassReplaces);

  const [ isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const onCancel = () => {
    setIsModalVisible(false);
  };


  const onEditClick = (modalData:any) => {
    setModalData(modalData)
    setIsModalVisible(true);
  };

  const withAction = (record: any) => {
    return (
      <span className="action" style={{ display: 'flex' }}>
        <Dropdown.Button
          overlayClassName="table-action-menu"
          menu={{
            items: [
              {
                key: '1',
                label: (
                  <Link to={`/admin/classReplaces/${record?.id}`}>
                    <Button className="btn-table-action-primary" type="link">
                      View
                    </Button>
                  </Link>
                ),
              },
              {
                key: '2',
                label: (
                  <Link to={`/admin/classReplaces/${record?.id}/edit`}>
                    <Button className="btn-table-action-primary" type="link">
                      Edit
                    </Button>
                  </Link>
                ),
              },
              {
                key: '3',
                label: <DangerButton label="Delete" onOk={() => handleClickDelete(record)} />,
              },
            ],
          }}
        ></Dropdown.Button>
      </span>
    );
  };

  const handleClickDelete = async (course: Course) => {
    await mutationDelete(course.id);
    queryClient.invalidateQueries({
      queryKey: ['classReplaces', 'list'],
    });
  };

  const handleFilterSubmit = (values: any) => {
    setQueryParams({ start: 0, count: PAGE_SIZE, filter: values });
  };

  const handleResetFilter = () => {
    setQueryParams({ start: 0, count: PAGE_SIZE, filter: undefined });
  };

  const { mutateAsync: updateMutate } = useMutation(updateClassReplaces, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['classReplaces', 'list'],
      });
      message.info('classReplaces update completed.');
    },
    onError: () => {
      message.error('Update classReplaces failed! Please try again');
    },
  });

  const handleSubmit = async (values: any) => {
    const id =values.id
    updateMutate({ ...values, id });
    setIsModalVisible(false);
  };


  return (
    <div className="courses-container">
      <div className="page-container">
      <div className="page-content-header">
          <h2 className="title-page">Class Replacement Listing</h2>
        </div>
        <div className="page-header">
          <div></div>
          <div className="page-header-actions">
            <Link to="/admin/classReplaces/new">
              <PrimaryButton label="Create Class Replacement" icon={<AddCircleOutlineIcon />} />
            </Link>
          </div>
        </div>
        <div className="page-content">
          <CoachesFilterForm handleFilter={handleFilterSubmit} handleReset={handleResetFilter} />
          <ClassReplacesTable
            rowKey="id"
            loading={isLoading}
            pagination={pagination}
            dataSource={data?.data}
            assignClass={onEditClick}
            withAction={withAction}
          />
        </div>
        <Modal
        visible={isModalVisible}
        onOk={onCancel}
        onCancel={onCancel}
        footer={null}
        width={536}
          >
            <AssignClassForm
                modalData={modalData}
                handleSubmit={handleSubmit}
                // stundentId={modalData.studentId}
                   />
      </Modal>
      </div>
    </div>
  );
};

export default ClassReplacementsPage;
