import './SiderContent.scss';
import React, { useState } from 'react';
import { Button, Layout } from 'antd';
import { Link } from 'react-router-dom';

import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import CoachMenu from '../menu/Coach';

type Props = {
  user?: any;
};

const { Sider } = Layout;

const CoachSiderContent: React.FC<Props> = ({ user }) => {
  const [collapsed, setCollapsed] = useState(false);

  const onTriggerClick = () => {
    setCollapsed(!collapsed);
  };

  return (
    <>
      <Sider
        breakpoint="lg"
        theme="light"
        collapsible
        collapsedWidth={0}
        trigger={null}
        collapsed={collapsed}
        onBreakpoint={(broken) => {
          if (broken) setCollapsed(true);
          else setCollapsed(false);
        }}
        className="sider side-menu-component"
      >
        <div className="logo" style={{display: "flex",flexDirection: "column", alignItems: "center"}}>
          <Link to="/coach/dashboard">
            <img src="/logo.png" alt="logo" style={{width:"135px",height:"65px"}} />
          </Link>
        </div>
        <CoachMenu onTriggerClick={onTriggerClick}/>
      </Sider>
      <div className={`mobile-toggle-menu ${collapsed ? 'hide' : 'open'}`}>
        <Button className="trigger" onClick={onTriggerClick}>
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button>
      </div>
    </>
  );
};

export default CoachSiderContent;
