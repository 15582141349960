import { pathToUrl } from '../utils/router';
import { Payable } from '../interfaces/payable';
import { GetListResponse } from '../interfaces';
import { useFetch, usePost } from '../shared/reactQuery';
import { api } from '../shared/api';
import { handleFilterParams } from '../utils/global';
import request from '../utils/request';
export const useGetPayablesList = () => useFetch<GetListResponse>('payables');
import { handleDownloadExportFile,handleMobileDownloadExportFile } from '../utils/global';
export const useGetPayable = (id: number) => useFetch<Payable>(`payables/${id}`);

const MONTH_NAME = [
  { index: 1 ,name:"JANUARY", value: 'JANUARY' },
  { index: 2 ,name:"FEBRUARY", value: 'FEBRUARY' },
  { index: 3 ,name:"MARCH", value: 'MARCH' },
  { index: 4 ,name:"APRIL", value: 'APRIL' },
  { index: 5 ,name:"MAY", value: 'MAY' },
  { index: 6 ,name:"JUNE", value: 'JUNE' },
  { index: 7 ,name:"JULY", value: 'JULY' },
  { index: 8 ,name:"AUGUST", value: 'AUGUST' },
  { index: 9 ,name:"SEPTEMBER", value: 'SEPTEMBER' },
  { index: 10 ,name:"OCTOBER", value: 'OCTOBER' },
  { index: 11 ,name:"NOVEMBER", value: 'NOVEMBER' },
  {index: 12 , name:"DECEMBER", value: 'DECEMBER' },
];

export const getPayablesList = async ({ start, count, filter }: { start?: number; count?: number; filter?: any }) => {
  const newParams = handleFilterParams({
    start,
    count,
    filter,
  });
  const { data: response }: any = await api.get(`payables?${newParams}`);
  return response;
};

export const createPayable = async (values: Payable) => {
  const { data: response }: any = await api.post('payables', values);
  return response.data;
};

export const updatePayable = async ({ id, ...values }: { id: string; values: Payable }) => {
  const { data: response }: any = await api.patch(`payables/${id}`, values);
  return response.data;
};

export const deletePayable = async (id: number) => {
  const { data: response }: any = await api.delete(`payables/${id}`);
  return response.data;
};


export const downloadPayable = async (id: number) => {
  // return  await api.post(`payments/${id}/generatePaymentDoc`,{
  //   method: 'POST',
  //   data: {},
  //   responseType: 'blob',
  // });

  const payload: any = await request(`payables/${id}/generatePayableDoc`, {
    method: 'GET',
    data: {},
    responseType: 'blob',
  });
}