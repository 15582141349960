import React from 'react';
import { Routes, Route } from 'react-router-dom';
import MainLayout from './shared/components/MainLayout';
import AdminRequireAuth from './shared/components/AdminRequireAuth';
import ParentRequireAuth from './shared/components/ParentRequireAuth';
import CoachRequireAuth from './shared/components/CoachRequireAuth';
import { 
  LoginPage, 
  UsersPage,
  UserUpdatePage,
  UserDetailsPage,
  UserNewPage,
  StudentsPage,
  StudentUpdatePage,
  StudentDetailsPage,
  StudentNewPage,
  CoursesPage,
  CourseUpdatePage,
  CourseDetailsPage,
  CourseNewPage,
  ParentsPage,
  ParentUpdatePage,
  ParentDetailsPage,
  ParentNewPage,
  CoachesPage,
  CoachNewPage,
  CoachDetailsPage,
  CoachUpdatePage,
  CoachLeavesPage,
  CoachLeaveNewPage,
  CoachLeaveDetailsPage,
  CoachLeaveUpdatePage,
  CoachesCoachLeavesPage,
  CoachesCoachLeaveNewPage,
  CoachesCoachLeaveDetailsPage,
  CoachesCoachLeaveUpdatePage,
  LoginParentPage,
  LoginCoachPage,
  ParentClassWorksPage,
  ParentProfilePage,
  ParentDashboardPage,
  CoachDashboardPage,
  AttendancesPage,
  AttendancesNewPage,
  AttendancesDetailsPage,
  AttendancesUpdatePage,
  MaterialsPage,
  MaterialNewPage,
  MaterialDetailsPage,
  MaterialUpdatePage,
  CourseMaterialsPage,
  CourseMaterialNewPage,
  CourseMaterialDetailsPage,
  CourseMaterialUpdatePage,
  ParentAttendancesPage,
  PaymentsPage,
  PaymentNewPage,
  PaymentUpdatePage,
  PaymentDetailsPage,
  PayablesPage,
  PayableNewPage,
  PayableUpdatePage,
  PayableDetailsPage,
  PayrollsPage,
  PayrollNewPage,
  PayrollUpdatePage,
  PayrollDetailsPage,
  ParentReceiptsPage,
  // ParentStudentDetailsPage,
  ParentsDashboardPage,
  ClassWorksCourseAttendancesPage,
  ClassWorksCourseClassWorksPage,
  ClassWorksCourseClassWorksUpdatePage,
  ClassWorksCoursesPage,
  PaymentsExpiredSoonPage,
  PaymentReceiptReady,
  PaymentExpiredSoonDetailsPage,
  ClassReplacesPage,
  ClassReplacementNewPage,
  ClassReplacesDetailsPage,
  ClassReplacesUpdatePage,
  TuitionFeesPage,
  TuitionDaysPage,
  DashboardPage,
  MaterialRequestsPage,
  MaterialRequestNewPage,
  MaterialRequestDetailsPage,
  MaterialRequestUpdatePage,
  E403Page,
  E404Page,
  ForgotPasswordPage,
  ResetPasswordPage
 } from './pages';

const App: React.FC = () => {
  return (
    <Routes>
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route path="admin/login" element={<LoginPage />} />
      <Route path="/admin" element={<AdminRequireAuth />}>
        <Route path="/admin" element={<UsersPage />} />
        <Route path="/admin/users" element={<UsersPage />} />
        <Route path="/admin/users/new" element={<UserNewPage />} />
       <Route path="/admin/users/:id" element={<UserDetailsPage />} />
       <Route path="/admin/users/:id/edit" element={<UserUpdatePage />} />

       <Route path="/admin/coachLeaves" element={<CoachLeavesPage />} />
        <Route path="/admin/coachLeaves/new" element={<CoachLeaveNewPage />} />
       <Route path="/admin/coachLeaves/:id" element={<CoachLeaveDetailsPage />} />
       <Route path="/admin/coachLeaves/:id/edit" element={<CoachLeaveUpdatePage />} />

       <Route path="/admin/coaches" element={<CoachesPage />} />
        <Route path="/admin/coaches/new" element={<CoachNewPage />} />
       <Route path="/admin/coaches/:id" element={<CoachDetailsPage />} />
       <Route path="/admin/coaches/:id/edit" element={<CoachUpdatePage />} />
        <Route path="/admin/students" element={<StudentsPage />} />
        <Route path="/admin/students/new" element={<StudentNewPage />} />
       <Route path="/admin/students/:id" element={<StudentDetailsPage />} />
       <Route path="/admin/students/:id/edit" element={<StudentUpdatePage />} />
       <Route path="/admin/parents" element={<ParentsPage />} />
        <Route path="/admin/parents/new" element={<ParentNewPage />} />
       <Route path="/admin/parents/:id" element={<ParentDetailsPage />} />
       <Route path="/admin/parents/:id/edit" element={<ParentUpdatePage />} />
        <Route path="/admin/courses" element={<CoursesPage />} />
        <Route path="/admin/courses/new" element={<CourseNewPage />} />
       <Route path="/admin/courses/:id" element={<CourseDetailsPage />} />
       <Route path="/admin/courses/:id/edit" element={<CourseUpdatePage />} />
       <Route path="/admin/attendances" element={<AttendancesPage />} />
        <Route path="/admin/attendances/new" element={<AttendancesNewPage />} />
       <Route path="/admin/attendances/:id" element={<AttendancesDetailsPage />} />
       <Route path="/admin/attendances/:id/edit" element={<AttendancesUpdatePage />} />

       <Route path="/admin/classReplaces" element={<ClassReplacesPage />} />
        <Route path="/admin/classReplaces/new" element={<ClassReplacementNewPage />} />
       <Route path="/admin/classReplaces/:id" element={<ClassReplacesDetailsPage />} />
       <Route path="/admin/classReplaces/:id/edit" element={<ClassReplacesUpdatePage />} />

       <Route path="/admin/payrolls" element={<PayrollsPage />} />
       <Route path="/admin/payrolls/new" element={<PayrollNewPage />} />
       <Route path="/admin/payrolls/:id/edit" element={<PayrollUpdatePage />} />
       <Route path="/admin/payrolls/:id" element={<PayrollDetailsPage />} />
       <Route path="/admin/payments" element={<PaymentsPage />} />
       <Route path="/admin/payments/new" element={<PaymentNewPage />} />
       <Route path="/admin/payments/:id/edit" element={<PaymentUpdatePage />} />
       <Route path="/admin/payments/:id" element={<PaymentDetailsPage />} />
       <Route path="/admin/paymentsexpiredsoon" element={<PaymentsExpiredSoonPage />} />
       <Route path="/admin/paymentreceiptready" element={<PaymentReceiptReady />} />
       <Route path="/admin/paymentsexpiredsoon/:id" element={<PaymentExpiredSoonDetailsPage />} />
       <Route path="/admin/classWorks" element={<ClassWorksCoursesPage />} />
       <Route path="/admin/classWorks/:id/attendances" element={<ClassWorksCourseAttendancesPage />} />
        <Route path="/admin/classWorks/:id/classworks" element={<ClassWorksCourseClassWorksPage />} />
        <Route path="/admin/classWorks/:id/classworksupdate" element={<ClassWorksCourseClassWorksUpdatePage />} />
       
        <Route path="/admin/payables" element={<PayablesPage />} />
       <Route path="/admin/payables/new" element={<PayableNewPage />} />
       <Route path="/admin/payables/:id/edit" element={<PayableUpdatePage />} />
       <Route path="/admin/payables/:id" element={<PayableDetailsPage />} />

       <Route path="/admin/materials" element={<MaterialsPage />} />
        <Route path="/admin/materials/new" element={<MaterialNewPage />} />
       <Route path="/admin/materials/:id" element={<MaterialDetailsPage />} />
       <Route path="/admin/materials/:id/edit" element={<MaterialUpdatePage />} />

       <Route path="/admin/materialRequests" element={<MaterialRequestsPage />} />
        <Route path="/admin/materialRequests/new" element={<MaterialRequestNewPage />} />
       <Route path="/admin/materialRequests/:id" element={<MaterialRequestDetailsPage />} />
       <Route path="/admin/materialRequests/:id/edit" element={<MaterialRequestUpdatePage />} />

       <Route path="/admin/dashboard" element={<DashboardPage />} />
       <Route path="/admin/parentsDashboard" element={<ParentsDashboardPage />} />
       <Route path="/admin/courseMaterials" element={<CourseMaterialsPage />} />
        <Route path="/admin/courseMaterials/:id/new" element={<CourseMaterialNewPage />} />
       <Route path="/admin/courseMaterials/:id" element={<CourseMaterialDetailsPage />} />
       <Route path="/admin/courseMaterials/:id/edit" element={<CourseMaterialUpdatePage />} />
       <Route path="/admin/tuitionFee" element={<TuitionFeesPage />} />
       <Route path="/admin/tuitionDays" element={<TuitionDaysPage />} />
       
      </Route> 
      <Route path="parent/login" element={<LoginParentPage />} />
        <Route path="/parent" element={<ParentRequireAuth />}>
        <Route path="/parent/dashboard" element={<ParentDashboardPage />} />
        <Route path="/parent/profile" element={<ParentProfilePage />} />
        <Route path="/parent/classworks" element={<ParentClassWorksPage />} />
        <Route path="/parent/students/:id/attendances" element={<ParentAttendancesPage />} />
        {/* <Route path="/parent/students/:id/details" element={<ParentStudentDetailsPage />} /> */}
       
        <Route path="/parent/receipts" element={<ParentReceiptsPage />} />
      </Route> 

      <Route path="coach/login" element={<LoginCoachPage />} />
        <Route path="/coach" element={<CoachRequireAuth />}>
        <Route path="/coach/dashboard" element={<CoachDashboardPage />} />
        <Route path="/coach/coachLeaves" element={<CoachesCoachLeavesPage />} />
        <Route path="/coach/coachLeaves/new" element={<CoachesCoachLeaveNewPage />} />
       <Route path="/coach/coachLeaves/:id" element={<CoachesCoachLeaveDetailsPage />} />
       <Route path="/coach/coachLeaves/:id/edit" element={<CoachesCoachLeaveUpdatePage />} />
      </Route> 
      
 
      <Route path="/" element={<MainLayout />}>
        <Route path="/*" element={<E404Page />} />
        <Route path="/unauthorized" element={<E403Page />} />
      </Route>
    </Routes>
  );
};

export default App;
