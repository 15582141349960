// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-form-container .ant-input-affix-wrapper {
  padding: 0px 11px !important;
}
.login-form-container .ant-input {
  height: 48px !important;
  padding: 4px 11px;
  border-radius: 8px !important;
}
.login-form-container .forgot-password-section {
  margin-bottom: 24px;
  margin-top: 24px;
}
.login-form-container .FormFooter {
  margin-top: 32px;
}
.login-form-container .FormFooter button {
  font-size: 14px;
  line-height: 22px;
  padding: 10px 12px;
}
.login-form-container .ant-form-item-with-help.password-input .ant-form-item-explain div, .login-form-container .ant-form-item-with-help.password-input .ant-form-item-explain .ant-form-item-explain-error, .login-form-container .ant-form-item-with-help.password-input .ant-form-item-explain .ant-form-item-explain-success {
  top: 50px;
}`, "",{"version":3,"sources":["webpack://./src/shared/components/forms/LoginForm.scss"],"names":[],"mappings":"AACE;EACE,4BAAA;AAAJ;AAGE;EACE,uBAAA;EACA,iBAAA;EACA,6BAAA;AADJ;AAIE;EACE,mBAAA;EACA,gBAAA;AAFJ;AAKE;EACE,gBAAA;AAHJ;AAII;EACE,eAAA;EACA,iBAAA;EACA,kBAAA;AAFN;AAME;EACE,SAAA;AAJJ","sourcesContent":[".login-form-container {\n  .ant-input-affix-wrapper {\n    padding: 0px 11px !important;\n  }\n\n  .ant-input {\n    height: 48px !important;\n    padding: 4px 11px;\n    border-radius: 8px !important;\n  }\n\n  .forgot-password-section {\n    margin-bottom: 24px;\n    margin-top: 24px;\n  }\n\n  .FormFooter {\n    margin-top: 32px;\n    button {\n      font-size: 14px;\n      line-height: 22px;\n      padding: 10px 12px;\n    }\n  }\n\n  .ant-form-item-with-help.password-input .ant-form-item-explain div, .ant-form-item-with-help.password-input .ant-form-item-explain .ant-form-item-explain-error, .ant-form-item-with-help.password-input .ant-form-item-explain .ant-form-item-explain-success {\n    top: 50px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
