import { Form, Input, Select } from 'antd';
import React, { useState } from 'react';
import { MONTH_NAME , PAGE_SIZE} from '../../../shared/constants';
import ClearIcon from '../../../shared/svgs/ClearIcon';
import SearchIcon from '../../../shared/svgs/SearchIcon';
import PrimaryButton from '../../../shared/components/buttons/PrimaryButton';
import SecondaryButton from '../../../shared/components/buttons/SecondaryButton';
import { getStudentsList } from '../../../api/students';
import { getMaterialsList } from '../../../api/materials';
import { QueryParams } from '../../../interfaces';
import { useQuery } from 'react-query';
type Props = {
  handleFilter(_: any): void;
  handleReset(): void;
};

const MaterialRequestFilterForm: React.FC<Props> = ({ handleFilter, handleReset }) => {
  const [form] = Form.useForm();
  const [queryParams, setQueryParams] = useState<QueryParams>({ start: 0, count: PAGE_SIZE });
  const { data:StudentList, isLoading } = useQuery(['students', 'list', queryParams], () => getStudentsList(queryParams), {
    staleTime: Infinity,
  })
  const { data:MaterialList, isLoading:isMaterialLoading } = useQuery(['materials', 'list', queryParams], () => getMaterialsList(queryParams), {
    staleTime: Infinity,
  })

  const handleOnFinish = (values: any) => {
    handleFilter(values);
  };

  const handleResetForm = () => {
    form.resetFields();
    handleReset();
  };

  return (
    <div className="users-filter-form-container filter-form-container">
      <Form form={form} requiredMark={false} colon={false} onFinish={handleOnFinish} scrollToFirstError>
        <div className="form-container">
          <div className="form-content">
          <Form.Item name="studentId">
              <Select placeholder="Student">
                {StudentList?.data?.map((student: any) => (
                  <Select.Option key={student.id} value={student.id}>
                    {student.chineseName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="materialId">
              <Select placeholder="Material">
                {MaterialList?.data?.map((material: any) => (
                  <Select.Option key={material.id} value={material.id}>
                    {material.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="form-footer">
            <PrimaryButton label={'Search'} htmlType="submit" icon={<SearchIcon />} />
            <SecondaryButton label={'Reset'} onClick={handleResetForm} icon={<ClearIcon />} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default MaterialRequestFilterForm;
