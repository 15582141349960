import { message } from 'antd';
import React from 'react';
import { useMutation, useQueryClient,useQuery } from 'react-query';
import { createMaterial, getMaterialsList } from '../../../api/materials';
import { useNavigate,useParams } from 'react-router-dom';
import { createCourse,useGetCourse, useGetCourseAttendances} from '../../../api/courses';
import { createCourseAttendances} from '../../../api/attendances';
import { Material } from '../../../interfaces/material';
import Form from '../../../shared/components/forms/attendances/Form';
import { PAGE_SIZE } from '../../../shared/constants';
import { getCoachesList } from '../../../api/coaches';
import { Attendances } from '../../../interfaces/attendances';
import MaterialsForm from '../../../shared/components/forms/material/Form';

const MaterialNewPage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: createMutate } = useMutation(createMaterial, {
    onSuccess: async () => {
      message.info('Create material successfully!');
      queryClient.invalidateQueries({
        queryKey: ['materials', 'list'],
      });
      navigate(`/admin/materials`);
    },
    onError: () => {
      message.error('Create material failed! Please try again');
    },
    onSettled: () => {
      queryClient.prefetchQuery({
        queryKey: ['materials', 'list', { start: 0, count: PAGE_SIZE, filter: undefined }],
        queryFn: () => getMaterialsList({ start: 0, count: PAGE_SIZE, filter: undefined }),
      });
    },
  });

  const handleSubmit = async (values: Material) => {
    createMutate(values);
  };

  return (
    <div className="users-container">
      <div className="page-container">
        <div className="page-content">
          <div className="page-content-header">
            <h2 className="title-page">Add New Material</h2>
          </div>
          <MaterialsForm handleSubmit={handleSubmit} MaterialId={0} Material={undefined} />
        </div>
      </div>
    </div>
  );
};

export default MaterialNewPage;
