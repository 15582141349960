import { Form,Collapse,Spin  } from 'antd';
import React, { useEffect , useState} from 'react';
import './Profile.scss';
import { BellOutlined } from '@ant-design/icons';
import { DateFormat } from '../../../../utils/global';
import { Student } from '../../../../interfaces/student';
import { Course } from '../../../../interfaces/course';
import { DATE_FORMAT_FRONTEND, PAGE_SIZE } from '../../../../shared/constants';
import { useGetStudentClassWork} from '../../../../api/students';
import { Props as TableProps } from './Base';
import '../../../../../src/mixins.scss';
import  "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import PrimaryButton from '../../../../shared/components/buttons/PrimaryButton';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ClassWorksAttendancesList from './ClassWorksAttendancesList';

const { Panel } = Collapse;
dayjs.extend(customParseFormat);

type Props = TableProps & {
  student : Student
};


const StudentClassWorks: React.FC<Props> = ({ student, ...props }) => {
  const { data: studentAttendances, isLoading } = useGetStudentClassWork(Number(student.id));
const [modalData, setModalData] = useState(null); 
  const onChange = (key: string | string[],modalData:any) => {

    if(key.length>0){
      setModalData(modalData) 
      console.log(modalData)
    }
  };
  const [form] = Form.useForm();
  useEffect(() => {
    if (student) {
      form.setFieldsValue(student);
    }
  }, [student]);
      return (  <Spin spinning={isLoading}>
    {studentAttendances?.courses.map((course: Course) => (
      <Collapse expandIconPosition="end" onChange={e => onChange(e, {'studentId':Number(student.id),'courseId':Number(course.id)})} style={{color:'#939ca3 !important'  ,backgroundColor:'#23395d',fontSize:'large'}}>
          <Panel style={{ color: 'snow' , textAlign:'center'}} key="1" className="collapse-custom-header" header={`按这边看看我的作品`}>
          {/* <Panel style={{ color: 'snow' , textAlign:'center'}} key="1" className="collapse-custom-header" header={`Class Works ${course.name}`}> */}
          <div className='ant-collapse-content-box'>
                  <ClassWorksAttendancesList 
                  attendances={course.attendances}     
                  studentId={student.id}
                  courseId={course.id}             
                  />
            </div>
          </Panel>
      </Collapse>
    ))}
   </Spin> 
)
  }
export default StudentClassWorks;