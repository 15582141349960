// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  font-family: sans-serif;
  text-align: center;
}

.row {
  margin-top: 10px;
}

.card-body {
  padding: 0.5rem;
}

@media (max-width: 623px) {
  .card-header {
    padding: 0.45rem 0.15rem !important;
  }
  .card {
    margin-left: 0px !important;
  }
}
@media (max-width: 320) {
  .card {
    margin-left: 0px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/shared/components/cardViews/TuitionFee/Card.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,kBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAGA;EACE,eAAA;AAAF;;AAIA;EAME;IACE,mCAAA;EANF;EASA;IACE,2BAAA;EAPF;AACF;AAUA;EAEE;IACE,2BAAA;EATF;AACF","sourcesContent":[".App {\n  font-family: sans-serif;\n  text-align: center;\n}\n\n.row{\n  margin-top:10px\n}\n\n\n.card-body{\n  padding:0.5rem;\n}\n\n\n@media (max-width: 623px) {\n  // .rowmb{\n  //   // box-sizing: content-box !important;\n  //   height: 200px !important;\n  // }\n  \n  .card-header{\n    padding:0.45rem 0.15rem !important;\n  }\n\n  .card{\n    margin-left:0px !important; \n  }\n}\n\n@media (max-width: 320) {\n\n  .card{\n    margin-left:0px !important; \n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
