import {Button, message, Dropdown, Spin, Collapse } from 'antd';
import React, {useState} from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { DATE_FORMAT_FRONTEND, PAGE_SIZE } from '../../../shared/constants';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getCoursesList, updateCourse, useGetCourseAttendances } from '../../../api/courses';
import CourseStudentAttendancesListView from '../../../shared/components/tables/attendances/CourseStudentAttendancesListView';
import { Attendances } from '../../../interfaces/attendances';
import { Student } from '../../../interfaces/student';
import { QueryParams } from '../../../interfaces';
import PrimaryButton from '../../../shared/components/buttons/PrimaryButton';
const { Panel } = Collapse;
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import DangerButton from '../../../shared/components/buttons/DangerButton';
import AddCircleOutlineIcon from '../../../shared/svgs/AddCircleOutlineIcon';

const CourseDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [queryParams] = useState<QueryParams>({ start: 0, count: PAGE_SIZE });
  const { data: courseAttendances, isLoading } = useGetCourseAttendances(Number(id));

  const currentPage = queryParams.start ? Math.round((Number(queryParams.start) - 1) / PAGE_SIZE + 1) : 1;
  const pagination: any = {
    current: currentPage,
    pageSize: PAGE_SIZE,
    total: courseAttendances?.attendances?.studentAttendances?.total || 0,
    showTotal: (total: string) => `${total} Items`,
  };


  const { mutateAsync: updateMutate } = useMutation(updateCourse, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['courses', 'list'],
      });
      queryClient.invalidateQueries({
        queryKey: [`courses/${id}`, undefined],
      });
      message.info('Update course successfully!');
      navigate(`/admin/courses`);
    },
    onError: () => {
      message.error('Update course failed! Please try again');
    },
    onSettled: () => {
      queryClient.prefetchQuery({
        queryKey: ['courses', 'list', { start: 0, count: PAGE_SIZE, filter: undefined }] ,
        queryFn: () => getCoursesList({ start: 0, count: PAGE_SIZE, filter: undefined }),
      });
    },
  });

  // const { mutateAsync: mutationUpdate } = useMutation(deleteStudentFromCourse);

  const handleClickDelete = async (courseId : number , studentId : number) => {
    // await deleteStudentFromCourse(courseId,studentId);
    // queryClient.invalidateQueries({
    //   queryKey: ['students', 'list'],
    // });
  };

  const handleSubmit = async (values: any) => {
    updateMutate({ ...values, id });
  };

  const withAction = (record: any) => {
    return (
      <span className="action" style={{ display: 'flex' }}>
        <Dropdown.Button
          overlayClassName="table-action-menu"
          menu={{
            items: [
              {
                key: '1',
                label: (
                  <Link to={`/admin/users/${record?.id}`}>
                    <Button className="btn-table-action-primary" type="link">
                      View
                    </Button>
                  </Link>
                ),
              },
              {
                key: '4',
                label: <DangerButton label="Delete" onOk={() => handleClickDelete(Number(id),Number(record?.id))} />,
              },
            ],
          }}
        ></Dropdown.Button>
      </span>
    );
  };

  return (
    <Spin spinning={isLoading}>
  <div id="user-details-container">
    <div className="page-container">
      <div className="page-content">
        <div className="page-content-header">
          <h2 className="title-page">Course Details</h2>
        </div>
        <div className="page-header">
          <div></div>
          {/* <div className="page-header-actions">
            <Link to={`/admin/attendances/${courseAttendances?.id}/new`}>
              <PrimaryButton label="Create Attendances" icon={<AddCircleOutlineIcon />} />
            </Link>
          </div> */}
        </div>
        <div className="details-container">
          <div className="item">
            <label>Course Name</label>
            <span className="value">{courseAttendances?.name}</span>
          </div>
          <div className="item">
            <label>Start At</label>
            <span className="value">
              {courseAttendances?.startAt}
            </span>
          </div>
          <div className="item">
            <label>End At</label>
            <span className="value">
              {courseAttendances?.endAt} 
            </span>
          </div>
          <div className="item">
            <label>Students</label>
            <span className="value">
            {courseAttendances?.students?.map((student: Student) => (
                <span className="value">
                      {student.chineseName}
                      <br></br>
                  </span>
              ))}
            </span>
          </div>
          <div className="item">
            <label>Students Count</label>
            <span className="value">
            {courseAttendances?.students.length}
            </span>
          </div>
        </div>
      </div>
      {courseAttendances?.attendances?.map((a: Attendances) => (
      <><Collapse expandIconPosition="end" style={{color:'#939ca3 !important'  ,backgroundColor:'#23395d', alignItems:'self-end',fontSize:'large', borderRadius: '36px'}}>
          <Panel header={a.location + ' <'+ dayjs(a.date).format(DATE_FORMAT_FRONTEND) + '-' +a.startAt+ '-' + a.endAt + '>'} key="1" className="collapse-custom-header">
            <CourseStudentAttendancesListView
              rowKey="id"
              loading={isLoading}
              pagination={pagination}
              attendancesId={a?.id}
              editable={false}
              withAction={withAction} />
          </Panel>
        </Collapse><div style={{paddingTop:'10px'}}></div></>
       ))}
      
    </div>
  </div>
</Spin>
);
};

export default CourseDetailsPage;
