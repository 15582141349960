import React, { useState } from 'react';
import { Material } from '../../../interfaces/material';
import TableBase, { Props as TableProps } from '../../../shared/components/tables/Base';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
type Props = TableProps & {
  notification?: any[];
};


const NotificationLogs: React.FC<Props> = ({ notification,...props }) => {
  

  const COLUMNS = [
    {
      title: 'actions',
      width: 50,
      dataIndex: 'actions'
    },
    {
      title: 'remark',
      width: 50,
      dataIndex: 'remark'
    },
    {
      title: 'status',
      width: 50,
      dataIndex: 'status'
    },
    {
      title: 'createdAt',
      width: 50,
      dataIndex: 'createdAt'
    },
  ];
  return <TableBase dataSource={notification} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default NotificationLogs;
