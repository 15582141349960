import { message } from 'antd';
import React from 'react';
import { useMutation, useQueryClient,useQuery } from 'react-query';
import { useNavigate,useParams } from 'react-router-dom';
import { createCourse,useGetCourse, useGetCourseAttendances} from '../../../api/courses';
import { createCourseAttendances} from '../../../api/attendances';
import { Course } from '../../../interfaces/course';
import Form from '../../../shared/components/forms/attendances/Form';
import { PAGE_SIZE } from '../../../shared/constants';
import { getCoachesList } from '../../../api/coaches';
import { Attendances } from '../../../interfaces/attendances';

const CourseMaterialNewPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: course, isLoading } = useGetCourse(Number(id));

  const { mutate: createMutate } = useMutation(createCourseAttendances, {
    onSuccess: async () => {
      message.info('Create Course Attendances successfully!');
      queryClient.invalidateQueries({
        queryKey: ['course', 'list'],
      });
      navigate(`/admin/attendances/${id}`);
    },
    onError: () => {
      message.error('Create Course Attendances failed! Please try again');
    },
    onSettled: () => {
      queryClient.prefetchQuery({
        // queryKey: ['course', 'list', { start: 0, count: PAGE_SIZE, filter: undefined }],
        queryFn: () => useGetCourseAttendances(Number(id)),
      });
    },
  });

  const handleSubmit = async (values: Attendances) => {
    createMutate(values);
  };

  return (
    <div className="courses-container">
      <div className="page-container">
        <div className="page-content">
          <div className="page-content-header">
            <h2 className="title-page">Add New Course Matertial</h2>
          </div>
          <Form 
          handleSubmit={handleSubmit}
          course={course}
          isFetching={isLoading} 
          courseId={Number(id)}
          />
        </div>
      </div>
    </div>
  );
};

export default CourseMaterialNewPage;
