import { message } from 'antd';
import React from 'react';
import { useMutation, useQueryClient,useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { createCourse, getCoursesList } from '../../../api/courses';
import { Course } from '../../../interfaces/course';
import CourseForm from '../../../shared/components/forms/course/Form';
import { PAGE_SIZE } from '../../../shared/constants';
import { getCoachesList } from '../../../api/coaches';

const CourseNewPage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: coachesData, isFetching: isCoachFetching } = useQuery(['coaches', 'list'], () => getCoachesList({}));
  
  const { mutate: createMutate } = useMutation(createCourse, {
    onSuccess: async () => {
      message.info('Create course successfully!');
      queryClient.invalidateQueries({
        queryKey: ['courses', 'list'],
      });
      navigate(`/admin/courses`);
    },
    onError: () => {
      message.error('Create course failed! Please try again');
    },
    onSettled: () => {
      queryClient.prefetchQuery({
        queryKey: ['courses', 'list', { start: 0, count: PAGE_SIZE, filter: undefined }],
        queryFn: () => getCoursesList({ start: 0, count: PAGE_SIZE, filter: undefined }),
      });
    },
  });

  const handleSubmit = async (values: Course) => {
    createMutate(values);
  };

  return (
    <div className="courses-container">
      <div className="page-container">
        <div className="page-content">
          <div className="page-content-header">
            <h2 className="title-page">Add New Parent</h2>
          </div>
          <CourseForm 
          handleSubmit={handleSubmit}
          coachesList={coachesData?.data}
            isCoachFetching={isCoachFetching} 
          />
        </div>
      </div>
    </div>
  );
};

export default CourseNewPage;
