import { message, Spin } from 'antd';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { getPayrollsList, updatePayroll, useGetPayroll } from '../../../api/payrolls';
import PayrollForm from '../../../shared/components/forms/payroll/Form';
import { PAGE_SIZE } from '../../../shared/constants';

const PayrollUpdatePage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useGetPayroll(Number(id));

  const { mutateAsync: updateMutate } = useMutation(updatePayroll, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['payments', 'list'],
      });
      queryClient.invalidateQueries({
        queryKey: [`payments/${id}`, undefined],
      });
      message.info('Update payroll successfully!');
      navigate(`/admin/payments`);
    },
    onError: () => {
      message.error('Update payroll failed! Please try again');
    },
    onSettled: () => {
      queryClient.prefetchQuery({
        queryKey: ['payments', 'list', { start: 0, count: PAGE_SIZE, filter: undefined }],
        queryFn: () => getPayrollsList({ start: 0, count: PAGE_SIZE, filter: undefined }),
      });
    },
  });

  const handleSubmit = async (values: any) => {
    updateMutate({ ...values, id });
  };

  return (
    <Spin spinning={isLoading}>
      <div className="users-container">
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page">Edit User</h2>
            </div>
            <PayrollForm handleSubmit={handleSubmit} payroll={data} payrollId={Number(id)} />
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default PayrollUpdatePage;
