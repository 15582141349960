import { Button, Dropdown, Menu, message } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { deletePayment, mobileDownloadPayment, getPaymentsList, updatePayment } from '../../../api/payments';
import { QueryParams } from '../../../interfaces';
import { Payment } from '../../../interfaces/payment';
import DangerButton from '../../../shared/components/buttons/DangerButton';
import PrimaryButton from '../../../shared/components/buttons/PrimaryButton';
import PaymentsFilterForm from './parentReceipts/Filter';
import ReceiptsTable from './parentReceipts/Receipts';
import { PAGE_SIZE } from '../../../shared/constants';
import AddCircleOutlineIcon from '../../../shared/svgs/AddCircleOutlineIcon';

const ReceiptsPage: React.FC = () => {
  const queryClient = useQueryClient();
  const [queryParams, setQueryParams] = useState<QueryParams>({ start: 0, count: PAGE_SIZE });

  const { data, isLoading } = useQuery(['payments', 'list', queryParams], () => getPaymentsList(queryParams), {
    staleTime: Infinity,
  });

  const currentPage = queryParams.start ? Math.round((Number(queryParams.start) - 1) / PAGE_SIZE + 1) : 1;

  const pagination: any = {
    current: currentPage,
    pageSize: PAGE_SIZE,
    total: data?.total || 0,
    showTotal: (total: string) => `${total} Items`,
  };

  const { mutateAsync: mutationDownload} = useMutation(mobileDownloadPayment);


  const handleClickDownload = async (paymentId: number) => {
     await mutationDownload(paymentId); 
  };

  const { mutateAsync: updateMutate } = useMutation(updatePayment, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['payments', 'list'],
      });
      message.info('Unblocked this user.');
    },
    onError: () => {
      message.error('Update user failed! Please try again');
    },
  });

  const handleUnblockUser = async (values: any) => {
    const newValues: any = values;
    const id = values.id;
    delete values.blockedAt;
    newValues.blockedAt = 'null';
    updateMutate({ ...newValues, id });
  };

  return (
    <div className="payments-container">
      <div className="page-container">
      <div className="page-content-header">
          <h2 className="title-page">Receipts</h2>
        </div>
        <div className="page-content">
          {/* <PaymentsFilterForm handleFilter={handleFilterSubmit} handleReset={handleResetFilter} /> */}
          <ReceiptsTable
            dataSource={data?.data}
            handleClickDownload={handleClickDownload}
          />
        </div>
      </div>
    </div>
  );
};

export default ReceiptsPage;
