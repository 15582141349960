import React from 'react';
import { Payment } from '../../../../interfaces/payment';
import  { Props as TableProps } from './Base';
import './Card.scss';
import { DATE_FORMAT_FRONTEND } from '../../../../shared/constants';
import PrimaryButton from '../../../../shared/components/buttons/PrimaryButton';
import dayjs, { Dayjs } from 'dayjs';
type Props = TableProps & {
  handleClickDownload(_: number): void;
  dataSource?: [Payment];
};

const ReceiptsTable: React.FC<Props> = ({ dataSource, handleClickDownload, ...props }) => {

  const handleDownload = (paymnet :any) => {
    handleClickDownload(Number(paymnet.id))
  };

  return (
    <div className="row" style={{display:'inline-block'}}>
       {dataSource?.map((payment: Payment) => (
    <div className="col-sm-6">
    <div className="card" style={{width: '18rem', marginLeft:'10px'}}>
    <div className="card-body">
      <h5 className="card-title" style={{textAlign: 'center'}} >{ dayjs(payment.periodStartDate).format(DATE_FORMAT_FRONTEND)}-{ dayjs(payment.periodEndDate).format(DATE_FORMAT_FRONTEND)}</h5>
    </div>
   <ul className="list-group list-group-flush">
     <li className="list-group-item">{payment.student.chineseName}<br></br>{payment.paymentType}</li>
      <li className="list-group-item">{payment.remark}</li>
       <h5 className="card-title" style={{textAlign: 'center'}} >{( payment.status ? "PAID":"PENDING")}</h5>
    </ul> 
    <div className="card-body">
          {/* <a href={`/admin/classWorks/${courseData.id}/attendances`} className="card-link">Attendances</a>
          <a href={`/admin/classWorks/${courseData.id}/classworks`} className="card-link">Class Works</a> */}
           <PrimaryButton  label="View" onClick={() => handleDownload(payment)}/>
        </div>
  </div>
  </div>
  ))}
  </div>
   
  ); 
};

export default ReceiptsTable;
