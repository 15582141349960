import React from 'react';
import { Student } from '../../../interfaces/student';
import TableBase, { Props as TableProps } from '../tables/Base';
import { useNavigate } from 'react-router-dom';
import {enrollStudent} from '../../../api/courses';
import BorderLessButton from '../buttons/BorderLessButton';
import DisabledBorderLessButton from '../buttons/DisabledBorderLessButton';
import AddCircleOutlineIcon from '../../svgs/AddCircleOutlineIcon';
import CheckCircleIcon from '../../svgs/CheckCircleIcon';
type Props = TableProps & {
  withAction?(_: any): void;
  studentsData?: any[];
  courseId?: Number
};


const CourseStudentsForEnrollment: React.FC<Props> = ({ studentsData, courseId , withAction, ...props }) => {

// const navigate = useNavigate();
const handleClickEnroll = async (studentId:Number, courseId:Number) => {
   await enrollStudent(Number(courseId), Number(studentId));
   window.location.reload();
  
};
  const COLUMNS = [
    {
      title: 'Name',
      width: 150,
      render: (student: Student) => {
        if(student?.firstName=='Aing'){
          return (
            <DisabledBorderLessButton label={student?.firstName+' '+student?.lastName+' ('+student?.chineseName+')'} icon={<CheckCircleIcon />} />
          );
        }else{
          return (
            <BorderLessButton label={student?.firstName+' '+student?.lastName+' ('+student?.chineseName+')'} icon={<AddCircleOutlineIcon />} onClick={() => handleClickEnroll(Number(student.id), Number(courseId))} />
          );
        }
       
      },
      
    }
  ];
  return <TableBase dataSource={studentsData} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default CourseStudentsForEnrollment;
