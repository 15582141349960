import { Form, Input } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import PrimaryButton from '../buttons/PrimaryButton';
import LoginSVG from '../../svgs/logo.svg';
// import './LoginForm.scss';

type Props = {
  handleSubmit?: (_: any) => void;
};

const ForgotPasswordForm: React.FC<Props> = ({ handleSubmit }) => {
  return (
    <div className="login-form-container">
      <div className="form-container">
        <div className="logo-section">
        <Link to="/parent/login">
            <ReactSVG src={LoginSVG} />
          </Link>
        </div>
        <div className="content-page" style={{ margin: '0px 0px 0px 0px', maxWidth: '600px', maxHeight: '600px' }}>

      </div>
        <h2 className="title">Forgot Password</h2>
        <Form scrollToFirstError layout="vertical" requiredMark={false} colon={false} onFinish={handleSubmit}>
          <div className="FormContent">
            <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Required' }]}>
              <Input size="large" placeholder="Email" />
            </Form.Item>
          </div>
          <div className="FormFooter">
            <PrimaryButton htmlType="submit" label="Send" style={{ width: '100%' }} />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
