import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import Cookies from 'js-cookie';
import { SUPER_ADMIN_ACCESS_ROLE, TOKEN_NAME } from '../constants';
import AdminLayout from './layout/AdminLayout';
import { useGetProfile } from '../../api/auth';
import { Spin } from 'antd';

const AdminRequireAuth: React.FC = () => {
  const { auth }: any = useAuth();
  const location = useLocation();
  const token = Cookies.get(TOKEN_NAME);
  const profile = token ? useGetProfile() : undefined;

  if (!token) {
    return <Navigate to="/parent/login" state={{ from: location }} replace />;
  }

  if ((auth && Object.values(auth).length > 0) || (profile && profile.isSuccess === true)) {
    if (SUPER_ADMIN_ACCESS_ROLE.includes(auth?.role) || SUPER_ADMIN_ACCESS_ROLE.includes(profile?.data?.role)) {
      return <AdminLayout currentUser={profile?.data || auth} />;
    } else {
      return <Navigate to="/unauthorized" state={{ from: location }} replace />;
    }
  } else {
    return (
      <div className="loading-screen">
        <Spin spinning={true}> </Spin>;
      </div>
    );
  }
};

export default AdminRequireAuth;
