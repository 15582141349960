// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-collapse-header-text {
  color: #ffffff !important;
}

.ant-collapse-expand-icon {
  color: #ffffff !important;
  display: "flow" !important;
  align-items: center;
  padding-inline-end: 12px;
  margin-inline-start: 4px;
  justify-content: flex-end;
  flex-direction: column;
}

.css-dev-only-do-not-override-1m62vyb .ant-collapse .ant-collapse-item {
  border-radius: 0 0 28px 28px !important;
}

@media (max-width: 623px) {
  .card-header {
    padding: 0.45rem 0.15rem !important;
  }
  .card {
    margin-left: 0px !important;
  }
}
@media (max-width: 320) {
  .card {
    margin-left: 0px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/tuitionDays/Collapse.scss"],"names":[],"mappings":"AACA;EACE,yBAAA;AAAF;;AAGA;EACE,yBAAA;EACA,0BAAA;EACA,mBAAA;EACE,wBAAA;EACA,wBAAA;EACA,yBAAA;EACA,sBAAA;AAAJ;;AAGA;EACG,uCAAA;AAAH;;AAKA;EAME;IACE,mCAAA;EAPF;EAUA;IACE,2BAAA;EARF;AACF;AAWA;EAEE;IACE,2BAAA;EAVF;AACF","sourcesContent":["\n.ant-collapse-header-text{\n  color:#ffffff !important;\n}\n\n.ant-collapse-expand-icon{\n  color:#ffffff !important;\n  display:'flow'!important;\n  align-items: center;\n    padding-inline-end: 12px;\n    margin-inline-start: 4px;\n    justify-content: flex-end;\n    flex-direction: column;\n}\n\n.css-dev-only-do-not-override-1m62vyb .ant-collapse .ant-collapse-item {\n   border-radius: 0 0 28px 28px !important;\n}\n\n\n\n@media (max-width: 623px) {\n  // .rowmb{\n  //   // box-sizing: content-box !important;\n  //   height: 200px !important;\n  // }\n  \n  .card-header{\n    padding:0.45rem 0.15rem !important;\n  }\n\n  .card{\n    margin-left:0px !important; \n  }\n}\n\n@media (max-width: 320) {\n\n  .card{\n    margin-left:0px !important; \n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
