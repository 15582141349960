import React from 'react';
import { DateFormat } from '../../../utils/global';
import { Payable } from '../../../interfaces/payable';
import TableBase, { Props as TableProps } from './Base';

type Props = TableProps & {
  withAction?(_: any): void;
  dataSource?: Payable[];
};

const PayablesTable: React.FC<Props> = ({ dataSource, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: 'DATE',
      // dataIndex: 'parent.email',
      width: 15,
      render: (payable: Payable) => {
        return (
          <span className="text-capitalize">
            {DateFormat(payable?.date)}
          </span>
        );
      },
    },
    {
      title: 'Type',
      // dataIndex: 'parent.email',
      width: 15,
      render: (payable: Payable) => {
        return (
          <span className="text-capitalize">
            {payable?.type}
          </span>
        );
      },
    },
    {
      title: 'Amount(RM)',
      dataIndex: 'amount',
      width: 15
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      width: 55
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 5,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default PayablesTable;
