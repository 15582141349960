import { Form, Input, Select } from 'antd';
import React ,{useState}from 'react';
import {useQuery } from 'react-query';
import { QueryParams } from '../../../interfaces';
import ClearIcon from '../../../shared/svgs/ClearIcon';
import SearchIcon from '../../../shared/svgs/SearchIcon';
import PrimaryButton from '../../../shared/components/buttons/PrimaryButton';
import SecondaryButton from '../../../shared/components/buttons/SecondaryButton';
import { getCoursesList } from '../../../api/courses';
import { Course } from '../../../interfaces/course';
type Props = {
  handleFilter(_: any): void;
  handleReset(): void;
};

interface filterI {
  courseId?: number;
}

const CoursesFilterForm: React.FC<Props> = ({ handleFilter, handleReset }) => {
  const [form] = Form.useForm();
  const [queryParams, setQueryParams] = useState<QueryParams>();
  const [filterFields, setFilterFields] = useState<filterI>();
  const { data:CoursesList, isLoading:isCourseFetching } = useQuery(['courses', 'list', queryParams], () => getCoursesList({}), {
    staleTime: Infinity,
  });

  const handleOnFinish = (values: any) => {
    handleFilter(values);
  };

  const handleResetForm = () => {
    form.resetFields();
    handleReset();
  };

  const onCourseChange = (event: any) => {
    // const { value } = event.target;
    setFilterFields({ ...filterFields, courseId: event });
  };

  return (
    <div className="users-filter-form-container filter-form-container">
      <Form form={form} requiredMark={false} colon={false} onFinish={handleOnFinish} scrollToFirstError>
        <div className="form-container">
          <div className="form-content">
          <Form.Item  name= 'courseId'  >
            <Select placeholder="Course Name" showSearch optionFilterProp="children" loading={isCourseFetching} 
             onChange={(value) => onCourseChange(value)}>
               {CoursesList?.data?.map((courses: Course) => (
                                <Select.Option key={courses.id} value={courses.id}>
                                  {courses.name} {courses.startAt}
                                </Select.Option>
                              ))}
                            </Select >
                          </Form.Item>
          </div>
          <div className="form-footer">
            <PrimaryButton label={'Search'} htmlType="submit" icon={<SearchIcon />} />
            <SecondaryButton label={'Reset'} onClick={handleResetForm} icon={<ClearIcon />} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default CoursesFilterForm;
