import React  from 'react';
import TableBase, { Props as TableProps } from './Base';
import {StudentAttendances} from '../../../interfaces/studentAttendances';
import BorderLessButton from '../buttons/BorderLessButton';
import DocumentFileIcon from '../../svgs/DocumentFileIcon';
import '../../../../src/global.scss';
type Props = TableProps & {
  studentAttendances: any[];
  courseId:any;

};


const CourseStudentClassWorksList: React.FC<Props> = ({ studentAttendances , courseId, ...props }) => {
 

// const navigate = useNavigate();
const handleClickSetAttendances = async (courseId:number,studentId:Number) => {
   window.location.href ="/admin/classWorks/"+Number(courseId)+"/classworksupdate?studentId="+Number(studentId);
  
};


  const COLUMNS = [
    {
      title: 'Name1',
      width: 150,
      render: (studentAttendances: StudentAttendances) => {
        if(studentAttendances.status == "absent"){
          return (
            <BorderLessButton  color={"danger-color"}  label={studentAttendances.students.firstName+' '+studentAttendances.students.lastName+' ('+studentAttendances.status+') '}  />
          );
        }else{
          return (
            <BorderLessButton color={"primary-color"}  label={studentAttendances.students.firstName+' '+studentAttendances.students.lastName+' ('+studentAttendances.status+') '} icon={<DocumentFileIcon />}  onClick={() => handleClickSetAttendances(Number(courseId),Number(studentAttendances.students.id))} />
          );
        }
          
      },
      
    }
  ];
  return <TableBase dataSource={studentAttendances} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default CourseStudentClassWorksList;
