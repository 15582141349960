import { Button, Dropdown,message, Modal, Spin, Collapse } from 'antd';
import React, { useState } from 'react';
import { Student } from '../../../interfaces/student';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { getCoursesList, updateCourse, useGetCourseAttendances,deleteStudentFromCourse} from '../../../api/courses';
import { getStudentsList ,deleteStudent} from '../../../api/students';
import { QueryParams } from '../../../interfaces';
import { Attendances } from '../../../interfaces/attendances';
import PrimaryButton from '../../../shared/components/buttons/PrimaryButton';
import CourseForm from '../../../shared/components/forms/course/Form';
import { getCoachesList } from '../../../api/coaches';
import { PAGE_SIZE,DATE_FORMAT_FRONTEND } from '../../../shared/constants';
import DangerButton from '../../../shared/components/buttons/DangerButton';
import CourseStudentsAddAttendances from '../../../shared/components/modal/CourseStudentsAddAttendances';
import CourseStudentAttendancesList from '../../../shared/components/tables/attendances/CourseStudentAttendancesList';
import { Link } from 'react-router-dom';
import SecondaryButton from '../../../shared/components/buttons/SecondaryButton';
import AddCircleOutlineIcon from '../../../shared/svgs/AddCircleOutlineIcon';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import StatusForm from '../../../shared/components/modal/Attendances/statusForm';

const { Panel } = Collapse;
const CourseUpdatePage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [queryParams] = useState<QueryParams>({ start: 0, count: PAGE_SIZE });
  const { id } = useParams<{ id: string }>();
  const { data: courseData, isLoading } = useGetCourseAttendances(Number(id));
  const currentPage = queryParams.start ? Math.round((Number(queryParams.start) - 1) / PAGE_SIZE + 1) : 1;
  const pagination: any = {
    current: currentPage,
    pageSize: PAGE_SIZE,
    total: courseData?.total || 0,
    showTotal: (total: string) => `${total} Items`,
  };

  const [ isCreateAttendancestModalVisible, setCreateAttendancestModalVisible] = useState<boolean>(false);
  const [ isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [modalData, setModalData] = useState(null);

  const onEmergencyContactCancel = () => {
    setCreateAttendancestModalVisible(false);
  };

  const onCancel = () => {
    setIsModalVisible(false);
  };

  const onEditClick = () => {
    setCreateAttendancestModalVisible(true);
  };

  const onStatusEditClick = (modalData:any) => {
    setModalData(modalData)
    setIsModalVisible(true);
  };
 
  const { mutateAsync: updateMutate } = useMutation(updateCourse, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['courses', 'list'],
      });
      queryClient.invalidateQueries({
        queryKey: [`courses/${id}`, undefined],
      });
      message.info('Update course successfully!');
      navigate(`/admin/courses`);
    },
    onError: () => {
      message.error('Update course failed! Please try again');
    },
    onSettled: () => {
      queryClient.prefetchQuery({
        queryKey: ['courses', 'list', { start: 0, count: PAGE_SIZE, filter: undefined }],
        queryFn: () => getCoursesList({ start: 0, count: PAGE_SIZE, filter: undefined }),
      });
    },
  });

  // const { mutateAsync: mutationUpdate } = useMutation(deleteStudentFromCourse);

  const handleClickDelete = async (courseId : number , studentId : number) => {
    await deleteStudentFromCourse(courseId,studentId);
    queryClient.invalidateQueries({
      queryKey: ['students', 'list'],
    });
  };

  const handleSubmit = async (values: any) => {
    updateMutate({ ...values, id });
  };

  const withAction = (record: any) => {
    return (
      <span className="action" style={{ display: 'flex' }}>
        <Dropdown.Button
          overlayClassName="table-action-menu"
          menu={{
            items: [
              {
                key: '1',
                label: (
                  <Link to={`/admin/users/${record?.id}`}>
                    <Button className="btn-table-action-primary" type="link">
                      View
                    </Button>
                  </Link>
                ),
              },
              {
                key: '4',
                label: <DangerButton label="Delete" onOk={() => handleClickDelete(Number(id),Number(record?.id))} />,
              },
            ],
          }}
        ></Dropdown.Button>
      </span>
    );
  };

  return (
    <Spin spinning={isLoading}>
      <div className="users-container">
        <div className="page-container">
        <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page">Attendance Details</h2>
            </div>
            <div className="details-container">
              <div className="item">
                <label>Course Name</label>
                <span className="value">{courseData?.name}</span>
              </div>
              <div className="item">
                <label>Start At - End At</label>
                <span className="value">
                  {courseData?.startAt} -  {courseData?.endAt} 
                </span>
              </div>
              <div className="item">
            <label>Students</label>
            <span className="value">
            {courseData?.students?.map((student: Student) => (
                <span className="value">
                      {student.chineseName}
                      <br></br>
                  </span>
              ))}
            </span>
          </div>
          <div className="item">
            <label>Students Count</label>
            <span className="value">
            {courseData?.students.length}
            </span>
            </div>
              <div className="item">
                <label>Status</label>
                <span className={`value status ${courseData?.isActive ? 'active' : 'inactive'}`}>
                  {courseData?.isActive ? 'Active' : 'Inactive'}
                </span>
              </div>
            </div> 
          </div>
          <PrimaryButton label="Create Attendances" icon={<AddCircleOutlineIcon />} onClick={() => onEditClick()}  />
          <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page">Attendances List</h2>
            </div>   
        {courseData?.attendances?.map((a: Attendances) => (
          <><Collapse expandIconPosition="end" style={{color:'#939ca3 !important'  ,backgroundColor:'#23395d', alignItems:'self-end',fontSize:'large', borderRadius: '36px'}}>
          
              <Panel header={a.location + ' <'+ dayjs(a.date).format(DATE_FORMAT_FRONTEND) + '-' +a.startAt+ '-' + a.endAt + '> Absent :'+ a.absentCount + ', Attended :'+ a.attendedCount
            
            } key="1" className="collapse-custom-header">
                <CourseStudentAttendancesList
                  rowKey="id"
                  loading={isLoading}
                  pagination={pagination}
                  attendancesId={a?.id}
                  editable={true}
                  withAction={withAction} 
                  modalClick={onStatusEditClick}
                  />
              </Panel>
            </Collapse><div style={{paddingTop:'10px'}}></div></>
           ))} 
              </div>
        </div>
      </div>
      <Modal
        visible={isCreateAttendancestModalVisible}
        onOk={onEmergencyContactCancel}
        onCancel={onEmergencyContactCancel}
        footer={null}
        width={600}
          >
              <CourseStudentsAddAttendances
              courseName={courseData?.name}
                courseId={Number(id)}
                startAt={courseData?.startAt}
                endAt={courseData?.endAt}
                studentCount={courseData?.students.length}
                setCreateAttendancestModalVisible={setCreateAttendancestModalVisible}
              />
      </Modal>
      <Modal
        visible={isModalVisible}
        onOk={onCancel}
        onCancel={onCancel}
        footer={null}
        width={350}
          >
            <StatusForm
                modalData={modalData}
                onCancel={onCancel}
                   />
      </Modal>
    </Spin>
  );
};

export default CourseUpdatePage;
