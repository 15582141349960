import { pathToUrl } from '../utils/router';
import { Payment } from '../interfaces/payment';
import { GetListResponse } from '../interfaces';
import { useFetch, usePost } from '../shared/reactQuery';
import { api } from '../shared/api';
import { handleFilterParams } from '../utils/global';
import request from '../utils/request';
export const useGetPaymentsList = () => useFetch<GetListResponse>('payments');
import { handleDownloadExportFile,handleMobileDownloadExportFile } from '../utils/global';
export const useGetPayment = (id: number) => useFetch<Payment>(`payments/${id}`);
export const useCreateUser = (updater: (oldData: Payment[], newData: Payment) => Payment[]) => {
  return usePost<Payment[], Payment>(pathToUrl('payments'), undefined, updater);
};
const MONTH_NAME = [
  { index: 1 ,name:"JANUARY", value: 'JANUARY' },
  { index: 2 ,name:"FEBRUARY", value: 'FEBRUARY' },
  { index: 3 ,name:"MARCH", value: 'MARCH' },
  { index: 4 ,name:"APRIL", value: 'APRIL' },
  { index: 5 ,name:"MAY", value: 'MAY' },
  { index: 6 ,name:"JUNE", value: 'JUNE' },
  { index: 7 ,name:"JULY", value: 'JULY' },
  { index: 8 ,name:"AUGUST", value: 'AUGUST' },
  { index: 9 ,name:"SEPTEMBER", value: 'SEPTEMBER' },
  { index: 10 ,name:"OCTOBER", value: 'OCTOBER' },
  { index: 11 ,name:"NOVEMBER", value: 'NOVEMBER' },
  {index: 12 , name:"DECEMBER", value: 'DECEMBER' },
];

export const getPaymentsList = async ({ start, count, filter }: { start?: number; count?: number; filter?: any }) => {
  const newParams = handleFilterParams({
    start,
    count,
    filter,
  });
  const { data: response }: any = await api.get(`payments?${newParams}`);
  return response;
};

export const getParentExpiredList = async ({ start, count, filter }: { start?: number; count?: number; filter?: any }) => {
  const newParams = handleFilterParams({
    start,
    count,
    filter,
  });
  const { data: response }: any = await api.get(`payments/getParentExpiredList?${newParams}`);
  return response;
};

export const getParentExpiredListByParent = async ({ id,start, count, filter }: { id?: number ,start?: number; count?: number; filter?: any }) => {
  const newParams = handleFilterParams({
    start,
    count,
    filter,
  });
  const { data: response }: any = await api.get(`payments/${id}/getParentExpiredListByParent?${newParams}`);
  return response;
};

export const getParentAllList = async ({ start, count, filter }: { start?: number; count?: number; filter?: any }) => {
  const newParams = handleFilterParams({
    start,
    count,
    filter,
  });
  const { data: response }: any = await api.get(`payments/getParentAllList?${newParams}`);
  return response;
};

export const getPaymentsExpiredList = async ({ start, count, filter }: { start?: number; count?: number; filter?: any }) => {
  const newParams = handleFilterParams({
    start,
    count,
    filter,
  });
  const { data: response }: any = await api.get(`payments/getExpiredList?${newParams}`);
  return response;
};

export const getPaymentsReceiptList= async ({ start, count, filter }: { start?: number; count?: number; filter?: any }) => {
  const newParams = handleFilterParams({
    start,
    count,
    filter,
  });
  const { data: response }: any = await api.get(`payments/getReceiptList?${newParams}`);
  return response;
};

export const mobileDownloadPayment = async (id: number) => {
  // return  await api.post(`payments/${id}/generatePaymentDoc`,{
  //   method: 'POST',
  //   data: {},
  //   responseType: 'blob',
  // });

  const payload: any = await request(`payments/${id}/generatePaymentDoc`, {
    method: 'GET',
    data: {},
    responseType: 'blob',
  });
  
  const date  =new Date()
      const year = date.getFullYear()
      var m = date.getMonth()
  const fileName=`${MONTH_NAME[m].name}_${year}`
  handleMobileDownloadExportFile(payload,`TheArtisanLab-Tuition-Fee-${fileName}.pdf`);
  
};

export const downloadPayment = async (id: number) => {
  // return  await api.post(`payments/${id}/generatePaymentDoc`,{
  //   method: 'POST',
  //   data: {},
  //   responseType: 'blob',
  // });

  const payload: any = await request(`payments/${id}/generatePaymentDoc`, {
    method: 'GET',
    data: {},
    responseType: 'blob',
  });
  
  const date  =new Date()
      const year = date.getFullYear()
      var m = date.getMonth()
  const fileName=`${MONTH_NAME[m].name}_${year}`

  handleDownloadExportFile(payload,`TheArtisanLab-Tuition-Fee-${fileName}.pdf`);
  
};

export const paymentMake = async ({ id, values }: { id: string; values: any }) => {
  const { data: response }: any = await api.post(`payments/${id}/payments`, values);
  return response.data;
};

export const createPayment = async (values: Payment) => {
  const { data: response }: any = await api.post('payments', values);
  return response.data;
};

export const updatePayment = async ({ id, ...values }: { id: string; values: Payment }) => {
  const { data: response }: any = await api.patch(`payments/${id}`, values);
  return response.data;
};

export const updatePaymentReceipt = async (id: number) => {
  const { data: response }: any = await api.patch(`payments/${id}/receiptsent`, {});
  return response.data;
};

export const deletePayment = async (id: number) => {
  const { data: response }: any = await api.delete(`payments/${id}`);
  return response.data;
};
