import { Button, Dropdown, Menu, message } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { deletePayment, downloadPayment, getPaymentsExpiredList, updatePayment } from '../../../api/payments';
import { QueryParams } from '../../../interfaces';
import { Payment } from '../../../interfaces/payment';
import DangerButton from '../../../shared/components/buttons/DangerButton';
import PrimaryButton from '../../../shared/components/buttons/PrimaryButton';
import PaymentsFilterForm from '../../../shared/components/forms/payment/Filter';
import PaymentsTable from '../../../shared/components/tables/Payments';
import { PAGE_SIZE } from '../../../shared/constants';
import AddCircleOutlineIcon from '../../../shared/svgs/AddCircleOutlineIcon';

const PaymentsPage: React.FC = () => {
  const currentDate = new Date()
  currentDate.setHours(0, 0, 0, 0)
  // const todayDate = new Date(currentDate.getFullYear(),currentDate.getMonth()).toString()
  const todayDate = currentDate.setDate(currentDate.getDate()).toString()
  // const endDate2 = new Date(startDate1.getFullYear(),startDate1.getMonth()+1,0)
  // const startDate = startDate1.setDate(startDate1.getDate()).toString()
  // const endDate = endDate2.setDate(endDate2.getDate()).toString()
  // const afterPeriod = startDate

  const queryClient = useQueryClient();
  const [queryParams, setQueryParams] = useState<QueryParams>({ start: 0, count: PAGE_SIZE,filter:{todayDate} });

  const { data, isLoading } = useQuery(['payments', 'list', queryParams], () => getPaymentsExpiredList(queryParams), {
    staleTime: Infinity,
  });

  const currentPage = queryParams.start ? Math.round((Number(queryParams.start) - 1) / PAGE_SIZE + 1) : 1;

  const pagination: any = {
    current: currentPage,
    pageSize: PAGE_SIZE,
    total: data?.total || 0,
    showTotal: (total: string) => `${total} Items`,
  };

  const handleFilter = (params: any) => {
    const currentPage = params.page;
    const offset = currentPage ? (currentPage === 1 ? 0 : (Number(currentPage) - 1) * PAGE_SIZE) : 0;
    setQueryParams({ start: Number(offset), count: PAGE_SIZE });
  };

  const { mutateAsync: mutationDelete } = useMutation(deletePayment);
  const { mutateAsync: mutationDownload} = useMutation(downloadPayment);

  const handleClickDelete = async (payment: Payment) => {
    await mutationDelete(payment.id);
    queryClient.invalidateQueries({
      queryKey: ['payments', 'list'],
    });
  };

  const handleClickDownload = async (payment: Payment) => {
    await mutationDownload(payment.id);
    queryClient.invalidateQueries({
      queryKey: ['payments', 'list'],
    });
  };

  const handleFilterSubmit = (values: any) => {
    values = {...values,todayDate}
    setQueryParams({ start: 0, count: PAGE_SIZE, filter: values });
  };

  const handleResetFilter = () => {
    setQueryParams({ start: 0, count: PAGE_SIZE, filter:{todayDate} });
  };

  const { mutateAsync: updateMutate } = useMutation(updatePayment, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['payments', 'list'],
      });
      message.info('Unblocked this user.');
    },
    onError: () => {
      message.error('Update user failed! Please try again');
    },
  });

  const handleUnblockUser = async (values: any) => {
    const newValues: any = values;
    const id = values.id;
    delete values.blockedAt;
    newValues.blockedAt = 'null';
    updateMutate({ ...newValues, id });
  };

  const withAction = (record: any) => {
    return (
      <span className="action" style={{ display: 'flex' }}>
        <Dropdown.Button
          overlayClassName="table-action-menu"
          menu={{
            items: [
              {
                key: '1',
                label: (
                  <Link to={`/admin/payments/${record?.id}`}>
                    <Button className="btn-table-action-primary" type="link">
                      View
                    </Button>
                  </Link>
                ),
              },
              {
                key: '2',
                label: (
                    <Button className="btn-table-action-primary"  onClick={() => handleClickDownload(record)}>
                      Download
                    </Button>
                ),
              },
              {
                key: '3',
                label: (
                    <Button className="btn-table-action-primary" onClick={() => handleClickDelete(record)} >
                    Delete
                    </Button>
                ),
              }
            ],
          }}
        ></Dropdown.Button>
      </span>
    );
  };

  return (
    <div className="payments-container">
      <div className="page-container">
      <div className="page-content-header">
          <h2 className="title-page">Payment Expired Soon</h2>
        </div>
        
        <div className="page-content">
          <PaymentsFilterForm handleFilter={handleFilterSubmit} handleReset={handleResetFilter} />
          <PaymentsTable
            rowKey="id"
            loading={isLoading}
            pagination={pagination}
            dataSource={data?.data}
            withAction={withAction}
            onChange2={handleFilter}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentsPage;
