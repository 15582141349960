import { pathToUrl } from '../utils/router';
import { ClassReplaces } from '../interfaces/classReplaces';
import { GetListResponse } from '../interfaces';
import { useFetch, usePost } from '../shared/reactQuery';
import { api } from '../shared/api';
import { handleFilterParams } from '../utils/global';

export const useGetClassReplacesList  = () => useFetch<GetListResponse>('classReplaces');
export const useGetClassReplaces = (id: number) => useFetch<ClassReplaces>(`classReplaces/${id}`);
export const getClassReplacesList = async ({ start, count, filter }: { start?: number; count?: number; filter?: any }) => {
  const newParams = handleFilterParams({
    start,
    count,
    filter,
  });
  const { data: response }: any = await api.get(`classReplaces?${newParams}`);
  return response;
};
export const updateClassReplaces= async ({ id, ...values }: { id: string; values: GetListResponse }) => {
  const { data: response }: any = await api.patch(`classReplaces/${id}`, values);
  return response.data;
};

export const deleteClassReplaces = async (classReplacesId: number) => {
  const { data: response }: any = await api.delete(`classReplaces/${Number(classReplacesId)}`);
    return response;
};

export const createClassReplaces = async (values: ClassReplaces) => {
  const { data: response }: any = await api.post('classReplaces', values);
  return response.data;
};