import { message } from 'antd';
import React from 'react';
import { useMutation, useQueryClient,useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { createStudent, getStudentsList } from '../../../api/students';
import { Student } from '../../../interfaces/student';
import StudentForm from '../../../shared/components/forms/student/Form';
import { PAGE_SIZE } from '../../../shared/constants';
import { getParentsList } from '../../../api/parents';

const StudentNewPage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: parentsData, isFetching: isParentFetching } = useQuery(['parents', 'list'], () => getParentsList({}));

  const { mutate: createMutate } = useMutation(createStudent, {
    onSuccess: async () => {
      message.info('Create student successfully!');
      queryClient.invalidateQueries({
        queryKey: ['students', 'list'],
      });
      navigate(`/admin/students`);
    },
    onError: () => {
      message.error('Create student failed! Please try again');
    },
    onSettled: () => {
      queryClient.prefetchQuery({
        queryKey: ['students', 'list', { start: 0, count: PAGE_SIZE, filter: undefined }],
        queryFn: () => getStudentsList({ start: 0, count: PAGE_SIZE, filter: undefined }),
      });
    },
  });

  const handleSubmit = async (values: Student) => {
    createMutate(values);
  };

  return (
    <div className="users-container">
      <div className="page-container">
        <div className="page-content">
          <div className="page-content-header">
            <h2 className="title-page">Add New Student</h2>
          </div>
          <StudentForm 
          isParentsFetching={isParentFetching}
          parentsList={parentsData?.data}
           handleSubmit={handleSubmit} />
        </div>
      </div>
    </div>
  );
};

export default StudentNewPage;
