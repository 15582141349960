import { useGetProfile } from '../../../api/auth';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getStudentsList } from '../../../api/students';
import { QueryParams } from '../../../interfaces';
import { Student } from '../../../interfaces/student';
import {  TOKEN_NAME } from '../../../shared/constants';
import { PAGE_SIZE } from '../../../shared/constants';
import Cookies from 'js-cookie';
import StudentDashboardView from './StudentDashboard/View';
import { useGetCoach } from '../../../api/coaches';
import StudentsFilterForm from './StudentsFilter';
const ClassWorksCoursesPage: React.FC = () => {
  const queryClient = useQueryClient();
  const [queryParams, setQueryParams] = useState<QueryParams>({ start: 0, count: PAGE_SIZE });

  const { data:students, isLoading } = useQuery(['students', 'list', queryParams], () => getStudentsList(queryParams), {
    staleTime: Infinity,
  });
 
  const handleFilterSubmit = (values: any) => {
    setQueryParams({ start: 0, count: PAGE_SIZE, filter: values });
  };

  const handleResetFilter = () => {
    setQueryParams({ start: 0, count: PAGE_SIZE, filter: undefined });
  };

  return (
    // <div className="students-container">
    // <div className="page-container" style={{padding:'2px'}}>
    // <div className="page-content" style={{display: "flex",flexWrap: "wrap",flexDirection: "row",justifyContent: "space-between",alignItems: "center"}}>
    <div className="courses-container">
    <div className="page-container">
    <div className="page-content-header">
    <h2 className="title-page">Class Works</h2>
        <StudentsFilterForm handleFilter={handleFilterSubmit} handleReset={handleResetFilter} />
        </div>
            {students?.data?.map((student: Student) => (
                 <StudentDashboardView 
                 student={student}
                 />  
          ))}
      </div>
    </div>
  );
};

export default ClassWorksCoursesPage;
