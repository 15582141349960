import React, { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import {getStudentsList } from '../../../api/students';
import { useGetProfile } from '../../../api/auth';
import { useGetParentDashboard } from '../../../api/parents';
import { QueryParams } from '../../../interfaces';
import { Student } from '../../../interfaces/student';
import {  TOKEN_NAME } from '../../../shared/constants';
import Cookies from 'js-cookie'; 
import StudentsCardView from '../../../shared/components/cardViews/Student/StudentsCardView';
import StudentDashboardView from './StudentDashboard/View';

import { PAGE_SIZE } from '../../../shared/constants';

const DashboardPage: React.FC = () => {
  const token = Cookies.get(TOKEN_NAME);
  const profile = token ? useGetProfile() : undefined;
  const { data: parent, isLoading:isParentLoading } = useGetParentDashboard(Number(profile?.data?.id));
 
  return (
    <div className="students-container">
    <div className="page-container" style={{padding:'2px'}}>
            {parent?.students?.map((student: Student) => (
                 <StudentDashboardView 
                 student={student}
                 parentId={Number(parent.id)}
                 />  
          ))}
      </div>
    </div>
  );
};

export default DashboardPage;
