import { Form, Input, Select } from 'antd';
import React ,{useState}from 'react';
import { STATUS } from '../../../shared/constants';
import ClearIcon from '../../../shared/svgs/ClearIcon';
import SearchIcon from '../../../shared/svgs/SearchIcon';
import PrimaryButton from '../../../shared/components/buttons/PrimaryButton';
import SecondaryButton from '../../../shared/components/buttons/SecondaryButton';
import {useQuery } from 'react-query';
import { Student } from '../../../interfaces/student';
import { QueryParams } from '../../../interfaces';
import { getStudentsList } from '../../../api/students'
type Props = {
  handleFilter(_: any): void;
  handleReset(): void;
};

interface filterI {
  studentId?: number;
  isDone?: number;
}

const CoursesFilterForm: React.FC<Props> = ({ handleFilter, handleReset }) => {
  const [form] = Form.useForm();
  const [queryParams, setQueryParams] = useState<QueryParams>();
  const [filterFields, setFilterFields] = useState<filterI>();
  const { data:StudentsList, isLoading:isStudentFetching } = useQuery(['students', 'list', queryParams], () => getStudentsList({}), {
    staleTime: Infinity,
  });

  const handleOnFinish = (values: any) => {
    handleFilter(values);
  };

  const onStudentChange = (event: any) => {
    // const { value } = event.target;
    setFilterFields({ ...filterFields, studentId: event });
  };

  const onStatusChange = (event: any) => {
    // const { value } = event.target;
    setFilterFields({ ...filterFields, isDone: event });
  };

  const handleResetForm = () => {
    form.resetFields();
    handleReset();
  };

  return (
    <div className="users-filter-form-container filter-form-container">
      <Form form={form} requiredMark={false} colon={false} onFinish={handleOnFinish} scrollToFirstError>
        <div className="form-container">
          <div className="form-content">
          <Form.Item  name= 'studentId'  >
            <Select placeholder="Student Name" showSearch optionFilterProp="children" loading={isStudentFetching} 
             onChange={(value) => onStudentChange(value)}>
               {StudentsList?.data?.map((student: Student) => (
                                <Select.Option key={student.id} value={student.id}>
                                  {student.chineseName}
                                </Select.Option>
                              ))}
                            </Select >
                          </Form.Item>
            <Form.Item name="isDone">
              <Select placeholder="Status" onChange={(value) => onStatusChange(value)}>
                {STATUS.map((item: any, index: number) => (
                  <Select.Option key={index} value={item.value}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="form-footer">
            <PrimaryButton label={'Search'} htmlType="submit" icon={<SearchIcon />} />
            <SecondaryButton label={'Reset'} onClick={handleResetForm} icon={<ClearIcon />} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default CoursesFilterForm;
