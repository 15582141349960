import { Form, Input,DatePicker, Select, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { convertDateTimeToNumber } from '../../../utils/global';
import { Link } from 'react-router-dom';
import { Coach } from '../../../interfaces/coach';
import { PASSWORD_TIP, USER_ROLES,DATE_FORMAT_FRONTEND } from '../../../shared/constants';
import CancelIcon from '../../../shared/svgs/CancelIcon';
import CheckCircleIcon from '../../../shared//svgs/CheckCircleIcon';
import PrimaryButton from '../../../shared/components/buttons/PrimaryButton';
import SecondaryButton from '../../../shared/components/buttons/SecondaryButton';
import { CoachLeave } from '../../../interfaces/coachLeave';
import {getCoachesList } from '../../../api/coaches';
import { useQuery } from 'react-query';
type Props = {
  isFetching?: boolean;
  coachLeaveId?: number;
  coachLeave?: any;
  handleSubmit(_: any): void;
};

const CoachLeaveForm: React.FC<Props> = ({ coachLeave, coachLeaveId, isFetching, handleSubmit }) => {
  const { data: coaches, isFetching: isCoachFetching } = useQuery(['coaches', 'list'], () => getCoachesList({}));
  const [password, setPassword] = useState<any>({});
  const [form] = Form.useForm();
  const passwordTips = PASSWORD_TIP;
  function validatePassword(value: string) {
    const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{12,})');
    if (value === '') {
      return {
        validateStatus: 'error',
        errorMsg: 'Required',
      };
    }

    if (!value || strongRegex.test(value)) {
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    }

    return {
      validateStatus: 'error',
      errorMsg: 'Your password too weak',
    };
  }

  const onPasswordChange = (event: any) => {
    const { value } = event.target;
    setPassword({ ...validatePassword(value), value });
  };

  const handleOnFinish = (values: Coach) => {
    const newValues: any = values;
    if (coachLeaveId) {
      delete newValues.password;
    }
    newValues.date = convertDateTimeToNumber(dayjs(newValues.date))
    handleSubmit(newValues);
  };

  useEffect(() => {
    if (coachLeave && coachLeave.id === coachLeaveId) {
      coachLeave.date = dayjs(coachLeave.date)
      form.setFieldsValue(coachLeave);
    }
  }, [coachLeave]);

  return (
    <div className="coach-form-container">
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        onFinish={handleOnFinish}
        scrollToFirstError
        autoComplete="off"
        initialValues={{ isActive: true }}
      >
        <div className="form-container">
          <div className="form-content">
             <Form.Item
              name="date"
              label="Leave Date"
              rules={[{ required: true, message: 'required' }]}
            >
              <DatePicker className="ant" format={DATE_FORMAT_FRONTEND} />
              </Form.Item>
              <Form.Item label="Reason" name="remark">
              <Input placeholder="Optional" />
            </Form.Item>
          </div>
          <div className="form-footer">
            <PrimaryButton
              loading={isFetching}
              label={coachLeaveId ? 'Update' : 'Create'}
              icon={<CheckCircleIcon />}
              htmlType="submit"
            />
            <Link to="/coach/coachLeaves">
              <SecondaryButton label={'Cancel'} icon={<CancelIcon />} />
            </Link>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default CoachLeaveForm;
