import { Button, Dropdown, Menu, message } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { deleteParent, getParentsList, updateParent } from '../../../api/parents';
import { QueryParams } from '../../../interfaces';
import { Parent } from '../../../interfaces/parent';
import DangerButton from '../../../shared/components/buttons/DangerButton';
import PrimaryButton from '../../../shared/components/buttons/PrimaryButton';
import ParentsFilterForm from './ParentsFilter';
import ParentsCardView from './ParentsCardView';
import { PAGE_SIZE } from '../../../shared/constants';
import AddCircleOutlineIcon from '../../../shared/svgs/AddCircleOutlineIcon';

const ParentsPage: React.FC = () => {
  const queryClient = useQueryClient();
  const [queryParams, setQueryParams] = useState<QueryParams>({ start: 0, count: PAGE_SIZE });

  const { data, isLoading } = useQuery(['parents', 'list', queryParams], () => getParentsList(queryParams), {
    staleTime: Infinity,
  });

  const currentPage = queryParams.start ? Math.round((Number(queryParams.start) - 1) / PAGE_SIZE + 1) : 1;

  const pagination: any = {
    current: currentPage,
    pageSize: PAGE_SIZE,
    total: data?.total || 0,
    showTotal: (total: string) => `${total} Items`,
  };

  const handleFilter = (params: any) => {
    const currentPage = params.page;
    const offset = currentPage ? (currentPage === 1 ? 0 : (Number(currentPage) - 1) * PAGE_SIZE) : 0;
    setQueryParams({ start: Number(offset), count: PAGE_SIZE });
  };

  const { mutateAsync: mutationDelete } = useMutation(deleteParent);

  const handleDelete = async (parent: Parent) => {
    await mutationDelete(parent.id);
    queryClient.invalidateQueries({
      queryKey: ['parents', 'list'],
    });
  };

  const handleFilterSubmit = (values: any) => {
    setQueryParams({ start: 0, count: PAGE_SIZE, filter: values });
  };

  const handleResetFilter = () => {
    setQueryParams({ start: 0, count: PAGE_SIZE, filter: undefined });
  };

  const { mutateAsync: updateMutate } = useMutation(updateParent, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['parents', 'list'],
      });
      message.info('Unblocked this user.');
    },
    onError: () => {
      message.error('Update user failed! Please try again');
    },
  });


  return (
    <div className="courses-container">
    <div className="page-container">
    <div className="page-content-header">
          <h2 className="title-page">Parent Listing</h2>
        </div>
        <div className="page-content-header">
          <div className="page-header" style={{marginBottom:"10px"}}>
            <Link to="/admin/parents/new">
              <PrimaryButton label="Create Parent" icon={<AddCircleOutlineIcon />} />
            </Link>
            </div>
            <div className="page-content">
            <ParentsFilterForm handleFilter={handleFilterSubmit} handleReset={handleResetFilter} />
            </div>
          <div className="page-header">
          
          {data?.data?.map((parent: Parent) => (
          <ParentsCardView
            rowKey="id"
            loading={isLoading}
            pagination={pagination}
            parent={parent}
            handleClickDelete={handleDelete}
          />
          ))}
        </div>
        </div>
      </div>
    // </div>
  );
};

export default ParentsPage;
