import React, { useEffect, useState }  from 'react';
import { message, Spin } from 'antd';
// import { StudentAttendances } from '../../../interfaces/studentAttendances';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import TableBase, { Props as TableProps } from './Base';
import { QueryParams } from '../../../interfaces';
import { useNavigate } from 'react-router-dom';
import {useGetAttendancesList, updateStudentAttendances} from '../../../api/studentAttendances';
import BorderLessButton from '../../../shared/components/buttons/BorderLessButton';
import AddCircleOutlineIcon from '../../../shared/svgs/AddCircleOutlineIcon';
import CancelIcon from '../../../shared/svgs/CancelIcon';
import { PAGE_SIZE } from '../../../shared/constants';
import CheckCircleIcon from '../../../shared/svgs/CheckCircleIcon';
import {CloseSquareOutlined,CheckSquareOutlined}from '@ant-design/icons';
type Props = TableProps & {
  attendancesId:number
  editable:boolean
};


const CourseStudentAttendancesList: React.FC<Props> = ({ attendancesId,editable, ...props }) => {
  const queryClient = useQueryClient();
  const [queryParams, setQueryParams] = useState<QueryParams>({ start: 0, count: PAGE_SIZE , filter:{'attendancesId':attendancesId}});
  const { data:attendancesData, isLoading } = useQuery(['studentAttendances', 'list', queryParams], () => useGetAttendancesList(queryParams), {
    staleTime: Infinity,
  });
  const [data, setAttendances] = React.useState<any>([]);
  const [active, setActive] = useState(false);
// const navigate = useNavigate();
const handleClickSetAttendances = async (id:Number,status:string) => {
  const newValues : any = {status}
    const  {data:attendancesData}  = await updateStudentAttendances({ ...newValues,id })
    message.info('Attendances Updated');
   setAttendances(attendancesData)
  setActive(!active);
};
useEffect(() => { 
  setAttendances(attendancesData?.data)
}, [attendancesData]);

const COLUMNS = [
  {
    width: 150,
    render: (data: any) => {
      if(data.status == "absent"){
        return (
          <BorderLessButton color={"danger-color"} label={data.students.chineseName+' '+' <'+data.status+'> '} icon={<CloseSquareOutlined />}   />
        );
      }else{
        return (
          <BorderLessButton color={"primary-color"} label={data.students.chineseName+' '+' <'+data.status+'> '} icon={<CheckSquareOutlined />}   />
        );
      }
        
    },
    
  }
];
  return <TableBase dataSource={data} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default CourseStudentAttendancesList;
