import { Form, Dropdown,Modal,Button, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import './Card.scss';
import { Props as TableProps } from '../Base';
import { useGetAttendanceByStudent} from '../../../../api/attendances';
import "bootstrap-css-only/css/bootstrap.min.css";
import '../../../../mixins.scss';
import { useNavigate } from 'react-router-dom';
import { ClassWorks } from '../../../../interfaces/classWorks';
import { File } from '../../../../interfaces/file';
import { Attendances } from '../../../../interfaces/attendances';
import CourseStudentsUpdateClassWorks from '../../modal/CourseStudentsUpdateClassWorks';
import DangerButton from '../../buttons/DangerButton';
import {deleteStudent} from '../../../../api/files';
import htmr from 'htmr';
import {createClassWorks} from '../../../../api/classWorks';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import AddCircleOutlineIcon from '../../../svgs/AddCircleOutlineIcon';
import SecondaryButton from '../../buttons/SecondaryButton';
import CourseStudentsAddClassWorks from '../../../../shared/components/modal/CourseStudentsAddClassWorks';
import DocumentViewer from '../../../../shared/components/Upload/DocumentViewer';
import ReactPlayer from 'react-player'
const videoType ='mp4'

type Props = TableProps & {
  classWorkInfo:any
};

const ClassWorksCardUpdate: React.FC<Props> = ({classWorkInfo,...props }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [ isEmergencyContactModalVisible , setIsEmergencyContactModalVisible] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>();
  const { mutateAsync: mutationAddClassWorks} = useMutation(createClassWorks);
  const [ isEditModalVisible , setIsEditModalVisible] = useState<boolean>(false);
  const [ isCreateModalVisible , setIsCreateModalVisible] = useState<boolean>(false);
  const { data: attendance, isLoading } = useGetAttendanceByStudent(Number(classWorkInfo.attendancesId),Number(classWorkInfo.studentId));

  (attendance ? (console.log('1')):(console.log('2')))
  const onAddClassWorksClick = (modalData:any) =>   { 
    setModalData(modalData)
    setIsCreateModalVisible(true);
  };

  const handleDelete = (id: number) => {
    deleteStudent(id);
    queryClient.invalidateQueries({
      queryKey: [`attendances/${Number(classWorkInfo.attendancesId)}/studentId/${classWorkInfo.studentId}`, undefined],
    });
    navigate(`/admin/classWorks/${classWorkInfo.courseId}/classworksupdate?studentId=${classWorkInfo.studentId}`);
  };

  const onEditCancel = () => {
    setIsEditModalVisible(false);
  };

  const handleAddClassWorks = async (values: any) => {
    await mutationAddClassWorks({ id: values.fileId, body: values});
    queryClient.invalidateQueries({
      queryKey:  [`attendances/${Number(classWorkInfo.attendancesId)}/studentId/${classWorkInfo.studentId}`, undefined],
    });
    navigate(`/admin/classWorks/${classWorkInfo.courseId}/classworksupdate?studentId=${classWorkInfo.studentId}`);
  };

  const onEditClick = (modalData:any) => {
    setModalData(modalData)
    setIsEditModalVisible(true);
  };

  const onCreateCancel = () => {
    setIsCreateModalVisible(false);
  };


      return (
              <>
              <div className="page-header-actions" style={{ flexDirection: 'row-reverse' }}>
          <SecondaryButton label={'Add Class Works'} icon={<AddCircleOutlineIcon />} 
          onClick={() => onAddClassWorksClick({ 'studentId': Number(classWorkInfo.studentId),
           'attendancesId': Number(classWorkInfo.attendancesId), 
           'courseId': Number(classWorkInfo.courseId) })} />
        </div>
        <Spin spinning={isLoading}>
       {attendance?.classWorks.map((classWorks: ClassWorks) => (
          <div className="card-row" style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '0px', marginLeft: '0px' }}>
            <div className="col-sm-6" style={{ width: '100%' }}>
            {classWorks?.files.map((file: File) => (
              <div className="card" style={{width:"100% !important", height:"100%  !important"}}>
                <Dropdown.Button style={{ display: 'flex', flexDirection: 'row-reverse' }}
                  menu={{
                    items: [
                      {
                        key: '1',
                        label: (
                          // <Link to={`/admin/users/${record?.id}`}>
                          <Button className="btn-table-action-primary" type="link" onClick={() => onEditClick({ 'fileId': file.id, 'file': file, 'comment': file.comment })}>
                            Edit
                          </Button>
                          // </Link>
                        ),
                      },
                      {
                        key: '2',
                        label: <Button onClick={() => handleDelete(file.id)}>
                          Delete
                        </Button>,
                      },
                    ],
                  }}
                ></Dropdown.Button>
                {
                  file.name.toUpperCase().split('.').includes(videoType.toUpperCase()) ?
                  (<div className="card-body">
               <ReactPlayer loop={true}  muted={true}  controls={true} url={file.s3Url} />
               </div>):(
                   <DocumentViewer files={file} />)
                }
                
                <div className="card-body">
                  <p className="card-text" style={{color:"#ffff"}}> {file && htmr(file.comment) }</p>
                </div>
              </div>
         
                  ))}
                  
            </div>
            <Modal
              visible={isEditModalVisible}
              onOk={onEditCancel}
              onCancel={onEditCancel}
              footer={null}
              width={700}
            >
              <CourseStudentsUpdateClassWorks
                fileId={Number(modalData?.fileId)}
                file={modalData?.file}
                handleCancel={onEditCancel} />
            </Modal>

            
          </div>
         
         ))}

            <Modal
              visible={isCreateModalVisible}
              onOk={onCreateCancel}
              onCancel={onCreateCancel}
              footer={null}
              width={1236}
            >
              <CourseStudentsAddClassWorks
                modalData={modalData}
                handleCancel={onCreateCancel}
                handleAddClassWorks={handleAddClassWorks}
              />
            </Modal>
            </Spin>
      </>
      ); 
  }
export default ClassWorksCardUpdate;