import React, { useState } from 'react';
import { Material } from '../../../../interfaces/material';
import TableBase, { Props as TableProps } from '../../tables/Base';
import { useNavigate } from 'react-router-dom';
import { QueryParams } from '../../../../interfaces';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getMaterialsList } from '../../../../api/materials';
import { studentAddMaterialQty } from '../../../../api/students';
import {enrollStudent} from '../../../../api/courses';
import BorderLessButton from '../../buttons/BorderLessButton';
import DisabledBorderLessButton from '../../buttons/DisabledBorderLessButton';
import AddCircleOutlineIcon from '../../../svgs/AddCircleOutlineIcon';
import CheckCircleIcon from '../../../svgs/CheckCircleIcon';
import DocumentViewer from '../../../../shared/components/Upload/DocumentViewer';

import { PAGE_SIZE } from '../../../../shared/constants';
type Props = TableProps & {
  studentId?: Number;
  dataSource?: any[];
  modalClick(_: any): void;
};


const MaterialListing: React.FC<Props> = ({ studentId,dataSource ,modalClick,...props }) => {
  
// const navigate = useNavigate();
const handleClickAdd = (materialId:Number) => {
  modalClick({'materialId':materialId})
  
};
  const COLUMNS = [
    {
      title: 'Images',
      width: 100,
      render: (material: Material) => {
        return (
          //  <img src={material?.files.s3Url} width="200" height="200" />
           <span className="value" style={{width: '150px', height:'150px'}}>
                {material && <DocumentViewer files={material?.files} />}
            </span>
        );
      },
    },
    {
      title: 'qty',
      width: 50,
      dataIndex: 'qty'
    },
    {
      title: 'total',
      width: 50,
      dataIndex: 'totalQty'
    },
    {
      title: 'Name',
      width: 150,
      render: (material: Material) => {
        return (
          <BorderLessButton label={material?.name} icon={<AddCircleOutlineIcon />} onClick={() => handleClickAdd(material?.id)} />
        );
      },
    }
  ];
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default MaterialListing;
