import { Button, Dropdown, Menu, message } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { deleteMaterialRequest, getMaterialRequestsList, updateMaterialRequest } from '../../../api/materialRequests';
import { QueryParams } from '../../../interfaces';
import { Coach } from '../../../interfaces/coach';
import DangerButton from '../../../shared/components/buttons/DangerButton';
import PrimaryButton from '../../../shared/components/buttons/PrimaryButton';
import MaterialRequestFilterForm from './Filter';
import MaterialRequestsTable from './MaterialRequestsTable';
import { PAGE_SIZE } from '../../../shared/constants';
import AddCircleOutlineIcon from '../../../shared/svgs/AddCircleOutlineIcon';

const MaterialRequestsPage: React.FC = () => {
  const queryClient = useQueryClient();
  const [queryParams, setQueryParams] = useState<QueryParams>({ start: 0, count: PAGE_SIZE });

  const { data, isLoading } = useQuery(['materialRequests', 'list', queryParams], () => getMaterialRequestsList(queryParams), {
    staleTime: Infinity,
  });

  const currentPage = queryParams.start ? Math.round((Number(queryParams.start) - 1) / PAGE_SIZE + 1) : 1;

  const pagination: any = {
    current: currentPage,
    pageSize: PAGE_SIZE,
    total: data?.total || 0,
    showTotal: (total: string) => `${total} Items`,
  };

  const handleFilter = (params: any) => {
    const currentPage = params.page;
    const offset = currentPage ? (currentPage === 1 ? 0 : (Number(currentPage) - 1) * PAGE_SIZE) : 0;
    setQueryParams({ start: Number(offset), count: PAGE_SIZE });
  };

  const { mutateAsync: mutationDelete } = useMutation(deleteMaterialRequest);

  const handleClickDelete = async (coachLeave: Coach) => {
    await mutationDelete(coachLeave.id);
    queryClient.invalidateQueries({
      queryKey: ['materialRequests', 'list'],
    });
  };

  const handleFilterSubmit = (values: any) => {
    setQueryParams({ start: 0, count: PAGE_SIZE, filter: values });
  };

  const handleResetFilter = () => {
    setQueryParams({ start: 0, count: PAGE_SIZE, filter: undefined });
  };

  const { mutateAsync: updateMutate } = useMutation(updateMaterialRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['materialRequests', 'list'],
      });
      message.info('Unblocked this coachLeave.');
    },
    onError: () => {
      message.error('Update coachLeave failed! Please try again');
    },
  });

  const handleUnblockCoach = async (values: any) => {
    const newValues: any = values;
    const id = values.id;
    delete values.blockedAt;
    newValues.blockedAt = 'null';
    updateMutate({ ...newValues, id });
  };

  const withAction = (record: any) => {
    return (
      <span className="action" style={{ display: 'flex' }}>
        <Dropdown.Button
          overlayClassName="table-action-menu"
          menu={{
            items: [
              {
                key: '1',
                label: (
                  <Link to={`/admin/materialRequests/${record?.id}`}>
                    <Button className="btn-table-action-primary" type="link">
                      View
                    </Button>
                  </Link>
                ),
              },
              {
                key: '2',
                label: (
                  <Link to={`/admin/materialRequests/${record?.id}/edit`}>
                    <Button className="btn-table-action-primary" type="link">
                      Edit
                    </Button>
                  </Link>
                ),
              },
              {
                key: '4',
                label: <DangerButton label="Delete" onOk={() => handleClickDelete(record)} />,
              },
            ],
          }}
        ></Dropdown.Button>
      </span>
    );
  };

  return (
    <div className="coaches-container">
      <div className="page-container">
      <div className="page-content-header">
          <h2 className="title-page">Material Request</h2>
        </div>
        <div className="page-header">
          <div></div>
          <div className="page-header-actions">
            <Link to="/admin/materialRequests/new">
              <PrimaryButton label="Create Material Request" icon={<AddCircleOutlineIcon />} />
            </Link>
          </div>
        </div>
        <div className="page-content">
          <MaterialRequestFilterForm handleFilter={handleFilterSubmit} handleReset={handleResetFilter} />
          <MaterialRequestsTable
            rowKey="id"
            loading={isLoading}
            pagination={pagination}
            materialRequest={data?.data}
            withAction={withAction}
            onChange2={handleFilter}
          />
        </div>
      </div>
    </div>
  );
};

export default MaterialRequestsPage;
