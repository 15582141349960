import React from 'react';
import TableBase, { Props as TableProps } from './Base';
import { DateFormat } from '../../../utils/global';
import { Student } from '../../../interfaces/student';
type Props = TableProps & {
  withAction?(_: any): void;
  students?: any[];
};

const MaterialsStudentsTable: React.FC<Props> = ({ students, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: 'name',
      width: 100,
      render: (student: Student) => {
        return (
          <span className="text-capitalize">
            {student?.student?.chineseName}
          </span>
        );
      },
    },
    {
      title: 'qty',
      width: 100,
      render: (student: Student) => {
        return (
          <span className="text-capitalize">
            {student?.qty}
          </span>
        );
      },
    },
    {
      title: 'createdAt',
      width: 100,
      render: (student: Student) => {
        return (
          <span className="text-capitalize">
            {student?.createdAt}
          </span>
        );
      },
    }
  ];

  // if (withAction) {
  //   COLUMNS.push({
  //     key: 'action',
  //     width: 50,
  //     fixed: 'right' as unknown as boolean,
  //     render(record: any) {
  //       return withAction(record);
  //     },
  //   } as any);
  // }
  return <TableBase dataSource={students} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default MaterialsStudentsTable;
