import { pathToUrl } from '../utils/router';
import { MaterialRequest } from '../interfaces/materialRequest';
import { GetListResponse } from '../interfaces';
import { useFetch, usePost } from '../shared/reactQuery';
import { api } from '../shared/api';
import { handleFilterParams } from '../utils/global';

export const useGetMaterialRequestsList = () => useFetch<GetListResponse>('materialRequests');

export const useGetMaterialRequest = (id: number) => useFetch<MaterialRequest>(`materialRequests/${id}`);

export const getMaterialRequestsList = async ({ start, count, filter }: { start?: number; count?: number; filter?: any }) => {
  const newParams = handleFilterParams({
    start,
    count,
    filter,
  });
  const { data: response }: any = await api.get(`materialRequests?${newParams}`);
  return response;
};

export const createMaterialRequest = async (values: MaterialRequest) => {
  const { data: response }: any = await api.post('materialRequests', values);
  return response.data;
};

export const updateMaterialRequest = async ({ id, ...values }: { id: string; values: MaterialRequest }) => {
  const { data: response }: any = await api.patch(`materialRequests/${id}`, values);
  return response.data;
};

export const deleteMaterialRequest = async (id: number) => {
  const { data: response }: any = await api.delete(`materialRequests/${id}`);
  return response.data;
};
