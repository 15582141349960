import React, { useState } from 'react';
import { getParentExpiredList, getParentAllList } from '../../../api/payments';
import { useGetProfile } from '../../../api/auth';
import { QueryParams } from '../../../interfaces';
import { Parent } from '../../../interfaces/parent';
import { Payment } from '../../../interfaces/payment';
import {  TOKEN_NAME } from '../../../shared/constants';
import Cookies from 'js-cookie'; 
import StudentsCardView from '../../../shared/components/cardViews/TuitionFee/StudentsCardView';
import { PAGE_SIZE } from '../../../shared/constants';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { paymentMake } from '../../../api/payments';

const TuitionFeesPage: React.FC = () => {
  const token = Cookies.get(TOKEN_NAME);
  const currentDate = new Date()
  currentDate.setHours(0, 0, 0, 0)
  const queryClient = useQueryClient();
  const todayDate = currentDate.setDate(currentDate.getDate()).toString()
  const [queryParams, setQueryParams] = useState<QueryParams>({ start: 0, count: PAGE_SIZE,filter:{todayDate} });

  const { data:ParentAllList } = useQuery(['payments', 'ParentAllList', queryParams], () => getParentAllList({}), {
    staleTime: Infinity,
  });
  
  const { data:ParentExpiredList, isLoading } = useQuery(['payments', 'ParentExpiredList', queryParams], () => getParentExpiredList(queryParams), {
    staleTime: Infinity,
  });


const { mutateAsync: mutationDone} = useMutation(paymentMake);


const handleClickPaid = async (values:any) => {
  await mutationDone({'id':values.parentId,values});
  //   queryClient.invalidateQueries({
  //     queryKey: ['payments', 'list'],
  //   });
};

  return (
    <><div id="user-details-container">
      <div className="page-container">
        <div className="page-content">
          <div className="page-content-header">
            <h2 className="title-page">Expired Soon</h2>
          </div>
          <div className="students-container">
            <div className="page-container" style={{ padding: '2px' }}>
              {ParentExpiredList?.data.map((parent: Parent) => (
                <StudentsCardView
                  parent={parent}
                  handleClickPaid={handleClickPaid} 
                  color={'#ff2c2c42'}
                  />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="user-details-container">
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page">All Parents</h2>
            </div>
            <div className="students-container">
              <div className="page-container" style={{ padding: '2px' }}>
                {ParentAllList?.data.map((parent: Parent) => (
                  <StudentsCardView
                    parent={parent}
                    handleClickPaid={handleClickPaid} 
                    color={'rgb(44 255 90 / 26%)'}
                    />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TuitionFeesPage;
