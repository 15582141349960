import React, { useState } from 'react';
import { Button, DatePicker, Form, Input, Modal, message } from 'antd';
import { MaterialIn } from '../../../../interfaces/materialIn';
import TableBase, { Props as TableProps } from '../../tables/Base';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { NumericFormat } from 'react-number-format';
import { getMaterialsList } from '../../../../api/materials';
import { useNavigate } from 'react-router-dom';
import { QueryParams } from '../../../../interfaces';
import {createMaterialIn,updateMaterialIn} from '../../../../api/materialIns';
import { PASSWORD_TIP, DATE_FORMAT_QUERY } from '../../../constants';
import { Link } from 'react-router-dom';
import CancelIcon from '../../../svgs/CancelIcon';
import CheckCircleIcon from '../../../svgs/CheckCircleIcon';
import PrimaryButton from '../../buttons/PrimaryButton';
import SecondaryButton from '../../buttons/SecondaryButton';
import { PAGE_SIZE } from '../../../../shared/constants';
import MaterialListing from '../../../../shared/components/modal/Student/MaterialListing';
import MaterialsInQtyForm from '../../../../shared/components/modal/Student/MaterialsInQtyForm';
import moment from 'moment';
const { TimePicker } = DatePicker;
type Props = TableProps & {
  studentId:number;
  handleSubmit(_: any): void;
  onCloseListing(_:boolean): void;
};


const MaterialsInForm: React.FC<Props> = ({ studentId, handleSubmit,onCloseListing, ...props }) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [modalData, setModalData] = useState(null);

  const [queryParams, setQueryParams] = useState<QueryParams>({ start: 0, count: PAGE_SIZE });

  const { data, isLoading } = useQuery(['materials', 'list', queryParams], () => getMaterialsList(queryParams), {
    staleTime: Infinity,
  });

  const [ isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const onCancel = () => {
    setIsModalVisible(false);
  };

  const onEditClick = (modalData:any) => {
    setModalData(modalData)
    setIsModalVisible(true);
  };

  const handleOnFinish = (values: MaterialIn) => {
    const newValues: any = { ...values };
    newValues.materialId=studentId
    newValues.quantity=Number(newValues.quantity)
    newValues.dateIn=Number( newValues.dateIn)
    createMutate(newValues);
  };

  const onDateSelect = (date: any) => {
    form.setFieldsValue({
      dateIn: moment(date, 'HH:mm:ss'),
    });
  };

  const { mutate: createMutate } = useMutation(createMaterialIn, {
    onSuccess: async () => {
      message.info('Create materialIns successfully!');
      queryClient.invalidateQueries({
        queryKey: ['materialIns', 'list'],
      });
      navigate(`/admin/materials`);
    },
    onError: () => {
      message.error('Create materialIns failed! Please try again');
    },
  });
// const navigate = useNavigate();

return (
  <div className="course-form-container">
     <MaterialListing
            studentId={Number(studentId)}
            dataSource={data?.data}
            modalClick={onEditClick}
          />

  <Modal
        visible={isModalVisible}
        onOk={onCancel}
        onCancel={onCancel}
        footer={null}
        width={350}
          >
            <MaterialsInQtyForm
                rowKey="id"
                studentId={Number(studentId)}
                modalData={modalData}
                onClose={onCancel}
                onCloseListing={onCloseListing}
                   />
      </Modal>
      </div>
);
};

export default MaterialsInForm;
function Float(price: any): any {
  throw new Error('Function not implemented.');
}

