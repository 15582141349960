import { Button, DatePicker, Form, Input, Select, Switch } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Course } from '../../../../interfaces/course';
import { Coach } from '../../../../interfaces/coach';
import { PASSWORD_TIP, TIME_FORMAT_QUERY } from '../../../constants';
import CancelIcon from '../../../svgs/CancelIcon';
import CheckCircleIcon from '../../../svgs/CheckCircleIcon';
import PrimaryButton from '../../buttons/PrimaryButton';
import SecondaryButton from '../../buttons/SecondaryButton';
import moment from 'moment';
import dayjs, { Dayjs } from 'dayjs';
const { TimePicker } = DatePicker;
type Props = {
  coachesList: Coach[];
  isCoachFetching?: boolean;
  isFetching?: boolean;
  course?: any;
  courseId?: number;
  handleSubmit(_: any): void;
};

const CourseForm: React.FC<Props> = ({ course, courseId, isFetching, coachesList , isCoachFetching, handleSubmit }) => {
  const [form] = Form.useForm();

  const handleOnFinish = (values: Course) => {
    const newValues: any = { ...values };
    // newValues.startAt=moment(values?.startAt).format('HH:mm:ss');
    // newValues.endAt=moment(values?.endAt).format('HH:mm:ss');
    newValues.startAt=dayjs(newValues?.startAt).format(TIME_FORMAT_QUERY) 
    newValues.endAt=dayjs(newValues?.endAt).format(TIME_FORMAT_QUERY) 
    handleSubmit(newValues);
  };

  useEffect(() => {
    if (course && course.id === courseId) {
      // const coachId= course.coach.firstName+ course.coach.lastName+'('+course.coach.email+')';
      form.setFieldsValue({
        ...course,
        // startTime: '2023-05-02T16:00:00.375Z',
        startAt: moment(course.startAt, 'HH:mm:ss'),
        endAt: moment(course.endAt, 'HH:mm:ss'),
        // coachId
      })
    }
  }, [course]);

  const onStartTimeSelect = (time: any) => {
    form.setFieldsValue({
      startAt: moment(time, 'HH:mm:ss'),
    });
  };

  const onEndTimeSelect = (time: any) => {
    form.setFieldsValue({
      endAt: moment(time, 'HH:mm:ss'),
    });
  };

  return (
    <div className="course-form-container">
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        onFinish={handleOnFinish}
        scrollToFirstError
        autoComplete="off"
        initialValues={{ isActive: true }}
      >
        <div className="form-container">
          <div className="form-content">
          <Form.Item
            label="Course Name"
            name= 'name'
            rules={[{ required: true, message: 'Required' }]}
            >
            <Input placeholder="Required" autoComplete="off" />
            </Form.Item>

            <Form.Item
                            label="Coach"
                            name= 'coachId'
                            rules={[{ required: true, message: 'Required' }]}
                          >
                            <Select
                              placeholder="Required"
                              showSearch
                              optionFilterProp="children"
                              loading={isCoachFetching}
                            >
                              {coachesList?.map((item: Coach) => (
                                <Select.Option key={item.id} value={item.id}>
                                  {item.firstName}{item.lastName}({item.email})
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            label="Assistant"
                            name= 'assistantId'
                          >
                            <Select
                              placeholder="Required"
                              showSearch
                              optionFilterProp="children"
                              loading={isCoachFetching}
                            >
                              {coachesList?.map((item: any) => (
                                <Select.Option key={item.id} value={item.id}>
                                  {item.firstName}{item.lastName}({item.email})
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>

            <Form.Item
              name="startAt"
              label="Course Start Time"
              rules={[{ required: true, message: 'Required' }]}
            >
              <TimePicker className="ant"  format={TIME_FORMAT_QUERY}  onChange={onStartTimeSelect} />
            </Form.Item>
            <Form.Item
              name="endAt"
              label="Course End Time"
              rules={[{ required: true, message: 'Required' }]}
            >
              <TimePicker className="ant" format={TIME_FORMAT_QUERY} onChange={onEndTimeSelect}/>
            </Form.Item>

            <Form.Item
            label="Day"
            name= 'day'
            rules={[{ required: true, message: 'Required' }]}
            >
            <Input placeholder="Required" autoComplete="off" />
            </Form.Item>

            <Form.Item label="Active" name="isActive" valuePropName="checked">
              <Switch />
            </Form.Item> 
          <div className="form-footer">
            <PrimaryButton
             loading={isFetching}
              label={courseId ? 'Update' : 'Create'}
              icon={<CheckCircleIcon />}
              htmlType="submit"
            />
            <Link to="/admin/courses">
              <SecondaryButton label={'Cancel'} icon={<CancelIcon />} />
            </Link>
          </div>
           
          </div> 
        </div>
      </Form>
    </div>
  );
};

export default CourseForm;
