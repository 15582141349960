import { message, Spin } from 'antd';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { DateFormat } from '../../../utils/global';
import { deleteParent, getParentsList, updateParent, useGetParent } from '../../../api/parents';
import DangerButton from '../../../shared/components/buttons/DangerButton';
import PrimaryButton from '../../../shared/components/buttons/PrimaryButton';
import SecondaryButton from '../../../shared/components/buttons/SecondaryButton';
import { PAGE_SIZE } from '../../../shared/constants';
import CancelIcon from '../../../shared/svgs/CancelIcon';
import DeleteForeverIcon from '../../../shared/svgs/DeleteForeverIcon';
import EditIcon from '../../../shared/svgs/EditIcon';
import PersonIcon from '../../../shared/svgs/PersonIcon';
import { Student } from '../../../interfaces/student';

const ParentDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { data: parent, isLoading } = useGetParent(Number(id));

  const { mutateAsync: mutationDelete } = useMutation(deleteParent, {
    onSuccess: async () => {
      message.info('Deleted!');
      queryClient.invalidateQueries({
        queryKey: ['parents', 'list'],
      });
      navigate(`/admin/parents`);
    },
    onError: () => {
      message.error('The action is failed! Please try again');
    },
    onSettled: () => {
      queryClient.prefetchQuery({
        queryKey: ['parents', 'list', { start: 0, count: PAGE_SIZE, filter: undefined }],
        queryFn: () => getParentsList({ start: 0, count: PAGE_SIZE, filter: undefined }),
      });
    },
  });

  const handleDelete = () => {
    mutationDelete(Number(id));
  };

  const { mutateAsync: updateMutate } = useMutation(updateParent, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['parents', 'list'],
      });
      queryClient.invalidateQueries({
        queryKey: [`parents/${id}`, undefined],
      });
      message.info('Unblocked this parent!');
    },
    onError: () => {
      message.error('The action is failed! Please try again');
    },
  });

  const handleUnblockUser = async () => {
    const newValues: any = parent;
    newValues.blockedAt = 'null';
    updateMutate({ ...newValues, id });
  };

  return (
    <Spin spinning={isLoading}>
      <div id="user-details-container">
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page">Parent Details</h2>
            </div>
            <div className="details-container">
              <div className="item">
                <label>Email</label>
                <span className="value">{parent?.email}</span>
              </div>
              <div className="item">
                <label>Name</label>
                <span className="value">
                  {parent?.firstName} {parent?.lastName} ({parent?.chineseName} )
                </span>
              </div>
              

              <div className="item">
                <label>Contact</label>
                <span className="value">
                {parent?.contact}
                </span>
              </div>
              <div className="item">
                <label>Address</label>
                <span className="value">
                {parent?.address}
                </span>
              </div>
              <div className="item">
                <label>Emergency Contact</label>
                <span className="value">
                {parent?.emergencyContact}
                </span>
              </div>
              <div className="item">
                <label>Emergency Name</label>
                <span className="value">
                {parent?.emergencyName}
                </span>
              </div>
              <div className="item">
                <label>Contact Relationship</label>
                <span className="value">
                {parent?.emergencyContactRelationship}
                </span>
              </div>

              <div className="item">
                <label>Status</label>
                <span className={`value status ${parent?.isActive ? 'active' : 'inactive'}`}>
                  {parent?.isActive ? 'Active' : 'Inactive'}
                </span>
              </div>
            </div>
            <div className="page-content-footer">
              {parent && parent.blockedAt !== null && (
                <PrimaryButton
                  icon={<PersonIcon />}
                  label="Unblock"
                  onClick={handleUnblockUser}
                  color="secondary-color"
                />
              )}
              <Link to={`/admin/parents/${id}/edit`}>
                <PrimaryButton label="Edit" icon={<EditIcon />} />
              </Link>
              <DangerButton label="Delete" onOk={handleDelete} icon={<DeleteForeverIcon />} />
              <Link to={`/admin/parents`}>
                <SecondaryButton label="Cancel" icon={<CancelIcon />} />
              </Link>
            </div>
          </div>

          <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page">Student Details</h2>
            </div>
            {parent?.students?.map((student: Student) => (
               <div className="page-content">
            <div className="details-container">
              <div className="item">
                <label>Student Name</label>
                <span className="value">{student?.firstName}{student?.lastName} ({student?.chineseName})</span>
              </div>
              <div className="item">
                <label>Email</label>
                <span className="value">
                  {student?.email}
                </span>
              </div>
              <div className="item">
                <label>Contact</label>
                <span className="value">
                {student?.contact}
                </span>
              </div>
              <div className="item">
                <label>Date Of Birth</label>
                <span className="value">
                {DateFormat(student?.dateOfBirth)}
                </span>
              </div>
              <div className="item">
                <label>Age</label>
                <span className="value">
                {student?.age}
                </span>
              </div>
              <div className="item">
                <label>Gender</label>
                <span className="value">
                {student?.gender}
                </span>
              </div>

              <div className="item">
                <label>Foundation</label>
                <span className="value">
                {student?.foundation}
                </span>
              </div>

              <div className="item">
                <label>Expectation</label>
                <span className="value">
                {student?.expectation}
                </span>
              </div>
            </div>
            </div>
             ))}

          </div>
        </div>
      </div>
    </Spin>
  );
};

export default ParentDetailsPage;
