import { Form  } from 'antd';
import React, { useEffect } from 'react';
import './Profile.scss';
import { DateFormat } from '../../../../utils/global';
import { Student } from '../../../../interfaces/student';
import { Props as TableProps } from './Base';
import '../../../../../src/mixins.scss';
import  "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";

type Props = TableProps & {
  student : any
};

const StudentDashboardView: React.FC<Props> = ({ student, ...props }) => {
// const CSS2 = CSS.Properties

  const [form] = Form.useForm();
  useEffect(() => {
    if (student) {
      form.setFieldsValue(student);
    }
  }, [student]);
      return (
      // <div className="card-row">
    <div className="col-sm-3"  style={{maxWidth:"100%", paddingRight:"0px", paddingLeft:"0px"}}>
    <div className="card" style={{width: '18rem', marginLeft:'10px'}}>
    <div className="card-body">
  {student?.gender?.toUpperCase() === "MALE" ? (
      <img src="/boy.png" alt="avatar"
              className="rounded-circle img-fluid" style={{width: '150px'}} />

    ):(
      <img src="/girl.png" alt="avatar"
              className="rounded-circle img-fluid" style={{width: '150px'}} />
    )
  }
   </div>
   <ul className="list-group list-group-flush">

   <h5 className="card-title" style={{textAlign: 'center'}} >{student?.chineseName}<br></br>{student?.firstName} {student?.lastName}</h5>
   <li className="list-group-item">{(student?.age) ? student?.age:'-'}</li>
   <li className="list-group-item">{DateFormat(student?.dateOfBirth)}</li>
   <li className="list-group-item">{student?.foundation}</li>
   <li className="list-group-item"> {student?.expectation} </li>
     <li className="list-group-item"> {student?.gender} </li>
      {/* <li className="list-group-item">{student.remark}</li> */}
    </ul>
      </div>
      </div>
      // </div>
      )
  }
export default StudentDashboardView;