import { Form, Input,DatePicker, Select, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { NumericFormat } from 'react-number-format';
import { convertDateTimeToNumber } from '../../../utils/global';
import { Link } from 'react-router-dom';
import { Material } from '../../../interfaces/material';
import { Student } from '../../../interfaces/student';
import { PASSWORD_TIP,DATE_FORMAT_FRONTEND,STATUS } from '../../../shared/constants';
import CancelIcon from '../../../shared/svgs/CancelIcon';
import CheckCircleIcon from '../../../shared//svgs/CheckCircleIcon';
import PrimaryButton from '../../../shared/components/buttons/PrimaryButton';
import SecondaryButton from '../../../shared/components/buttons/SecondaryButton';
import DocumentViewer from '../../../shared/components/Upload/DocumentViewer';
import { useGetMaterialsList } from '../../../api/materials';
import { useGetStudentsList } from '../../../api/students';
type Props = {
  isFetching?: boolean;
  materialRequestId?: number;
  materialRequest?: any;
  handleSubmit(_: any): void;
};

const MaterialRequestForm: React.FC<Props> = ({ materialRequest, materialRequestId, isFetching, handleSubmit }) => {
  const { data: materialList , isFetching: isMaterialFetching } = useGetMaterialsList()
  const { data: studentList , isFetching: isStudentFetching } = useGetStudentsList()
  const [password, setPassword] = useState<any>({});
  const [form] = Form.useForm();
  const passwordTips = PASSWORD_TIP;
  function validatePassword(value: string) {
    const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{12,})');
    if (value === '') {
      return {
        validateStatus: 'error',
        errorMsg: 'Required',
      };
    }

    if (!value || strongRegex.test(value)) {
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    }

    return {
      validateStatus: 'error',
      errorMsg: 'Your password too weak',
    };
  }

  const onPasswordChange = (event: any) => {
    const { value } = event.target;
    setPassword({ ...validatePassword(value), value });
  };

  const handleOnFinish = (values: Material) => {
    const newValues: any = values;
    if (materialRequestId) {
      delete newValues.password;
    }
    newValues.qty=Number(newValues.qty)
    newValues.date = convertDateTimeToNumber(dayjs(newValues.date))
    handleSubmit(newValues);
  };

  useEffect(() => {
    if (materialRequest && materialRequest.id === materialRequestId) {
      materialRequest.date = dayjs(materialRequest.date)
      form.setFieldsValue(materialRequest);
    }
  }, [materialRequest]);

  return (
    <div className="coach-form-container">
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        onFinish={handleOnFinish}
        scrollToFirstError
        autoComplete="off"
        initialValues={{ isActive: true }}
      >
        <div className="form-container">
          <div className="form-content">
          <Form.Item label="Material" name= 'materialId' rules={[{ required: true, message: 'Required' }]}>
            <Select placeholder="Required" showSearch optionFilterProp="children" loading={isMaterialFetching} >
                              {materialList?.data?.map((material: Material) => (
                                <Select.Option key={material.id} value={material.id}>
                                  {/* {<DocumentViewer files={material?.files} />}{material.name} */}
                                  <div className="item">
                                      <label>{material.name}</label>
                                      <div style={{width:'10%'}}>
                                        {<DocumentViewer files={material?.files} />}  
                                      </div>
                                    </div>
                                </Select.Option>
                              ))}
                            </Select>
           </Form.Item>
           <Form.Item label="Student" name= 'studentId' rules={[{ required: true, message: 'Required' }]}>
            <Select placeholder="Required" showSearch optionFilterProp="children" loading={isMaterialFetching} >
                              {studentList?.data?.map((student: Student) => (
                                <Select.Option key={student.id} value={student.id}>
                                  {student.chineseName}
                                </Select.Option>
                              ))}
                            </Select>
           </Form.Item>
           <Form.Item label="Quantity" name="qty">
              {/* <Input type='number' placeholder="Optional" defaultValue={1}/> */}
              <NumericFormat
                className="ant-input"
                placeholder="Required"
                thousandSeparator={true}
                decimalScale={0}
                fixedDecimalScale={true}
              />
            </Form.Item>
            <Form.Item label="Status" name= 'status' rules={[{ required: true, message: 'Required' }]}>
            <Select placeholder="Required" showSearch optionFilterProp="children" loading={isMaterialFetching} >
                              {STATUS.map((item: any, index: number) => (
                                <Select.Option key={index} value={item.name}>
                                  {item.name}
                                </Select.Option>
                              ))}
                            </Select>
           </Form.Item>
            <Form.Item label="IsPaid" name="isPaid" valuePropName="checked">
              <Switch />
            </Form.Item>
          </div>
          <div className="form-footer">
            <PrimaryButton
              loading={isFetching}
              label={materialRequestId ? 'Update' : 'Create'}
              icon={<CheckCircleIcon />}
              htmlType="submit"
            />
            <Link to="/admin/materialRequests">
              <SecondaryButton label={'Cancel'} icon={<CancelIcon />} />
            </Link>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default MaterialRequestForm;
