import { Spin,Button,Dropdown,Modal,message } from 'antd';
import './ClassWorksList.scss';
import { Props as TableProps } from './Base';
import { useGetAttendanceByStudent} from '../../../api/attendances';
import { useGetByStudentAttendances} from '../../../api/classWorks';
import "bootstrap-css-only/css/bootstrap.min.css";
import { useNavigate } from 'react-router-dom';
import { ClassWorks } from '../../../interfaces/classWorks';
import { File } from '../../../interfaces/file';
import AddCircleOutlineIcon from '../../../shared/svgs/AddCircleOutlineIcon';
import SecondaryButton from '../../../shared/components/buttons/SecondaryButton';
import htmr from 'htmr';
import {deleteStudent} from '../../../api/files';
import { useQueryClient,useMutation } from 'react-query';
import DocumentViewer from '../../../shared/components/Upload/DocumentViewer';
import ReactPlayer from 'react-player'
import CourseStudentsAddClassWorks from './modal/CourseStudentsAddClassWorks';
import {createClassWorks} from '../../../api/classWorks';
import CourseStudentsUpdateClassWorks from './modal/CourseStudentsUpdateClassWorks';
const videoType ='mp4'
import React, { useEffect, useState } from 'react';
type Props = TableProps & {
  classWorkInfo:any
};

const ClassWorksCardList: React.FC<Props> = ({classWorkInfo,...props }) => {
  const { data: dataclassWorks, isLoading } = useGetByStudentAttendances(Number(classWorkInfo.studentAttendanceId));
  const [ isCreateModalVisible , setIsCreateModalVisible] = useState<boolean>(false);
  const [ isEditModalVisible , setIsEditModalVisible] = useState<boolean>(false);
  const [modalData, setModalData] = useState(null);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutateAsync: mutationAddClassWorks} = useMutation(createClassWorks, {
    onSuccess: () => {
      queryClient.invalidateQueries({
      });
      message.info('Class Works Add completed.');
    },
    onError: () => {
      message.error('Update Class Works failed! Please try again');
    },
    onSettled: () => {
    },
  });
  
  const onEditClick = (modalData:any) => {
    setModalData(modalData)
    setIsEditModalVisible(true);
  };

  const handleAddClassWorks = async (values: any) => {
    await mutationAddClassWorks({ id: values.fileId, body: values});
    onCreateCancel()
  };

  const onCreateCancel = () => {
    setIsCreateModalVisible(false);
  };

  const onAddClassWorksClick = (modalData:any) =>   { 
    setModalData(modalData)
    setIsCreateModalVisible(true);
  };

  const handleDelete = (id: number) => {
    deleteStudent(id);
    window.location.reload();
    // queryClient.invalidateQueries({
    //   queryKey: [`attendances/${Number(classWorkInfo.attendancesId)}/studentId/${classWorkInfo.studentId}`, undefined],
    // });
    // navigate(`/admin/classWorks/${classWorkInfo.courseId}/classworksupdate?studentId=${classWorkInfo.studentId}`);
  };

  const onEditCancel = () => {
    setIsEditModalVisible(false);
  };



return (
        <>
      <div className='page-header'>
    <SecondaryButton label={'Add Class Works'} icon={<AddCircleOutlineIcon />}
      onClick={() => onAddClassWorksClick({
        'studentId': Number(classWorkInfo.studentId),
        'studentAttendanceId': Number(classWorkInfo.studentAttendanceId),
        'courseId': Number(classWorkInfo.courseId),
        'date': classWorkInfo.date
      })} />
  </div>
  
  <Spin spinning={isLoading}>
  {dataclassWorks?.id ?
      // dataclassWorks?.map((classWorks: ClassWorks) => (
          <div className="row" style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '0px', marginLeft: '0px' }}>
          <div className="col-sm-12" style={{ maxWidth: '100%' }}>
            {dataclassWorks?.files?.map((file: File) => (
              <div className="card" style={{ width: "100% !important", height: "100%  !important" }}>
                <Dropdown.Button style={{ display: 'flex', flexDirection: 'row-reverse' }}
                  menu={{
                    items: [
                      {
                        key: '1',
                        label: (
                          <Button className="btn-table-action-primary" type="link" onClick={() => onEditClick({ 'fileId': file.id, 'file': file, 'comment': file.comment })}>
                            Edit
                          </Button>
                        ),
                      },
                      {
                        key: '2',
                        label: <Button onClick={() => handleDelete(file.id)}>
                          Delete
                        </Button>,
                      },
                    ],
                  }}
                ></Dropdown.Button>
                <div className="card-body">
                {file.name.toUpperCase().split('.').includes(videoType.toUpperCase()) ?
                  (
                    <ReactPlayer loop={true} muted={true} controls={true} url={file.s3Url} />
                ) : (
                    <DocumentViewer  files={file} />)}
                    </div>
                <div className="card-body">
                  <p className="card-text" style={{ color: "#ffff" }}> {file && htmr(file.comment)}</p>
                </div>
              </div>

            ))}
          </div>
          <Modal
            visible={isEditModalVisible}
            onOk={onEditCancel}
            onCancel={onEditCancel}
            footer={null}
            width={700}
          >
            <CourseStudentsUpdateClassWorks
              fileId={Number(modalData)}
              file={modalData}
              handleCancel={onEditCancel} />
          </Modal>
          </div>
        //  ))
         : <p style={{color:"darkgrey"}}>No Data</p>}
         <Modal
              visible={isCreateModalVisible}
              onOk={onCreateCancel}
              onCancel={onCreateCancel}
             destroyOnClose={true}
              footer={null}
              width={1236}
            >
              <CourseStudentsAddClassWorks
                modalData={modalData}
                handleCancel={onCreateCancel}
                handleAddClassWorks={handleAddClassWorks}
              />
          </Modal>
  </Spin>
          
     </>
        );
  }
    export default ClassWorksCardList;