import { Spin,Button, Form, Input, Select, Switch } from 'antd';
import React, {Fragment, useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { Course } from '../../../../interfaces/course';
import { Student } from '../../../../interfaces/student';
import { TuitionDays } from '../../../../interfaces/tuitionDays';
import { useQuery } from 'react-query';
import { ClassReplaces } from '../../../../interfaces/classReplaces';
import CancelIcon from '../../../../shared/svgs/CancelIcon';
import CheckCircleIcon from '../../../../shared/svgs/CheckCircleIcon';
import  DeleteForeverIcon from '../../../../shared/svgs/DeleteForeverIcon';
import PrimaryButton from '../../../../shared/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../shared/components/buttons/SecondaryButton';
import { useGetStudentsList} from '../../../../api/students';
import { useGetCoursesList} from '../../../../api/courses';
import { getTuitionDaysList} from '../../../../api/tuitionDays';
import { QueryParams } from '../../../../interfaces';
import { PAGE_SIZE } from '../../../../shared/constants';
type Props = {
  classReplacesId?: number;
  handleSubmit(_: any): void;
  data?:ClassReplaces;
};

const ClassReplacesForm: React.FC<Props> = ({ handleSubmit,classReplacesId,data }) => {
  const [queryParams, setQueryParams] = useState<QueryParams>({ start: 0, count: PAGE_SIZE });
  const [listOfCourses, setListOfCourses] = useState<any>({});
  const [listOfTuitionDays, setListOfTuitionDays] = useState<any>({});
  const [stateSelectedStudent, setselectedStudent] = useState<any>({});
  
  const { data: studnetListing, isLoading:isStudentListingLoading } = useGetStudentsList();
  const { data: courseListing, isLoading:isCourseListingLoading } = useGetCoursesList();
  const { data: tuitionDayListing,isLoading:isTuitionDaysLoading } = useQuery(['tuitionDays', 'list', queryParams], () => getTuitionDaysList(queryParams), {
    staleTime: Infinity,
  });


  
  const [form] = Form.useForm();
  const handleOnFinish = (values: ClassReplaces) => {
    const newValues: any = { ...values };
    handleSubmit(newValues);
  };

  const onStudentChange = (value: any) => {
    const selectedStudent = studnetListing?.data?.filter((student: Student) => student.id === value);
    setselectedStudent(selectedStudent);
    const selectedStudentCourseDay = selectedStudent[0]?.courses?.map((course: Course)=>(course?.day));
    const tuitionDayOfListing = tuitionDayListing?.data?.filter((tuitionDay: TuitionDays) => selectedStudentCourseDay.includes(tuitionDay.day));
    setListOfTuitionDays(tuitionDayOfListing);
};

  const onReplaceClassChange = (value: any,event:any) => {
    const courseOfListing = courseListing?.data?.filter((course: Course) => course.day === event.label);
    setListOfCourses(courseOfListing);
};
  useEffect(() => {
    if (data && data.id === classReplacesId && studnetListing && courseListing && tuitionDayListing) {
      const selectedStudent = studnetListing?.data?.filter((student: Student) => student.id === data?.studentId);
    setselectedStudent(selectedStudent);
    const selectedStudentCourseDay = selectedStudent[0]?.courses?.map((course: Course)=>(course?.day));
    const tuitionDayOfListing = tuitionDayListing?.data?.filter((tuitionDay: TuitionDays) => selectedStudentCourseDay.includes(tuitionDay.day));
    setListOfTuitionDays(tuitionDayOfListing);
    setListOfCourses(courseListing?.data);
      form.setFieldsValue({
        ...data,
      })
    }
    // else{
    //   navigate(`/admin/classReplaces`);
    // }
  }, [data]);

  return (
    <Spin spinning={isTuitionDaysLoading} >
    <div className="course-form-container">
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        onFinish={handleOnFinish}
        scrollToFirstError
        autoComplete="off"
        initialValues={{ isActive: true }}
      >
        <div className="form-container">
          <div className="form-content">
          <Form.Item label="Student" name= 'studentId' rules={[{ required: true, message: 'Required' }]}>
            <Select placeholder="Required" showSearch optionFilterProp="children" loading={isStudentListingLoading}  
             onChange={(value)=>onStudentChange(value)}
             >
                              {studnetListing?.data?.map((student: Student) => (
                                <Select.Option  key={student?.id}  value={student.id}>
                                  {student.chineseName}
                                </Select.Option>
                              ))}
                            </Select>
           </Form.Item>

           <Form.Item label="Absent Class" name='absentClassId' rules={[{ required: true, message: 'Required' }]}>
            <Select placeholder="Required" showSearch optionFilterProp="children" loading={isTuitionDaysLoading} >
                                  {Object.values(listOfTuitionDays).map((tuitionDays: any) => (
                                <Select.Option key={tuitionDays.id} value={tuitionDays.id}>
                                  {tuitionDays.date} [{tuitionDays.day}]
                                </Select.Option>
                              ))}
                            </Select>
           </Form.Item>

           <Form.Item label="Replace Class" name= 'replaceClassId' >
            <Select showSearch optionFilterProp="children" loading={isTuitionDaysLoading}  
             onSelect={(value, event) => onReplaceClassChange(value, event)}>
                             {/* {Object.values(listOfTuitionDays).map((tuitionDays: any) => ( */}
                              {tuitionDayListing?.data.map((tuitionDays: any) => (
                                <Select.Option key={tuitionDays.id} label={tuitionDays.day} value={tuitionDays.id}>
                              {tuitionDays.date} [{tuitionDays.day}]
                                </Select.Option>
                              ))}
                            </Select>
           </Form.Item>

           <Form.Item label="Replace time" name= 'replaceCourseId'>
            <Select  showSearch optionFilterProp="children" loading={isTuitionDaysLoading} >
                              {/* {courseListing?.data?.map((course: Course) => ( */}
                          {Object.values(listOfCourses).map((course: any) => (
                                <Select.Option key={course.id} value={course.id}>
                              {/* {course.startAt.substring(0,5)}-{course?.endAt.substring(0, 5)}  */}
                              [{course.day}] {course.name} {course.startAt}-{course.endAt}
                                </Select.Option>
                              ))}
                            </Select>
           </Form.Item>


            <Form.Item
            label="Remarks"
            name= 'Remarks'
            >
            <Input  autoComplete="off" />
            </Form.Item>

            <Form.Item label="Done" name="isDone" valuePropName="checked">
              <Switch />
            </Form.Item> 

          <div className="form-footer">
            <PrimaryButton
             loading={isStudentListingLoading}
              label={classReplacesId ? 'Update' : 'Create'}
              icon={<CheckCircleIcon />}
              htmlType="submit"
            />
            <Link to="/admin/classReplaces">
              <SecondaryButton label={'Cancel'} icon={<CancelIcon />} />
            </Link>
          </div>
           
          </div> 
        </div>
      </Form>
    </div>
    </Spin>
  );
};

export default ClassReplacesForm;
