import { Button,message,Dropdown, Spin, Modal,Collapse } from 'antd';
import React, {useState} from 'react';
import './Collapse.scss';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { deleteMaterial, getMaterialsList, updateMaterial, useGetMaterial } from '../../../api/materials';
import PrimaryButton from '../../../shared/components/buttons/PrimaryButton';
import DangerButton from '../../../shared/components/buttons/DangerButton';
import DocumentViewer from '../../../shared/components/Upload/DocumentViewer';
import DeleteForeverIcon from '../../../shared/svgs/DeleteForeverIcon';
import EditIcon from '../../../shared/svgs/EditIcon';
import CancelIcon from '../../../shared/svgs/CancelIcon';
import SecondaryButton from '../../../shared/components/buttons/SecondaryButton';
import { PAGE_SIZE } from '../../../shared/constants';
import { Material } from '../../../interfaces/material';
import AddCircleOutlineIcon from '../../../shared/svgs/AddCircleOutlineIcon';
import MaterialsTransactionsTable from '../../../shared/components/tables/MaterialsTransactions';
import MaterialsChildsTable from '../../../shared/components/tables/MaterialsChilds';
import MaterialsInForm from '../../../shared/components/modal/MaterialsInForm';
import MaterialsStudentsTable from '../../../shared/components/tables/MaterialsStudents';

import MaterialChildslist from '../../../shared/components/tables/Materials/MaterialChilds';

const { Panel } = Collapse;

const MaterialDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: material, isLoading } = useGetMaterial(Number(id));
  // const { data: materialInList } = useGetMaterialIn(Number(id));

  const MaterialchildsIds = material?.childrens.map((children) => Number(children.id))
  const [ isMaterialTransModalVisible, setIsMaterialTransModalVisible] = useState<boolean>(false);
  const [ isMaterialChildsModalVisible, setIsMaterialChildsModalVisible] = useState<boolean>(false);

  const onMaterialChildsCancel = () => {
    setIsMaterialChildsModalVisible(false);
  };


  const onAddMaterialChildsClick = () => {
    setIsMaterialChildsModalVisible(true);
  };

  const onMaterialTransCancel = () => {
    setIsMaterialTransModalVisible(false);
  };


  const onAddMaterialTransClick = () => {
    setIsMaterialTransModalVisible(true);
  };


  

  const { mutateAsync: mutationDelete } = useMutation(deleteMaterial, {
    onSuccess: async () => {
      message.info('Deleted!');
      queryClient.invalidateQueries({
        queryKey: ['materials', 'list'],
      });
      navigate(`/admin/materials`);
    },
    onError: () => {
      message.error('The action is failed! Please try again');
    },
    onSettled: () => {
      queryClient.prefetchQuery({
        queryKey: ['materials', 'list', { start: 0, count: PAGE_SIZE, filter: undefined }],
        queryFn: () => getMaterialsList({ start: 0, count: PAGE_SIZE, filter: undefined }),
      });
    },
  });

  const withAction = (record: any) => {
    return (
      <span className="action" style={{ display: 'flex' }}>
        <Dropdown.Button
          overlayClassName="table-action-menu"
          menu={{
            items: [
              {
                key: '1',
                label: (
                  <Link to={`/admin/users/${record?.id}`}>
                    <Button className="btn-table-action-primary" type="link">
                      View
                    </Button>
                  </Link>
                ),
              },
              {
                key: '4',
                label: <DangerButton label="Delete" onOk={() => handleDelete()} />,
              },
            ],
          }}
        ></Dropdown.Button>
      </span>
    );
  };

  const handleDelete = () => {
    mutationDelete(Number(id));
  };

  // const { mutateAsync: mutationUpdate } = useMutation(deleteStudentFromCourse);

  const handleClickDelete = async (courseId : number , studentId : number) => {
    // await deleteStudentFromCourse(courseId,studentId);
    // queryClient.invalidateQueries({
    //   queryKey: ['students', 'list'],
    // });
  };

  return (
    <Spin spinning={isLoading}>
  <div id="user-details-container">
    <div className="page-container">
      <div className="page-content">
        <div className="page-content-header">
          <h2 className="title-page">Matertial Details</h2>
        </div>
        <div className="page-header">
          <div></div> 
        </div>
        <div className="details-container">
          <div className="item">
            <label>Name</label>
            <span className="value">{material?.name}</span>
          </div>
          <div className="item">
            <label>Supplier Source</label>
            <span className="value">
              {material?.supplierSource}
            </span>
          </div>
          <div className="item">
            <label>Supplir Price</label>
            <span className="value">
              {material?.supplirPrice} 
            </span>
          </div>
          <div className="item">
            <label>Supplir Price</label>
            <span className="value">
              {material?.sellingPrice} 
            </span>
          </div>
          <div className="item">
            <label>Quantity</label>
            <span className="value">
              {material?.qty} 
            </span>
          </div>
          <div className="item">
            <label>Remark</label>
            <span className="value">
              {material?.remark} 
            </span>
          </div>
          <div className="item">
                  <label>Files/Images</label>
                  <span className="value">
                {material && <DocumentViewer files={material?.files} />}
            </span>
          </div>
        </div>
        <div className="page-content-footer" style={{paddingBottom:'10px'}}> 
              <Link to={`/admin/materials/${id}/edit`}>
                <PrimaryButton label="Edit" icon={<EditIcon />} />
              </Link>
              <DangerButton label="Delete" onOk={handleDelete} icon={<DeleteForeverIcon />} />
              <Link to={`/admin/materials`}>
                <SecondaryButton label="Cancel" icon={<CancelIcon />} />
              </Link>
            </div>

            <><Collapse expandIconPosition="start" style={{color:'#939ca3 !important'  ,backgroundColor:'#23395d', alignItems:'self-end',fontSize:'large', borderRadius: '36px'}}>
              <Panel  className="ant-collapse-header-text" header='Materials children list' key="1" style={{color:'#939ca3 !important'}} >
              <div className="page-header-actions" style={{flexDirection:'row-reverse',paddingBottom:'10px'}}>
                  <SecondaryButton label={'Add Child Material'} icon={<AddCircleOutlineIcon />} onClick={onAddMaterialChildsClick}  />
                    </div>
                    <MaterialsChildsTable
                      withAction={withAction}
                      materialChild={material?.childrens}
                    />
              </Panel>
            </Collapse><div style={{paddingTop:'10px'}}></div></>

            <><Collapse expandIconPosition="start" style={{color:'#939ca3',backgroundColor:'#23395d', alignItems:'self-end',fontSize:'large', borderRadius: '36px'}}>
              <Panel className="ant-collapse-header-text" header='Materials In List' style={{color:'#939ca3'}} key="2" >
                <div className="page-header-actions" style={{flexDirection:'row-reverse',paddingBottom:'10px'}}>
                  <SecondaryButton label={'Add Material'} icon={<AddCircleOutlineIcon />} onClick={onAddMaterialTransClick}  />
                    </div>
                      <MaterialsTransactionsTable
                      withAction={withAction}
                      materialIns={material?.materialIns}
                    />
              </Panel>
            </Collapse><div style={{paddingTop:'10px'}}></div></>

            <><Collapse expandIconPosition="start" style={{color:'#939ca3',backgroundColor:'#23395d', alignItems:'self-end',fontSize:'large', borderRadius: '36px'}}>
              <Panel className="ant-collapse-header-text" header='Student Taken' style={{color:'#939ca3'}} key="2" >
                <div className="page-header-actions" style={{flexDirection:'row-reverse',paddingBottom:'10px'}}>
                    </div>
                      <MaterialsStudentsTable
                      students={material?.students}
                    />
              </Panel>
            </Collapse><div style={{paddingTop:'10px'}}></div></>
      <Modal
        visible={isMaterialTransModalVisible}
        onOk={onMaterialTransCancel}
        onCancel={onMaterialTransCancel}
        footer={null}
        width={536}
          >
            <MaterialsInForm
                rowKey="id"
                MaterialId={Number(id)}
                handleSubmit={withAction}
                onMaterialTransCancel={onMaterialTransCancel}
                   />
                    
      </Modal>

      <Modal
        visible={isMaterialChildsModalVisible}
        onOk={onMaterialChildsCancel}
        onCancel={onMaterialChildsCancel}
        footer={null}
        width={536}
          >
            <MaterialChildslist
                onMaterialChildsCancel={onMaterialChildsCancel}
                materialChilds={MaterialchildsIds}
                materialId={Number(material?.id)}
                   />
      </Modal>
      </div>
    </div>
  </div>
</Spin>
);
};

export default MaterialDetailsPage;
