export { default as LoginPage } from './auth/LoginPage';
export { default as LoginParentPage } from './auth/LoginParentPage';
export { default as LoginCoachPage } from './auth/LoginCoachPage';
export { default as ForgotPasswordPage } from './auth/ForgotPasswordPage';
export { default as ResetPasswordPage } from './auth/ResetPassword';
export { default as LogoutPage } from './auth/LogoutPage';
export { default as E404Page } from './E404';
export { default as E403Page } from './E403';
export { default as UsersPage } from './admin/user/Users';
export { default as UserNewPage } from './admin/user/UserNew';
export { default as UserDetailsPage } from './admin/user/UserDetails';
export { default as UserUpdatePage } from './admin/user/UserUpdate';
export { default as StudentsPage } from './admin/student/Students';
export { default as StudentNewPage } from './admin/student/StudentNew';
export { default as StudentDetailsPage } from './admin/student/StudentDetails';
export { default as StudentUpdatePage } from './admin/student/StudentUpdate';
export { default as CoursesPage } from './admin/course/Courses';
export { default as CourseNewPage } from './admin/course/CourseNew';
export { default as CourseDetailsPage } from './admin/course/CourseDetails';
export { default as CourseUpdatePage } from './admin/course/CourseUpdate';
export { default as ParentsPage } from './admin/parent/Parents';
export { default as ParentNewPage } from './admin/parent/ParentNew';
export { default as ParentDetailsPage } from './admin/parent/ParentDetails';
export { default as ParentUpdatePage } from './admin/parent/ParentUpdate';
export { default as CoachesPage } from './admin/coach/Coaches';
export { default as CoachNewPage } from './admin/coach/CoachNew';
export { default as CoachDetailsPage } from './admin/coach/CoachDetails';
export { default as CoachUpdatePage } from './admin/coach/CoachUpdate';

export { default as CoachLeavesPage } from './admin/coachLeave/CoachLeaves';
export { default as CoachLeaveNewPage } from './admin/coachLeave/CoachLeaveNew';
export { default as CoachLeaveDetailsPage } from './admin/coachLeave/CoachLeaveDetails';
export { default as CoachLeaveUpdatePage } from './admin/coachLeave/CoachLeaveUpdate';

export { default as AttendancesPage } from './admin/attendances/Attendances';
export { default as AttendancesNewPage } from './admin/attendances/AttendancesNew';
export { default as AttendancesDetailsPage } from './admin/attendances/AttendancesDetails';
export { default as AttendancesUpdatePage } from './admin/attendances/AttendancesUpdate';

export { default as DashboardPage } from './admin/dashboard/Dashboard';
export { default as ParentsDashboardPage } from './admin/parentsDashboard/ParentsDashboard';
export { default as ClassReplacesPage } from './admin/classReplaces/ClassReplaces';
export { default as ClassReplacementNewPage } from './admin/classReplaces/ClassReplacesNew';
export { default as ClassReplacesDetailsPage } from './admin/classReplaces/ClassReplacesDetails';
export { default as ClassReplacesUpdatePage } from './admin/classReplaces/ClassReplacesUpdate';

export { default as ClassWorksCoursesPage } from './admin/classWorks/Courses';
export { default as ClassWorksCourseAttendancesPage } from './admin/classWorks/CourseAttendances';
export { default as ClassWorksCourseClassWorksPage } from './admin/classWorks/CourseClassWorks';
export { default as ClassWorksCourseClassWorksUpdatePage } from './admin/classWorks/CourseClassWorksUpdate';

export { default as PaymentsPage } from './admin/payment/Payments';
export { default as PaymentNewPage } from './admin/payment/PaymentNew';
export { default as PaymentUpdatePage } from './admin/payment/PaymentUpdate';
export { default as PaymentDetailsPage } from './admin/payment/PaymentDetails';

export { default as PayablesPage } from './admin/payable/Payables';
export { default as PayableNewPage } from './admin/payable/PayableNew';
export { default as PayableUpdatePage } from './admin/payable/PayableUpdate';
export { default as PayableDetailsPage } from './admin/payable/PayableDetails';

export { default as PayrollsPage } from './admin/payroll/Payrolls';
export { default as PayrollNewPage } from './admin/payroll/PayrollNew';
export { default as PayrollUpdatePage } from './admin/payroll/PayrollUpdate';
export { default as PayrollDetailsPage } from './admin/payroll/PayrollDetails';

export { default as PaymentsExpiredSoonPage } from './admin/paymentExpiredSoon/Payments';
export { default as PaymentExpiredSoonDetailsPage } from './admin/paymentExpiredSoon/PaymentDetails';


export { default as PaymentReceiptReady } from './admin/paymentReceiptReady/Payments';

export { default as MaterialsPage } from './admin/material/Material';
export { default as MaterialNewPage } from './admin/material/MaterialNew';
export { default as MaterialDetailsPage } from './admin/material/MaterialDetails';
export { default as MaterialUpdatePage } from './admin/material/MaterialUpdate';

export { default as MaterialRequestsPage } from './admin/materialRequest/MaterialRequests';
export { default as MaterialRequestNewPage } from './admin/materialRequest/MaterialRequestNew';
export { default as MaterialRequestDetailsPage } from './admin/materialRequest/MaterialRequestDetails';
export { default as MaterialRequestUpdatePage } from './admin/materialRequest/MaterialRequestUpdate';

export { default as CourseMaterialsPage } from './admin/courseMaterial/CourseMaterial';
export { default as CourseMaterialNewPage } from './admin/courseMaterial/CourseMaterialNew';
export { default as CourseMaterialDetailsPage } from './admin/courseMaterial/CourseMaterialDetails';
export { default as CourseMaterialUpdatePage } from './admin/courseMaterial/CourseMaterialUpdate';

export { default as TuitionFeesPage } from './admin/tuitionFee/TuitionFee';
export { default as TuitionDaysPage } from './admin/tuitionDays/TuitionDays';

export { default as ParentClassWorksPage } from './parent/classWorks/classWorks';
// export { default as ParentStudentDetailsPage } from './parent/classWorks/StudentDetails';
export { default as ParentDashboardPage } from './parent/dashboard/Dashboard';
export { default as ParentProfilePage } from './parent/profile/Profile';
export { default as ParentAttendancesPage } from './parent/classWorks/Attendances';

export { default as ParentReceiptsPage } from './parent/receipt/Receipts';



export { default as CoachDashboardPage } from './coach/dashboard/Dashboard';
export { default as CoachesCoachLeavesPage } from './coach/coachLeave/CoachLeaves';
export { default as CoachesCoachLeaveNewPage } from './coach/coachLeave/CoachLeaveNew';
export { default as CoachesCoachLeaveDetailsPage } from './coach/coachLeave/CoachLeaveDetails';
export { default as CoachesCoachLeaveUpdatePage } from './coach/coachLeave/CoachLeaveUpdate';