import React from 'react';
import { Button, message, Spin,Modal,Dropdown } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { deleteStudentMaterial } from '../../../../api/studentMaterials';
import TableBase, { Props as TableProps } from './Base';
import { DateFormat } from '../../../../utils/global';
import { Material } from '../../../../interfaces/material';
import DocumentViewer from '../../../../shared/components/Upload/DocumentViewer';
import DangerButton from '../../../../shared/components/buttons/DangerButton';
import { useGetStudent } from '../../../../api/students';
type Props = TableProps & {
  studentId:any
};

const MaterialsTakenListing: React.FC<Props> = ({ studentId, ...props }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { data: student, isLoading } = useGetStudent(Number(studentId));
const materials=student?.materials
  const handleDelete = (StudentMaterials:any) => {
    mutationDelete(Number(StudentMaterials.id))
  };

  const withAction = (record: any) => {
    return (
      <span className="action" style={{ display: 'flex' }}>
        <Dropdown.Button
          overlayClassName="table-action-menu"
          menu={{
            items: [
              {
                key: '4',
                label: <DangerButton label="Delete" onOk={() => handleDelete(record)} />,
              },
            ],
          }}
        ></Dropdown.Button>
      </span>
    );
  };

  const { mutateAsync: mutationDelete } = useMutation(deleteStudentMaterial, {
    onSuccess: async () => {
      message.info('Deleted!');
      queryClient.invalidateQueries({
        queryKey: [`students/${studentId}`, undefined],
      });
      navigate(`/admin/students/${studentId}`);
    },
    onError: () => {
      message.error('The action is failed! Please try again');
    },
    onSettled: () => {
      queryClient.prefetchQuery({
        // queryKey: ['students', 'list', { start: 0, count: PAGE_SIZE, filter: undefined }],
        // queryFn: () => getStudentsList({ start: 0, count: PAGE_SIZE, filter: undefined }),
      });
    },
  });
  
  const COLUMNS = [
    {
      title: 'Image',
      width: 50,
      render: (materials: any) => {
        return (
          <span className="value" style={{width: '150px'}}>
                {materials && <DocumentViewer files={materials?.material.files} />}
            </span>
        );
      },
    },
    {
      title: 'name',
      width: 100,
      render: (materials: any) => {
        return (
          <span className="text-capitalize">
            {materials?.material.name}
          </span>
        );
      },
    },
    {
      title: 'Qty',
      width: 50,
      render: (materials: any) => {
        return (
          <span className="text-capitalize">
            {materials?.qty}
          </span>
        );
      },
    },
    {
      title: 'Date',
      width: 50,
      render: (materials: any) => {
        return (
          <span className="text-capitalize">
            {materials?.createdAt}
          </span>
        );
      },
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 50,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={materials} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default MaterialsTakenListing;
