import { Form, Input, Select, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Parent } from '../../../../interfaces/parent';
import { PASSWORD_TIP } from '../../../constants';
import CancelIcon from '../../../svgs/CancelIcon';
import CheckCircleIcon from '../../../svgs/CheckCircleIcon';
import PrimaryButton from '../../buttons/PrimaryButton';
import SecondaryButton from '../../buttons/SecondaryButton';

type Props = {
  isFetching?: boolean;
  parentId?: number;
  parent?: any;
  handleSubmit(_: any): void;
};

const ParentForm: React.FC<Props> = ({ parent, parentId, isFetching, handleSubmit }) => {
  const [form] = Form.useForm();

  const handleOnFinish = (values: Parent) => {
    const newValues: any = values;
    if (parentId) {
      delete newValues.password;
    }
    handleSubmit(newValues);
  };

  useEffect(() => {
    if (parent && parent.id === parentId) {
      form.setFieldsValue(parent);
    }
  }, [parent]);

  return (
    <div className="parent-form-container">
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        onFinish={handleOnFinish}
        scrollToFirstError
        autoComplete="off"
        initialValues={{ isActive: true }}
      >
        <div className="form-container">
          <div className="form-content">
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { type: 'email', message: 'Invalid Email' },
                { required: true, message: 'Required' },
              ]}
            >
              <Input type="email" placeholder="Required" autoComplete="off" />
            </Form.Item>
            <Form.Item label="First Name" name="firstName">
              <Input placeholder="Optional" />
            </Form.Item>
            <Form.Item label="Last Name" name="lastName">
              <Input placeholder="Optional" />
            </Form.Item>
            <Form.Item label="Chinese Name" name="chineseName">
              <Input placeholder="Optional" />
            </Form.Item>
            <Form.Item label="Contact" name="contact">
              <Input placeholder="Optional" />
            </Form.Item>
            <Form.Item label="Address" name="address">
              <Input placeholder="Optional" />
            </Form.Item>
           
            <Form.Item label="Emergency Contact" name="emergencyContact">
              <Input placeholder="Optional" />
            </Form.Item>
            <Form.Item label="Emergency Name" name="emergencyName">
              <Input placeholder="Optional" />
            </Form.Item>
            <Form.Item label="Contact Relationship" name="emergencyContactRelationship">
              <Input placeholder="Optional" />
            </Form.Item>
            
            <Form.Item label="Active" name="isActive" valuePropName="checked">
              <Switch />
            </Form.Item>
          </div>
          <div className="form-footer">
            <PrimaryButton
              loading={isFetching}
              label={parentId ? 'Update1' : 'Create'}
              icon={<CheckCircleIcon />}
              htmlType="submit"
            />
            <Link to="/admin/parents">
              <SecondaryButton label={'Cancel'} icon={<CancelIcon />} />
            </Link>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ParentForm;
