import { Collapse, Button,message,Modal } from 'antd';
import React, { useState,useEffect } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useQuery, useQueryClient,useMutation } from 'react-query';
import {useGetAttendanceByToday } from '../../../api/attendances';
// import {updateStudentAttendances } from '../../../api/studentAttendances';
import {useNavigate } from 'react-router-dom';
import { useGetProfile } from '../../../api/auth';
import { QueryParams } from '../../../interfaces';
import { Course } from '../../../interfaces/course';
import { Attendances } from '../../../interfaces/attendances';
import { StudentAttendances } from '../../../interfaces/studentAttendances';
import { Student } from '../../../interfaces/student';
import {  TOKEN_NAME } from '../../../shared/constants';
import Cookies from 'js-cookie'; 
import dayjs, { Dayjs } from 'dayjs';
import StudentsCardView from '../../../shared/components/cardViews/Student/StudentsCardView';
import { PAGE_SIZE } from '../../../shared/constants';
import { updateStudentAttendances } from '../../../api/studentAttendances';
const { Panel } = Collapse;
const attendancesButtonColor : any= {attended:"rgb(75 35 93)",absent:"#23395d",done:"rgb(35 93 55)",finish:"grey"}

const DashboardPage: React.FC = () => {  
  const token = Cookies.get(TOKEN_NAME);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [queryParams, setQueryParams] = useState<QueryParams>({ start: 0, count: PAGE_SIZE ,filter:{isActive:1}});
  const { data:AttendanceByTodayList, isLoading } = useQuery(['AttendanceByToday', 'AttendanceByTodayList'], () => useGetAttendanceByToday({}), {
    staleTime: Infinity,
  });
  // const [data, setAttendances] = React.useState<any>(useGetAttendanceByToday({}));
  // const [url, setUrl] = useState("");
  const updateOnClick = async (id:number,status:string) => {
    const newValues : any = {status}
    updateMutate({...newValues,id});
  };

  function showConfirm(id:number,status:string,name:string) {
    const { confirm } = Modal;
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: `confirm mark ${name} attendances`,
      onOk() {
        updateOnClick(id,status);
      },
      onCancel() {
      },
    });
  }

  const { mutateAsync: updateMutate } = useMutation(updateStudentAttendances, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        // queryKey: ['attendances/todayAttendances', 'AttendanceByTodayList'],
      });
      message.info('Attendance update completed.');
      // navigate(`/admin/dashboard`);
    },
    onError: () => {
      message.error('Update Attendance failed! Please try again');
    },
    onSettled: () => {
      // queryClient.prefetchQuery({
      //   queryKey: ['attendances/todayAttendances', 'AttendanceByTodayList', {}],
      //   queryFn: () => useGetAttendanceByToday({}),
      // });
      // navigate(`/admin/dashboard`);
    },
  });
  return (
    <div className="students-container">
    <div className="page-container" style={{padding:'2px'}}>
            {AttendanceByTodayList?.map((attendances: Attendances) => (
               <><Collapse   style={{color:'#939ca3 !important', textAlign: "center",backgroundColor:'#23395d', alignItems:'self-end',fontSize:'large', borderRadius: '36px'}} expandIconPosition="end" >
               <Panel header={attendances.date + ' ('+ attendances.course?.day + ') <'+ attendances.startAt.substring(0, 5)+ '-' + attendances.endAt.substring(0, 5) + '>'} key={attendances.id} className="collapse-custom-header" >
                       <div className='ant-collapse-content-box' style={{display:"flex", flexDirection: "column"}}>
                       {attendances?.studentAttendances?.map((studentAttendances: StudentAttendances) => (
                          <Button onClick={() => showConfirm(studentAttendances.id,studentAttendances.status,studentAttendances.students.chineseName)}
                          style={{lineHeight: "50px",marginBottom:"10px",borderRadius: "15px",backgroundColor:attendancesButtonColor[`${studentAttendances.status}`], color:"#ffff", fontSize: "22px"}}>
                                { studentAttendances.students.chineseName}({studentAttendances.status})
                          </Button>
                          
                              ))}
                         </div>
               </Panel>
             </Collapse><div style={{paddingTop:'10px'}}></div></>
          ))}
      </div>
    </div>
  );
};

export default DashboardPage;
