import React from 'react';
import './cardview.scss'
import {Collapse  } from 'antd';
import { Course } from '../../../interfaces/course';
import { Student } from '../../../interfaces/student'
import { studentNotification } from '../../../api/students'
import { humanizeText } from '../../../utils/global';
import TableBase, { Props as TableProps } from './Base';
import dayjs, { Dayjs } from 'dayjs';
import { DATE_FORMAT_FRONTEND } from '../../../shared/constants';
import { Link } from 'react-router-dom';
const { Panel } = Collapse;
import PrimaryButton from '../../../shared/components/buttons/PrimaryButton'; 
type Props = TableProps & {
  handleClickDelete(_: any): void;
  student?: Student;
};


const StudentsCardView: React.FC<Props> = ({ student, handleClickDelete, ...props }) => {


  const handleDelete = (student :any) => {
    handleClickDelete(student)
  };

  const handleTestNotification = (studentId :number,notificationType:string) => {
    const values :any = {
      studentId,
      notificationType
    }
    studentNotification(values)
  };

  return (
    // <div className="card-row">maxWidth:"100%", 
  <div className="col-sm-4"  style={{paddingRight:"1px", width:"100%",paddingLeft:"1px"}}>
    <div className="card-row">
  <div className="card" style={{marginLeft:'10px', minHeight:'635px !important'}}>
  <div className="card-body">
  {student?.gender.toUpperCase() === "FATHER" ? (
    <img src="/boy.png" alt="avatar"
            className="rounded-circle img-fluid" style={{width: '150px'}} />

  ):(
    <img src="/girl.png" alt="avatar"
            className="rounded-circle img-fluid" style={{width: '150px'}} />
  )
}
 </div>
 <h4 className="card-title" style={{textAlign:"center"}}>
 <span>{student?.age}</span><br></br>
 <span>{student?.chineseName}</span><br></br>
 {student?.firstName} {student?.lastName}<br></br>
 <span>{dayjs(student?.dateOfBirth).format(DATE_FORMAT_FRONTEND)}</span><br></br>
 </h4>

 <ul className="list-group list-group-flush">
 <li className="list-group-item">
 {student?.courses?.map((course: Course) => (
    <><span>{course.name}&emsp;[{course.startAt}{course.endAt}]&emsp;{course.day}</span><br></br></>
  ))}
    </li>
 <li className="list-group-item">
  <span>{student?.foundation}</span><br></br>
  <span>{student?.expectation}</span><br></br>
  <span style={{color:'green'}}>{student?.remarks}</span><br></br>
  </li>
  <li className="list-group-item">
  <span>{student?.parents?.email}</span><br></br>
  <span>{student?.parents?.contact}</span><br></br>
  <span>({student?.parents?.address})</span><br></br>
  <span style={{color:'#ff4242'}}>{student?.parents?.emergencyContactRelationship}({student?.parents?.emergencyContact})</span>
  </li>
  </ul>
  <Collapse expandIconPosition="end"  style={{color:'#939ca3 !important'  ,backgroundColor:'#23395d',fontSize:'large'}}>
            {/* <Panel style={{ color: 'snow' , textAlign:'center'}}  key={attendances.id}  className="collapse-custom-header" header={`${attendances.name}<${attendances.startAt}-${attendances.endAt}>`}> */}
            <Panel key={Number(student?.id)} style={{ color: 'snow' , textAlign:'center'}} header={"Actions"} className="collapse-custom-header">
            <div style={{width:"100%"}}>
              <Link style={{width:"100%", marginBottom:"10px"}}to={`/admin/students/${student?.id}`}>
                        <PrimaryButton style={{width:"100%" ,marginBottom:"10px",borderRadius:"20px" }} label="View" />
                  </Link>
                  <Link style={{width:"100%",marginBottom:"10px"}} to={`/admin/students/${student?.id}/edit`}>
                  <PrimaryButton  style={{width:"100%" ,marginBottom:"10px",borderRadius:"20px" }}label="Edit" />
                  </Link>
                  <PrimaryButton style={{width:"100%" ,color:"red" ,borderRadius:"20px" }} label="Delete" onClick={() => handleDelete(student)} />
                  </div>
            </Panel>
            <Panel key={2} style={{ color: 'snow' , textAlign:'center'}} header={"Notification"} className="collapse-custom-header">
            {student?.parents?.mobileToken ?  
            <div style={{width:"100%"}}> 
                  <PrimaryButton style={{width:"100%",lineHeight:"25px" ,marginBottom:"10px",borderRadius:"20px" }} label="Reminder" onClick={() => handleTestNotification(Number(student?.id),'reminder')}/>
                  <PrimaryButton style={{width:"100%",lineHeight:"25px" ,marginBottom:"10px",borderRadius:"20px" }} label="Attended" onClick={() => handleTestNotification(Number(student?.id),'attended')}/>
                  <PrimaryButton  style={{width:"100%",lineHeight:"25px" ,marginBottom:"10px",borderRadius:"20px" }}label="Done" onClick={() => handleTestNotification(Number(student?.id),'done')}/>
                  <PrimaryButton style={{width:"100%",lineHeight:"25px" ,borderRadius:"20px" }} label="Finish" onClick={() => handleTestNotification(Number(student?.id),'finish')} />
                  </div> :  
                  <div style={{width:"100%"}}> 
                  <span>please register parent mobile phone token </span>
                  </div> 
                  }
            </Panel>
        </Collapse>
    {/* <div className="card-body-buttons">
    <Link to={`/admin/students/${student?.id}`}>
           <PrimaryButton  label="View" />
    </Link>
    <Link to={`/admin/students/${student?.id}/edit`}>
    <PrimaryButton  label="Edit" />
     </Link>
    <PrimaryButton  label="Delete" onClick={() => handleDelete(student)} />,
        </div> */}
    </div>
    </div>
</div>
    )
};

export default StudentsCardView;
