import { DatePicker,Form, Input, Select } from 'antd';
import React ,{useState}from 'react';
import {useQuery } from 'react-query';
import { USER_ROLES,DATE_FORMAT_FRONTEND } from '../../../shared/constants';
import ClearIcon from '../../../shared/svgs/ClearIcon';
import SearchIcon from '../../../shared/svgs/SearchIcon';
import debounce from 'lodash/debounce';
import { QueryParams } from '../../../interfaces';
import { Parent } from '../../../interfaces/parent';
import { Student } from '../../../interfaces/student';
import { Course } from '../../../interfaces/course';
import { getStudentsList } from '../../../api/students';
import { getCoursesList } from '../../../api/courses';
import PrimaryButton from '../../../shared/components/buttons/PrimaryButton';
import SecondaryButton from '../../../shared/components/buttons/SecondaryButton';
import dayjs, { Dayjs } from 'dayjs';
const { RangePicker } = DatePicker;
type Props = {
  handleFilter(_: any): void;
  handleReset(): void;
};

interface filterI {
  studentId?: number;
  courseId?: number;
}


const StudentFilterForm: React.FC<Props> = ({ handleFilter, handleReset }) => {
  const [form] = Form.useForm();
  const [queryParams, setQueryParams] = useState<QueryParams>();
  const [filterFields, setFilterFields] = useState<filterI>();
  const { data:StudentsList, isLoading:isStudentFetching } = useQuery(['students', 'list', queryParams], () => getStudentsList({}), {
    staleTime: Infinity,
  });

  const { data:CoursesList, isLoading:isCourseFetching } = useQuery(['courses', 'list', queryParams], () => getCoursesList({}), {
    staleTime: Infinity,
  });

  
  const handleOnFinish = debounce(async () => {
    handleFilter(filterFields);
  }, 800);

  const onCourseChange = (event: any) => {
    // const { value } = event.target;
    setFilterFields({ ...filterFields, courseId: event });
  };
  const onStudentChange = (event: any) => {
    // const { value } = event.target;
    setFilterFields({ ...filterFields, studentId: event });
  };

  const handleResetForm = () => {
    form.resetFields();
    handleReset();
  };

  return (
    <div className="users-filter-form-container filter-form-container">
      <Form form={form} requiredMark={false} colon={false} onFinish={handleOnFinish} scrollToFirstError>
        <div className="form-container">
          <div className="form-content">
                          <Form.Item  name= 'studentId'  >
            <Select placeholder="Student Name" showSearch optionFilterProp="children" loading={isStudentFetching} 
             onChange={(value) => onStudentChange(value)}>
               {StudentsList?.data?.map((student: Student) => (
                                <Select.Option key={student.id} value={student.id}>
                                  {student.chineseName}
                                </Select.Option>
                              ))}
                            </Select >
                          </Form.Item>
                          <Form.Item  name= 'courseId'  >
            <Select placeholder="Course Name" showSearch optionFilterProp="children" loading={isCourseFetching} 
             onChange={(value) => onCourseChange(value)}>
               {CoursesList?.data?.map((courses: Course) => (
                                <Select.Option key={courses.id} value={courses.id}>
                                  {courses.name} {courses.startAt}
                                </Select.Option>
                              ))}
                            </Select >
                          </Form.Item>
          </div>
          <div className="form-footer">
            <PrimaryButton label={'Search'} htmlType="submit" icon={<SearchIcon />} />
            <SecondaryButton label={'Reset'} onClick={handleResetForm} icon={<ClearIcon />} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default StudentFilterForm;
