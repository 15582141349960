import React from 'react';
import { MaterialIn } from '../../../interfaces/materialIn';
import TableBase, { Props as TableProps } from './Base';
import { DateFormat } from '../../../utils/global';
import { Material } from '../../../interfaces/material';
type Props = TableProps & {
  withAction?(_: any): void;
  materialIns?: any[];
};

const MaterialsTransactionsTable: React.FC<Props> = ({ materialIns, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: 'Supplier Source',
      width: 100,
      render: (materialIn: MaterialIn) => {
        return (
          <span className="text-capitalize">
            {materialIn?.supplierSource}
          </span>
        );
      },
    },
    {
      title: 'Price',
      width: 50,
      render: (materialIn: MaterialIn) => {
        return (
          <span className="text-capitalize">
            {materialIn?.price}
          </span>
        );
      },
    },
    {
      title: 'price Per Unit',
      width: 50,
      render: (materialIn: MaterialIn) => {
        return (
          <span className="text-capitalize">
            {materialIn?.pricePerUnit}
          </span>
        );
      },
    },{
      title: 'Delivery Fee',
      width: 50,
      render: (materialIn: MaterialIn) => {
        return (
          <span className="text-capitalize">
            {materialIn?.deliveryFee}
          </span>
        );
      },
    },
    {
      title: 'Quantity',
      width: 50,
      render: (materialIn: MaterialIn) => {
        return (
          <span className="text-capitalize">
            {materialIn?.quantity}
          </span>
        );
      },
    },{
      title: 'remark',
      width: 150,
      render: (materialIn: MaterialIn) => {
        return (
          <span className="text-capitalize">
            {materialIn?.remark}
          </span>
        );
      },
    },{
      title: 'DateIn',
      width: 100,
      render: (materialIn: MaterialIn) => {
        return (
          <span className="text-capitalize">
            {materialIn?.dateIn}
          </span>
        );
      },
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 50,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={materialIns} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default MaterialsTransactionsTable;
