import { Form, Input, Select, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { User } from '../../../../interfaces/user';
import { PASSWORD_TIP, USER_ROLES } from '../../../constants';
import CancelIcon from '../../../svgs/CancelIcon';
import CheckCircleIcon from '../../../svgs/CheckCircleIcon';
import PrimaryButton from '../../buttons/PrimaryButton';
import SecondaryButton from '../../buttons/SecondaryButton';

type Props = {
  isFetching?: boolean;
  userId?: number;
  user?: any;
  handleSubmit(_: any): void;
};

const UserForm: React.FC<Props> = ({ user, userId, isFetching, handleSubmit }) => {
  const [password, setPassword] = useState<any>({});
  const [form] = Form.useForm();
  const passwordTips = PASSWORD_TIP;
  function validatePassword(value: string) {
    const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{12,})');
    if (value === '') {
      return {
        validateStatus: 'error',
        errorMsg: 'Required',
      };
    }

    if (!value || strongRegex.test(value)) {
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    }

    return {
      validateStatus: 'error',
      errorMsg: 'Your password too weak',
    };
  }

  const onPasswordChange = (event: any) => {
    const { value } = event.target;
    setPassword({ ...validatePassword(value), value });
  };

  const handleOnFinish = (values: User) => {
    const newValues: any = values;
    if (userId) {
      delete newValues.password;
    }
    handleSubmit(newValues);
  };

  useEffect(() => {
    if (user && user.id === userId) {
      form.setFieldsValue(user);
    }
  }, [user]);

  return (
    <div className="user-form-container">
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        onFinish={handleOnFinish}
        scrollToFirstError
        autoComplete="off"
        initialValues={{ isActive: true }}
      >
        <div className="form-container">
          <div className="form-content">
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: 'Required' },
              ]}
            >
              <Input type="email" placeholder="Required" autoComplete="off" />
            </Form.Item>
            <Form.Item label="First Name" name="firstName">
              <Input placeholder="Optional" />
            </Form.Item>
            <Form.Item label="Last Name" name="lastName">
              <Input placeholder="Optional" />
            </Form.Item>
            {!userId && (
              <Form.Item
                className="password-input"
                label="Password"
                name="password"
                validateStatus={password.validateStatus}
                help={password.errorMsg || passwordTips}
                rules={[{ required: !userId, message: 'Required' }]}
              >
                <Input
                  type="password"
                  placeholder={userId ? 'Optional' : 'Required'}
                  onChange={onPasswordChange}
                  autoComplete="off"
                />
              </Form.Item>
            )}
            <Form.Item label="Role" name="role" rules={[{ required: true, message: 'Required' }]}>
              <Select placeholder="Required" style={{ width: '100%' }}>
                {USER_ROLES.map((item: any, index: number) => (
                  <Select.Option key={index} value={item.value}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Active" name="isActive" valuePropName="checked">
              <Switch />
            </Form.Item>
          </div>
          <div className="form-footer">
            <PrimaryButton
              loading={isFetching}
              label={userId ? 'Update' : 'Create'}
              icon={<CheckCircleIcon />}
              htmlType="submit"
            />
            <Link to="/admin/users">
              <SecondaryButton label={'Cancel'} icon={<CancelIcon />} />
            </Link>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default UserForm;
