import { message,Collapse } from 'antd';
import React, { useEffect, useState } from 'react';
import './Card.scss';
import { Props as TableProps } from './Base';
import { useQuery, useQueryClient,useMutation } from 'react-query';
import { updateStatus,useGetByStudent} from '../../../../api/classWorks';
import "bootstrap-css-only/css/bootstrap.min.css";
import { BellOutlined,CheckCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { ClassWorks } from '../../../../interfaces/classWorks';
import ClassWorksCardList from './ClassWorksList';
import ReactPlayer from 'react-player'
const { Panel } = Collapse;
const videoType ='mp4'

type Props = TableProps & {
  attendances:any
  studentId:number
  courseId:number
};

const ClassWorksAttendancesList: React.FC<Props> = ({attendances,studentId,courseId,...props }) => {
  const { data: dataClassWorks, isLoading } = useGetByStudent(Number(studentId));

  const [modalData, setModalData] = useState(null);
  const queryClient = useQueryClient();
  const onChange = (key: string | string[],modalData:any,classWorks:any) => {
    const newKey = [key[key.length-1]]
    if(key.length>0){
      setModalData(modalData)
    //  updateClassWorksStatus(classWorks)
    }
  };

  const updateClassWorksStatus = async (classWorks:any)=>{
    if(classWorks.isRead === false){
      const id = classWorks.id
      updateMutate({...classWorks,id})
    }
  };

  const { mutateAsync: updateMutate } = useMutation(updateStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries({
      });
    },
    onError: () => {
      message.error('Update Attendance failed! Please try again');
    },
  });

      return (
        <>
         {/* {attendance.classWorks[0]?.isRead===false ? (expandIcon={({ isActive }) =><BellOutlined/>})} */}
       {dataClassWorks?.map((classwork: ClassWorks) => (
         <Collapse expandIcon={classwork.isRead===false ? (() =><BellOutlined style={{fontSize:"18px",color:"red"}}/>):(() =><CheckCircleOutlined style={{fontSize:"18px",color:"green"}}/>)} 
         expandIconPosition="end" onChange={e => onChange(e, {'studentId':Number(studentId),'courseId':Number(courseId)},{"id":classwork?.id,"isRead":classwork?.isRead})} 
         style={{color:'#939ca3 !important'  ,backgroundColor:'#23395d',fontSize:'large'}}>
          <Panel   style={{ color: 'snow' , textAlign:'center'}} key="1" className="collapse-custom-header" header={`${classwork.date}`}>
               <div className='ant-collapse-content-box'>
                  <ClassWorksCardList 
                  files={classwork.files}                   
                  />
            </div>
          </Panel>
        </Collapse>
         ))}
     </>
      ); 
  }
export default ClassWorksAttendancesList;