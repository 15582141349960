import { pathToUrl } from '../utils/router';
import { TuitionDays } from '../interfaces/tuitionDays';
import { GetListResponse } from '../interfaces';
import { useFetch, usePost } from '../shared/reactQuery';
import { api } from '../shared/api';
import { handleFilterParams } from '../utils/global';
import request from '../utils/request';
export const useGetPaymentsList = () => useFetch<GetListResponse>('payments');


export const getTuitionDaysList = async ({ start, count, filter }: { start?: number; count?: number; filter?: any }) => {
  const newParams = handleFilterParams({
    start,
    count,
    filter,
  });
  const { data: response }: any = await api.get(`tuitionDays?${newParams}`);
  return response;
};
export const getStudentComingTuitionDay = (courseDay: string) => useFetch<TuitionDays>(`tuitionDays/${courseDay}/studentComingTuitionDay`);
export const getYear = async ({ start, count, filter }: { start?: number; count?: number; filter?: any }) => {
  const newParams = handleFilterParams({
    start,
    count,
    filter,
  });
  const { data: response }: any = await api.get(`tuitionDays/year`);
  return response;
};

export const updateTuitionDays = async ({ id, ...values }: { id: string; values: TuitionDays }) => {
  const { data: response }: any = await api.patch(`tuitionDays/${id}`, values);
  return response.data;
};

// export const getStudentComingTuitionDay = async ({ courseDay }: { courseDay: string }) => {
//   const { data: response }: any = await api.get(`tuitionDays/${courseDay}/studentComingTuitionDay`);
//   return response.data;
// };

export const deleteTuitionDays = async (id: number) => {
  const { data: response }: any = await api.delete(`tuitionDays/${id}`);
  return response.data;
};
