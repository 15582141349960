import { Button, message, Spin,Modal,Dropdown } from 'antd';
import React, {useState} from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { DateFormat } from '../../../utils/global';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { deleteStudent, getStudentsList, updateStudent, useGetStudent } from '../../../api/students';
import DangerButton from '../../../shared/components/buttons/DangerButton';
import PrimaryButton from '../../../shared/components/buttons/PrimaryButton';
import SecondaryButton from '../../../shared/components/buttons/SecondaryButton';
import { PAGE_SIZE } from '../../../shared/constants';
import CancelIcon from '../../../shared/svgs/CancelIcon';
import DeleteForeverIcon from '../../../shared/svgs/DeleteForeverIcon';
import EditIcon from '../../../shared/svgs/EditIcon';
import PersonIcon from '../../../shared/svgs/PersonIcon';
import { humanizeText } from '../../../utils/global';
import AddCircleOutlineIcon from '../../../shared/svgs/AddCircleOutlineIcon';
import MaterialsInForm from '../../../shared/components/modal/Student/MaterialsInForm';
import MaterialsTakenListing from '../../../shared/components/modal/Student/MaterialsTakenListing';
import NotificationLogs from './NotificationLogs'

const StudentDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { data: student, isLoading } = useGetStudent(Number(id));

  const { mutateAsync: mutationDelete } = useMutation(deleteStudent, {
    onSuccess: async () => {
      message.info('Deleted!');
      queryClient.invalidateQueries({
        queryKey: ['students', 'list'],
      });
      navigate(`/admin/students`);
    },
    onError: () => {
      message.error('The action is failed! Please try again');
    },
    onSettled: () => {
      queryClient.prefetchQuery({
        queryKey: ['students', 'list', { start: 0, count: PAGE_SIZE, filter: undefined }],
        queryFn: () => getStudentsList({ start: 0, count: PAGE_SIZE, filter: undefined }),
      });
    },
  });
  const [ isEmergencyContactModalVisible, setIsEmergencyContactModalVisible] = useState<boolean>(false);

  const onEmergencyContactCancel = () => {
    setIsEmergencyContactModalVisible(false);
  };

  const onEditClick = () => {
    setIsEmergencyContactModalVisible(true);
  };
  const handleDelete = () => {
    mutationDelete(Number(id));
  };

  const { mutateAsync: updateMutate } = useMutation(updateStudent, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['students', 'list'],
      });
      queryClient.invalidateQueries({
        queryKey: [`students/${id}`, undefined],
      });
      message.info('Unblocked this student!');
    },
    onError: () => {
      message.error('The action is failed! Please try again');
    },
  });

  const handleUnblockStudent = async () => {
    const newValues: any = student;
    newValues.blockedAt = 'null';
    updateMutate({ ...newValues, id });
  };
  const withAction = (record: any) => {
    return (
      <span className="action" style={{ display: 'flex' }}>
        <Dropdown.Button
          overlayClassName="table-action-menu"
          menu={{
            items: [
              {
                key: '1',
                label: (
                  <Link to={`/admin/users/${record?.id}`}>
                    <Button className="btn-table-action-primary" type="link">
                      View
                    </Button>
                  </Link>
                ),
              },
              {
                key: '4',
                label: <DangerButton label="Delete" onOk={() => handleDelete()} />,
              },
            ],
          }}
        ></Dropdown.Button>
      </span>
    );
  };
  

  return (
    <Spin spinning={isLoading}>
      <div id="student-details-container">
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page">Student Details</h2>
            </div>
            <div className="details-container">
              <div className="item">
                <label>Email</label>
                <span className="value">{student?.email}</span>
              </div>
              <div className="item">
                <label>Name</label>
                <span className="value">
                  {student?.firstName} {student?.lastName} ({student?.chineseName})
                </span>
              </div>

              <div className="item">
                <label>Age</label>
                <span className="value">{student?.age}</span>
              </div>

              <div className="item">
                <label>Gender</label>
                <span className="value">{student?.gender}</span>
              </div>

              <div className="item">
                <label>Date Of Birth</label>
                <span className="value"> {DateFormat(student?.dateOfBirth)} </span>
              </div>

              <div className="item">
                <label>Email</label>
                <span className="value">{student?.email}</span>
              </div>

              <div className="item">
                <label>Foundation</label>
                <span className="value">{student?.foundation}</span>
              </div>
              <div className="item">
                <label>Expectation</label>
                <span className="value">{student?.expectation}</span>
              </div>
              <div className="item">
                <label>Remarks</label>
                <span className="value">{student?.remarks}</span>
              </div>

              <div className="item">
                <label>Class Credit</label>
                <span className="value">{student?.creditClass}</span>
              </div>
              
              <div className="item">
                <label>Status</label>
                <span className={`value status ${student?.isActive ? 'active' : 'inactive'}`}>
                  {student?.isActive ? 'Active' : 'Inactive'}
                </span>
              </div>
            </div>
            <div className="page-content-footer">
              {student && student.blockedAt !== null && (
                <PrimaryButton
                  icon={<PersonIcon />}
                  label="Unblock"
                  onClick={handleUnblockStudent}
                  color="secondary-color"
                />
              )}
              <Link to={`/admin/students/${id}/edit`}>
                <PrimaryButton label="Edit" icon={<EditIcon />} />
              </Link>
              <DangerButton label="Delete" onOk={handleDelete} icon={<DeleteForeverIcon />} />
              <Link to={`/admin/students`}>
                <SecondaryButton label="Cancel" icon={<CancelIcon />} />
              </Link>
            </div>
          </div>

          <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page">Parent Details</h2>
            </div>
            <div className="details-container">
              <div className="item">
                <label>Email</label>
                <span className="value">{student?.parents?.email}</span>
              </div>
              <div className="item">
                <label>Name</label>
                <span className="value">
                  {student?.parents?.firstName} {student?.parents?.lastName} ({student?.parents?.chineseName})
                </span>
              </div> 

              <div className="item">
                <label>Contact</label>
                <span className="value">
                {student?.parents?.contact}
                </span>
              </div>
              <div className="item">
                <label>Address</label>
                <span className="value">
                {student?.parents?.address}
                </span>
              </div>
              <div className="item">
                <label>Emergency Contact</label>
                <span className="value">
                {student?.parents?.emergencyContact}
                </span>
              </div>
              <div className="item">
                <label>Emergency Name</label>
                <span className="value">
                {student?.parents?.emergencyName}
                </span>
              </div>
              <div className="item">
                <label>Contact Relationship</label>
                <span className="value">
                {student?.parents?.emergencyContactRelationship}
                </span>
              </div>
            </div>
          </div>
          <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page">Materials In List</h2>
            </div>
            <div className="page-header-actions" style={{flexDirection:'row-reverse',paddingBottom:'10px'}}>
              <SecondaryButton label={'Add Material'} icon={<AddCircleOutlineIcon />} onClick={onEditClick}  />
          </div>
             
             <Modal
        visible={isEmergencyContactModalVisible}
        onOk={onEmergencyContactCancel}
        onCancel={onEmergencyContactCancel}
        footer={null}
        width={736}
          >
            <MaterialsInForm
                rowKey="id"
                studentId={Number(id)}
                handleSubmit={withAction}
                onCloseListing={onEmergencyContactCancel}
                   />
                   
      </Modal>
      <MaterialsTakenListing
                studentId={Number(id)}
                   />
        </div>

        <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page">Notification Logs</h2>
            </div>
            <NotificationLogs
                notification={student?.notificationLogs}
                   />
        </div>
        </div>
      </div>
      
    </Spin>
  );
};

export default StudentDetailsPage;
