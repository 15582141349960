import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useGetProfile } from '../../api/auth';
import { TOKEN_NAME } from '../constants';

const MainLayout: React.FC = () => {
  const token = Cookies.get(TOKEN_NAME);
  let tokenData: any = undefined;
  let isTokenSuccess = false;
  if (token) {
    const { data, isSuccess } = useGetProfile();
    if (data && isSuccess) {
      isTokenSuccess = true;
      tokenData = data;
    }
  }
  const navigate = useNavigate();
  const handleNavigate = (role: string) => {
    switch (role) {
      case 'super_admin':
        navigate('/admin/login');
        break;
      case 'parent':
        navigate('/parent/login');
        break;
        case 'coach':
          navigate('/coach/login');
          break;
      default:
        navigate('/parent/login');
        break;
    }
  };

  useEffect(() => {
    if (token) {
      if (tokenData && isTokenSuccess) {
        handleNavigate(tokenData.role);
      }
    } else {
      navigate('/parent/login');
    }
  }, [tokenData]);

  return <Outlet />;
};

export default MainLayout;
