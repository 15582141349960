import { Button, Dropdown,message, Modal, Spin, Collapse } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { getCoursesList, updateCourse, useGetCourse,deleteStudentFromCourse} from '../../../api/courses';
import { getStudentsList ,deleteStudent} from '../../../api/students';
import { QueryParams } from '../../../interfaces';
import { Student } from '../../../interfaces/student';
import CourseForm from '../../../shared/components/forms/course/Form';
import { getCoachesList } from '../../../api/coaches';
import { PAGE_SIZE } from '../../../shared/constants';
import DangerButton from '../../../shared/components/buttons/DangerButton';
import CourseEnrolledStudents from '../../../shared/components/tables/CourseEnrolledStudents';
import CourseStudentsForEnrollment from '../../../shared/components/modal/CourseStudentsForEnrollment';
import { Link } from 'react-router-dom';
import SecondaryButton from '../../../shared/components/buttons/SecondaryButton';
import AddCircleOutlineIcon from '../../../shared/svgs/AddCircleOutlineIcon';
const { Panel } = Collapse;
const CourseUpdatePage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [queryParams] = useState<QueryParams>({ start: 0, count: PAGE_SIZE });
  const { id } = useParams<{ id: string }>();
  const { data: courseData, isLoading } = useGetCourse(Number(id));
  const { data: coachesData, isFetching: isCoachFetching } = useQuery(['coaches', 'list'], () => getCoachesList({}));
  const { data: studentsData, isFetching: isStudentFetching } = useQuery(['students', 'list', queryParams], () => getStudentsList(queryParams), {
    staleTime: Infinity,
  });

  const currentPage = queryParams.start ? Math.round((Number(queryParams.start) - 1) / PAGE_SIZE + 1) : 1;
  const pagination: any = {
    current: currentPage,
    pageSize: PAGE_SIZE,
    total: studentsData?.total || 0,
    showTotal: (total: string) => `${total} Items`,
  };

  // const paginationStudents: any = {
  //   current: currentPage,
  //   pageSize: PAGE_SIZE,
  //   total: studentsData?.students.total || 0,
  //   showTotal: (total: string) => `${total} Items`,
  // };
  const [ isEmergencyContactModalVisible, setIsEmergencyContactModalVisible] = useState<boolean>(false);

  const onEmergencyContactCancel = () => {
    setIsEmergencyContactModalVisible(false);
  };

  const onEditClick = () => {
    setIsEmergencyContactModalVisible(true);
  };
 
  const { mutateAsync: updateMutate } = useMutation(updateCourse, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['courses', 'list'],
      });
      queryClient.invalidateQueries({
        queryKey: [`courses/${id}`, undefined],
      });
      message.info('Update course successfully!');
      navigate(`/admin/courses`);
    },
    onError: () => {
      message.error('Update course failed! Please try again');
    },
    onSettled: () => {
      queryClient.prefetchQuery({
        queryKey: ['courses', 'list', { start: 0, count: PAGE_SIZE, filter: undefined }],
        queryFn: () => getCoursesList({ start: 0, count: PAGE_SIZE, filter: undefined }),
      });
    },
  });

  // const { mutateAsync: mutationUpdate } = useMutation(deleteStudentFromCourse);

  const handleClickDelete = async (courseId : number , studentId : number) => {
    await deleteStudentFromCourse(courseId,studentId);
    queryClient.invalidateQueries({
      queryKey: ['students', 'list'],
    });
  };

  const handleSubmit = async (values: any) => {
    updateMutate({ ...values, id });
  };

  const withAction = (record: any) => {
    return (
      <span className="action" style={{ display: 'flex' }}>
        <Dropdown.Button
          overlayClassName="table-action-menu"
          menu={{
            items: [
              {
                key: '1',
                label: (
                  <Link to={`/admin/students/${record?.id}`}>
                    <Button className="btn-table-action-primary" type="link">
                      View
                    </Button>
                  </Link>
                ),
              },
              {
                key: '4',
                label: <DangerButton label="Delete" onOk={() => handleClickDelete(Number(id),Number(record?.id))} />,
              },
            ],
          }}
        ></Dropdown.Button>
      </span>
    );
  };

  return (
    <Spin spinning={isLoading}>
      <div className="users-container">
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page">Edit Course</h2>
            </div>
            <CourseForm 
            handleSubmit={handleSubmit} 
            course={courseData} 
            courseId={Number(id)} 
            coachesList={coachesData?.data}
            isCoachFetching={isCoachFetching}
            />
          </div>
          <Collapse expandIconPosition="end" style={{color:'#939ca3 !important'  ,backgroundColor:'#23395d', alignItems:'self-end',fontSize:'large', borderRadius: '36px'}}>
            <Panel header="Students" key="1" className="collapse-custom-header">
            <div className="page-header">
          <div></div>
          <div className="page-header-actions">
              <SecondaryButton label={'Add Student'} icon={<AddCircleOutlineIcon />} onClick={onEditClick}  />
          </div>
        </div>
            <CourseEnrolledStudents
                rowKey="id"
                loading={isLoading}
                // pagination={paginationStudents}
                enrolledStudentsData={courseData?.students}
                withAction={withAction}
              />
            </Panel>
          </Collapse>
          <Modal
        visible={isEmergencyContactModalVisible}
        onOk={onEmergencyContactCancel}
        onCancel={onEmergencyContactCancel}
        footer={null}
        width={536}
          >
            <CourseStudentsForEnrollment
                rowKey="id"
                loading={isStudentFetching}
                pagination={pagination}
                studentsData={studentsData?.data}
                withAction={withAction}
                courseId={Number(courseData?.id)} 
              />
      </Modal>
        </div>
      </div>
    </Spin>
  );
};

export default CourseUpdatePage;
