import React from 'react';
import { Layout } from 'antd';
import { LoginResponseI } from '../../../interfaces/auth';
import CoachHeaderMain from './CoachHeaderMain';
import { Outlet } from 'react-router-dom';
import CoachSiderContent from '../siderContents/Coach';

type Props = {
  currentUser: LoginResponseI;
};

const CoachLayout: React.FC<Props> = ({ currentUser }) => {
  return (
    <Layout className="database-layout">
      <CoachSiderContent />
      <Layout className="site-layout">
        <CoachHeaderMain currentUser={currentUser} />
        <Outlet />
      </Layout>
    </Layout>
  );
};

export default CoachLayout;
