import React from 'react';
import { Form,Collapse,Spin  } from 'antd';
import { Course } from '../../../interfaces/course';
import { Student } from '../../../interfaces/student';
import { humanizeText } from '../../../utils/global';
import './Card.scss';
import { DATE_FORMAT_FRONTEND, PAGE_SIZE } from '../../../shared/constants';
import {useGetCourseAttendances } from '../../../api/courses';
import { Attendances } from '../../../interfaces/attendances';
import TableBase, { Props as TableProps } from './Base';
import CourseStudentAttendancesListView from './CourseStudentAttendancesListView'
import { Link } from 'react-router-dom';
import PrimaryButton from '../../../shared/components/buttons/PrimaryButton';
const { Panel } = Collapse;
import dayjs, { Dayjs } from 'dayjs';

type Props = TableProps & {
  // withAction?(_: any): void;
  courseId?: number;
};

const AttendancesListCardView: React.FC<Props> = ({ courseId, ...props }) => {
  const { data: courseAttendances, isLoading } = useGetCourseAttendances(Number(courseId));
  
  let [openPanels, setOpenPanels] = React.useState<any>([]);
 const handleDelete = (student :any) => {
    // handleClickDelete(student)
  };

  const onChange = (key: string | string[],modalData:any) => {
    const newKey = [key[key.length-1]]
    setOpenPanels([]) //close all
    setOpenPanels(newKey) //open selected
    if(key.length>0){
      // setModalData(modalData) 
      // console.log(modalData)
    }
  };
  
    return (  <Spin spinning={isLoading}>
      {courseAttendances?.attendances.map((attendances: Attendances) => (
        <Collapse expandIconPosition="end"  style={{color:'#939ca3 !important'  ,backgroundColor:'#23395d',fontSize:'large'}}>
            {/* <Panel style={{ color: 'snow' , textAlign:'center'}}  key={attendances.id}  className="collapse-custom-header" header={`${attendances.name}<${attendances.startAt}-${attendances.endAt}>`}> */}
            <Panel style={{ color: 'snow' , textAlign:'center'}} header={dayjs(attendances.date).format(DATE_FORMAT_FRONTEND)} key={Number(attendances?.id)} className="collapse-custom-header">
            <CourseStudentAttendancesListView
              attendancesId={attendances?.id}
              editable={false} />
            </Panel>
        </Collapse>
      ))}
     </Spin> 
  )
 
};

export default AttendancesListCardView;
