import { Button, Dropdown, Menu, message } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { deleteCourse, getCoursesList, updateCourse } from '../../../api/courses';
import { QueryParams } from '../../../interfaces';
import { Course } from '../../../interfaces/course';
import DangerButton from '../../../shared/components/buttons/DangerButton';
import PrimaryButton from '../../../shared/components/buttons/PrimaryButton';
import CoursesFilterForm from './CoursesFilterForm';
import CoursesCardView from './CoursesCardView';
import { PAGE_SIZE } from '../../../shared/constants';
import AddCircleOutlineIcon from '../../../shared/svgs/AddCircleOutlineIcon';

const CoursesPage: React.FC = () => {
  const queryClient = useQueryClient();
  const [queryParams, setQueryParams] = useState<QueryParams>({ start: 0, count: PAGE_SIZE });

  const { data, isLoading } = useQuery(['courses', 'list', queryParams], () => getCoursesList(queryParams), {
    staleTime: Infinity,
  });

  const currentPage = queryParams.start ? Math.round((Number(queryParams.start) - 1) / PAGE_SIZE + 1) : 1;

  const pagination: any = {
    current: currentPage,
    pageSize: PAGE_SIZE,
    total: data?.total || 0,
    showTotal: (total: string) => `${total} Items`,
  };

  const handleFilter = (params: any) => {
    const currentPage = params.page;
    const offset = currentPage ? (currentPage === 1 ? 0 : (Number(currentPage) - 1) * PAGE_SIZE) : 0;
    setQueryParams({ start: Number(offset), count: PAGE_SIZE });
  };

  const { mutateAsync: mutationDelete } = useMutation(deleteCourse);

  const handleDelete = async (course: Course) => {
    await mutationDelete(course.id);
    queryClient.invalidateQueries({
      queryKey: ['courses', 'list'],
    });
  };

  const handleFilterSubmit = (values: any) => {
    setQueryParams({ start: 0, count: PAGE_SIZE, filter: values });
  };

  const handleResetFilter = () => {
    setQueryParams({ start: 0, count: PAGE_SIZE, filter: undefined });
  };

  const { mutateAsync: updateMutate } = useMutation(updateCourse, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['courses', 'list'],
      });
      message.info('Unblocked this course.');
    },
    onError: () => {
      message.error('Update course failed! Please try again');
    },
  });


  return (
    <div className="courses-container">
      <div className="page-container">
      <div className="page-content-header">
          <h2 className="title-page">Courses Listing</h2>
        </div>
        <div className="page-header">
          <div></div>
          <div className="page-header-actions">
            <Link to="/admin/courses/new">
              <PrimaryButton label="Create Course" icon={<AddCircleOutlineIcon />} />
            </Link>
          </div>
        </div>
        <div className="page-content">
          <CoursesFilterForm handleFilter={handleFilterSubmit} handleReset={handleResetFilter} />
          </div>
          <div className="page-content-card">
          {data?.data?.map((course: Course) => (
          <CoursesCardView
            rowKey="id"
            loading={isLoading}
            pagination={pagination}
            course={course}
            handleClickDelete={handleDelete}
          />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CoursesPage;
